@import '_mixins';

html {
    &.lock {
        overflow: hidden;
    }

    * {
        box-sizing: border-box;
    }

    .Mobile {
        // main, loading 페이지에서 사용
        .global-logo {
            display: flex;
            width: 100%;
            padding: 0 getPercent(1920, 89);

            div {
                position: relative;
                overflow: hidden;
                flex-shrink: 0;

                svg {
                    width: 100%;
                    height: auto;
                }

                // a
                &:nth-child(1) {
                    width: getPercent(1742, 234.953);
                    margin-top: getPercent(1742, 92.5);
                }
                // p
                &:nth-child(2) {
                    width: getPercent(1742, 235.132);
                    margin-left: getPercent(1742, 54.62);
                    margin-top: getPercent(1742, 92.5);
                }
                // p
                &:nth-child(3) {
                    width: getPercent(1742, 235.042);
                    margin-left: getPercent(1742, 37.61);
                    margin-top: getPercent(1742, 92.5);
                }
                // k
                &:nth-child(4) {
                    width: getPercent(1742, 219.821);
                    margin-left: getPercent(1742, 37.88);
                }
                // n
                &:nth-child(5) {
                    width: getPercent(1742, 208.359);
                    margin-left: getPercent(1742, 26.77);
                    margin-top: getPercent(1742, 92.5);
                }
                // o
                &:nth-child(6) {
                    width: getPercent(1742, 241.221);
                    margin-left: getPercent(1742, 36.98);
                    margin-top: getPercent(1742, 92.5);
                }
                // t
                &:nth-child(7) {
                    width: getPercent(1742, 164.948);
                    margin-left: getPercent(1742, 9.85);
                }
            }
        }

        .Gnb {
            z-index: 9;
            width: 100vw;
            color: #000;
            position: fixed;
            top: 0;
            left: 0;

            & + .logo {
                position: fixed;
                top: getVw(10px);
                left: getVw(10px);
                mix-blend-mode: difference;
                z-index: 80;
            }

            .btn-menu {
                position: absolute;
                top: getVw(10px);
                right: getVw(10px);
                display: inline-block;
                padding: getVw(10px) getVw(20px);
                border-radius: getRem(10px);
                border: 1px solid rgba(161, 161, 161, 0.4);
                background-color: rgba(101, 101, 101, 0.6);
                @include blur(10px);
                @include fontStyle(11px, 300, 1.2, -0.22px);
                color: #fff;
            }

            .menu-wrap {
                &::before {
                    content: '';
                    display: block;
                    position: fixed;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.7);
                    @include blur(10px);
                    z-index: 1;
                }
                .menu {
                    position: relative;
                    width: 100%;
                    background-color: #1e1e1e;
                    padding: getPercent(390, 20) getPercent(390, 14) getPercent(390, 30);
                    z-index: 2;
                    border-radius: 0px 0px getRem(10px) getRem(10px);

                    .btn-close {
                        position: absolute;
                        top: getVw(10px);
                        right: getVw(10px);
                        display: inline-block;
                        padding: getVw(10px) getVw(20px);
                        background-color: transparent;
                        border: 1px solid transparent;
                        @include fontStyle(11px, 300, 1.2, -0.22px);
                        color: #fff;
                    }

                    address {
                        @include fontStyle(11px, 300, 16.5px, -0.55px);
                        color: #fff;
                    }
                    .tel {
                        @include fontStyle(11px, 300, 16.5px, -0.55px);
                        color: #fff;
                        margin-top: getRem(4px);
                    }
                    svg {
                        display: block;
                        width: 100%;
                        margin-top: getPercent(844, 25);
                    }
                    nav {
                        margin-top: getPercent(390, 141);
                        ul {
                            li,
                            a {
                                font-family: $font-en;
                                @include fontStyle(45px, 300, 57px);
                                color: #fff;
                                text-decoration: none;
                                text-transform: uppercase;
                            }
                        }
                    }

                    .copy-right {
                        font-family: $font-en;
                        @include fontStyle(11px, 300);
                        text-transform: uppercase;
                        color: #fff;
                        margin-top: getPercent(844, 121);
                    }
                }
            }

            &.white {
                .header-container {
                    .full {
                        li {
                            a {
                                color: #fff;
                            }
                        }
                    }

                    .logo {
                        svg {
                            path {
                                fill: #fff;
                            }
                        }
                    }

                    .copy-right {
                        color: #fff;
                    }
                }
            }

            &.on {
                .header-container {
                    .logo {
                        > svg {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }

                    .nav {
                        .bg {
                            opacity: 1;
                            transform: translateY(0) !important;
                        }
                    }

                    ul {
                        &.full {
                            width: getPercent(1920, 416);

                            li {
                                opacity: 0;
                                transform: none;
                            }
                        }

                        &:not(.full) {
                            opacity: 1;
                            visibility: visible;
                        }
                    }

                    .copy-right {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }

            .header-container {
                position: relative;
                width: 100%;
                display: flex;
                justify-content: center;

                .logo {
                    position: absolute;
                    left: getPercent(1920, 30);
                    top: 50%;
                    transform: translateY(-50%);
                    box-sizing: border-box;
                    width: getPercent(1920, 80);

                    > svg {
                        width: 100%;
                        height: 100%;
                        transform: translateY(-100%);
                        transition: transform 1.3s, opacity 1.3s;
                        opacity: 0;

                        path {
                            transition: fill 1.3s;
                        }
                    }
                }

                .nav {
                    position: relative;
                    margin: getRem(20) 0;
                    padding: 0 getPercent(1920, 5);
                    transition: width 0.5s cubic-bezier(0.4, 0, 0.2, 1);

                    .bg {
                        opacity: 0;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        position: absolute;
                        border-radius: 10px;
                        border: 1px solid #a1a1a166;
                        background: linear-gradient(#a1a1a166, #65656566);
                        @include blur(10px);
                        transform: translateY(-100%);
                        transition: transform 0.5s, opacity 0.2s;
                    }
                }

                ul {
                    display: flex;
                    justify-content: space-between;

                    &:not(.full) {
                        position: relative;
                        z-index: 1;
                        opacity: 0;
                        visibility: hidden;
                        transition: opacity 0.3s;
                        transition-delay: 0.2s;

                        li {
                            a {
                                color: #fff;
                            }
                        }
                    }

                    &.full {
                        position: absolute;
                        width: 100%;
                        left: 50%;
                        top: 0;
                        transform: translate(-50%, 0);
                        transition: width 0.5s cubic-bezier(0.4, 0, 0.2, 1);
                        li {
                            transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1),
                                opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1);
                            transform: translate3d(0, 20px, 0);
                        }
                    }

                    li {
                        a {
                            display: block;
                            height: getRem(38px);
                            color: #000;
                            text-decoration: none;
                            font-family: $font-en;
                            @include fontStyle(14px, 300, 17.75px, -0.02em);
                            line-height: getRem(38px);
                            transition: color 0.5s;
                            padding: 0 getRem(15px);
                        }
                    }
                }

                .copy-right {
                    opacity: 0;
                    transform: translateY(-100%);
                    position: absolute;
                    right: 0;
                    top: getRem(30);
                    transition: opacity 0.7s, transform 1.3s, color 0.7s;
                }
            }
        }

        .MainPage {
            position: relative;
            .MainSectionIntro {
                z-index: 2;
                position: fixed;
                top: 0;
                width: 100vw;
                height: calc(var(--vh, 1vh) * 100);

                & ~ * {
                    position: relative;
                    z-index: 2;
                }

                .MatterJs {
                    width: 100%;
                    -ms-overflow-style: none; /* IE and Edge */
                    scrollbar-width: none; /* Firefox */
                    ::-webkit-scrollbar {
                        display: none; /* Chrome, Safari, Opera*/
                    }
                    #canvas {
                        border: none !important;
                        width: 100%;
                    }
                }
            }

            .MainIntroText {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                z-index: 3;
                width: 100%;
                height: calc(var(--vh, 1vh) * 100);
                pointer-events: none;
                padding-bottom: getPercent(390, 18);

                .main-text-wrap {
                    text-align: center;
                    width: 100%;
                }

                .what-we-do {
                    position: absolute;
                    font-family: $font-en;
                    @include fontStyle(11px, 300, 13px, -0.02em);
                    color: #000;
                    text-align: left;
                    left: getPercent(390, 150);
                    bottom: getPercent(844, 267);
                    text-transform: uppercase;

                    > div {
                        overflow: hidden;

                        > span {
                            display: block;
                        }
                    }
                }

                .greeting-msg {
                    position: absolute;
                    font-family: $font-en;
                    @include fontStyle(16px, 300, 20.8px, -0.32px);
                    color: #000;
                    text-transform: uppercase;
                    margin-top: getPercent(1920, -19);
                    left: 50%;
                    transform: translate(-50%, -100%);
                }

                .global-logo {
                    position: relative;
                    transform-origin: left top;
                    transition: transform 1.3s;

                    path {
                        transition: fill 0.5s;
                    }

                    &.white {
                        svg {
                            path {
                                fill: #fff;
                            }
                        }
                    }
                }
            }

            .MainSectionAbout {
                width: 100%;
                .contents-wrap {
                    width: 100%;
                    padding-bottom: getPercent(390, 150);
                    background-color: #000;

                    .about-bg-container {
                        width: 100%;
                        position: sticky;
                        top: 0;
                        z-index: 2;
                        .about-bg {
                            img {
                                display: block;
                                width: 100%;
                                will-change: transform;
                            }
                        }
                    }

                    .about-contents {
                        width: 100%;

                        .contents {
                            position: relative;
                            z-index: 3;
                            margin-top: $vh-100;

                            .slogan {
                                width: 100%;
                                overflow: hidden;
                                z-index: 3;
                                mix-blend-mode: difference;
                                margin-top: getPercent(390, 151);

                                .marquee-wrap {
                                    display: flex;
                                    animation: marquee 25s linear infinite forwards;
                                    width: fit-content;

                                    .slogan-text {
                                        position: relative;
                                        width: fit-content;
                                        flex-shrink: 0;
                                        @include fontStyle(170px, 500, 130%, 0);
                                        font-size: getVw(170px);
                                        text-transform: uppercase;
                                        color: #393939;
                                        padding: 0 getRem(20px);
                                        font-family: $font-en;
                                    }
                                }

                                @keyframes marquee {
                                    0% {
                                        transform: translateX(0);
                                    }
                                    100% {
                                        transform: translateX(-50%);
                                    }
                                }
                            }

                            .Typography {
                                &.unleashing {
                                    margin-top: getPercent(390, 91);
                                    margin-left: getPercent(390, 37);
                                }

                                &.innovation {
                                    margin-top: getPercent(390, -19);
                                    margin-left: getPercent(390, 9);
                                }

                                &.with-a {
                                    margin-top: getPercent(390, 215);
                                    margin-left: getPercent(390, 65);
                                }

                                &.touch-of {
                                    margin-top: getPercent(390, -19);
                                    margin-left: getPercent(390, 101);
                                }

                                &.geekiness {
                                    margin-top: getPercent(390, -19);
                                    margin-left: getPercent(390, 9);
                                }
                            }

                            .small-msg-01 {
                                margin-top: getVh(450px);
                                padding: getVw(14px);
                                .en {
                                    .Typography {
                                        &:nth-child(1) {
                                            margin-left: getPercent(390, 54);
                                        }
                                        &:nth-child(4) {
                                            margin-left: getPercent(390, 46);
                                        }
                                    }
                                }

                                .ko {
                                    margin-left: getPercent(390, 166);
                                }
                            }

                            .small-msg-02 {
                                margin-top: getPercent(390, 450);
                                padding: 0 getVw(14px);
                            }
                        }
                    }
                }
            }

            .video-section {
                background-color: #000;
                padding-bottom: getPercent(390, 324);

                height: 200vh;

                .about-video {
                    width: 100%;
                    position: sticky;
                    top: 0;
                    padding: getPercent(390, 14);

                    .video-wrap {
                        position: relative;
                        width: 100%;
                        height: getVw(535px);
                        border-radius: 15px;
                        overflow: hidden;
                        transform: translateZ(0);

                        video {
                            position: absolute;
                            top: 50%;
                            left: 0;
                            width: 100%;
                            height: 140%;
                            object-fit: cover;
                            transform: translateY(-50%);
                        }
                    }

                    a {
                        text-decoration: none;
                        .video-text {
                            font-family: $font-en;
                            @include fontStyle(11px, 300, 1.2);
                            color: #fff;
                            text-transform: uppercase;
                            margin-top: getRem(10);
                        }
                    }
                }
            }

            .MainSectionWork {
                background-color: #000;

                .section-title {
                    text-transform: uppercase;
                    font-family: $font-en;
                    text-align: center;
                    color: #fff;
                    z-index: 1;

                    .Typography {
                        @include fontStyle(65px, 300, 65px, -0.02em);
                    }
                }

                .work-list {
                    width: 100%;
                    padding: 0 getPercent(390, 14);
                    margin-top: getPercent(390, 308);

                    li.WorkList {
                        width: 100%;
                        flex-shrink: 0;

                        & ~ .WorkList {
                            margin-top: getRem(50px);
                        }

                        a {
                            text-decoration: none;
                        }

                        .thumb-nail {
                            border-radius: getRem(15);
                            overflow: hidden;
                            img {
                                display: block;
                                width: 100%;
                            }
                        }

                        .work-text {
                            margin-top: getRem(14px);
                            color: #fff;

                            h4 {
                                @include fontStyle(13px, 700, 120%, -0.65px);
                            }

                            span {
                                display: block;
                                @include fontStyle(11px, 300, 120%, -0.22px);
                                margin-top: 4px;
                                font-family: $font-en;
                                text-transform: uppercase;
                            }
                        }
                    }
                }

                .btn-view-all {
                    display: block;
                    position: relative;
                    width: getPercent(390, 362);
                    padding: getPercent(390, 20) 0;
                    margin: getPercent(390, 70) auto 0;
                    font-family: $font-en;
                    @include fontStyle(11px, 300, 1.2, -0.02em);
                    text-transform: uppercase;
                    color: #fff;
                    text-align: center;
                    text-decoration: none;
                    border: 1px solid #505050;
                    border-radius: getRem(10);
                    overflow: hidden;
                    isolation: isolate;
                }
            }

            .MainSectionInsight {
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 2;
                background-color: #000;
                padding: getPercent(390, 300) 0 getPercent(390, 200);
                gap: getVw(200px) 0;

                > h3 {
                    position: sticky;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 1;
                    width: 100%;
                    text-transform: uppercase;
                    text-align: center;
                    margin-top: getPercent(390, 100);

                    > small {
                        font-family: $font-en;
                        @include fontStyle(20px, 300, 22px);
                        color: #fff;
                    }

                    > .Typography {
                        display: block;
                        font-family: $font-en;
                        @include fontStyle(260px, 300, 286px);
                        font-size: 13.542vw;
                        color: #fff;

                        & ~ .Typography {
                            margin-top: getPercent(390, 191);
                        }
                    }
                }
                .card {
                    position: sticky;
                    width: getPercent(390, 200);
                    transition: transform 0.4s;
                    will-change: transform;
                    bottom: getPercent(390, 140);

                    img {
                        display: block;
                        width: 100%;
                        @include blur(15px);
                        border-radius: getRem(15px);
                        filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.7));
                    }

                    &.handly {
                        z-index: 3;
                        transform: rotate(7deg);
                    }

                    &.popee {
                        z-index: 2;
                        transform: rotate(-18.616deg);
                    }

                    &.mentor {
                        z-index: 1;
                        transform: rotate(-5deg);
                    }
                    &.empty {
                        height: getVw(200px);
                    }
                }
            }

            .MainSectionRecruit {
                z-index: 2;
                padding-top: 0;
                background-color: #000;
                color: #fff;
                transition: background-color 0.6s ease-out, color 0.6s ease-out;
                overflow: hidden;
                padding-bottom: getPercent(390, 40);

                &.on {
                    background-color: #fff;
                    color: #000;
                }

                .recruit-top {
                    position: relative;
                    text-transform: uppercase;
                    padding-bottom: getPercent(390, 70);

                    .line {
                        position: absolute;
                        background-color: #dcdcdc;
                        pointer-events: none;
                        z-index: -1;

                        &.horizontal {
                            width: 100%;
                            height: 1px;
                            left: 0;
                            top: getPercent(844, 514);
                        }
                        &.vertical {
                            width: 1px;
                            height: 100%;
                            left: getPercent(390, 302);
                            top: 0;
                        }
                    }

                    .recruit-msg {
                        position: relative;
                        padding-top: getPercent(1920, 137);
                        font-family: $font-en;
                        @include fontStyle(40px, 300, 48px);
                        padding-left: getPercent(1920, 47);
                        margin-left: 0;

                        .row {
                            display: flex;
                            align-items: center;
                        }
                        .emoji-alien {
                            display: inline-block;
                            width: getRem(40px);
                        }
                    }

                    .recruit-tag {
                        position: absolute;
                        top: getPercent(390, 254);
                        left: getPercent(390, 182);
                        transform: rotate(-18.4deg);
                        border-radius: 30px;
                        background-color: #000000;
                        @include fontStyle(12px, 500, 16.8px, -0.48px);
                        color: #fff;
                        padding: getVw(15px) getVw(26px);
                        text-align: center;
                        font-family: $font-ko;
                        transition: transform 0.5s;
                        z-index: 2;

                        &:hover {
                            transform: rotate(12.45deg);
                        }

                        .emoji-point {
                            display: inline-block;
                            width: getRem(12px);
                            vertical-align: middle;
                            transform: rotate(40deg);
                            margin-left: 3px;
                            margin-top: -2px;
                        }
                    }

                    .recruit-job {
                        display: flex;
                        gap: 0 getRem(30px);
                        justify-content: flex-end;
                        padding-right: getPercent(390, 27);
                        margin-top: getPercent(390, 117);
                        font-family: $font-en;
                        @include fontStyle(11px, 300, 13.2px);
                    }
                }

                .slide-wrap {
                    transform: rotate(-4deg);
                    .swiper {
                        overflow: visible !important;
                    }
                    .slide-content {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        background-color: #d3d3d3;
                        border-radius: getRem(10px);
                        @include reactive;

                        &::after {
                            padding-bottom: 100%;
                        }

                        .slide-img {
                            width: getPercent(250, 135);
                            img {
                                display: block;
                                width: 100%;
                            }
                        }

                        .slide-text {
                            position: absolute;
                            top: 0;
                            left: 0;
                            padding: getRem(12px);
                            @include fontStyle(11px, 300, 13.2px);
                            text-transform: uppercase;
                            color: #000;
                        }
                    }
                }
            }
            
        }

        .BlockPage {
            background-color: #000;
            height: calc(var(--vh, 1vh) * 100);
            overflow: hidden;

            .text-container {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                margin-top: getPercent(390, 211);
                padding: 0 getPercent(390, 14);

                .en {
                    width: 100%;
                    .Typography {
                        width: 100%;
                        font-family: $font-en;
                        @include fontStyle(40px, 300, 52px);
                        text-transform: uppercase;

                        &.right {
                            text-align: right;
                        }

                        & ~ .Typography {
                            margin-top: getRem(-12px);
                        }
                    }
                }

                .ko {
                    margin-top: getPercent(390, 12);
                    margin-left: getPercent(390, 184);
                    .Typography {
                        font-family: $font-ko;
                        @include fontStyle(13px, 300, 16.9px, -0.65px);
                    }
                }
            }
        }

        //about page
        .AboutPage {
            contain: paint;
            .AboutSectionIntro {
                position: relative;
                padding-top: calc(calc(var(--vh, 1vh) * 100) + #{getPercent(1080, 41)});

                .bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: getVh(357px);
                        background: linear-gradient(180deg, #000 17.46%, rgba(0, 0, 0, 0) 98.6%);
                        mix-blend-mode: multiply;
                        transform: rotate(180deg);
                    }

                    img {
                        object-fit: cover;
                    }
                }

                img {
                    display: block;
                    width: 100%;
                }

                .intro-text-container {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;

                    .solution-entry-text {
                        color: #fff;
                        font-family: $font-en;

                        &.text-01 {
                            width: fit-content;
                            margin-top: getVh(105px);
                            padding: getVw(14px);
                            width: 100%;

                            .text-en {
                                @include fontStyle(20px, 300, 130%, -0.4px);
                                text-transform: uppercase;

                                &.center{
                                    padding-left: getRem(50px);
                                }
                            }
                            .text-ko {
                                width: 100%;
                                margin-left: 0;
                                margin-top: getRem(29px);

                                .Typography {
                                    font-family: $font-ko;
                                    @include fontStyle(13px, 300, 19.6px, -0.65px);

                                    &.padding-right{
                                        padding-right: getRem(17px);
                                    }
                                }
                            }
                        }
                        &.text-02 {
                            margin-top: getVh(105px);
                            margin-left: getVw(24px);
                            .about {
                                @include fontStyle(65px, 300, 110%);
                            }
                        }
                    }
                }

                .history-container {
                    width: 100%;
                    height: 200vh;
                    background-color: #000;
                    .year {
                        position: sticky;
                        top: getVh(148px);
                        font-family: $font-en;
                        @include fontStyle(177px, 400, 120%);
                        font-size: getVw(177px);
                        text-transform: uppercase;
                        color: #fff;
                        text-align: center;
                        mix-blend-mode: difference;
                        height: $vh100;
                    }

                    .contents {
                        position: sticky;
                        top: 0;
                        padding-top: getVh(154px);
                        height: $vh100;
                        .img-ipad {
                            position: relative;
                            width: getPercent(390, 362);
                            margin: 0 auto;
                            &::after {
                                content: '';
                                display: block;
                                position: absolute;
                                top: getPercent(576, 19.34);
                                left: getPercent(576, 19.34);
                                width: getPercent(576, 534);
                                height: getPercent(576, 534);
                                background-color: #1f1f1f;
                                border-radius: getRem(8px);
                                transition: opacity 0.2s;
                            }

                            &.on {
                                &::after {
                                    opacity: 0;
                                }
                            }

                            .screen {
                                position: absolute;
                                top: getPercent(576, 22);
                                left: getPercent(576, 19.34);
                                width: getPercent(576, 534);
                                border-radius: getRem(8px);
                                overflow: hidden;

                                .slide-wrap {
                                    display: flex;
                                    transition: transform 0.4s;
                                }

                                img {
                                    display: block;
                                    width: 100%;
                                    object-fit: cover;
                                    flex-shrink: 0;
                                }
                            }
                        }

                        .text-container {
                            width: 100%;
                            color: #fff;
                            text-align: center;
                            margin-top: getPercent(390, 20);
                            opacity: 0;
                            transition: opacity 1s;
                            @include fontStyle(17px, 400, 140%, -0.51px);

                            > p {
                                width: 100%;
                                flex-shrink: 0;
                            }

                            &.on {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .AboutSectionAbout {
                width: 100vw;

                .container {
                    position: sticky;
                    top: getVh(-468px);
                    width: 100vw;
                    // overflow: hidden;
                    .scroll-wrap {
                        display: flex;
                        width: fit-content;

                        .scroll-section {
                            position: relative;
                            flex-shrink: 0;
                            min-width: 100vw;
                            height: getVh(1312px);

                            &.section-01 {
                                position: absolute;
                                width: 100vw;
                                height: getVh(1312px);
                                background-color: #000;
                                overflow: hidden;

                                & ~ section {
                                    margin-top: getVh(468px);
                                    height: $vh100;
                                }

                                .text-container {
                                    position: relative;
                                    margin-left: getVw(14px);
                                    margin-top: getVh(70px);
                                    align-self: flex-end;

                                    .typo {
                                        position: relative;
                                        font-family: $font-en;
                                        > div {
                                            display: inline-block;
                                            @include fontStyle(53px, 300, 47.7px,0);
                                            text-transform: uppercase;
                                            color: #fff;
                                        }
                                    }
                                    .typo-tag {
                                        position: absolute;
                                        top: getRem(68px);
                                        left: getRem(139px);
                                        padding: getRem(9.69px) getRem(13px);
                                        background-color: #7a7a7a;
                                        border-radius: 100px;
                                        transform: rotate(0);
                                        opacity: 0;
                                        transition: opacity 0.8s;

                                        &:hover {
                                            transform: rotate(-8.47deg);
                                        }

                                        &.on{
                                            opacity: 1;
                                            transition-delay: 1.8s;
                                        }

                                        span {
                                            font-family: $font-en;
                                            display: inline-block;
                                            @include fontStyle(12px, 500, 16.8px);
                                            color: #fff;
                                            text-transform: uppercase;
                                        }
                                        img {
                                            display: inline-block;
                                            width: getRem(16px);
                                            vertical-align: middle;
                                        }
                                    }

                                    .text-container {
                                        width: getRem(260px);
                                        margin-top: getRem(33px);
                                        margin-left: auto;
                                        padding-right: getVw(14px);
                                        margin-bottom: getVw(100px);

                                        .Typography {
                                            color: #fff;
                                            @include fontStyle(13px, 400, 150%, -0.65px);
                                            font-family: $font-ko;

                                            &.margin-left{
                                                margin-left: getRem(10px);
                                            }
                                        }
                                    }
                                }

                                .img-container {
                                    position: relative;
                                    display: flex;
                                    flex-direction: column;
                                    height: fit-content;

                                    div[class^='img'] {
                                        img {
                                            display: block;
                                            width: 100%;
                                        }
                                        &.img-01 {
                                            width: getVh(239px);
                                            align-self: flex-end;

                                            .tag {
                                                margin-top: getVw(50.44px);
                                                margin-left: getVh(106px);
                                                transform: rotate(-16.2deg);
                                            }
                                        }
                                        &.img-02 {
                                            width: getVh(228px);
                                            margin-top: getVw(-110px);
                                            margin-left: getVh(10px);
                                            .tag {
                                                margin-top: getVw(101.48px);
                                                transform: rotate(6.94deg);
                                            }
                                        }
                                        &.img-03 {
                                            width: getVh(241px);
                                            margin-top: getVw(-110px);
                                            align-self: flex-end;

                                            .tag {
                                                margin-left: getVw(14px);
                                                margin-top: getVw(125px);
                                                transform: rotate(-3.85deg);
                                            }
                                        }
                                        &.img-04 {
                                            width: getVh(228px);
                                            margin-top: getVw(-85px);

                                            .tag {
                                                margin-top: getVw(35px);
                                                margin-left: getVh(22px);
                                                transform: rotate(17.57deg);
                                            }
                                        }
                                        &.img-05 {
                                            width: getVh(197px);
                                            align-self: flex-end;
                                            margin-top: getVw(-96px);

                                            .tag {
                                                margin-top: getVw(70px);
                                                transform: rotate(-11.12deg);
                                            }
                                        }
                                        &.img-06 {
                                            width: getVh(267px);
                                            margin-top: getVw(-110px);

                                            .tag {
                                                margin-top: getVw(110px);
                                                transform: rotate(-11.12deg);
                                            }
                                        }

                                        .tag-wrap {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            pointer-events: none;
                                            opacity: 0;
                                            display: inline-block;
                                            width: fit-content;

                                            .tag {
                                                // padding: getVw(11px) getVw(17.5px);
                                                padding: getRem(11px) getRem(16px);
                                                font-family: $font-en;
                                                @include fontStyle(12px, 500, 16.8px, -0.48px);
                                                border-radius: getRem(19.49px);
                                                border: 0.5px solid #9d9d9d;
                                                background-color: rgba(163, 163, 163, 0.6);
                                                backdrop-filter: blur(10px);
                                                text-transform: uppercase;
                                                color: #fff;
                                                top: 0;
                                                left: 0;

                                                @media all and (max-width:380px){
                                                    font-size: 10px;
                                                    padding: 10px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &.section-02 {
                                clip-path: circle(
                                    6.5% at getVw(318.25px)
                                        calc(calc(var(--vh, 1vh) * 100) - getVh(61.5px))
                                );
                                background-color: #fff;
                                transition: clip-path 0.2s;
                                display: flex;
                                align-items: center;
                                z-index: 2;

                                > * {
                                    flex-grow: 0;
                                    flex-shrink: 0;
                                }
                                .circle-area-text {
                                    position: absolute;
                                    bottom: getVh(45px);
                                    left: 0;
                                    // margin-top: getVh(1220px);
                                    margin-left: getVw(266px);
                                    transition: opacity 0.2s;

                                    > span {
                                        display: block;
                                        @include fontStyle(10px, 300, 10px);
                                        color: #000;
                                        text-transform: uppercase;

                                        &.right {
                                            text-align: right;
                                        }
                                    }
                                }

                                .logo {
                                    position: relative;
                                    width: getVw(914px);
                                    height: fit-content;
                                    margin-left: getVw(44px);
                                    transition: opacity 0.2s;

                                    svg {
                                        position: relative;
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                        z-index: 1;
                                    }
                                    .Tag{
                                        padding: getVw(10px) getVw(12px);
                                        border-radius: getRem(20.50px);
                                        border: 0.5px solid #B9B9B9;
                                        background: rgba(163, 163, 163, 0.50);
                                        @include fontStyle(12px, 400, 15.6px);
                                    }
                                }

                                .symbol {
                                    position: absolute;
                                    top: 0;
                                    left: 0;

                                    img {
                                        display: block;
                                        width: 100%;
                                    }

                                    &.symbol-01 {
                                        width: getVw(291px);
                                        margin-left: getVw(37px);
                                        margin-top: getVh(93px);
                                    }
                                    &.symbol-02 {
                                        width: getVw(244px);
                                        transform: rotate(110deg);
                                        margin-left: getVw(260px);
                                        bottom: 0;
                                        top: initial;
                                    }
                                    &.symbol-03 {
                                        width: getVw(265px);
                                        margin-left: getVw(463px);
                                        margin-top: getVh(-61px);
                                        img {
                                            transform: rotate(90deg);
                                        }
                                    }
                                    &.symbol-04 {
                                        width: getVw(283px);
                                        transform: rotate(-30deg);
                                        margin-left: getVw(733px);
                                        margin-bottom: getVh(88px);
                                        bottom: 0;
                                        top: initial;
                                    }
                                }
                            }

                            &.section-03 {
                                margin-left: getVw(325px);
                                width: 100vw;
                                // overflow: hidden;

                                .text-cont-wrap {
                                    margin-top: getVh(70px);
                                    margin-left: getVw(31px);
                                    .introduce-msg {
                                        &.en {
                                            .Typography {
                                                @include fontStyle(20px, 400, 120%, -0.6px);
                                                color: #000;
                                            }
                                        }

                                        &.ko {
                                            margin-left: getVw(5px);
                                            margin-top: getVh(25px);
                                            .Typography {
                                                font-family: $font-ko;
                                                @include fontStyle(13px, 400, 150%, -0.65px);
                                                color: #000;
                                            }
                                        }
                                    }
                                }

                                .card-container {
                                    position: relative;
                                    display: flex;
                                    flex-wrap: wrap;
                                    justify-content: center;
                                    margin-right: 0;
                                    margin-top: getVh(108px);

                                    .card {
                                        flex-shrink: 0;
                                        flex-grow: 0;
                                        width: getVw(153.85px);

                                        img {
                                            display: block;
                                            width: 100%;
                                        }

                                        &.card-02 {
                                            margin-top: getVh(130px);
                                        }
                                        &.card-03 {
                                            margin-top: getVh(-91px);
                                        }
                                        &.card-04 {
                                            margin-top: getVh(20px);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .AboutSectionCard {
                transform: scale(#{getPercent(390, 325)}) rotate(5deg);
                height: 250vh;
                // margin-top: getVh(144px);
                margin-top: getVh(50px);

                .card-wrap {
                    position: sticky;
                    top: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    background-color: #000;
                    border-radius: getRem(15px);
                    padding-top: getVh(82px);
                    overflow: hidden;
                    min-height: $vh100;

                    .text-container {
                        display: block;
                        @include fontStyle(17px, 400, 25.5px, -0.05px);
                        color: #fff;
                        text-align: center;
                        margin: 0 auto;
                        z-index: 1;
                        img {
                            width: getRem(24px);
                            vertical-align: top;
                            margin-left: 5px;
                        }
                    }

                    .card-video {
                        position: relative;

                        video {
                            position: absolute;
                            bottom: 0;
                            left: getVw(-547px);
                            display: block;
                            width: getPercent(390, 1318);
                            object-fit: cover;
                        }
                    }
                }
            }

            .AboutSectionWorkWeDo {
                position: relative;
                z-index: 1;
                background-color: #fff;

                .large-text-container {
                    position: sticky;
                    top: getVh(400px);
                    mix-blend-mode: difference;
                    transform: translateY(calc(-100% - getVh(20px)));
                    padding-left: getVw(10px);
                    font-family: $font-en;

                    > div {
                        display: inline-block;
                        @include fontStyle(80px, 300, 80px);
                        font-size: getVw(80px);
                        text-transform: uppercase;
                        color: #fff;
                        &.space {
                            width: getVw(20px);
                        }
                    }
                }

                .scroll-contents {
                    position: relative;
                    z-index: 2;

                    &.we-do {
                        padding-top: getPercent(1920, 1013);
                        padding-bottom: getPercent(390, 483);

                        div[class^='card-wedo'] {
                            position: relative;
                            width: getPercent(390, 240);
                            border-radius: getRem(12px);
                            overflow: hidden;

                            .hover-contents {
                                opacity: 1;
                            }

                            &.card-wedo-01 {
                                margin-left: getVw(30px);

                                .hover-contents {
                                    background-image: url('../../../public/image/img_about_wedo_01_hover.webp');
                                }
                            }
                            &.card-wedo-02 {
                                margin-left: getVw(122px);
                                margin-top: getVh(200px);

                                .hover-contents {
                                    background-image: url('../../../public/image/img_about_wedo_02_hover.webp');
                                }
                            }
                            &.card-wedo-03 {
                                margin-left: getVw(30px);
                                margin-top: getVh(300px);

                                .hover-contents {
                                    background-image: url('../../../public/image/img_about_wedo_03_hover.webp');
                                }
                            }
                            &.card-wedo-04 {
                                margin-left: getVw(98px);
                                margin-top: getVh(200px);

                                .hover-contents {
                                    background-image: url('../../../public/image/img_about_wedo_04_hover.webp');
                                }
                            }
                            &.card-wedo-05 {
                                margin-left: getVw(53px);
                                margin-top: getVh(200px);

                                .hover-contents {
                                    background-image: url('../../../public/image/img_about_wedo_05_hover.webp');
                                }
                            }
                            &.card-wedo-06 {
                                margin-left: getVw(122px);
                                margin-top: getVh(200px);

                                .hover-contents {
                                    background-image: url('../../../public/image/img_about_wedo_06_hover.webp');

                                    p {
                                        width: getPercent(240, 212);
                                    }
                                }
                            }

                            &.card-wedo-07 {
                                margin-left: getVw(32px);
                                margin-top: getVh(200px);

                                .hover-contents {
                                    background-image: url('../../../public/image/img_about_wedo_07_hover.webp');
                                }
                            }

                            img {
                                display: block;
                                width: 100%;
                            }

                            .hover-contents {
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-repeat: no-repeat;
                                background-size: cover;
                                pointer-events: none;
                                opacity: 1;

                                span {
                                    position: absolute;
                                    font-family: $font-en;
                                    @include fontStyle(10px, 400, 10px);
                                    color: #000;
                                    top: getPercent(240, 30);
                                    left: getPercent(240, 16);
                                    text-transform: uppercase;
                                }

                                p {
                                    position: absolute;
                                    @include fontStyle(13px, 400, 16.9px, -0.04em);
                                    color: #000;
                                    width: getPercent(240, 208);
                                    bottom: getPercent(240, 16);
                                    left: getPercent(240, 16);

                                    &.center {
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }

                    &.black {
                        min-height: $vh100;
                        padding-top: getPercent(1920, 62);
                        background-color: #000;
                        background: {
                            image: url('../../../public/image/mobile/img_bg_about_earth_mo.webp');
                            position: top getVh(-256px) left getVw(-225px);
                            repeat: no-repeat;
                            size: cover;
                        }

                        .RandomTypo {
                            @include fontStyle(80px, 300, 80px);
                            font-size: getVw(80px);
                            color: #fff;
                            text-transform: uppercase;
                            font-family: $font-en;
                        }

                        .row {
                            display: flex;
                            align-items: center;
                            padding: 0 0 0 getVw(14px);

                            img {
                                width: getPercent(376, 58);
                            }

                            &.right {
                                justify-content: flex-end;
                            }
                        }

                        .text-container {
                            margin-left: getVw(47px);
                            padding-right: getVw(16px);

                            .Typography {
                                @include fontStyle(13px, 400, 19.5px, -0.05em);
                                text-align: right;
                            }

                            &.en {
                                margin-top: getRem(209px);
                                margin-bottom: getRem(43px);
                                .Typography {
                                    font-family: $font-en;
                                    @include fontStyle(20px, 300, 26px);
                                }
                            }
                            &.ko{
                                .Typography{
                                    font-family: $font-ko;
                                    text-align: left;

                                    &.right{
                                        text-align: right;
                                        padding-right: getRem(5px);
                                    }
                                }
                            }
                        }

                        .typo {
                            position: relative;
                            padding-bottom: getVh(259px);
                            @include fontStyle(60px, 400, 60px);
                            font-size: getVw(60px);
                            color: #fff;
                            text-align: center;
                            margin-top: getVh(185px);
                            z-index: 2;
                            font-family: $font-en;

                            .icn-wrap {
                                position: absolute;
                                display: flex;
                                justify-content: space-between;
                                left: 50%;
                                top: getVw(82px);
                                transform: translateX(-50%);

                                > div {
                                    width: getVw(64px);

                                    &.right {
                                        margin-right: getVw(250px);
                                        transition: margin-right 0.2s;
                                    }

                                    img {
                                        width: 100%;
                                    }
                                }
                            }

                            .btn-contact {
                                background-color: transparent;
                                border: 1px solid rgba(255, 255, 255, 0.5);
                                background: rgba(255, 255, 255, 0.4);
                                @include fontStyle(17px, 400, 25.5px);
                                color: #000;
                                padding: getRem(16px) getRem(27px);
                                text-transform: uppercase;
                                border-radius: getRem(54px);
                                transition: transform 0.2s, background-color 0.2s, color 0.2s;
                                cursor: pointer;
                                margin-top: getVw(80px);

                                &:hover {
                                    background-color: #000;
                                    transform: rotate(-9.5deg);
                                    color: #fff;
                                }

                                img {
                                    width: getRem(16px);
                                }
                            }
                        }
                        .bg {
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            overflow: hidden;
                            background-color: #fff;
                            transition: transform 0.1s;
                            min-height: 100vh;

                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }

                            &::after {
                                content: '';
                                display: block;
                                position: absolute;
                                bottom: 0;
                                height: getVh(665px);
                                width: 100%;
                                background: linear-gradient(
                                    180deg,
                                    rgba(255, 255, 255, 0) 0%,
                                    #fff 75%
                                );
                            }
                        }

                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 100%;
                            height: 5px;
                            bottom: -1px;
                            background-color: #fff;
                        }
                    }
                }
            }
        }

        //contact page
        .Contactpage {
            background-color: #000;
            .ContactSectionEntry {
                position: relative;
                width: 100%;
                height: $vh100;
                .contact-entry-bg {
                    background-color: #000;
                    width: 100%;
                    height: 100%;

                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: top center;
                    }
                }
                .contact-entry-text {
                    position: absolute;
                    bottom: getVh(20px);
                    width: 100%;
                    text-align: center;

                    .contact {
                        font-family: $font-en;
                        @include fontStyle(65px, 400, 71.5px);
                    }
                }
            }
            .ContactSectionSummary {
                background-color: #fff;
                transition: background-color 0.7s;
                &.white {
                    background-color: #fff;
                    .contact-summary-container {
                        .small-msg {
                            &.text01 {
                                .Typography {
                                    color: #000;
                                }
                            }
                        }
                    }
                }
                .contact-summary-container {
                    padding: getVh(75px) getVw(14px);

                    .small-msg {
                        &.text01 {
                            .Typography {
                                color: #000;
                                transition: color 0.7s;
                                @include fontStyle(34px, 400, 44.2px, -0.68px);
                                font-size: getVw(34px);
                            }
                        }
                        &.text02 {
                            margin-left: auto;
                            margin-top: getVh(153px);

                            .Typography {
                                font-family: $font-ko;
                                color: #000;
                                @include fontStyle(13px, 400, 19.5px, -0.65px);
                                font-size: getVw(13px);
                            }
                        }
                    }
                }
            }
            .ContactSectionReview {
                position: relative;
                padding-bottom: getVh(66px);
                background-color: #fff;
                transition: background-color 0.5s;
                &.black {
                    background-color: #000;
                }

                .contact-review-stick {
                    position: sticky;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: $vh100;
                    z-index: 0;

                    .text-container {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);

                        .text-slide-box {
                            display: flex;
                            width: 100vw;
                            overflow: hidden;

                            &.action {
                                .hidden-text {
                                    opacity: 1;
                                }
                            }

                            @keyframes scrollAction {
                                0% {
                                    transform: translateX(0);
                                }
                                100% {
                                    transform: translateX(-50%);
                                }
                            }
                        }
                        .text-wrap {
                            flex-shrink: 0;
                            display: flex;
                            text-transform: uppercase;
                            color: #fff;
                            @include fontStyle(120px, 400, 100px);
                            font-size: getVw(120px);
                            font-family: $font-en;
                            transition-timing-function: linear;
                            transition: transform 0.3s;
                            color: #fff;
                            padding: 0 getVw(12px);

                            .hidden-text {
                                flex-shrink: 0;
                                opacity: 0;
                            }
                        }

                        .text-normal-wrap {
                            flex-shrink: 0;
                            @include fontStyle(120px, 300, 100px);
                            font-family: $font-en;
                            text-transform: uppercase;
                        }
                        .small-text-box {
                            position: absolute;
                            right: getVw(14px);
                            margin-top: getVh(31px);

                            p {
                                @include fontStyle(13px, 400, 19.5px, -0.65px);
                                font-family: $font-ko;
                                color: #fff;

                                &:nth-of-type(2){
                                    text-align: right;
                                }
                                &:nth-of-type(3){
                                    margin-left: getRem(23px);
                                }
                            }
                        }
                    }
                }
                .work-thumbnail-wrap {
                    position: relative;
                    z-index: 1;
                    display: flex;
                    flex-direction: column;
                    .work-thumbnail {
                        position: relative;
                        width: getPercent(390, 220);
                        img {
                            display: block;
                            width: 100%;
                        }

                        &.thumbnail02 {
                            align-self: flex-end;
                            margin-top: getVh(279px);
                        }
                        &.thumbnail03 {
                            margin-top: getVh(420px);
                        }
                        &.thumbnail04 {
                            align-self: flex-end;
                            margin-top: getVh(309px);
                        }
                        &.thumbnail05 {
                            align-self: center;
                            margin-top: getVh(310px);
                        }
                    }
                }
            }
            .ContactSectionFinish {
                position: relative;
                padding: getVh(130px) 0;
                background-color: #fff;
                clip-path: circle(10% at 50% 120vh);
                padding-top: $vh100;
                margin-top: $vh-100;
                transition: clip-path 0.5s;

                .finish-container {
                    .finish-text-box {
                        position: relative;
                        width: 100%;
                        text-align: center;
                        text-transform: uppercase;
                        margin-bottom: getVh(30px);
                        margin-top: getVh(130px);
                        color: #000;
                        transition: color 0.8s;

                        strong {
                            @include fontStyle(65px, 400, 58.5px);
                            font-family: $font-en;
                            display: inline-block;
                        }
                    }

                    .tag {
                        position: absolute;
                        transition: transform 0.4s;
                        top: 0;
                        left: 0;

                        > * {
                            display: inline-block;
                            background-color: #000;
                            padding: getRem(8px) getRem(12px);
                            color: #fff;
                            @include fontStyle(12px, 400, 16.8px);
                            font-family: $font-en;
                            border-radius: 30px;
                            cursor: pointer;
                            opacity: 0;
                            transform: translateY(500%);
                            transition: transform 0.7s, opacity 0.5s;
                            text-decoration: none;

                            &.action {
                                opacity: 1;
                                transform: translateY(0);
                            }
                        }
                        &.instagram {
                            margin-top: getVh(23px);
                            margin-left: getVw(79px);
                            > * {
                                transition-delay: 0.5s;
                            }
                        }
                        &.youtube {
                            top: initial;
                            bottom: 0;
                            margin-bottom: getVh(15px);
                            margin-left: getVw(178px);
                            > * {
                                transition-delay: 1.4s;
                            }
                        }
                        &.contact {
                            margin-top: getVh(139px);
                            margin-left: getVw(222px);
                            > * {
                                transition-delay: 1s;
                            }
                        }

                        &:hover {
                            transform: rotate(12.9deg);
                        }
                    }
                    .finish-member-img {
                        position: relative;
                        width: 100%;
                        clip-path: inset(0 getPercent(390, 101));
                        transition: clip-path 0.5s;

                        img {
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                    .info-box {
                        position: relative;
                        margin-top: getRem(10px);
                        padding: 0 getVw(14px);
                        @include fontStyle(10px, 400, 15px, -0.5px);
                        color: #000;

                        a {
                            color: #000;
                            text-decoration: none;
                        }

                        .tel {
                            li {
                                display: inline-block;

                                &:first-of-type {
                                    margin-right: getRem(26.94px);
                                }
                            }
                        }
                    }
                }
            }
        }

        // (S) component
        .Footer {
            position: relative;
            z-index: 2;
            background-color: #fff;
            padding-top: getPercent(390, 30);
            padding-bottom: getPercent(390, 25);

            .footer-text-box {
                display: flex;
                flex-direction: column;
                gap: getVw(8px) 0;
                padding: 0 getPercent(390, 14);
                text-transform: uppercase;

                &.right {
                    text-align: right;
                    align-items: flex-end;
                }

                &.row {
                    justify-content: space-between;
                    flex-direction: row;
                    align-items: center;
                    margin-top: getPercent(390, 63);
                }

                .menu-list {
                    font-family: $font-en;
                    text-align: right;
                    @include fontStyle(20px, 300, 30px);

                    .rnd {
                        margin-top: getVw(20px);
                    }

                    a {
                        text-transform: uppercase;
                        text-decoration: none;
                        color: #000;
                    }
                }

                .address-box {
                    @include fontStyle(12px, 300, 18px, -0.6px);
                    text-transform: uppercase;
                    padding-top: getPercent(1863, 33.5);

                    address {
                        text-align: right;
                        margin-bottom: getPercent(220, 10);
                    }

                    .num-info {
                        display: flex;
                        justify-content: space-around;
                    }
                }

                .footer-msg {
                    @include fontStyle(35px, 300, 38.5px, -0.7px);
                    font-family: $font-en;
                    position: relative;
                    margin-top: getPercent(390, 8);
                }

                .rights {
                    display: flex;
                    @include fontStyle(11px, 300, 25px);
                    gap: 0 getVw(14px);
                    margin-top: getPercent(390, 14);
                }
            }

            .tag-wrap {
                flex-shrink: 0;
                flex-grow: 0;
                width: fit-content;
            }

            .LinkTag {
                display: inline-block;
                @include fontStyle(10px, 300, 13px, 0);
                border-radius: 23px;
                padding: getRem(10) getRem(15);
                border: 0.5px solid #88888880;
                text-align: center;
                background: linear-gradient(#ffffffb3, #8888880b);
                font-family: $font-en;

                & ~ .LinkTag {
                    margin-left: getVw(10px);
                }

                &.handly {
                    top: getRem(66);
                    left: getRem(127);
                }
                &.mentor {
                    top: getRem(100);
                    right: getRem(103);
                }
                &.instagram {
                    top: getRem(64);
                    right: getRem(45.89);
                }
                &.youtube {
                    top: getRem(100);
                    left: getRem(320.11);
                }

                .link-name {
                    text-decoration: none;
                    color: #000;
                }
            }
        }

        .contact-badge {
            z-index: 8;
            position: fixed;
            bottom: getPercent(844, 14);
            right: getPercent(390, 14);
            width: getPercent(390, 66);
            border-radius: 50%;
            background-color: rgba(101, 101, 101, 0.6);
            @include blur(5px);
            @include reactive;

            &::after {
                padding-bottom: 100%;
            }

            .badge-container {
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                position: absolute;
                padding: getPercent(66, 4);

                svg {
                    width: 100%;
                    height: 100%;
                    animation: rotate360 60s linear infinite;
                    will-change: transform;
                }
                @keyframes rotate360 {
                    100% {
                        transform: rotate(1turn);
                    }
                }
                .badge-emoji {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: getPercent(66, 22);
                    background: {
                        image: url('../../../public/image/img_icon_raised_fist.webp');
                        repeat: no-repeat;
                        position: center;
                        size: contain;
                    }

                    &:active {
                        background-image: url('../../../public/image/mobile/img_icon_palm.webp');
                    }
                }
            }
        }

        .contact-popup {
            z-index: 10;
            position: fixed;
            top: 0;
            transform-origin: left bottom;
            width: 100%;
            height: calc(var(--vh, 1vh) * 100);
            @include blur(10px);
            background-color: rgba(0, 0, 0, 0.7);

            .popup-container {
                display: flex;
                gap: 0 1px;
                width: 100%;

                .popup-box {
                    width: 100%;
                    height: 100%;
                    text-transform: uppercase;

                    &.box-right {
                        border-radius: 10px;
                        overflow: hidden;
                        transform: translateZ(0);

                        .popup-header {
                            @include popupHeader();
                            p {
                                img {
                                    width: getRem(13);
                                    height: getRem(13);
                                }
                            }
                        }
                        .popup-main {
                            height: getRem(465);
                            overflow-y: auto;
                            padding: getPercent(390, 20);
                            padding-right: getPercent(390, 10);
                            background-color: rgba(30, 30, 30, 1);
                            box-sizing: border-box;
                            padding-bottom: getRem(30);

                            &::-webkit-scrollbar {
                                width: 12px; /* 스크롤바의 너비 */
                            }

                            &::-webkit-scrollbar-thumb {
                                border-radius: 3px;
                                background-color: #6f6f6f;
                                background-clip: padding-box;
                                border: 4px solid transparent;
                            }

                            &::-webkit-scrollbar-track {
                                background: transparent; /*스크롤바 뒷 배경 색상*/
                            }

                            .contact-question-wrap {
                                text-align: right;
                                .chat-time {
                                    display: block;
                                    font-family: $font-en;
                                    @include fontStyle(11px, 300, 13.2px, -0.02em);
                                    color: rgba(201, 201, 201, 0.87);
                                }
                                .chat-question {
                                    display: inline-block;
                                    padding: getPercent(390, 9) getPercent(390, 18);
                                    text-align: center;
                                    background-color: rgba(0, 125, 238, 1);
                                    color: rgba(255, 255, 255, 1);
                                    font-family: $font-ko;
                                    @include fontStyle(14px, 300, 21px, -0.05em);
                                    border-radius: getRem(20px);
                                    margin-left: auto;
                                    margin-top: getRem(8);
                                    margin-bottom: getRem(10);
                                }
                            }
                            .list-btn-wrap {
                                .list-btn-box {
                                    .list-btn-input {
                                        width: 0;
                                        height: 0;
                                        margin: 0;
                                        opacity: 0;

                                        &:checked + label {
                                            background-color: rgba(255, 255, 255, 1);
                                            color: rgba(0, 0, 0, 1);
                                            border-color: rgba(255, 255, 255, 1);
                                        }
                                    }
                                    .list-btn-label {
                                        display: inline-block;
                                        cursor: pointer;
                                        padding: getVw(6px) getVw(18px);
                                        background: transparent;
                                        border-radius: getRem(17px);
                                        border: 1px solid rgba(75, 75, 75, 1);
                                        color: rgba(255, 255, 255, 1);
                                        @include fontStyle(14px, 300, 21px, -0.05em);
                                        font-family: $font-ko;

                                        strong {
                                            font-weight: 700;
                                        }
                                        img {
                                            width: getRem(14);
                                            height: getRem(14);
                                            display: inline-block;
                                            vertical-align: middle;
                                            transform: translateY(-5%);
                                        }
                                    }
                                }
                            }
                            .default-wrap {
                                margin-bottom: getRem(25);
                                .contact-question-wrap {
                                    opacity: 0;
                                    transform: translateY(30%);
                                    transition: opacity 0.5s, transform 0.5s;
                                }
                                .service-list-wrap {
                                    opacity: 0;
                                    transform: translateY(30%);
                                    transition: all 0.6s;
                                    .list-btn-wrap {
                                        .list-btn-box {
                                            margin-bottom: getRem(10);

                                            &:nth-of-type(5) {
                                                .list-btn-label {
                                                    img {
                                                        transform: translateY(-15%);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &.on {
                                    .contact-question-wrap {
                                        opacity: 1;
                                        transform: translateY(0);
                                    }
                                    .service-list-wrap {
                                        opacity: 1;
                                        transform: translateY(0);
                                        transition-delay: 0.3s;
                                    }
                                }
                            }

                            .budget-list,
                            .open-day-list {
                                .list-btn-wrap {
                                    display: flex;
                                    flex-wrap: wrap;
                                    gap: getRem(10) getRem(6);
                                }
                            }
                            .project-budget {
                                margin-bottom: getRem(25);

                                &.on {
                                    .contact-question-wrap {
                                        opacity: 1;
                                        transform: translateY(0);
                                        transition-delay: 0.2s;
                                    }
                                    .budget-list {
                                        opacity: 1;
                                        transform: translateY(0);
                                        transition-delay: 0.5s;
                                    }
                                }
                                .contact-question-wrap,
                                .budget-list {
                                    transform: translateY(30%);
                                    opacity: 0;
                                    transition: all 0.5s;
                                }
                            }
                            .project-open-day {
                                margin-bottom: getRem(25);
                                &.on {
                                    .contact-question-wrap {
                                        opacity: 1;
                                        transform: translateY(0);
                                        transition-delay: 0.2s;
                                    }
                                    .open-day-list {
                                        opacity: 1;
                                        transform: translateY(0);
                                        transition-delay: 0.5s;
                                    }
                                }
                                .contact-question-wrap,
                                .open-day-list {
                                    transform: translateY(30%);
                                    opacity: 0;
                                    transition: all 0.5s;
                                }
                            }
                            .info-box {
                                .contact-question-wrap {
                                    opacity: 0;
                                    transform: translateY(30%);
                                    transition: all 0.5s;
                                }
                                .info-input-wrap {
                                    opacity: 0;
                                    transform: translateY(30%);
                                    transition: all 0.5s;
                                    .input-box {
                                        width: 100%;
                                        margin-bottom: getRem(10);
                                        .info-input {
                                            width: 100%;
                                            box-sizing: border-box;
                                            font-family: $font-ko;
                                            @include fontStyle(14px, 300, 21px, -0.05em);
                                            border-radius: 18px;
                                            padding: getRem(6) getRem(14);
                                            border: 1px solid rgba(75, 75, 75, 1);
                                            background: transparent;
                                            color: rgba(255, 255, 255, 1);
                                            outline: none;
                                            transition: background 0.2s, color 0.2s;

                                            &.error {
                                                color: #ff0000;
                                                border-color: #ff0000;
                                                &:focus {
                                                    border-color: #ff0000;
                                                }
                                            }
                                            &.on {
                                                background-color: #ffffff;
                                                color: #000000;
                                            }
                                            &:focus {
                                                border-color: rgba(255, 255, 255, 1);
                                            }
                                            &::placeholder {
                                                color: rgba(255, 255, 255, 1);
                                            }
                                        }
                                        &.file-box {
                                            .file-input {
                                                display: none;
                                            }
                                            .file-label {
                                                display: flex;
                                                justify-content: space-between;
                                                align-items: center;
                                                width: 100%;
                                                box-sizing: border-box;
                                                border-radius: 18px;
                                                padding: getRem(6) getRem(14);
                                                border: 1px solid rgba(75, 75, 75, 1);
                                                background: transparent;

                                                &.upload {
                                                    background-color: #ffffff;
                                                    border-color: #ffffff;

                                                    span {
                                                        color: #000;
                                                    }
                                                    .delete-btn {
                                                        display: block;
                                                    }
                                                }
                                                &.error {
                                                    border-color: #ff0000;
                                                    span {
                                                        color: #ff0000;
                                                    }
                                                    & + .info-text {
                                                        color: #ff0000;
                                                    }
                                                }

                                                span {
                                                    color: rgba(255, 255, 255, 1);
                                                    @include fontStyle(14px, 300, 21px, -0.05em);
                                                }
                                                .delete-btn {
                                                    display: none;
                                                    background: transparent;
                                                    border: 0;
                                                    padding: 0;
                                                    cursor: pointer;
                                                }
                                            }
                                        }
                                        .textarea {
                                            width: 100%;
                                            height: getRem(130);
                                            border-radius: 18px;
                                            border: 1px solid #4b4b4b;
                                            background: transparent;
                                            padding: getRem(7) getRem(15);
                                            box-sizing: border-box;
                                            resize: none;
                                            font-family: $font-ko;
                                            @include fontStyle(15px, 300, 22.5px, -0.05em);
                                            color: rgba(255, 255, 255, 1);
                                            outline: none;

                                            &:focus {
                                                border-color: rgba(255, 255, 255, 1);
                                            }
                                            &::placeholder {
                                                color: rgba(255, 255, 255, 1);
                                            }
                                            &.on {
                                                background-color: #ffffff;
                                                color: #000000;
                                            }
                                        }
                                        .info-text {
                                            display: block;
                                            @include fontStyle(11px, 300, 16.5px, -0.05em);
                                            color: rgba(201, 201, 201, 1);
                                            text-align: right;
                                            margin-top: 3px;
                                            padding-right: 15px;
                                        }
                                        &.check-input-box {
                                            input {
                                                display: none;
                                                width: 0;

                                                &.on {
                                                    + label {
                                                        svg {
                                                            fill: #fff;
                                                            rect {
                                                                stroke: #fff;
                                                            }
                                                            path {
                                                                fill: #000;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.error {
                                                    & + label {
                                                        svg {
                                                            rect {
                                                                stroke: #ff0000;
                                                            }
                                                            path {
                                                                fill: #ff0000;
                                                            }
                                                        }
                                                        span {
                                                            color: #ff0000;
                                                        }
                                                    }
                                                }
                                            }
                                            label {
                                                display: flex;
                                                align-items: center;
                                                span {
                                                    font-family: $font-ko;
                                                    @include fontStyle(12px, 300, 18px, -0.05em);
                                                    color: #fff;
                                                    display: inline-block;
                                                    margin-left: getRem(10);

                                                    .policy-btn {
                                                        font-family: inherit;
                                                        @include fontStyle(12px, 300, 18px, -0.05em);
                                                        color: inherit;
                                                        background-color: transparent;
                                                        border: none;
                                                        outline: none;
                                                        text-decoration: underline;
                                                        padding: 0;
                                                        cursor: pointer;
                                                    }
                                                }

                                                &:hover {
                                                    svg {
                                                        rect {
                                                            stroke: #fff;
                                                        }
                                                        path {
                                                            fill: #fff;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &.on {
                                    .contact-question-wrap {
                                        opacity: 1;
                                        transform: translateY(0);
                                        transition-delay: 0.2s;
                                    }
                                    .info-input-wrap {
                                        opacity: 1;
                                        transform: translateY(0);
                                        transition-delay: 0.5s;
                                    }
                                }
                            }
                            .submit-box {
                                margin-bottom: 1.5625rem;

                                .submit-btn {
                                    font-family: $font-ko;
                                    width: 100%;
                                    background: transparent;
                                    border-radius: 18px;
                                    font-family: $font-ko;
                                    @include fontStyle(17px, 300, 1.2, -0.05em);
                                    color: rgba(255, 255, 255, 1);
                                    padding: getRem(13) getRem(8);
                                    border: 1px solid rgba(75, 75, 75, 1);
                                    text-align: center;
                                    cursor: pointer;

                                    &:hover,
                                    &.active {
                                        color: rgba(0, 0, 0, 1);
                                        background-color: #ffffff;
                                    }
                                    &:disabled {
                                        color: #5c5c5c;
                                        background-color: #2b2b2b;
                                        cursor: text;
                                    }
                                }
                            }
                            .last-box {
                                .chat-question {
                                    &:first-of-type {
                                        margin-bottom: getRem(8);
                                    }
                                    &:last-of-type {
                                        margin-top: 0;
                                    }
                                    img {
                                        width: getRem(14);
                                        height: getRem(14);
                                        vertical-align: middle;
                                    }
                                }
                            }
                            .loading-animation {
                                opacity: 0;
                                position: absolute;
                                bottom: getRem(22px);
                                right: getPercent(352, 30);
                                transition: opacity 0.3s;
                                &.load {
                                    opacity: 1;
                                }

                                .indicator {
                                    display: flex;
                                    width: getRem(30);
                                    height: getRem(6);
                                    justify-content: space-between;
                                    margin-left: auto;

                                    span {
                                        display: block;
                                        width: getRem(6);
                                        border-radius: 50%;
                                        background-color: #cecece;
                                        animation: loadingAni 1.4s infinite forwards;

                                        &:nth-of-type(2) {
                                            animation-delay: -1s;
                                        }
                                        &:nth-of-type(3) {
                                            animation-delay: -0.6s;
                                        }
                                    }

                                    @keyframes loadingAni {
                                        0%,
                                        80%,
                                        100% {
                                            transform: translateY(0);
                                        }
                                        40% {
                                            transform: translateY(-100%);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .Loading {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #000;
            z-index: 999;
            transition: opacity 0.5s;

            .bg {
                position: absolute;
                height: 100%;
                background-color: #fff;
                transition: width 0.2s;
            }

            .content {
                position: relative;
                z-index: 1;
                mix-blend-mode: difference;
                height: 100%;
                color: #fff;

                .text {
                    position: absolute;
                    top: 0;
                    left: 0;
                    margin-top: getPercent(390, 53);
                    width: 100%;
                    > p {
                        width: fit-content;
                        @include fontStyle(17px, 300, 22.1px, -0.34px);
                        margin-left: getPercent(390, 13);
                        text-transform: uppercase;
                    }

                    > small {
                        display: block;
                        width: getPercent(390, 300);
                        @include fontStyle(10px, 300, 13px, -0.2px);
                        text-transform: uppercase;
                        margin-left: getPercent(390, 77);
                        margin-top: getPercent(390, 30);
                    }

                    span {
                        display: block;
                        &.center {
                            text-align: center;
                        }

                        &.right {
                            text-align: right;
                        }
                    }
                }
                .progress {
                    position: absolute;
                    bottom: getVw(107px);
                    right: 0;
                    margin-right: getPercent(390, 71);
                    @include fontStyle(48px, 300, 62.4px, -0.96px);
                }

                .global-logo {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: getVw(14px);

                    > div {
                        overflow: hidden;
                        svg {
                            transform: translateX(-100%);
                            transition: transform 2s;
                        }
                    }
                }
            }
        }

        .Transition {
            display: flex;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 99;

            .dim {
                background-color: #000;
                width: 100%;
                height: 100%;
            }
        }

        .Typography {
            font-family: $font-en;
            @include fontStyle(53px, 300, 67px);
            font-size: getVw(53px);
            color: #fff;
            text-transform: uppercase;
            top: 0;
            white-space: nowrap;
            &.typo-ani {
                display: inline-block;
                overflow: hidden;
                > .text-wrap {
                    display: inline-block;
                    white-space: nowrap;
                    opacity: 0;
                    transform-origin: left top;
                }
            }

            > .alphabet-wrap {
                display: inline-block;
                overflow: hidden;

                > span {
                    display: block;
                }
            }
        }

        *[class^='small-msg'] {
            color: #fff;
            text-transform: uppercase;
            display: flex;
            flex-direction: column;

            .flex-column {
                display: inline-flex;
                flex-direction: column;
                .right {
                    align-self: flex-end;
                }
                .center {
                    align-self: center;
                }
            }
            .en {
                .Typography {
                    font-family: $font-en;
                    @include fontStyle(40px, 300, 40px, -0.02em);
                    font-size: getVw(40px);
                }
            }

            .ko {
                margin-top: getRem(26);

                .Typography {
                    @include fontStyle(13px, 300, 16.9px, -0.05em);
                }
            }

            .typo-ani {
                .text-wrap {
                    transform: translate3d(0px, 250%, 0px) rotate(16deg);
                }
            }
        }

        .Tag {
            z-index: 99;
            position: absolute;
            transform: translate(-50%, -50%);
            padding: getRem(14) getRem(15);
            @include fontStyle(10px, 300, 13px, 0);
            font-family: $font-en;
            border-radius: 21px;
            background: linear-gradient(#616161, rgba(105, 105, 105, 0.5));
            border: 0.5px solid rgba(97, 97, 97, 1);
            color: #fff;
            @include blur(10px);
            text-transform: uppercase;

            &.innovative {
                bottom: getRem(70);
                left: getRem(340);
            }
            &.creative {
                top: getRem(110);
                left: getRem(775);
            }
            &.confidence {
                bottom: getRem(70);
                right: getRem(1560);
            }
            &.experience {
                top: getRem(170);
                right: getRem(1160);
            }
            &.service {
                top: getRem(32);
                left: getRem(-17);
            }
            &.ui {
                top: getRem(149.5);
                left: getRem(221.5);
            }
            &.ux {
                top: getRem(34.5);
                left: getRem(372.5);
            }
            &.hybrid {
                top: getRem(103.5);
                left: getRem(498.5);
            }
            &.native {
                top: getRem(40.5);
                right: getRem(87.5);
            }
        }

        .Privacy{
            display: none;
            position: fixed;
            z-index: 9;
            bottom: 0;
            transform: translate3d(0,100%,0);
            width: 100%;
            height: calc(var(--vh, 1vh) * 100);
            
            background-color: rgba(0, 0, 0, 0.7);
            padding-bottom: getPercent(390,145);

            &.up-index {
                z-index: 100;
            }
            .privacy-container{
                width: 100%;
                @include blur(10px);
                height: 100%;


                .popup-header{
                    @include popupHeader();
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }
                .privacy-main{
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding: getRem(20) getRem(20) getRem(40);
                    background: #1E1E1E;
                    color: #E9E9E9;
                    @include fontStyle(14px,300,22.5px,-0.06em);
                    height: 100%;
                    overflow-y: scroll;
                    h4{
                        margin-bottom: getRem(5);
                    }
                    .mb-5{
                        margin-bottom: getRem(5);
                    }
                    .list{
                        margin-top: getRem(20);
                    }
                    .link-text{
                        color: #888888;
                        text-decoration: none;
                    }
                    button.link-text{
                        border: none;
                        background: transparent;
                        display: inline-block;
                        padding: 0;
                        @include fontStyle(12px,300,16.8px,-0.06em);
                        cursor: pointer;
                    }
                    &::-webkit-scrollbar {
                        width: 12px;  /* 스크롤바의 너비 */
                    }
    
                    &::-webkit-scrollbar-thumb {
                        border-radius: 3px;
                        background-color: #6F6F6F;
                        background-clip: padding-box;
                        border: 4px solid transparent;
                    }
    
                    &::-webkit-scrollbar-track {
                        background: transparent;  /*스크롤바 뒷 배경 색상*/
                    }
                }
            }
        }
        // (E) component

        //work page
        .WorkPage {
            background-color: #fff;
            .WorkEntry {
                .transition {
                    position: fixed;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 7;
                    pointer-events: none;
                    transition: opacity 0.2s;
                    background-color: #000;
                }
                .sticky-container {
                    height: getVh(757px);
                    .sticky {
                        position: sticky;
                        top: 0;
                        padding-top: getVh(70px);
                        height: getVh(489px);
                        .msg-wrap {
                            width: 100%;
                            transition-delay: 1s;
                            .small-msg {
                                display: flex;
                                flex-direction: column;
                                padding: 0 getVw(14px);
                                .text-en {
                                    .Typography {
                                        color: #000;
                                        @include fontStyle(24px, 300, 31.2px);
                                        font-family: $font-en;
                                    }
                                }
                                .text-ko {
                                    width: fit-content;
                                    margin-left: auto;
                                    margin-top: getRem(20px);
                                    align-self: flex-end;
                                    .Typography {
                                        color: #000;
                                        @include fontStyle(13px, 300, 19.5px);
                                        font-family: $font-ko;
                                        &.last {
                                            padding-left: getRem(37px);
                                        }
                                    }
                                }

                                .Typography {
                                    color: #000;
                                }
                            }
                        }

                        .title-box {
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            transition: transform 0.7s;
                            z-index: 3;

                            h2.title {
                                position: relative;
                                width: fit-content;
                                margin: 0 auto;
                                transition: transform 0.7s;

                                .word {
                                    text-align: center;
                                    font-family: $font-en;
                                    @include fontStyle(65px, 300, 110%);

                                    span {
                                        display: inline-block;
                                    }
                                }
                                .project-lenght {
                                    opacity: 0;
                                    transform: scale(0) translateZ(0) !important;
                                    position: absolute;
                                    right: getVw(-20px);
                                    bottom: getVw(57px);
                                    border-radius: 30px;
                                    padding: getRem(9px) getRem(15px);
                                    background-color: rgba(146, 146, 146, 0.5);
                                    border: 0.5px solid #b9b9b9;
                                    @include blur(10px);
                                    span {
                                        display: inline-block;
                                        @include fontStyle(14.4px, 400, 17.28px);
                                        color: #fff;
                                        font-family: $font-en;
                                    }
                                    &.show {
                                        opacity: 1;
                                        transform: scale(1) translateZ(0) !important;
                                        transition: opacity 0.5s, transform 0.7s;
                                    }
                                }
                            }
                        }
                    }
                }

                .scroll {
                    background-color: #fff;
                }

                .thumb-box {
                    width: 100%;
                    padding: 0 getVw(14px);
                    opacity: 0;
                    transition: opacity 0.5s;

                    &.end {
                        opacity: 1;
                    }

                    .thumb-box-container {
                        width: 100%;

                        .thumb-wrap {
                            .thumb-list {
                                display: block;
                                width: 100%;
                                a {
                                    display: block;
                                    cursor: pointer;
                                    text-decoration: none;
                                    color: #000;
                                }
                                &:not(:first-child) {
                                    padding-top: getPercent(362, 24);
                                }
                                .img-wrap {
                                    width: 100%;
                                    border-radius: getRem(11px);
                                    overflow: hidden;
                                    img {
                                        display: block;
                                        width: 100%;
                                    }
                                }
                                .desc-wrap {
                                    margin-top: getRem(13px);
                                    font-family: $font-en;
                                    .work-name {
                                        @include fontStyle(13px, 700, 120%, -0.65px);
                                    }
                                    .work-name-en {
                                        @include fontStyle(11px, 300, 120%, -0.22px);
                                        margin-top: getRem(4px);
                                        text-transform: uppercase;
                                    }
                                }
                                &.top-box {
                                    .img-wrap {
                                        position: relative;
                                        width: 100%;
                                        left: 0;
                                        transition: left 0.6s,
                                            transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
                                        transform-origin: center;
                                        transition-delay: 0.3s;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        //WorkDetail
        .WorkDetail {
            // position: absolute;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 8;

            &:has(.lexus_new) {
                position: absolute;

                .work-detail-wrap {
                    height: auto;
                    overflow: unset;
                }
            }
        }

        //solution page
        .SolutionPage {
            .SolutionSectionEntry {
                width: 100%;
                height: 100vh;
                background-color: #000;
                position: relative;

                .solution-entry-bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #000;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: top;
                    }

                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: linear-gradient(
                            180deg,
                            rgba(0, 0, 0, 0) 0%,
                            rgba(0, 0, 0, 0.73) 95.26%,
                            #000 100%
                        );
                    }
                }
                .solution-entry-text {
                    color: #fff;
                    font-family: $font-en;

                    &.text-01 {
                        padding: getVh(100px) getVw(14px) 0;
                        .text-en {
                            width: fit-content;
                            @include fontStyle(20px, 300, 26px, -0.4px);
                            text-transform: uppercase;
                        }
                        .text-ko {
                            width: fit-content;
                            margin-left: auto;
                            margin-top: getRem(20px);

                            .Typography {
                                font-family: $font-ko;
                                @include fontStyle(13px, 300, 19.5px, -0.65px);
                            }
                        }
                    }
                    &.text-02 {
                        position: absolute;
                        bottom: getRem(20px);
                        left: 50%;
                        transform: translateX(-50%);

                        .we-have {
                            position: absolute;
                            left: getVw(96px);
                            top: 0;
                            transform: translateY(-100%);
                            @include fontStyle(13px, 300, 14.3px);
                        }
                        .solutions {
                            @include fontStyle(65px, 400, 71.5px);
                            font-size: getVw(65px);
                        }
                    }
                }
            }
            .SolutionSectionIntro {
                min-height: 200vh;
                background-color: #000;
                position: relative;
                transition: background-color 0.5s;

                &.white {
                    background-color: #fff;
                }

                .sticky {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position: sticky;
                    top: 0;
                    left: 0;
                    min-height: 100vh;
                    padding: getVh(151px) 0;
                }
                .solution-intro-text {
                    z-index: 2;
                    position: relative;
                    font-family: $font-en;
                    text-transform: uppercase;
                    overflow: hidden;
                    width: 100%;
                    padding-left: getVw(11px);
                    span {
                        font-family: $font-en;
                        @include fontStyle(13px, 200, 14.3px);
                    }
                    .show-text-box {
                        display: flex;
                        text-align: center;
                        
                        .hidden-num {
                            display: inline-block;
                            @include fontStyle(65px, 700, 71.5px);
                            color: #007dee;
                            width: getVw(39px);
                        }
                        h2 {
                            display: inline-block;
                            @include fontStyle(65px, 400, 71.5px);
                        }
                    }
                }
                .solution-intro-text-ko {
                    z-index: 2;
                    font-family: $font-ko;
                    @include fontStyle(13px, 500, 28px, -0.65px);
                    margin-top: getVh(5px);
                }
                .solution-intro-icon {
                    z-index: 2;
                    width: getVw(368px);
                    margin: getVh(74px) auto 0;
                    .icon-wrap {
                        display: flex;
                        justify-content: space-between;

                        li {
                            flex: 1 1;
                            img {
                                width: 100%;
                                transform: rotate(3.914deg);
                            }
                        }
                    }
                }
                .tag {
                    &.handly {
                        margin-top: getVh(38px);
                        margin-left: getVw(74px);
                        > span {
                            transform: rotate(-8.2deg);
                        }
                    }
                    &.popee {
                        margin-top: getVh(25px);
                        margin-left: getVw(195px);
                        > span {
                            transform: rotate(7.82deg);
                        }
                    }
                    &.mentor {
                        margin-top: getVh(30px);
                        margin-left: getVw(305px);
                        > span {
                            transform: rotate(-8.2deg);
                        }
                    }
                }
                .line {
                    opacity: 0;
                    z-index: 1;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: #dcdcdc;
                    pointer-events: none;

                    &.on {
                        opacity: 1;
                    }

                    &.horizontal {
                        width: 100%;
                        height: 1px;
                        top: 0;
                        left: 0;
                    }
                    &.vertical {
                        width: 1px;
                        height: 100%;
                        top: 0;
                        left: 0;
                    }
                }
            }
            .SolutionSectionPopee {
                background-color: #000;
                width: 100%;
                .container {
                    
                    .sticky {
                        position: sticky;
                        height: 100vh;
                        width: 100%;
                        top: 0;
                        .bg {
                            height: 100%;
    
                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                    
                    .SolutionIntroduceIntro {
                        position: relative;
                        z-index: 1;
                        color: #ff60ae;

                        .num,
                        .text {
                            color: #ff60ae;
                        }
                        strong {
                            @include fontStyle(65px, 400, 71.5px);
                            div {
                                span {
                                    color: #ff60ae;
                                }
                            }
                        }
                    }
                }
                .contents {
                    .section02 {
                        position: relative;
                        background-color: #000;
                        overflow: hidden;
                        padding-bottom: getVh(116px);
    
                        .big-title {
                            position: relative;
                            width: 100%;
                            z-index: 1;
    
                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .bg-img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            margin-top: getVh(115px);
                            overflow: hidden;

                            img {
                                width: 100vw;
                            }
                        }

                        .popee-info-box {
                            width: 100%;
                            margin-top: getVh(182px);
    
                            ul {
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                gap: 0 getVw(24px);
                                padding: 0 getVw(11px);
    
                                li {
                                    position: relative;
    
                                    img {
                                        display: block;
                                        width: 100%;
                                        transform: scale(1.15);
                                    }
                                }
                            }
                        }
    
                        .small-msg {
                            display: flex;
                            padding: 0 getVw(20px);
                            margin-top: getVh(51px);

                            .text-box-top {
                                .Typography {
                                    font-family: $font-ko;
                                    color: #ff60ae;
                                    @include fontStyle(20px, 700, 26px, -1px);
                                }
                            }
                            .text-box-bottom {
                                margin-top: getVh(20px);
                                .Typography {
                                    font-family: $font-ko;
                                    color: #ff60ae;
                                    @include fontStyle(14px, 500, 23.8px, -0.7px);
                                }
                            }
                        }

                        
                    }
                    .section03 {
                        position: relative;
                        overflow: hidden;
                        background-color: #fff;
                        padding-bottom: getVh(90px);
                        
                        .painter-bg-01 {
                            position: absolute;
                            top: -2px;
                            left: 0;
                            display: flex;
                            width: 100%;
                            z-index: 1;
                            overflow: hidden;
                            transform: rotate(-180deg);
                            @keyframes move_wave_mobile {
                                0% {
                                    transform: translateX(0) translateZ(0);
                                }
                                50% {
                                    transform: translateX(-25%) translateZ(0);
                                }
                                100% {
                                    transform: translateX(-100%) translateZ(0);
                                }
                            }
                            
                            &.scroll {
                                svg {
                                    animation-play-state: running;
                                }
                            }
                            
                            svg {
                                animation: move_wave_mobile 2s forwards infinite;
                                animation-play-state: paused;
                                display: block;
                                flex-shrink: 0;
                                width: 100%;
                            }
                        }
                        
                        .text-container {
                            position: absolute;
                            top: 0;
                            left: 0;
                            margin-top: getVh(128px);
                            padding: 0 getVw(28px);
                            z-index: 1;
    
                            > strong {
                                @include fontStyle(20px, 700, 26px);
                                color: #ff60ae;
                            }
    
                            > p {
                                @include fontStyle(14px, 500, 23.8px, -0.7px);
                                color: #ff60ae;
                                margin-top: getVh(10px);
                            }
                        }

                        .rect-text {
                            position: absolute;
                            pointer-events: none;
                            transform: translate(-50%, -50%);
    
                            span {
                                display: block;
                                width: fit-content;
                                padding: getRem(5px) getRem(7px);
                                border-radius: getRem(20px);
                                border: 1px solid #fff;
                                @include fontStyle(12px, 500, 1.2, -0.5px);
                                color: #fff;
                                white-space: nowrap;
    
                                & ~ span {
                                    margin-top: getRem(5px);
                                    margin-left: getRem(42px);
                                }
                            }
                        }
    
                        .painter-bg {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            display: block;
                            width: 100%;
                            z-index: 1;
                            transform-origin: right;
    
                            img {
                                display: block;
                                width: 100%;
                            }
                        }
                    }
                    .section04 {
                        position: relative;
                        background-color: #000;
                        padding-bottom: getVh(139px);

                        .contents {
                            height: 100%;
    
                            .SolutionInfoIntro {
                                height: fit-content;
                                width: 100vw;
                                z-index: 2;
                                .title {
                                    color: #ff60ae;
                                }
                                &:nth-child(1) {
                                    position: relative;
                                }
                            }
    
                            .solution-introduction {
                                position: relative;
                                z-index: 2;
                                padding: 0 getVw(20px);
    
                                flex-direction: column;
                                color: #fff;
                                .detail-text {
                                    margin-top: getRem(20px);
                                }
                                .btn-link {
                                    background-color: #ff60ae;
                                }
                            }
                            .section04-bg {
                                position: absolute;
                                top: 0;
                                width: 100%;
                                overflow: hidden;
                                img {
                                    display: block;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
            }
            .SolutionSectionDestination {
                height: 150vh;
                &.black {
                    background-color: #000;
                    transition: background-color 0.5s;
                }

                .fade-in {
                    opacity: 0;
                }
                
                .sticky {
                    position: sticky;
                    top: 0;
                    padding-top: getVh(150px);
                    overflow: hidden;
                    height: getVh(782px);
                }
                
                .scroll-text {
                    font-family: $font-en;
                    @include fontStyle(80px, 400, 76px);
                    font-size: getVh(80px);
                    padding: 0 getVw(20px);
                    color: #fff;
                    text-transform: uppercase;
                    width: fit-content;
                }

                
                .msg-wrap {
                    display: flex;
                    flex-direction: column;
                    margin-top: getVh(130px);

                    .msg-en {
                        margin-left: getVw(22px);
    
                        .Typography {
                            font-family: $font-en;
                            @include fontStyle(20px, 400, 24px);
                            text-transform: uppercase;
                            color: #fff;
    
                            &:nth-child(2) {
                                margin-left: getVw(20px);
                            }
                        }
                    }

                    .msg-ko {
                        align-self: flex-end;
                        display: flex;
                        flex-direction: column;
                        margin-top: getVh(30px);
                        margin-right: getVw(14px);
                        width: getVw(300px);
    
                        .Typography {
                            @include fontStyle(14px, 400, 21px, -0.7px);
                            color: #fff;
    
                            &.right {
                                align-self: flex-end;
                            }
                        }
                    }
                }

                .tag {
                    &.handly {
                        margin-top: getVh(45px);
                        margin-left: getVw(97px);
                        > span {
                            transform: rotate(11deg);
                        }
                    }

                    &.popee {
                        margin-top: getVh(116px);
                        margin-left: getVw(30px);
                        > span {
                            transform: rotate(-5deg);
                        }
                    }
                    &.mentor {
                        margin-top: getVh(76px);
                        margin-left: getVw(290px);
                        > span {
                            transform: rotate(9deg);
                        }
                    }
                }
            }
            .SolutionSectionFinish {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: #000;
                padding-bottom: getVh(168px);

                .title {
                    font-family: $font-en;
                    @include fontStyle(14px, 400);
                    color: #fff;
                    margin-bottom: getVh(50px);
                }
                .solution {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    a {
                        display: inline-flex;
                        position: relative;
                        font-family: $font-en;
                        @include fontStyle(73.5px, 300, 80.85px);
                        text-transform: uppercase;
                        text-decoration: none;

                        > span {
                            display: inline-block;
                            position: relative;
                            z-index: 1;
                            background-color: #000;
                        }

                        .img {
                            display: flex;
                            align-items: center;
                            width: getVw(62px);

                            img {
                                display: block;
                                width: 100%;
                            }
                        }
                    }

                    &.handly {
                        a {
                            color: #6b3be9;
                        }

                        .rolling-msg-01 {
                            background-color: #a561c5;
                            margin-top: getPercent(1920, 70);
                            margin-left: getPercent(1920, 728);
                        }

                        .rolling-msg-02 {
                            background-color: #5b5b5b;
                            margin-top: getPercent(1920, 122);
                            margin-left: getPercent(1920, 1189);
                        }
                    }

                    &.popee {
                        a {
                            color: #ffffff;
                        }

                        .rolling-msg-01 {
                            margin-top: getPercent(1920, 72);
                            margin-left: getPercent(1920, 568);
                            background-color: #ff60ae;
                        }

                        .rolling-msg-02 {
                            font-family: $font-en;
                            margin-top: getPercent(1920, 135);
                            margin-left: getPercent(1920, 1035);
                            background-color: #5275d3;
                        }
                    }

                    &.mentor {
                        a {
                            color: #36a569;
                        }

                        .rolling-msg-01 {
                            margin-top: getPercent(1920, 88);
                            margin-left: getPercent(1920, 1194);
                            background-color: #2f6b38;
                        }

                        .rolling-msg-02 {
                            font-family: $font-en;
                            margin-top: getPercent(1920, 136);
                            margin-left: getPercent(1920, 750);
                            background-color: #ec7303;
                        }
                    }

                    .rolling-msg {
                        position: absolute;
                        display: flex;
                        width: fit-content;
                        border-radius: 16.5px;
                        overflow: hidden;
                        z-index: 2;
                        top: 0;
                        left: 0;
                        pointer-events: none;

                        .animation-container {
                            display: flex;
                            align-items: center;
                            animation: rollingMsgAni 5s linear forwards infinite;
                        }
                        .msg-container {
                            display: flex;
                            flex-shrink: 0;
                            align-items: center;

                            & ~ .msg-container {
                                position: absolute;
                                transform: translateX(100%);
                            }

                            @keyframes rollingMsgAni {
                                0% {
                                    transform: translateX(0);
                                }
                                100% {
                                    transform: translateX(-100%);
                                }
                            }

                            span {
                                display: block;
                                flex-shrink: 0;
                                font-family: $font-ko;
                                @include fontStyle(14px, 600, 16.8px, -0.7px);
                                color: #fff;
                                padding: getRem(8px) getRem(24px);
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
            .SolutionSectionHandly {
                .sticky {
                    position: sticky;
                    height: 100vh;
                    width: 100%;
                    top: 0;
                    .bg {
                        height: 100%;

                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .SolutionIntroduceIntro {
                    height: fit-content;
                    padding-bottom: getVh(80px);
                    .introduce {
                        width: 100%;
                        margin-top: getPercent(1920, 234);

                        .desktop-img {
                            position: relative;
                            width: getVw(335px);

                            img {
                                z-index: 1;
                                position: relative;
                                display: block;
                                width: 100%;
                            }
                        }

                        .text {
                            padding: 0 getVw(20px);
                            margin-top: getVh(120px);
                            strong {
                                @include fontStyle(25px, 700, 37.5px, -1.25px);
                            }
                            p {
                                @include fontStyle(13px, 500, 22.1px, -0.65px);
                                margin-top: getVh(20px);
                            }
                        }
                    }
                }

                .contents {
                    position: relative;
                    background-color: #fff;
                    padding: getVh(80px) getVw(20px);
                    width: 100%;
                    overflow: hidden;

                    .SolutionInfoIntro {
                        width: 100%;

                        .title {
                            > div {
                                > span {
                                    background: linear-gradient(
                                        156deg,
                                        #40dcfd 0%,
                                        #9f78fb 34.54%,
                                        #723dfc 100%
                                    );
                                    background-clip: text;
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                    backdrop-filter: blur(28.940698623657227px);
                                }
                            }
                        }
                    }

                    .solution-introduction {
                        gap: 0 getPercent(1920, 122);

                        .detail-text {
                            .btn-link {
                                background-color: #8b5ffc;
                            }
                        }
                    }
                }
            }
            .SolutionSectionMentor {
                .sticky {
                    position: sticky;
                    height: 100vh;
                    width: 100%;
                    top: 0;
                    .bg {
                        height: 100%;

                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
                .contents {
                    position: relative;
                    background-color: #fff;
                    padding: getVh(80px) getVw(20px);
                    .SolutionInfoIntro {
                        .title {
                            > div {
                                > span {
                                    color: #33a467;
                                }
                            }
                        }
                    }
                    .solution-introduction {

                        .detail-text {
                            margin-top: getVh(20px);

                            .btn-link {
                                background-color: #e94d20;
                            }
                        }
                    }
                }
            }

            .solution-introduction {
                margin-top: getVh(80px);
                
                .simple-text {
                    @include fontStyle(20px, 700, 30px, -1px);
                    white-space: nowrap;
                }
                
                .detail-text {
                    margin-top: getVh(20px);
                    > p {
                        @include fontStyle(14px, 500, 23.8px, -0.7px);

                        & ~ p {
                            margin-top: getVh(30px);
                        }

                        span.en {
                            font-family: $font-en;
                            letter-spacing: 0;
                            text-transform: uppercase;
                        }
                    }

                    .btn-link {
                        display: inline-block;
                        padding: getVh(16px) getVw(30px);
                        border-radius: getRem(46.5px);
                        @include fontStyle(20px, 700, 30px, -1px);
                        text-decoration: none;
                        color: #fff;
                        margin-top: getRem(40px);
                        text-transform: uppercase;
                    }
                }
            }

            .SolutionInfoIntro {
                .info-wrap {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .title {
                        text-align: center;

                        > div {
                            display: inline-block;

                            > span {
                                font-family: $font-en;
                                @include fontStyle(54px, 700, 64.8px);
                                font-size: getVw(54px);
                                display: inline-block;
                            }
                        }
                    }

                    .img {
                        position: absolute;
                        width: getVw(92px);
                        top: 50%;
                        transform: translateY(-50%);
                        img {
                            width: 100%;
                            display: block;
                            margin: 0 auto;
                            filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.3));
                        }
                    }
                }
            }

            .SolutionIntroduceIntro {
                position: sticky;
                top: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                gap: getRem(10px) 0;
                min-height: 100vh;
                margin-top: -50vh;

                .num {
                    font-family: $font-en;
                    @include fontStyle(36px, 300, 39.6px);
                    text-transform: uppercase;
                    color: #fff;
                }

                .text {
                    @include fontStyle(14px, 700, 16.8px);
                    color: #fff;
                    text-transform: uppercase;
                }

                strong {
                    margin-top: getVh(20px);
                    > div {
                        display: inline-block;
                        > span {
                            display: inline-block;
                            font-family: $font-en;
                            @include fontStyle(65px, 500, 71.5px);
                            text-transform: uppercase;
                            color: #fff;
                        }
                    }
                }
            }

            .tag {
                position: absolute;
                display: inline-block;
                top: 0;
                left: 0;
                transform: translateY(500%);
                span {
                    opacity: 0;
                    display: block;
                    padding: getRem(6px) getRem(10px);
                    @include fontStyle(12px, 700, 14.4px);
                    color: #fff;
                    background-color: rgba(146, 146, 146, 0.5);
                    transform: rotate(11deg);
                    border-radius: getRem(30px);
                    border: 0.5px solid #b9b9b9;
                    border-radius: getRem(30px);
                    @include blur(10px);
                }
            }
        }

        
        // NotFound
        .NotFound {
            position: relative;
            height: 100vh;
            overflow: hidden;
            background-color: #000;


            &.symbol-show {
                .symbol {
                    opacity: 1;
                }
            }

            .symbol {
                position: absolute;
                opacity: 0;
                transition: opacity 0.5s;
                pointer-events: none;
                
                &.symbol-01 {
                    width: getVw(297px);
                    top: getVh(132px);
                    left: getVw(-84px);
                }
                &.symbol-02 {
                    width: getVw(258px);
                    bottom: getVh(204px);
                    right: getVw(-76px);

                    img {
                        transform: rotate(110deg);
                    }
                }
                &.symbol-03 {
                    width: getVw(243px);
                    bottom: getVh(-31px);
                    left: getVw(-41px);


                    img {
                        transform: rotate(124deg);
                    }
                }
                &.symbol-04 {
                    width: getVw(280px);
                    right: getVw(-97px);
                    top: getVh(-16px);

                    img {
                        transform: rotate(-30deg);
                    }
                }

                img {
                    display: block;
                    width: 100%;
                }
            }

            .typo-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: absolute;
                width: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                .typo-wrap {
                    width: fit-content;
                    &:first-child {
                        margin-bottom: getRem(28px);
                    }
                    a {
                        text-decoration: none;
                    }
                    .Typography {
                        text-align: center;
                        @include fontStyle(66px, 300, 66px, -1.32px);
                        font-size: getVw(66px);
                        color: #fff;

                        
                        .text-wrap {
                            transition-timing-function: cubic-bezier(0.23,1,0.32,1);
                            transition-duration: 1.1s;
                        }
    
                        .btn-back {
                            position: absolute;
    
                            .Tag {
                                @include fontStyle(16px, 500, 22.4px, -0.64px);
                            }
                        }
                    }
                }

                .btn-back {
                    position: absolute;
                    cursor: pointer;
                    width: 100%;
                    transition: opacity 0.2s;
                    margin-top: getVh(60px);
                    transform: rotate(8deg);

                    @keyframes showBtn {
                        0% {
                            transform: translateX(-100%);
                        }
                        100% {
                            transform: translateX(0%);
                        }
                    }
                    
                    > a {
                        display: block;
                        transform: translateX(-100%);
                        animation: showBtn 0.5s 0.6s forwards;
                        text-decoration: none;
                        
                        .Tag {
                            position: relative;
                            width: fit-content;
                            white-space: nowrap;
                            margin: 0 auto;
                            border-radius: getRem(25px);
                            text-align: center;
                            padding: getRem(15px) getRem(26px);
                            @include fontStyle(12px, 500, 16.8px, -0.48px);
                        }
                    }
                }
            }

        }
    }
}
