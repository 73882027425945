@import "_mixins.scss";

.Pc {
    //base
    ul,
    li {
        list-style: none;
    }
    .screen-off {
        position: relative;
        width: 1px;
        height: 1px;
        margin: -1px;
        overflow: hidden;
        clip-path: polygon(0 0, 0 0, 0 0);
    }

    .clearfix::after {
        content: "";
        display: block;
        clear: both;
    }

    .reactive-rectangle {
        position: relative;
    }

    .reactive-rectangle::after {
        content: "";
        display: block;
    }

    .reactive-rectangle > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .reactive-rectangle > img {
        object-fit: cover;
    }

    .container {
        position: relative;
    }

    .reactive-rectangle::after {
        content: "";
        display: block;
    }

    .font-18 {
        font-size: 1.125rem;
        letter-spacing: -0.03em;
        line-height: 1.28;
    }
    .font-20 {
        font-size: 1.25rem;
    }

    .font-32 {
        font-size: 2rem;
    }

    .font-38 {
        font-size: 2.375rem;
    }

    .font-48 {
        font-size: 3rem;
    }

    .tit {
        font-weight: 600;
        letter-spacing: -0.03em;
        line-height: 3.75rem;
    }

    ///

    .work-detail-header {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
    }

    .work-detail-header .gnb {
        width: 100%;
        justify-content: flex-end;
    }

    .work-detail-header .gnb .header-menu-container {
        justify-content: flex-end;
    }

    .work-detail-cover {
        position: relative;
        width: 100%;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 10.156% 0 0;
        overflow: hidden;
    }

    .work-detail-cover .work-detail-cover-img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .work-detail-cover .work-detail-cover-img img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .work-detail-cover-text {
        margin-left: 9.323%;
    }

    .work-detail-cover .work-detail-cover-sub-title {
        font-size: 1.75rem;
        line-height: 1.285;
        color: #fff;
    }

    .work-detail-cover h3.work-detail-cover-title {
        font-size: 5rem;
        line-height: 1.262;
        font-weight: normal;
        color: #fff;
        margin-top: 0.8334%;
    }

    .work-detail-intro {
        display: flex;
        padding: 6.146% 9.271%;
    }

    .work-detail-intro .work-detail-desc {
        width: 49.617%;
        margin-right: 5.18%;
    }

    .work-detail-intro .work-detail-desc .work-detail-intro-text p {
        font-size: 1.5rem;
        line-height: 1.917;
        font-weight: 500;
        letter-spacing: -0.04em;
        word-break: break-all;
    }

    .work-detail-contents.dark .work-detail-intro .work-detail-desc .work-detail-intro-text p {
        color: #fff;
    }

    .work-detail-intro .work-detail-desc .work-detail-intro-text ~ .work-detail-intro-text {
        margin-top: 5.155%;
    }

    .work-detail-team > h5 {
        font-size: 1.25rem;
        letter-spacing: -0.03em;
        line-height: 1.2;
    }

    .work-detail-contents.dark .work-detail-team > h5 {
        color: #fff;
    }

    .work-detail-project-role {
        display: flex;
        flex-wrap: wrap;
    }

    .work-detail-wrap .work-detail-project-role article {
        min-width: 26.277%;
        flex-shrink: 0;
        margin-top: 24px;
    }

    .work-detail-wrap .work-detail-project-role article:nth-child(2) {
        width: 50%;
    }

    .work-detail-wrap .work-detail-project-role article > div::after {
        content: "";
        display: block;
        clear: both;
    }

    .work-detail-wrap .work-detail-project-role ul.project-people {
        float: left;
    }

    .work-detail-project-role article ul ~ ul {
        margin-left: 24px;
    }

    .work-detail-project-role article h6,
    .work-detail-project-role article ul li {
        font-size: 1.125rem;
        line-height: 1.666;
        letter-spacing: -0.03em;
    }

    .work-detail-contents.dark .work-detail-project-role article h6,
    .work-detail-contents.dark .work-detail-project-role article ul li {
        color: #fff;
    }

    div[class^="cover-img"] img {
        object-fit: contain;
    }

    .work-detail-container .work-detail-information {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .work-detail-container .work-detail-information-contents-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .work-detail-container .work-detail-information-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        /* overflow: hidden; */
    }

    .work-detail-container .work-detail-information-img ul:not(.swiper-wrapper) {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
    }

    .work-detail-information-img img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .work-detail-wrap video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    /* toyota */
    .work-detail-wrap[data-hash="toyota"] {
        .project-cover {
            .cover-img-wrap {
                background-image: url("../../../public/site/res/image/img_cover_toyota.webp");
                background-size: cover;
                background-position: left;
            }
        }
        .cover-text-wrap {
            .container {
                .project-desc {
                    margin-bottom: getPercent(1920, 198);
                }
            }
        }
    }
    .work-detail-wrap[data-hash="toyota"] {
        background-color: #1f1f1f;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information h5.work-detail-information-title {
        position: absolute;
        text-align: center;
        font-size: 5rem;
        letter-spacing: -0.03em;
        line-height: 1.262;
        color: #fff;
        font-weight: 400;
        top: 0;
        margin-top: 10.678%;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information h5.work-detail-information-title > div {
        display: inline-block;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-video {
        margin-top: -21.563%;
        width: 81.459%;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-video::after {
        padding-bottom: 48.85%;
    }

    /* work-detail-information-container-01 */
    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-01 {
        padding-bottom: 10.105%;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-01 .work-detail-information-img-01 {
        width: 100%;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-01 .work-detail-information-img-02 {
        width: 81.459%;
        margin-top: -20.99%;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-01 .work-detail-information-img-02 ul {
        flex-direction: column;
    }
    .work-detail-wrap[data-hash="toyota"]
        .work-detail-information-container-01
        .work-detail-information-img-02
        ul
        li
        ~ li {
        margin-top: 1.087%;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-01 .work-detail-information-img-03 {
        width: 12.188%;
        margin-top: 10.105%;
        margin-right: 16.198%;
        align-self: flex-end;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-01 .work-detail-information-img-04 {
        position: relative;
        width: 41.355%;
        margin-top: -0.73%;
        margin-left: 10.209%;
        align-self: flex-start;
        z-index: 2;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-01 .work-detail-information-img-05 {
        width: 63.698%;
        margin-top: -19.349%;
        align-self: flex-end;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-01 .work-detail-information-img-06 {
        width: 70.625%;
        margin-top: 9.376%;
        align-self: flex-start;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-01 .work-detail-information-img-07 {
        width: 41.355%;
        margin-top: -18.907%;
        margin-right: 9.74%;
        align-self: flex-end;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-01 .work-detail-information-img-08 {
        width: 81.459%;
        margin-top: 9.714%;
        padding: 5.938% 7.292%;
        background-color: #ededed;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-01 .work-detail-information-img-08 ul {
        align-items: center;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-01 .work-detail-information-img-08 li {
        width: 46.382%;
    }

    .work-detail-wrap[data-hash="toyota"]
        .work-detail-information-container-01
        .work-detail-information-img-08
        li:last-child {
        margin-top: 6.619%;
    }

    /* //work-detail-information-container-01 */
    /* work-detail-information-container-02 */
    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-02 .work-detail-information-img-01 {
        width: 100%;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-02 .work-detail-information-img-02 {
        width: 81.459%;
        margin-top: -21.667%;
    }

    /* //work-detail-information-container-02 */
    /* work-detail-information-container-03 */
    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-03 {
        padding: 10.678% 0;
        justify-content: center;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-03 .work-detail-information-bg {
        width: 63.75%;
        background-color: #000;
        border-radius: 100%;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-03 .work-detail-information-bg::after {
        padding-bottom: 100%;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-03 .work-detail-information-img-01 {
        position: absolute;
        width: 100%;
    }

    .work-detail-wrap[data-hash="toyota"]
        .work-detail-information-container-03
        .work-detail-information-img-01
        .swiper-wrapper
        .swiper-slide {
        width: 35.678%;
    }

    .work-detail-wrap[data-hash="toyota"]
        .work-detail-information-container-03
        .work-detail-information-img-01
        .swiper-wrapper
        .swiper-slide
        ~ .swiper-slide {
        margin-left: 4.219%;
    }

    /* //work-detail-information-container-03 */
    /* work-detail-information-container-04 */

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-04 {
        padding-bottom: 10.573%;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-04 .work-detail-information-img-01 {
        width: 100%;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-04 .work-detail-information-img-02 {
        width: 19.167%;
        margin-left: 19.74%;
        margin-top: -6.1%;
        align-self: flex-start;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-04 .work-detail-information-img-03 {
        width: 35.678%;
        margin-top: -22.761%;
        margin-right: 19.375%;
        align-self: flex-end;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-04 .work-detail-information-img-04 {
        width: 35.678%;
        align-self: flex-start;
        margin-left: 13.073%;
        margin-top: 9.428%;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-04 .work-detail-information-img-05 {
        width: 35.678%;
        align-self: flex-end;
        margin-right: 13.073%;
        margin-top: -30.662%;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-04 .work-detail-information-img-06 {
        width: 35.678%;
        margin-top: 12.22%;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-04 .work-detail-information-img-07 {
        width: 81.459%;
        margin-top: 9.857%;
    }

    .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-04 .work-detail-information-title {
        position: relative !important;
    }

    /* //work-detail-information-container-04 */
    /* //toyota */
    /* beautyselfpick */
    .work-detail-wrap[data-hash="beautyselfpick"] {
        .project-cover {
            .cover-img-wrap {
                background-image: url("../../../public/site/res/image/img_cover_beautyselfpick.webp");
                background-size: cover;
            }
            .cover-text-wrap {
                .container {
                    .project-desc {
                        margin-bottom: getPercent(1920, 198);
                    }
                }
            }
        }
    }
    .work-detail-wrap[data-hash="beautyselfpick"] .cover-img-01 {
        position: absolute;
        width: 31.771%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .work-detail-wrap[data-hash="beautyselfpick"] .cover-img-01::after {
        padding-bottom: 105.738%;
    }

    .work-detail-wrap[data-hash="beautyselfpick"] .work-detail-intro {
        background-color: #fff;
    }

    /* work-detail-information-container-01 */
    .work-detail-wrap[data-hash="beautyselfpick"] .work-detail-information-container-01 {
        padding: 6.146% 9.271% 0;
    }

    /* 1 */
    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-01
        .work-detail-information-img.work-detail-information-img-01 {
        width: 100%;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-01
        .work-detail-information-img-01
        ul
        li:first-child {
        background-color: #f5f5f5;
        display: flex;
        justify-content: center;
        padding: 0 4.211%;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-01
        .work-detail-information-img-01
        ul
        li {
        width: 48.977%;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-01
        .work-detail-information-img-01
        ul
        li:last-child {
        position: relative;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-01
        .work-detail-information-img-01
        ul
        li
        .video-rectangle {
        position: absolute;
        width: 33.813%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-01
        .work-detail-information-img-01
        ul
        li
        .video-rectangle::after {
        padding-bottom: 105.792%;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-01
        .work-detail-information-img-02 {
        width: 100%;
        margin-top: 2.043%;
    }

    /* //work-detail-information-container-01 */
    /* work-detail-information-container-02 */
    .work-detail-wrap[data-hash="beautyselfpick"] .work-detail-information-container-02 {
        width: 100%;
        padding: 8.907% 0 6.355%;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-02
        .work-detail-information-img-01 {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-02
        .work-detail-information-img-01::after {
        padding-bottom: 54.792%;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-02
        .work-detail-information-img-01
        .work-detail-information-img-list {
        display: flex;
        justify-content: center;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-02
        .work-detail-information-img-01
        .work-detail-information-img-list {
        transform: none;
        opacity: 1;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-02
        .work-detail-information-img-01
        ul {
        width: 5%;
        background-color: #f1f1f1;
        align-items: flex-start;
        justify-content: center;
        left: auto;
        padding-bottom: getPercent(997, 100);
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-02
        .work-detail-information-img-01.show
        ul {
        transition: width 2s;
        width: 63.854%;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-02
        .work-detail-information-img-01
        ul
        li {
        width: 24.47%;
        flex-shrink: 0;
        filter: drop-shadow(0px 12px 13px rgba(0, 0, 0, 0.03));
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-02
        .work-detail-information-img-01
        ul
        li
        ~ li {
        margin-left: 4.9%;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-02
        .work-detail-information-img-01
        ul
        li:nth-child(1) {
        margin-top: 24.062%;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-02
        .work-detail-information-img-01
        ul
        li:nth-child(2) {
        margin-top: 21.616%;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-02
        .work-detail-information-img-01
        ul
        li:nth-child(3) {
        margin-top: 19.169%;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-02
        .work-detail-information-img-01
        ul
        li:nth-child(4) {
        margin-top: 16.64%;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-02
        .work-detail-information-img-02 {
        align-self: flex-end;
        width: 16.928%;
        margin-right: 30.053%;
        margin-top: -2.292%;
        filter: drop-shadow(0px 12px 13px rgba(0, 0, 0, 0.03));
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-02
        .work-detail-information-img-03 {
        align-self: flex-start;
        width: 17.084%;
        margin-left: 30.313%;
        margin-top: -7.448%;
    }

    /* //work-detail-information-container-02 */
    /* work-detail-information-container-03 */
    .work-detail-wrap[data-hash="beautyselfpick"] .work-detail-information-container-03 {
        position: relative;
        padding: 0 0 25.834%;
        background-color: #f1f1f1;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-03
        div[class^="work-detail-information-img"]
        > .animation-container {
        border-radius: 10px;
        overflow: hidden;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-03
        .work-detail-information-img-01 {
        width: 26.563%;
        margin-top: -3.75%;
        margin-left: 16.822%;
        align-self: flex-start;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-03
        .work-detail-information-img-02 {
        position: absolute;
        width: 9.584%;
        top: 0;
        right: 16.511%;
        margin-top: -2.553%;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-03
        .work-detail-information-img-03 {
        position: absolute;
        width: 31.771%;
        align-self: flex-end;
        top: 0;
        margin-top: 19.427%;
        margin-right: 16.823%;
    }

    .work-detail-wrap[data-hash="beautyselfpick"] .work-detail-information-container-04 {
        padding: 19.219% 0 15.625%;
    }

    .work-detail-wrap[data-hash="beautyselfpick"]
        .work-detail-information-container-04
        .work-detail-information-img-01 {
        width: 33%;
    }

    /* //work-detail-information-container-05 */
    /* //daldabang*/
    /* lexus */
    .work-detail-wrap[data-hash="lexus"] {
        .project-cover {
            .cover-img-wrap {
                background-image: url("../../../public/site/res/image/img_cover_lexus.webp");
                background-size: cover;
            }
        }
        .cover-text-wrap {
            .container {
                .project-desc {
                    margin-bottom: getPercent(1920, 198);
                }
            }
        }
    }
    .work-detail-wrap[data-hash="lexus"] .btn-menu.close circle {
        fill: #4a4a4a;
    }

    .work-detail-wrap[data-hash="lexus"] .btn-menu.close rect {
        fill: #fff;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-contents {
        background-color: #e5e5e5;
    }

    /* work-detail-information-container-01 */
    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-01 {
        padding: 6.146% 9.271% 0;
    }

    /* 1 */
    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-01 .work-detail-information-video-01 {
        width: 100%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-01 .work-detail-information-video-01 {
        &::after {
            padding-bottom: 56.933%;
        }
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-01 .work-detail-information-img-01 {
        width: 100%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-01 .work-detail-information-img-01 {
        width: 100%;
        margin-top: 9.272%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-01 .work-detail-information-img-01::after {
        padding-bottom: 39.195%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-01 .work-detail-information-img-01 li {
        width: 48.977%;
    }

    /* //work-detail-information-container-01 */
    /* work-detail-information-container-02 */
    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-02 {
        justify-content: center;
        padding: 32.084% 0 36.25%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-02 .work-detail-information-img-01 {
        width: 100%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-02 .work-detail-information-img-01::after {
        padding-bottom: 17.709%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-02 .work-detail-information-img-01 ul {
        display: flex;
        height: 100%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-02 .work-detail-information-img-01 li {
        position: absolute;
    }

    .work-detail-wrap[data-hash="lexus"]
        .work-detail-information-container-02
        .work-detail-information-img-01
        li:nth-child(1) {
        width: 100%;
        height: 100%;
    }

    .work-detail-wrap[data-hash="lexus"]
        .work-detail-information-container-02
        .work-detail-information-img-01
        li:nth-child(2) {
        align-self: center;
        width: 18.23%;
        left: 20.105%;
    }

    .work-detail-wrap[data-hash="lexus"]
        .work-detail-information-container-02
        .work-detail-information-img-01
        li:nth-child(3) {
        width: 16.865%;
        right: 15.782%;
        margin-top: -11.771%;
    }

    .work-detail-wrap[data-hash="lexus"]
        .work-detail-information-container-02
        .work-detail-information-img-01
        li:nth-child(4) {
        width: 16.865%;
        margin-top: 9.479%;
        right: 25.844%;
    }

    /* //work-detail-information-container-02 */
    /* work-detail-information-container-03 */
    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-03 {
        justify-content: center;
        padding: 0 9.271%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-03 .work-detail-information-img-01 {
        width: 100%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-03 .work-detail-information-img-02 {
        align-self: flex-end;
        width: 20.461%;
        margin-right: 8.504%;
        margin-top: -2.622%;
        z-index: 1;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-03 .work-detail-information-img-02 ul {
        flex-direction: column;
    }

    .work-detail-wrap[data-hash="lexus"]
        .work-detail-information-container-03
        .work-detail-information-img-02
        ul
        li
        ~ li {
        margin-top: 11.286%;
    }

    /* //work-detail-information-container-03 */
    /* work-detail-information-container-04 */
    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-04 {
        justify-content: center;
        padding-top: 23.334%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-04 .work-detail-information-img-01 {
        width: 18.23%;
        z-index: 2;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-04 .work-detail-information-img-02 {
        position: absolute;
        width: 100%;
    }

    .work-detail-wrap[data-hash="lexus"]
        .work-detail-information-container-04
        .work-detail-information-img-02
        .swiper
        .swiper-wrapper
        .swiper-slide {
        width: 17.31%;
    }

    .work-detail-wrap[data-hash="lexus"]
        .work-detail-information-container-04
        .work-detail-information-img-02
        .swiper
        .swiper-wrapper
        .swiper-slide
        ~ .swiper-slide {
        margin-left: 2.822%;
    }

    /* //work-detail-information-container-04 */
    /* work-detail-information-container-05 */
    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-05 {
        padding-top: 14.584%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-05 .work-detail-information-bg.circle {
        position: absolute;
        width: 70.938%;
        top: 31.198%;
        border-radius: 100%;
        background-color: #ececec;
    }

    .work-detail-wrap[data-hash="lexus"]
        .work-detail-information-container-05
        .work-detail-information-bg.circle::after {
        padding-bottom: 100%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-05 .work-detail-information-img {
        z-index: 2;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-05 .work-detail-information-img-01 {
        width: 34.323%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-05 .work-detail-information-img-02 {
        margin-top: 4.584%;
        width: 60.938%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-05 .work-detail-information-img-02 ul {
        align-items: flex-start;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-05 .work-detail-information-img-02 li {
        width: 29.915%;
        filter: drop-shadow(0px 4px 69px rgba(0, 0, 0, 0.1));
    }

    .work-detail-wrap[data-hash="lexus"]
        .work-detail-information-container-05
        .work-detail-information-img-02
        li:nth-child(2) {
        margin-top: 15.983%;
    }

    /* //work-detail-information-container-05 */
    /* work-detail-information-container-06 */
    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-06:not(.for-mobile) {
        padding: 27.813% 0 24.896%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-06.for-mobile {
        display: none;
    }

    .work-detail-wrap[data-hash="lexus"]
        .work-detail-information-container-06:not(.for-mobile)
        .work-detail-information-img-01 {
        width: 18.23%;
        z-index: 2;
    }

    .work-detail-wrap[data-hash="lexus"]
        .work-detail-information-container-06:not(.for-mobile)
        .work-detail-information-img-02 {
        position: absolute;
        top: 0;
        margin-top: 22.657%;
        width: 23.386%;
        left: 20%;
    }

    .work-detail-wrap[data-hash="lexus"]
        .work-detail-information-container-06:not(.for-mobile)
        .work-detail-information-img-03 {
        position: absolute;
        bottom: 22.657%;
        width: 23.386%;
        left: 20%;
        z-index: 3;
    }

    .work-detail-wrap[data-hash="lexus"]
        .work-detail-information-container-06:not(.for-mobile)
        .work-detail-information-img-04 {
        position: absolute;
        top: 0;
        margin-top: 39.532%;
        width: 20.417%;
        right: 16.198%;
    }

    /* //work-detail-information-container-06 */
    /* work-detail-information-container-07 */
    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-07 .work-detail-information-img-01 {
        width: 100%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-07 .work-detail-information-img-02 {
        position: absolute;
        top: 7.032%;
        width: 59.896%;
        z-index: 1;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-07 .work-detail-information-img-02 li {
        width: 30.435%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-07 .work-detail-information-img-02 li img {
        height: auto;
    }

    .work-detail-wrap[data-hash="lexus"]
        .work-detail-information-container-07
        .work-detail-information-img-02
        li:nth-child(2) {
        margin-top: 23.653%;
    }

    .work-detail-wrap[data-hash="lexus"]
        .work-detail-information-container-07
        .work-detail-information-img-02
        li:nth-child(3) {
        margin-top: 11.566%;
    }

    /* //work-detail-information-container-07 */
    /* work-detail-information-container-08 */
    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-08 {
        overflow: hidden;
    }

    .work-detail-wrap[data-hash="lexus"]
        .work-detail-information-container-08
        div[class^="work-detail-information-bg"] {
        width: 100%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-08 .work-detail-information-bg-01 {
        background: linear-gradient(
            180deg,
            rgba(246, 246, 246, 0) 0%,
            rgba(214, 214, 214, 0.6) 25.51%,
            #878787 58.85%,
            #141414 100%
        );
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-08 .work-detail-information-bg-01 {
        &::after {
            padding-bottom: 94.636%;
        }
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-08 .work-detail-information-bg-02 {
        background-color: #141414;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-08 .work-detail-information-bg-02::after {
        padding-bottom: 26.146%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-08 .work-detail-information-bg-03 {
        // background-image: url('../../../public/site/res/image/img_lexus_section08_01.webp');
        background-image: url("../../../public/site/res/image/img_lexus_section08_01.webp");
        background-size: contain;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-08 .work-detail-information-bg-03::after {
        padding-bottom: 37.979%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-08 .work-detail-information-img-01 {
        position: absolute;
        width: 20.834%;
        bottom: 0;
        right: 20.469%;
    }

    .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-08 .work-detail-information-img-02 {
        position: absolute;
        width: 22.813%;
        bottom: 14.063%;
        left: 20.469%;
    }

    /* //work-detail-information-container-08 */
    /* //lexus*/
    /* ekolon */
    .work-detail-wrap[data-hash="ekolon"] {
        .project-cover {
            .cover-img-wrap {
                background-image: url("../../../public/site/res/image/img_cover_ekolon.webp");
                background-size: cover;
            }
            .cover-text-wrap {
                .container {
                    .project-desc {
                        margin-bottom: getPercent(1920, 203);
                    }
                }
            }
        }
    }
    .work-detail-wrap[data-hash="ekolon"] .btn-menu.close circle {
        fill: #001fd2;
    }

    .work-detail-wrap[data-hash="ekolon"] .btn-menu.close rect {
        fill: #fff;
    }

    .work-detail-wrap[data-hash="ekolon"] .fade-up.show > * {
        transition:
            transform 1.8s,
            opacity 2s;
    }

    .work-detail-wrap[data-hash="ekolon"] .work-detail-contents .work-detail-intro {
        padding-bottom: 12.032%;
    }
    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container {
        background-color: #fff;
    }
    /* work-detail-information-container-01 */
    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-01 {
        padding: 31.25% 0 25.105%;
    }

    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-01 .work-detail-information-img-00 {
        width: 15.625%;
        z-index: 2;
        filter: drop-shadow(4px 6px 16px rgba(0, 0, 0, 0.12));
    }

    .work-detail-wrap[data-hash="ekolon"]
        .work-detail-information-container-01
        .work-detail-information-img:not(.work-detail-information-img-00) {
        position: absolute;
    }

    /* 1 */
    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-01 .work-detail-information-img-01 {
        width: 42.032%;
        top: 5.209%;
        right: 8.803%;
    }

    /* 2 */
    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-01 .work-detail-information-img-02 {
        width: 21.875%;
        top: 19.792%;
        left: 10.834%;
    }

    /* 3 */
    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-01 .work-detail-information-img-03 {
        width: 31.25%;
        top: 54.167%;
        left: 16.198%;
    }

    /* 4 */
    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-01 .work-detail-information-img-04 {
        width: 20.834%;
        top: 72.896%;
        right: 23.125%;
    }

    /* 5 */
    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-01 .work-detail-information-img-05 {
        width: 12.5%;
        top: 53.907%;
        right: 6.303%;
    }

    .work-detail-wrap[data-hash="ekolon"]
        .work-detail-information-container-01
        .work-detail-information-img:nth-child(n + 7) {
        width: 7.292%;
    }

    /* 6 */
    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-01 .work-detail-information-img-06 {
        top: 11.146%;
        left: 34.948%;
    }

    /* 7 */
    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-01 .work-detail-information-img-07 {
        top: 47.24%;
        left: 5.625%;
    }

    /* 8 */
    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-01 .work-detail-information-img-08 {
        top: 57.24%;
        right: 27.032%;
    }

    /* 9 */
    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-01 .work-detail-information-img-09 {
        top: 87.605%;
        left: 11.98%;
    }

    /* 10 */
    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-01 .work-detail-information-img-10 {
        top: 77.657%;
        right: 6.303%;
    }

    /* //work-detail-information-container-01 */
    /* work-detail-information-container-02 */
    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-02 {
        padding: 0 9.271% 54.688%;
    }

    .work-detail-wrap[data-hash="ekolon"]
        .work-detail-information-container-02
        .work-detail-information-img:not(
            .work-detail-information-img-04,
            .work-detail-information-img-05,
            .work-detail-information-img-06
        ) {
        padding: 8.073% 9.48%;
        width: 42.52%;
    }

    /* 1 */
    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-02 .work-detail-information-img-01 {
        align-self: flex-start;
        background-color: #f6f7f8;
    }

    /* 2 */
    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-02 .work-detail-information-img-02 {
        align-self: flex-end;
        background-color: #b5b5b5;
        margin-top: -8.334%;
    }

    /* 3 */
    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-02 .work-detail-information-img-03 {
        align-self: flex-start;
        background-image: url("../../../public/site/res/image/img_ekolon_section02_03_bg.webp");
        background-size: cover;
    }

    /* 4 */
    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-02 .work-detail-information-img-04 {
        width: 100%;
        background-color: #f6f7f8;
        margin-top: 36.719%;
    }

    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-02 .work-detail-information-img-04::after {
        padding-bottom: 50.128%;
    }

    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-02 .work-detail-information-img-04 ul {
        justify-content: center;
        align-items: center;
        height: auto;
        bottom: 6.394%;
        top: auto;
    }

    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-02 .work-detail-information-img-04 ul li {
        width: 19.182%;
    }

    .work-detail-wrap[data-hash="ekolon"]
        .work-detail-information-container-02
        .work-detail-information-img-04
        ul
        li
        ~ li {
        margin-left: 5.116%;
    }

    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-02 .work-detail-information-img-05 {
        position: absolute;
        width: 100%;
        bottom: 0;
        margin-bottom: 14.063%;
    }

    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-02 .work-detail-information-img-06 {
        position: absolute;
        width: 100%;
        bottom: 0;
        margin-bottom: -12.149%;
        z-index: 1;
    }

    .work-detail-wrap[data-hash="ekolon"] {
        .work-detail-information-container-02 {
            .swiper-container {
                .swiper {
                    .swiper-wrapper {
                        .swiper-slide {
                            width: 19.8%;
                            margin-left: 4.167%;
                        }
                    }
                }
            }
        }
    }
    .work-detail-information-container-02 .swiper-container li {
        width: 19.8%;
    }

    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-02 .swiper-container li ~ li {
        margin-left: 4.167%;
    }

    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-02 .swiper-container ~ .swiper-container {
        margin-top: 4.167%;
    }

    /* //work-detail-information-container-02 */
    /* work-detail-information-container-03 */
    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-03 {
        background-color: #f6f7f8;
        padding-top: 90.521%;
    }

    .work-detail-wrap[data-hash="ekolon"]
        .work-detail-information-container-03
        .work-detail-information-img-container.for-mobile {
        display: none;
    }

    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-03 .work-detail-information-img-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        width: 100%;
        padding: 0 12.5%;
        top: 0;
        margin-top: 30.73%;
    }

    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-03 .work-detail-information-img {
        width: 20.834%;
    }

    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-03 .work-detail-information-img li ~ li {
        margin-top: 26.667%;
    }

    /* //work-detail-information-container-03 */
    /* work-detail-information-container-04 */
    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-04 {
        padding: 29.532% 9.271% 10.417%;
        overflow: hidden;
    }

    .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-04 .work-detail-information-img-01 {
        width: 100%;
        height: 100%;
        padding: 14.834% 32.226%;
        background-color: #1b1b1b;
    }

    /* //work-detail-information-container-04 */
    /* //ekolon */
    /* wego */
    .work-detail-wrap[data-hash="wego"] {
        .project-cover {
            .cover-img-wrap {
                background-image: url("../../../public/site/res/image/img_cover_wego.webp");
                background-size: cover;
            }
        }
        .cover-text-wrap {
            .container {
                .project-desc {
                    margin-bottom: getPercent(1920, 198);
                }
            }
        }
    }
    .work-detail-wrap[data-hash="wego"] .btn-menu.close circle {
        fill: #316bff;
    }

    .work-detail-wrap[data-hash="wego"] .btn-menu.close rect {
        fill: #fff;
    }

    .work-detail-wrap[data-hash="wego"] .work-detail-contents {
        background-color: #fff;
    }

    .work-detail-wrap[data-hash="wego"] .work-detail-project-role article {
        min-width: 35%;
    }

    /* work-detail-information-container-01 */
    .work-detail-wrap[data-hash="wego"]
        .work-detail-information-container-01
        .work-detail-information-img:not(.work-detail-information-img-01) {
        position: absolute;
        width: 19.532%;
        top: 0;
    }

    .work-detail-wrap[data-hash="wego"]
        .work-detail-information-container-01
        .work-detail-information-img:not(.work-detail-information-img-01)
        ul {
        flex-direction: column;
    }

    .work-detail-wrap[data-hash="wego"]
        .work-detail-information-container-01
        .work-detail-information-img:not(.work-detail-information-img-01)
        ul
        li
        img {
        -webkit-filter: drop-shadow(0px 16px 49px rgba(0, 0, 0, 0.08));
        filter: drop-shadow(0px 16px 49px rgba(0, 0, 0, 0.08));
    }

    .work-detail-wrap[data-hash="wego"]
        .work-detail-information-container-01
        .work-detail-information-img:not(.work-detail-information-img-01)
        ul
        li
        ~ li {
        margin-top: 25.6%;
    }

    .work-detail-wrap[data-hash="wego"] .work-detail-information-container-01 .for-mobile {
        display: none;
    }

    .work-detail-wrap[data-hash="wego"] .work-detail-information-container-01 .work-detail-information-img-01 {
        width: 100%;
    }

    .work-detail-wrap[data-hash="wego"] .work-detail-information-container-01 .work-detail-information-img-01::after {
        padding-bottom: 88.542%;
    }

    .work-detail-wrap[data-hash="wego"] .work-detail-information-container-01 .work-detail-information-img-02 {
        left: 14.532%;
        margin-top: 9.375%;
    }

    .work-detail-wrap[data-hash="wego"] .work-detail-information-container-01 .work-detail-information-img-03 {
        left: 40.209%;
        margin-top: 26.667%;
    }

    .work-detail-wrap[data-hash="wego"] .work-detail-information-container-01 .work-detail-information-img-04 {
        right: 14.584%;
        margin-top: 18.907%;
    }

    /* //work-detail-information-container-01 */
    /* work-detail-information-container-02 */
    .work-detail-wrap[data-hash="wego"] .work-detail-information-container-02 {
        padding: 48.803% 9.271% 41.771%;
    }

    .work-detail-wrap[data-hash="wego"] .work-detail-information-container-02 .work-detail-information-img-01 {
        width: 62.66%;
        align-self: flex-start;
    }

    .work-detail-wrap[data-hash="wego"] .work-detail-information-container-02 .work-detail-information-img-02 {
        position: absolute;
        width: 32.969%;
        right: 15%;
        top: 0;
        margin-top: 57.24%;
    }

    .work-detail-wrap[data-hash="wego"] .work-detail-information-container-02 .work-detail-information-img-02 ul {
        flex-direction: column;
    }

    .work-detail-wrap[data-hash="wego"]
        .work-detail-information-container-02
        .work-detail-information-img-02
        ul
        li
        ~ li {
        margin-top: 7.583%;
    }

    .work-detail-wrap[data-hash="wego"] .work-detail-information-container-02 .work-detail-information-img-02 ul li {
        position: relative;
        flex-basis: 0;
    }
    .work-detail-wrap[data-hash="wego"]
        .work-detail-information-container-02
        .work-detail-information-img-02
        ul
        li
        img {
        -webkit-filter: drop-shadow(0px 16px 49px rgba(0, 0, 0, 0.08));
        filter: drop-shadow(0px 16px 49px rgba(0, 0, 0, 0.08));
    }

    .work-detail-wrap[data-hash="wego"]
        .work-detail-information-container-02
        .work-detail-information-img-02
        ul
        li:nth-child(n + 2) {
        left: 15.955%;
    }

    /* //work-detail-information-container-02 */
    /* work-detail-information-container-03 */
    .work-detail-wrap[data-hash="wego"] .work-detail-information-container-03 {
        background-color: #f6f6f6;
        padding: 0 9.271%;
    }

    .work-detail-wrap[data-hash="wego"] .work-detail-information-container-03 .work-detail-information-img-01 {
        width: 100%;
        margin-top: -22.507%;
        border-radius: 100px;
    }

    .work-detail-wrap[data-hash="wego"] .work-detail-information-container-03 .work-detail-information-img-02 {
        width: 20.908%;
        margin-top: -12.405%;
        align-self: flex-start;
        margin-left: 5.499%;
    }

    .work-detail-wrap[data-hash="wego"] .work-detail-information-container-03 .work-detail-information-img-03 {
        width: 45.313%;
        margin-bottom: -17.264%;
        margin-top: -4.604%;
        margin-right: 10.295%;
        align-self: flex-end;
    }

    .work-detail-wrap[data-hash="wego"] .work-detail-information-container-03 .work-detail-information-img-03 li {
        width: 43.104%;
    }

    .work-detail-wrap[data-hash="wego"] .work-detail-information-container-03 .work-detail-information-img-03 li img {
        filter: drop-shadow(0px 16px 49px rgba(0, 0, 0, 0.08));
    }

    /* //work-detail-information-container-03 */
    /* work-detail-information-container-04 */
    .work-detail-wrap[data-hash="wego"] .work-detail-information-container-04 {
        padding: 37.5% 9.271% 9.948%;
    }

    .work-detail-wrap[data-hash="wego"]
        .work-detail-information-container-04
        .work-detail-information-img:not(.work-detail-information-img-04) {
        width: 100%;
    }

    .work-detail-wrap[data-hash="wego"]
        .work-detail-information-container-04
        .work-detail-information-img
        ~ .work-detail-information-img:not(.work-detail-information-img-04) {
        margin-top: 1.919%;
    }

    .work-detail-wrap[data-hash="wego"]
        .work-detail-information-container-04
        .work-detail-information-img-01
        li:nth-child(1) {
        width: 58.824%;
    }

    .work-detail-wrap[data-hash="wego"]
        .work-detail-information-container-04
        .work-detail-information-img-01
        li:nth-child(2) {
        width: 39.195%;
    }

    .work-detail-wrap[data-hash="wego"]
        .work-detail-information-container-04
        .work-detail-information-img-03
        li:nth-child(1) {
        width: 39.195%;
    }

    .work-detail-wrap[data-hash="wego"]
        .work-detail-information-container-04
        .work-detail-information-img-03
        li:nth-child(2) {
        width: 58.824%;
    }

    .work-detail-wrap[data-hash="wego"] .work-detail-information-container-04 .work-detail-information-img-04 {
        width: 41.177%;
        margin-top: 12.469%;
    }

    /* //work-detail-information-container-04 */
    /* //wego */
    /* picks */
    .work-detail-wrap[data-hash="picks"] {
        .project-cover {
            .cover-img-wrap {
                background-image: url("../../../public/site/res/image/img_cover_picks.webp");
                background-size: cover;
            }
        }
        .cover-text-wrap {
            .container {
                .project-desc {
                    margin-bottom: getPercent(1920, 198);
                }
            }
        }
        .work-detail-contents {
            .work-detail-information {
                background-color: #fff;
                .work-detail-information-container-04 {
                    .img-slide {
                        display: flex;
                        gap: 0 30px;

                        ul {
                            flex-shrink: 0;
                            animation: animateSymbolContainer 20s linear forwards infinite;
                        }
                    }
                }
            }
        }
    }
    .work-detail-wrap[data-hash="picks"] .btn-menu.close circle {
        fill: #eb128d;
    }

    .work-detail-wrap[data-hash="picks"] .btn-menu.close rect {
        fill: #fff;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-intro {
        background-color: #191919;
        padding-bottom: 62.469%;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-intro .work-detail-team > h5 {
        color: #eb128d;
    }

    /* work-detail-information-container-01 */
    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-01 {
        position: absolute;
        width: 90.782%;
        border-top-left-radius: 120px;
        // border-bottom-left-radius: 120px;
        align-self: flex-end;
        top: 0;
        // margin-top: -35.938%;
        z-index: 1;
        background-color: #fff;
        transform: translateY(-99%);
    }

    .work-detail-wrap[data-hash="picks"] {
        .work-detail-information-container-01 {
            &::after {
                padding-bottom: 48.711%;
            }
        }
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-01 .work-detail-information-img-container {
        display: flex;
        align-items: flex-start;
        padding-bottom: 4.069%;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-01 .work-detail-information-img-01 {
        position: relative;
        width: 32.092%;
        align-self: flex-end;
        margin-left: 11.462%;
        transform: translateY(-10%);
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-01 .work-detail-information-img-01::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 16px;
        background-color: #191919;
        opacity: 0;
        transition: opacity 0.6s;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-01 .work-detail-information-img-01::after {
        padding-bottom: 158.93%;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-01 .work-detail-information-img-01 img {
        transition:
            transform 2s,
            opacity 2.8s,
            margin 0.5s;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-01 .work-detail-information-img-01.on img {
        margin-top: -4.286%;
        margin-left: -4.286%;
    }

    .work-detail-wrap[data-hash="picks"]
        .work-detail-information-container-01
        .work-detail-information-img-01.on::before {
        opacity: 1;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-01 .work-detail-information-img-02 {
        width: 16.849%;
        margin-top: -5.788%;
        margin-left: 7.564%;
    }

    .work-detail-wrap[data-hash="picks"]
        .work-detail-information-container-01
        .work-detail-information-img-02
        .picks-video {
        width: 100%;
        border-radius: 16px;
        overflow: hidden;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-01 .work-detail-information-img-02 video {
        width: 100%;
        height: 100%;
    }

    .work-detail-wrap[data-hash="picks"]
        .work-detail-information-container-01
        .work-detail-information-img-02
        .picks-title {
        font-size: 1.625rem;
        font-weight: 700;
        letter-spacing: -0.03em;
        line-height: 1.192;
        margin-top: 5.443%;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-01 .work-detail-information-img-03 {
        width: 24.012%;
        align-self: flex-end;
    }

    /* //work-detail-information-container-01 */
    /* work-detail-information-container-02 */
    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-02 {
        padding: 16.667% 9.271% 12.084%;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-02 .work-detail-information-img-01 {
        width: 100%;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-02 .work-detail-information-img-01 li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 49.041%;
        background-color: #f6f7f8;
    }

    .work-detail-wrap[data-hash="picks"]
        .work-detail-information-container-02
        .work-detail-information-img-01
        li:nth-child(1)
        img {
        width: 13.038%;
        height: auto;
        animation: loading 3s ease infinite;
    }

    @keyframes loading {
        0% {
            transform: scale3d(1, 1, 1);
        }

        10%,
        5% {
            transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -5deg);
        }

        15%,
        25%,
        35%,
        45% {
            transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 5deg);
        }

        20%,
        30%,
        40% {
            transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -5deg);
        }

        50% {
            transform: scale3d(1, 1, 1);
        }
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-02 .work-detail-information-img-02 {
        width: 16.849%;
        margin-top: -5.788%;
        margin-left: 7.564%;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-02 .work-detail-information-img-03 {
        width: 100%;
        margin-top: 2.047%;
    }

    /* //work-detail-information-container-02 */
    /* work-detail-information-container-03 */
    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-03 {
        flex-direction: row;
        justify-content: space-between;
        padding: 12.917% 7.657% 0;
        background-color: #000;
        align-items: flex-start;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-03 .work-detail-information-img.for-mobile {
        display: none;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-03 .work-detail-information-img ul {
        flex-direction: column;
    }

    .work-detail-wrap[data-hash="picks"]
        .work-detail-information-container-03
        .work-detail-information-img:not(.work-detail-information-img-02)
        ul
        li
        ~ li {
        margin-top: 13.334%;
    }

    .work-detail-wrap[data-hash="picks"]
        .work-detail-information-container-03
        .work-detail-information-img-02
        ul
        li
        ~ li {
        margin-top: 5.926%;
    }

    .work-detail-wrap[data-hash="picks"]
        .work-detail-information-container-03
        .work-detail-information-img:not(.work-detail-information-img-02) {
        width: 22.141%;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-03 .work-detail-information-img-02 {
        width: 49.816%;
    }

    .work-detail-wrap[data-hash="picks"]
        .work-detail-information-container-03
        .work-detail-information-img-02
        li:nth-child(3),
    .work-detail-wrap[data-hash="picks"]
        .work-detail-information-container-03
        .work-detail-information-img-02
        li:nth-child(3)
        video {
        border-radius: 20px;
        overflow: hidden;
    }

    /* //work-detail-information-container-03 */
    /* work-detail-information-container-04 */
    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-04 {
        padding: 22.291% 0 15.73%;
        background-color: #000;
    }

    .picks-section-04-title {
        font-weight: bold;
        font-size: 3.5rem;
        letter-spacing: -0.09em;
        line-height: 1.214;
        color: #fff;
        margin-left: 5.938%;
        align-self: flex-start;
        margin-bottom: 2.605%;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-04 .work-detail-information-img-01 {
        width: 100%;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-04 .swiper-wrapper {
        justify-content: space-between;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-04 .swiper-slide {
        width: 15.053%;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-04 .swiper-slide ~ .swiper-slide {
        margin-left: 1.25%;
    }

    /* //work-detail-information-container-04 */
    /* work-detail-information-container-05 */
    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-05 {
        padding: 19.48% 0 5.261%;
        overflow: hidden;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-05 .work-detail-information-bg {
        width: 73.959%;
        align-self: flex-end;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-05 .work-detail-information-bg::after {
        padding-bottom: 54.93%;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-05 .work-detail-information-bg span {
        width: 100%;
        height: 100%;
        background-color: #eb128d;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-05 .work-detail-information-img-01 {
        position: absolute;
        align-self: flex-start;
        width: 46.25%;
    }

    .work-detail-wrap[data-hash="picks"] .work-detail-information-container-05 .work-detail-information-img-02 {
        position: absolute;
        align-self: flex-end;
        width: 53.333%;
        bottom: 0;
    }

    /* //work-detail-information-container-05 */
    /* //picks */
    /* styler */
    .work-detail-wrap[data-hash="styler"] {
        .project-cover {
            .cover-img-wrap {
                background-image: url("../../../public/site/res/image/img_cover_styler02.webp");
                background-size: cover;
            }
        }
        .cover-text-wrap {
            .container {
                .project-desc {
                    margin-bottom: getPercent(1920, 198);
                }
            }
        }
    }
    .work-detail-wrap[data-hash="styler"] {
        background-color: #fffcf9;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-cover {
        border-bottom: 1px solid #000;
    }

    .work-detail-wrap[data-hash="styler"] .btn-menu.close circle {
        fill: #fbe74d;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-cover-sub-title {
        color: #000;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-cover-title {
        color: #000;
        font-weight: 600;
        letter-spacing: -0.03em;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container {
        border-top: 1px solid #000;
    }

    .work-detail-wrap[data-hash="styler"] .img-clip-container {
        overflow: hidden;
        border: 1px solid black;
    }

    /* work-detail-information-container-01 */
    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-01 {
        padding: 2.709% 1.146%;
        overflow: hidden;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-01 .work-detail-information-img-01 {
        width: 100%;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-01 .work-detail-information-img-01 li {
        padding: 0 5.313%;
        will-change: transform;
    }

    .work-detail-wrap[data-hash="styler"]
        .work-detail-information-container-01
        .work-detail-information-img-01
        li
        ~ li {
        border-left: 1px solid #000;
    }

    .work-detail-wrap[data-hash="styler"]
        .work-detail-information-container-01
        .work-detail-information-img-01
        li:not(li:nth-child(2))
        .img-clip-container {
        border-radius: 300px 300px 0px 0px;
    }

    .work-detail-wrap[data-hash="styler"]
        .work-detail-information-container-01
        .work-detail-information-img-01
        li:nth-child(2)
        .img-clip-container {
        border-radius: 230px;
    }

    /* //work-detail-information-container-01 */
    /* work-detail-information-container-02 */
    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-02 {
        padding: 6.1% 0 7.448%;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-02 .work-detail-information-img-01 {
        align-self: flex-start;
        margin-top: 6.094%;
        margin-left: 10.886%;
        width: 35.462%;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-02 .work-detail-information-img-02 {
        width: 19.532%;
        margin-top: -4.125%;
        z-index: 2;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-02 .work-detail-information-img-03 {
        width: 45.886%;
        margin-top: -39.375%;
        margin-right: 6.511%;
        align-self: flex-end;
    }

    /* //work-detail-information-container-02 */
    /* work-detail-information-container-03 */
    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-03 {
        padding: 13.959% 9.271% 12.5%;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-03 .work-detail-information-img-01 {
        width: 76.727%;
        align-self: flex-start;
        margin-left: 13.236%;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-03 .work-detail-information-img-01::after {
        padding-bottom: 64.917%;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-03 .work-detail-information-img-01 img {
        object-fit: cover;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-03 .work-detail-information-img-02 {
        width: 52.814%;
        align-self: flex-start;
        margin-top: -36.509%;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-03 .work-detail-information-img-02 li {
        width: 45.4%;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-03 .work-detail-information-img-03 {
        width: 23.977%;
        margin-top: 6.394%;
        margin-right: 31.97%;
        align-self: flex-end;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-03 .work-detail-information-img-04 {
        position: relative;
        width: 35.295%;
        margin-top: -30.947%;
        margin-left: -1.087%;
        align-self: flex-end;
    }

    .work-detail-wrap[data-hash="styler"]
        .work-detail-information-container-03
        .work-detail-information-img-04
        .img-clip-container {
        padding: 6.341%;
        background-color: #fce54d;
        border: 1px solid #000;
    }

    .work-detail-wrap[data-hash="styler"]
        .work-detail-information-container-03
        .work-detail-information-img-04
        .img-clip-container
        img {
        border: 1px soli d #000;
    }

    /* //work-detail-information-container-03 */
    /* work-detail-information-container-04 */
    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-04 {
        padding: 2.709% 1.146%;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-04 .work-detail-information-img-01 {
        width: 100%;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-04 .work-detail-information-img-01 li {
        padding: 2.879% 6.663%;
        width: 100%;
    }

    .work-detail-wrap[data-hash="styler"]
        .work-detail-information-container-04
        .work-detail-information-img-01
        li
        ~ li {
        border-left: 1px solid #000;
    }

    .work-detail-wrap[data-hash="styler"]
        .work-detail-information-container-04
        .work-detail-information-img-01
        li:nth-child(2)
        .img-clip-container,
    .work-detail-wrap[data-hash="styler"]
        .work-detail-information-container-04
        .work-detail-information-img-01
        li:nth-child(2)
        .img-clip-container
        video {
        border-radius: 300px 300px 0px 0px;
    }

    .work-detail-wrap[data-hash="styler"]
        .work-detail-information-container-04
        .work-detail-information-img-01
        li:nth-child(2)
        .img-clip-container::after {
        padding-bottom: 216.534%;
    }

    /* //work-detail-information-container-04 */
    /* work-detail-information-container-05 */
    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-05 {
        padding: 13.959% 7.657%;
        overflow: hidden;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-05 .work-detail-information-img-01 {
        width: 33.723%;
        align-self: flex-start;
    }

    .work-detail-wrap[data-hash="styler"]
        .work-detail-information-container-05
        .work-detail-information-img-02
        .img-clip-container {
        padding: 8.03% 6.387%;
        background-color: #000;
    }

    .work-detail-wrap[data-hash="styler"]
        .work-detail-information-container-05
        .work-detail-information-img:nth-child(n + 2):nth-child(-n + 4) {
        position: absolute;
        top: 0;
        left: 0;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-05 .work-detail-information-img-02 {
        width: 28.542%;
        margin-left: 13.386%;
        margin-top: 18.073%;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-05 .work-detail-information-img-03 {
        width: 16.198%;
        margin-left: 48.594%;
        margin-top: 23.073%;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-05 .work-detail-information-img-03 {
        width: 16.198%;
        margin-left: 48.594%;
        margin-top: 23.073%;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-05 .work-detail-information-img-04 {
        width: 26.823%;
        margin-left: 48.594%;
        margin-top: 42.032%;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-05 .work-detail-information-img-04 ul {
        width: 100%;
        height: 100%;
    }

    .work-detail-wrap[data-hash="styler"]
        .work-detail-information-container-05
        .work-detail-information-img-04
        ul
        li
        ~ li {
        position: absolute;
        width: 100%;
    }

    .work-detail-wrap[data-hash="styler"]
        .work-detail-information-container-05
        .work-detail-information-img-04
        ul
        li:nth-child(1) {
        position: relative;
        z-index: 3;
    }

    .work-detail-wrap[data-hash="styler"]
        .work-detail-information-container-05
        .work-detail-information-img-04
        ul
        li:nth-child(2) {
        margin-top: -17.865%;
        margin-left: 28.156%;
        z-index: 2;
    }

    .work-detail-wrap[data-hash="styler"]
        .work-detail-information-container-05
        .work-detail-information-img-04
        ul
        li:nth-child(3) {
        margin-top: -35.73%;
        margin-left: 56.312%;
        z-index: 1;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-05 .work-detail-information-img-05 {
        width: 56.37%;
        margin-top: 20.862%;
        align-self: flex-end;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-05 .work-detail-information-img-06 {
        width: 40.247%;
        margin-top: -10.092%;
        margin-left: 21.477%;
        align-self: flex-start;
    }

    .work-detail-wrap[data-hash="styler"]
        .work-detail-information-container-05
        .work-detail-information-img-06
        .img-clip-container {
        border-radius: 327px;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-05 .work-detail-information-img-07 {
        width: 23.077%;
        margin-top: -44.8%;
        margin-right: 22.154%;
        align-self: flex-end;
    }

    /* //work-detail-information-container-05 */
    /* work-detail-information-container-06 */
    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-06 {
        padding: 14.584% 0%;
        border-top: none;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-06 .work-detail-information-img-01 {
        width: 52.761%;
        align-self: flex-start;
        margin-left: 14.584%;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-06 .work-detail-information-img-02 {
        width: 21.145%;
        align-self: flex-start;
        margin-left: 10%;
        margin-top: -18.75%;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-06 .work-detail-information-img-03 {
        width: 31.25%;
        align-self: flex-end;
        margin-right: 9.271%;
        margin-top: -12.709%;
    }

    /* //work-detail-information-container-06 */
    /* work-detail-information-container-07 */
    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-07 {
        padding: 2.708% 0%;
        flex-direction: row;
        border-bottom: 1px solid #000;
        overflow: hidden;
        margin-bottom: 2.813%;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-07 .work-detail-information-img-01 {
        width: 66.303%;
        padding: 3.282% 5% 3.282% 6.511%;
        border-right: 1px solid #000;
    }

    .work-detail-wrap[data-hash="styler"]
        .work-detail-information-container-07
        .work-detail-information-img-01
        .img-clip-container {
        padding: 3.327%;
        background-color: #fce54d;
    }

    .work-detail-wrap[data-hash="styler"]
        .work-detail-information-container-07
        .work-detail-information-img-01
        .img-clip-container
        img {
        border: 1px solid #000;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-07 .work-detail-information-img-02 {
        padding: 3.282% 5.313% 3.282%;
        width: 33.698%;
    }

    .work-detail-wrap[data-hash="styler"] .work-detail-information-container-07 .work-detail-information-img-02 ul {
        flex-direction: column;
        align-items: center;
    }

    .work-detail-wrap[data-hash="styler"]
        .work-detail-information-container-07
        .work-detail-information-img-02
        li:nth-child(1)
        .img-clip-container {
        border-radius: 300px 300px 0px 0px;
    }

    .work-detail-wrap[data-hash="styler"]
        .work-detail-information-container-07
        .work-detail-information-img-02
        li:nth-child(2) {
        margin-top: 8.346%;
    }

    .work-detail-wrap {
        // ---- (S) common
        .container {
            width: getPercent(1920, 1564);
            margin: 0 auto;
        }

        .d-flex {
            display: flex;
            &.stretch {
                > * {
                    flex: 1 1;
                }
            }

            &.flex-wrap {
                flex-wrap: wrap;
            }
        }

        .justify-between {
            justify-content: space-between;
        }

        .reactive {
            background-size: contain;
            background-repeat: no-repeat;

            &::after {
                content: "";
                display: block;
            }
        }

        // (S) project-cover
        .project-cover {
            width: 100%;
            position: relative;
            height: auto;
            .cover-img-wrap {
                z-index: 0;
                position: sticky;
                width: 100%;
                height: 100vh;
                overflow: hidden;
                top: 0;
                left: 0;
                background-position: center;

                &::before,
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .cover-text-wrap {
                z-index: 1;
                position: relative;
                width: 100%;
                background-color: #fff;
                .award-mark {
                    width: 100%;

                    img {
                        width: 100%;
                    }
                }
                .container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    margin: 0;
                    padding-top: getPercent(1920, 156);
                    .project-type {
                        @include fontStyle(28px, 500, 35.5px, -0.03em);
                        font-family: $font-en;
                    }
                    .project-name {
                        @include fontStyle(240px, 400, 240px, -0.03em);
                        font-size: getVw(240px);
                        padding: getRem(24px) 0 getRem(43px);
                        text-transform: uppercase;
                        text-align: center;
                        font-family: $font-en;
                        overflow: hidden;
                        max-width: 100%;
                    }
                    .project-team {
                        display: flex;
                        gap: 0 10px;
                        .team-tag {
                            border-radius: 30px;
                            border: 0.5px solid #000;
                            padding: getRem(9px) getRem(15px);
                            @include fontStyle(13px, 400, 15.6px);
                            color: #000;
                            font-family: $font-en;
                            text-transform: uppercase;
                        }
                    }
                    .project-desc {
                        width: getPercent(1920, 802);
                        margin-top: getPercent(1920, 143);
                        margin-left: auto;
                        margin-right: getPercent(1900, 208);

                        p {
                            @include fontStyle(20px, 500, 38px, -0.04em);
                        }
                    }
                }

                &.dark {
                    background-color: #000;
                    .project-type,
                    .project-name,
                    .project-desc p {
                        color: #fff;
                    }
                    .project-team {
                        .team-tag {
                            border-color: #fff;
                            color: #fff;
                        }
                    }
                }
            }
        }
        // (E) project-cover

        // (S) project-contents
        .project-contents {
            &.dark {
                * {
                    color: #fff;
                }
            }
            section {
                position: relative;

                // (S) project-intro
                &.project-intro {
                    display: flex;
                    gap: 0 getPercent(1564, 100);
                    padding-top: getRem(102px);

                    > div {
                        flex: 1 1;
                        &.left {
                            .project-description {
                                > p {
                                    @include fontStyle(24px, 500, 46.5px, -0.04em);
                                }
                            }
                        }

                        &.right {
                            .project-team {
                                .team-title {
                                    @include fontStyle(20px, 700, 24.2px, -0.03em);
                                }

                                .team-row {
                                    display: flex;
                                    gap: 0 getPercent(766, 106);
                                    margin-top: getRem(24px);

                                    .team-list {
                                        width: getPercent(725, 121);
                                        li {
                                            @include fontStyle(18px, 400, 30px, -0.03em);
                                            white-space: nowrap;
                                            &.team-name {
                                                font-weight: 600;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                // (E) project-intro
            }
        }
        // (E) project-contents

        *[class^="cont-img"] {
            &::before,
            img {
                display: block;
                width: 100%;
            }
        }

        @keyframes imgSlideAni {
            from {
                transform: translate3d(0px, 0px, 0px);
            }
            to {
                transform: translate3d(-100%, 0px, 0px);
            }
        }

        .img-slide-wrap {
            width: 100%;
            overflow: hidden;
            .img-slide {
                animation: imgSlideAni 15s linear forwards infinite;
                display: flex;
                width: fit-content;
                ul {
                    flex-shrink: 0;
                    display: flex;
                    justify-content: space-around;

                    li {
                        flex-shrink: 0;
                    }
                }
            }
        }
        // ---- (E) common

        &[data-hash="kiaRental"] {
            background-color: #000;

            .btn-menu {
                circle {
                    fill: #000;
                }
                rect {
                    fill: #fff;
                }
            }

            .project-cover {
                .cover-img-wrap {
                    background: {
                        image: url("../../../public/site/res/image/img_cover_kiaRental.webp");
                        size: cover;
                    }
                }
                .cover-text-wrap {
                    .project-desc {
                        margin-bottom: getPercent(1920, 240);
                    }
                }
            }
            .cont-sec-01 {
                padding-top: getPercent(1920, 220);
                .cont-wrap {
                    display: flex;
                    position: relative;
                    gap: 0 getPercent(1564, 100);

                    *[class^="cont-img"] {
                        flex: 1 1;
                    }

                    .cont-img-02 {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 1;
                        width: getPercent(1564, 157);
                    }
                }
            }

            .cont-sec-02 {
                overflow: hidden;
                padding: getPercent(1920, 260) 0 getPercent(1920, 270);
                background: {
                    image: url("../../../public/site/res/image/img_kia_section_02_bg.webp");
                    position: right top;
                    repeat: no-repeat;
                    size: getPercent(1920, 1674) auto;
                }
                *[class^="cont-img"]:not(.cont-img-01) {
                    position: absolute;

                    > * {
                        opacity: 1 !important;
                        transition: transform 0.7s;
                    }
                }
                .cont-img-01 {
                    position: relative;
                    margin: 0 auto;
                    width: getPercent(1920, 445);
                    z-index: 2;
                }

                .cont-img-02 {
                    width: getPercent(1920, 672);
                    top: getPercent(1708, 629);
                    left: getPercent(1920, 100);
                    z-index: 1;

                    > * {
                        transform: translateX(-50%);
                    }
                }

                .cont-img-03 {
                    width: getPercent(1920, 663);
                    right: getPercent(1920, 100);
                    top: getRem(808px);
                    z-index: 3;

                    > * {
                        transform: translateX(50%);
                    }
                }
            }

            .cont-sec-03 {
                *[class^="cont-img"] {
                    flex: 1 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50%;

                    .animation-container {
                        width: 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        &::after {
                            padding-bottom: getPercent(960, 1080);
                        }
                    }
                }

                .cont-img-01 {
                    background-color: #979d9f;
                    .animation-container {
                        background-image: url("../../../public/site/res/image/img_kia_section_03_01.webp");
                        background-size: getPercent(960, 375);
                    }
                }
                .cont-img-02 {
                    background-color: #34414a;
                    .animation-container {
                        background-image: url("../../../public/site/res/image/img_kia_section_03_02.webp");
                        background-size: getPercent(960, 320);
                    }
                }
            }

            .cont-sec-04 {
                padding-top: getPercent(1920, 360);
                background-color: #cdd0d2;

                .d-flex {
                    gap: getPercent(1564, 100);
                    margin-top: getPercent(1564, 360);
                }

                .cont-img-01 {
                    background: {
                        image: url("../../../public/site/res/image/img_kia_section_04_01.webp");
                        repeat: no-repeat;
                        size: cover;
                    }
                    &::before {
                        content: "";
                        display: block;
                        padding-bottom: getPercent(1564, 864);
                    }
                }
            }

            .cont-sec-05 {
                padding-top: getPercent(1920, 360);
                background-color: #cdd0d2;

                .img-card-list-wrap {
                    width: 100%;
                    overflow: hidden;
                    &:nth-child(2) {
                        margin-top: getRem(40px);
                        ul {
                            transform: translateX(#{getPercent(1920, -100)});
                        }
                    }
                    ul {
                        display: flex;
                        gap: 0 getPercent(1920, 40);
                        width: 100%;

                        li {
                            flex-shrink: 0;
                            width: getPercent(1920, 372);

                            img {
                                display: block;
                                width: 100%;
                            }
                        }
                    }
                }

                .d-flex {
                    margin-top: getRem(194px);
                    gap: 0 getPercent(1564, 120);

                    .cont-wrap {
                        transform: translateY(-25%);
                    }
                }

                *[class^="cont-img"] {
                    img {
                        border-radius: 40px;
                        border: 10px solid #fff;
                    }

                    &.cont-img-01,
                    &.cont-img-03 {
                        img {
                            box-shadow: 96px 116px 140px -50px rgba(0, 0, 0, 0.2);
                        }
                    }

                    &.cont-img-04 {
                        margin-top: getRem(153);
                    }
                }
            }

            .cont-sec-06 {
                .cont-wrap {
                    overflow: hidden;
                    *[class^="cont-img"] {
                        width: 50%;

                        &::after {
                            content: "";
                            display: block;
                            padding-bottom: 100%;
                        }

                        @for $i from 1 to 5 {
                            &.cont-img-0#{$i} {
                                background-image: url("../../../public/site/res/image/img_kia_section_06_0#{$i}.webp");
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: center;
                            }
                        }
                    }
                }
            }

            .cont-sec-07 {
                padding-top: getPercent(1920, 360);
                padding-bottom: getPercent(1920, 200);

                .cont-img-01 {
                    background: {
                        image: url("../../../public/site/res/image/img_kia_section_07_01.webp");
                        repeat: no-repeat;
                        size: cover;
                    }

                    &::after {
                        content: "";
                        display: block;
                        padding-bottom: getPercent(1564, 1080);
                    }
                }
            }

            .cont-sec-08 {
                padding-top: getPercent(1920, 468);
                padding-bottom: getPercent(1920, 399);
                background: {
                    image: url("../../../public/site/res/image/img_kia_section_08_bg.webp");
                    repeat: no-repeat;
                    size: contain;
                }

                .cont-img-01 {
                    width: getPercent(1564, 1600);
                }
            }

            .cont-sec-09 {
                padding: getPercent(1920, 144) 0;
                background-color: #ccd0d0;

                .cont-img-01 {
                    width: getPercent(1564, 1130);
                    margin: 0 auto;
                }
            }

            .cont-sec-10 {
                padding-top: getPercent(1920, 180);
                background-color: #fff;

                .cont-img-01 {
                    width: getPercent(1920, 1060);
                    margin-left: getPercent(1920, 180);
                }

                .cont-img-02 {
                    position: absolute;
                    right: 0;
                    top: getPercent(1920, 256);
                    width: getPercent(1920, 620);
                }
                .cont-img-03 {
                    width: getPercent(1920, 1130);
                    margin-top: getPercent(1920, 240);
                    transform: translateX(#{getPercent(1920, -66)});

                    img {
                        box-shadow: 75.3333px 91.0278px 109.861px -39.2361px rgba(0, 0, 0, 0.2);
                        border-radius: 20px;
                    }
                }

                .cont-img-04 {
                    position: relative;
                    z-index: 1;
                    width: getPercent(1920, 1130);
                    left: getPercent(1920, 612);
                    margin-top: getPercent(1920, 173);
                    transform: translateY(#{getPercent(1130, 67)});

                    img {
                        box-shadow: 75.3333px 91.0278px 109.861px -39.2361px rgba(0, 0, 0, 0.2);
                        border-radius: 20px;
                    }
                }
            }

            .cont-sec-11 {
                .cont-img-01 {
                    background: {
                        image: url("../../../public/site/res/image/img_kia_section_11_01.webp");
                        size: cover;
                        repeat: no-repeat;
                    }
                    &::after {
                        content: "";
                        display: block;
                        padding-bottom: getPercent(1920, 1440);
                    }
                }
            }

            .cont-sec-12 {
                padding: getPercent(1920, 360) 0;

                .cont-img-01 {
                    width: getPercent(1920, 1564);
                    margin: 0 auto;
                    background: {
                        image: url("../../../public/site/res/image/img_kia_section_12_01.webp");
                        repeat: no-repeat;
                        size: cover;
                    }
                    &::after {
                        content: "";
                        display: block;
                        padding-bottom: getPercent(1564, 1080);
                    }
                }
            }

            .cont-sec-13 {
                .cont-wrap {
                    *[class^="cont-img"] {
                        width: 50%;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;

                        &::after {
                            content: "";
                            display: block;
                            padding-bottom: getPercent(960, 1080);
                        }

                        @for $i from 1 to 5 {
                            &.cont-img-0#{$i} {
                                background-image: url("../../../public/site/res/image/img_kia_section_13_0#{$i}.webp");
                            }
                        }
                    }
                }
            }

            .cont-sec-14 {
                padding: getPercent(1920, 662) 0;
                background: {
                    image: url("../../../public/site/res/image/img_kia_section_14_bg.webp");
                    size: cover;
                    repeat: no-repeat;
                }

                .cont-img-01 {
                    width: getPercent(1920, 926);
                    margin: 0 auto;
                }
            }
        }

        &[data-hash="metaPassport"] {
            background-color: #000;

            .btn-menu {
                circle {
                    fill: #000;
                }
                rect {
                    fill: #fff;
                }
            }

            .project-cover {
                .cover-img-wrap {
                    background: {
                        image: url("../../../public/site/res/image/img_cover_bg_metaPassport02.webp");
                        size: cover;
                        position: bottom;
                    }
                }
                .cover-text-wrap {
                    background-color: #fff;

                    .project-desc {
                        margin-bottom: getPercent(1920, 322);
                    }
                }
            }

            .project-contents {
                background: {
                    image: url("../../../public/site/res/image/img_bg_metaPassport.webp");
                    repeat: no-repeat;
                    size: 100% auto;
                }

                .project-intro {
                    .left {
                        .project-description {
                            word-break: break-all;
                        }
                    }
                }

                .cont-sec-01 {
                    padding: getPercent(1920, 545) 0 getPercent(1920, 494);
                    .cont-img-01 {
                        width: getPercent(1920, 457);
                        margin: 0 auto;
                    }
                }

                .cont-sec-02 {
                    .cont-img-01 {
                        .animation-container {
                            background-image: url("../../../public/site/res/image/img_mpp_section_01_01.webp");
                            &::after {
                                padding-bottom: getPercent(1564, 600);
                            }
                        }
                    }
                    .cont-wrap-01 {
                        gap: 0 getPercent(1920, 32);
                        margin-top: getPercent(1920, 32);
                        .cont-img-02 {
                            .animation-container {
                                background-image: url("../../../public/site/res/image/img_mpp_section_01_02.webp");
                                &::after {
                                    padding-bottom: getPercent(766, 760);
                                }
                            }
                        }
                        .cont-img-03 {
                            .animation-container {
                                background-image: url("../../../public/site/res/image/img_mpp_section_01_03.webp");
                                &::after {
                                    padding-bottom: getPercent(766, 760);
                                }
                            }
                        }
                    }
                }

                .cont-sec-03 {
                    padding: getPercent(1920, 733) 0 getPercent(1920, 676);
                    background: {
                        image: url("../../../public/site/res/image/img_mpp_bg_section_02.webp");
                        size: 100% auto;
                        repeat: no-repeat;
                    }

                    .container {
                        *[class^="cont-wrap"] {
                            width: getPercent(1564, 886);
                        }

                        .cont-wrap-01 {
                            margin-left: getPercent(1564, -65);
                        }

                        .cont-wrap-02 {
                            position: absolute;
                            right: 0;
                            top: 0;
                            margin-top: getPercent(1564, 300);
                            transform: translateX(#{getPercent(886, -168)});
                        }

                        *[class^="cont-img"] ~ *[class^="cont-img"] {
                            margin-top: #{getPercent(886, -341)};
                        }
                    }
                }

                .cont-sec-04 {
                    position: relative;
                    transform: translateY(#{getPercent(1564, -32)});
                    z-index: 1;

                    .cont-wrap-01 {
                        gap: 0 getPercent(1920, 32);
                    }

                    .cont-img-03 {
                        position: absolute;
                        width: 100%;
                        margin-top: getPercent(1920, 32);

                        .animation-container {
                            background-image: url("../../../public/site/res/image/img_mpp_section_03_03.webp");
                            &::after {
                                padding-bottom: getPercent(1564, 760);
                            }
                        }
                    }
                }

                .cont-sec-05 {
                    background: {
                        image: url("../../../public/site/res/image/img_mpp_bg_section_04.webp");
                        size: 100% auto;
                        repeat: no-repeat;
                    }
                    padding-top: getPercent(1920, 1301);
                    overflow: hidden;

                    .cont-img-01 {
                        position: relative;
                        z-index: 1;
                        width: getPercent(1920, 886);
                        margin: 0 auto;
                        transform: translateX(#{getPercent(1920, -344)});

                        .animation-container {
                            background: {
                                image: url("../../../public/site/res/image/img_mpp_section_04_01.webp");
                            }

                            &::after {
                                padding-bottom: getPercent(886, 1353);
                            }
                        }
                    }

                    .img-slide {
                        display: flex;
                        position: absolute;
                        width: 100%;
                        top: 0;
                        margin-top: getPercent(1920, 1667);
                        ul {
                            animation: animateSymbolContainer 20s linear forwards infinite;
                            flex-shrink: 0;
                            justify-content: space-around;
                            width: getPercent(1980, 4844);
                            display: flex;
                            li {
                                width: getPercent(4804, 327);
                                img {
                                    display: block;
                                    width: 100%;
                                    transform: translate(-20px, -20px);
                                }
                            }
                        }
                    }

                    .container {
                        gap: 0 getPercent(1920, 120);
                        width: getPercent(1920, 1365);
                        align-items: center;

                        .cont-wrap-01 {
                            .cont-img-02 {
                                .animation-container {
                                    background-image: url("../../../public/site/res/image/img_mpp_section_05_01.webp");
                                    &::after {
                                        padding-bottom: getPercent(375, 1348);
                                    }
                                }
                            }
                            .cont-img-03 {
                                .animation-container {
                                    background-image: url("../../../public/site/res/image/img_mpp_section_05_02.webp");
                                    &::after {
                                        padding-bottom: getPercent(375, 812);
                                    }
                                }
                            }
                        }
                        .cont-wrap-02 {
                            .cont-img-04 {
                                .animation-container {
                                    background-image: url("../../../public/site/res/image/img_mpp_section_05_03.webp");
                                    &::after {
                                        padding-bottom: getPercent(375, 812);
                                    }
                                }
                            }
                            .cont-img-05 {
                                .animation-container {
                                    background-image: url("../../../public/site/res/image/img_mpp_section_05_04.webp");
                                    &::after {
                                        padding-bottom: getPercent(375, 812);
                                    }
                                }
                            }
                            .cont-img-06 {
                                .animation-container {
                                    background-image: url("../../../public/site/res/image/img_mpp_section_05_05.webp");
                                    &::after {
                                        padding-bottom: getPercent(375, 812);
                                    }
                                }
                            }
                        }
                        .cont-wrap-03 {
                            .cont-img-07 {
                                .animation-container {
                                    background-image: url("../../../public/site/res/image/img_mpp_section_05_06.webp");
                                    &::after {
                                        padding-bottom: getPercent(375, 812);
                                    }
                                }
                            }
                            .cont-img-08 {
                                .animation-container {
                                    background-image: url("../../../public/site/res/image/img_mpp_section_05_07.webp");
                                    &::after {
                                        padding-bottom: getPercent(375, 1488);
                                    }
                                }
                            }
                        }

                        div[class^="cont-img"] ~ div[class^="cont-img"] {
                            margin-top: getPercent(375, 120);
                        }
                    }

                    .cont-img-09 {
                        width: getPercent(1920, 537);
                        margin: getPercent(1920, 697) auto getPercent(1920, 399);
                    }
                }
            }
        }

        &[data-hash="ddb"] {
            background-color: #f5f6f7;

            * {
                color: #304c3f;
            }

            .btn-menu {
                circle {
                    fill: #c7e4d6;
                }
                rect {
                    fill: #304c3f;
                }
            }

            .project-cover {
                .cover-img-wrap {
                    background: {
                        image: url("../../../public/site/res/image/img_cover_ddb.webp");
                        size: cover;
                    }
                }
                .cover-text-wrap {
                    .container {
                        .project-type,
                        .project-name {
                            color: #304c3f;
                        }
                        .project-team {
                            .team-tag {
                                color: #304c3f;
                                border-color: #304c3f;
                            }
                        }
                        .project-desc {
                            margin-bottom: getPercent(1920, 198);
                            p {
                                color: #304c3f;
                                word-break: keep-all;
                            }
                        }
                    }
                }
            }

            .project-contents {
                .project-intro {
                    padding-bottom: getPercent(1920, 120);
                }
                .cont-sec-01 {
                    position: relative;
                    padding-top: getPercent(1920, 170);
                    background: {
                        image: url("../../../public/site/res/image/img_ddb_section_01_bg.webp");
                        size: contain;
                    }

                    .cont-img-01 {
                        width: getPercent(1920, 624);
                        margin: 0 auto;
                        transform: translateX(getPercent(1920, 250));
                        .animation-container {
                            background-image: url("../../../public/site/res/image/img_ddb_section_01_01.webp");
                            background-size: 100% auto;
                            &::after {
                                padding-bottom: getPercent(624, 1194);
                            }
                        }
                    }

                    .floating {
                        position: absolute;
                        width: getPercent(1920, 327);

                        img {
                            box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.12);
                        }
                    }

                    .cont-img-02 {
                        top: getPercent(1364, 660);
                        left: getPercent(1920, 321);
                    }
                    .cont-img-03 {
                        top: getPercent(1364, 814);
                        right: getPercent(1920, 321);
                    }
                    .cont-img-04 {
                        bottom: getPercent(1364, 331);
                        left: getPercent(1920, 502);
                    }
                }

                .cont-sec-02 {
                    padding: getPercent(1920, 280) 0 getPercent(1920, 225);
                    .cont-wrap-01 {
                        width: getPercent(1920, 1100);
                        margin: 0 auto;
                        gap: 0 getPercent(1920, 40);

                        div[class^="cont-img"] {
                            width: getPercent(1100, 373);
                            flex-shrink: 0;
                            img {
                                transform: translate(getPercent(373, -13), getPercent(373, -13));
                            }
                        }

                        .cont-img-01 {
                            transform: translateY(getPercent(1100, -120));
                        }
                        .cont-img-03 {
                            transform: translateY(getPercent(1100, 120));
                        }
                    }

                    .cont-wrap-02 {
                        position: absolute;
                        width: getPercent(1920, 280);
                        top: 0;
                        margin-top: getPercent(1920, 146);
                        left: getPercent(1920, 976);

                        div[class^="cont-img"] {
                            img {
                                box-shadow: 12px 12px 28px 2px rgba(0, 0, 0, 0.08);
                            }

                            & ~ div[class^="cont-img"] {
                                margin-top: getPercent(280, 14);
                            }
                        }
                    }
                }

                .cont-sec-03 {
                    background: url("../../../public/site/res/image/img_ddb_section_03_bg.webp");
                    background-size: 100% auto;
                    background-repeat: no-repeat;
                    padding-top: getPercent(1920, 90);

                    @keyframes drawLeftToRight {
                        0% {
                            width: 0;
                        }

                        100% {
                            width: 100%;
                        }
                    }
                    @keyframes drawBottomToTop {
                        0% {
                            height: 0;
                        }

                        100% {
                            height: 100%;
                        }
                    }
                    @keyframes emojiAniUp {
                        0% {
                            transform: translateY(-12%);
                            -webkit-transform: translateY(-12%);
                        }

                        50% {
                            transform: translateY(0);
                            -webkit-transform: translateY(0);
                        }

                        100% {
                            transform: translateY(-12%);
                            -webkit-transform: translateY(-12%);
                        }
                    }
                    @keyframes emojiAniDown {
                        0% {
                            transform: translateY(12%);
                            -webkit-transform: translateY(12%);
                        }

                        50% {
                            transform: translateY(0);
                            -webkit-transform: translateY(0%);
                        }

                        100% {
                            transform: translateY(12%);
                            -webkit-transform: translateY(12%);
                        }
                    }
                    .fade-up.show {
                        & ~ .bg-chart-line {
                            .line {
                                animation: drawLeftToRight 2s forwards;
                            }
                        }

                        & ~ .bg-chart-bar {
                            .bg {
                                animation: drawBottomToTop 1s forwards;
                            }
                        }

                        & ~ .emoji {
                            opacity: 1;
                        }
                    }

                    div[class^="bg-chart"] {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);

                        &.bg-chart-line {
                            width: getPercent(1920, 1654);
                            top: getPercent(960, 112);
                            &::after {
                                padding-bottom: getPercent(1654, 275);
                            }

                            .line {
                                position: absolute;
                                width: 0;
                                height: 100%;

                                .bg {
                                    position: absolute;
                                    width: getPercent(1654, 1633);
                                    height: getPercent(275, 259);
                                    top: 50%;
                                    left: getPercent(1633, 10.5);
                                    transform: translateY(-50%);
                                    background: {
                                        image: url("../../../public/site/res/image/img_ddb_section_03_bg_chart_line.webp");
                                        size: auto 100%;
                                        repeat: no-repeat;
                                    }
                                }
                            }

                            .point {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                z-index: 1;
                                background: {
                                    image: url("../../../public/site/res/image/img_ddb_section_03_bg_chart_point.webp");
                                    size: auto 100%;
                                    repeat: no-repeat;
                                }
                            }
                        }

                        &.bg-chart-bar {
                            width: getPercent(1920, 1647);
                            bottom: getPercent(960, 123);
                            .bg {
                                position: absolute;
                                width: 100%;
                                bottom: 0;
                                background: {
                                    image: url("../../../public/site/res/image/img_ddb_section_03_bg_chart_bar.webp");
                                    size: 100% auto;
                                    repeat: no-repeat;
                                }
                            }

                            &::after {
                                padding-bottom: getPercent(1647, 267);
                            }
                        }
                    }

                    .emoji {
                        position: absolute;
                        opacity: 0;
                        transition: opacity 0.8s;
                        transition-delay: 1s;

                        img {
                            display: block;
                            width: 100%;
                        }

                        &.emoji-01 {
                            width: getPercent(1920, 62);
                            top: getPercent(960, 182);
                            left: getPercent(1920, 400);
                            animation: emojiAniUp 2.7s linear forwards infinite;
                        }

                        &.emoji-02 {
                            width: getPercent(1920, 166);
                            top: getPercent(960, 392);
                            left: getPercent(1920, 143);
                            animation: emojiAniDown 2.7s linear forwards infinite;
                        }

                        &.emoji-03 {
                            width: getPercent(1920, 125);
                            bottom: getPercent(960, 160);
                            left: getPercent(1920, 423);
                            animation: emojiAniUp 2.7s linear forwards infinite;
                        }

                        &.emoji-04 {
                            width: getPercent(1920, 143);
                            top: getPercent(960, 84);
                            right: getPercent(1920, 384);
                            animation: emojiAniDown 2.7s linear forwards infinite;
                        }

                        &.emoji-05 {
                            width: getPercent(1920, 98);
                            top: getPercent(960, 385);
                            right: getPercent(1920, 239);
                            animation: emojiAniUp 2.7s linear forwards infinite;
                        }

                        &.emoji-06 {
                            width: getPercent(1920, 80);
                            bottom: getPercent(960, 268);
                            right: getPercent(1920, 366);
                            animation: emojiAniDown 2.7s linear forwards infinite;
                        }
                    }

                    .cont-img-01 {
                        position: relative;
                        width: getPercent(1920, 674);
                        z-index: 2;
                        margin: 0 auto;
                        transform: translateX(getPercent(1920, 144));

                        .animation-container {
                            background-image: url("../../../public/site/res/image/img_ddb_section_03_01.webp");
                            &::after {
                                padding-bottom: getPercent(600, 733);
                            }
                        }
                    }
                }

                .cont-sec-04 {
                    padding: getPercent(1920, 180) 0;
                    .cont-wrap-01 {
                        width: getPercent(1920, 1364);
                        align-items: center;
                        justify-content: space-between;
                        margin: 0 auto;

                        > div {
                            flex-shrink: 0;
                        }

                        .cont-img-01 {
                            width: getPercent(1364, 360);
                            transform: translateX(getPercent(1364, -53));
                        }

                        .cont-img-01 ~ div {
                            width: getPercent(1364, 352);
                            transform: translateX(getPercent(1364, -48));
                        }
                    }
                    .cont-wrap-02 {
                        display: none;
                    }
                }

                .cont-sec-05 {
                    &::before,
                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: getPercent(1920, 348);
                        height: 100%;
                        top: 0;
                        background: linear-gradient(270deg, #82b5a0 0%, rgba(130, 181, 160, 0) 100%);
                        z-index: 3;
                    }

                    &::before {
                        left: 0;
                        transform: rotate(180deg);
                    }
                    &::after {
                        right: 0;
                    }
                    background-color: #82b5a0;
                    padding: getPercent(1920, 193) 0;
                    .cont-wrap-01 {
                        position: relative;
                        width: getPercent(1920, 500);
                        margin: 0 auto;

                        .cont-img-01 {
                            z-index: 1;
                        }

                        .cont-img-02 {
                            z-index: 3;
                            position: absolute;
                            top: getPercent(500, 14);
                            left: 0;
                            width: getPercent(500, 664);
                            transform: translate(getPercent(500, -39), getPercent(500, -32));
                        }
                    }

                    .swiper-container {
                        position: absolute;
                        top: getPercent(1470, 471);
                        width: 100%;
                        z-index: 2;

                        .mySwiper {
                            .swiper-wrapper {
                                .swiper-slide {
                                    width: getPercent(1920, 284) !important;
                                    padding: 0 getPercent(1920, 8);

                                    img {
                                        display: block;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }

                .cont-sec-06 {
                    padding-top: getPercent(1920, 162);
                    > .container {
                        width: getPercent(1920, 1286);
                        align-items: flex-end;

                        div[class^="cont-wrap"] {
                            flex-shrink: 0;
                            transform: translateX(getPercent(1286, -48));
                            width: getPercent(1286, 439);
                        }
                    }
                }

                .cont-sec-07 {
                    padding: getPercent(1920, 430) 0;
                    background-color: #304c3f;

                    .cont-img-01 {
                        width: getPercent(1920, 334);
                        margin: 0 auto;
                    }
                }
            }
        }

        &[data-hash="archiveat"] {
            background-color: #fff;

            .btn-menu {
                circle {
                    fill: #000;
                }
                rect {
                    fill: #fff;
                }
            }

            .project-cover {
                .cover-img-wrap {
                    background-color: #ff5019;

                    &.on {
                        .phone-wrap {
                            transform: translateY(0);
                        }
                        .logo-wrap {
                            transform: translateY(0);
                        }
                        .bg-wrap {
                            opacity: 0.3;
                        }
                    }

                    .phone-wrap {
                        z-index: 2;
                        position: absolute;
                        width: getPercent(1920, 584);
                        bottom: 0;
                        right: getPercent(1920, 60);
                        transition: transform 1s;
                        transform: translateY(100%);
                        transition-delay: 0.5s;
                    }
                    .logo-wrap {
                        z-index: 1;
                        position: absolute;
                        width: getPercent(1920, 1349);
                        bottom: getPercent(1920, 68);
                        left: getPercent(1920, 48);
                        transform: translateY(150%);
                        transition: transform 0.5s;
                        transition-delay: 1s;
                    }
                    .bg-wrap {
                        z-index: 0;
                        position: absolute;
                        bottom: 0;
                        left: getPercent(1920, 250);
                        width: getPercent(1920, 1263);
                        opacity: 0;
                        transition: opacity 1.5s;
                        transition-delay: 0.5s;
                    }
                }
                .cover-text-wrap {
                    background-color: #fff;
                    .container {
                        .project-type,
                        .project-name {
                            color: #ff5019;
                        }
                        .project-team {
                            .team-tag {
                                color: #ff5019;
                                border-color: #ff5019;
                            }
                        }

                        .project-desc {
                            margin-bottom: getPercent(1920, 202);
                            p {
                                color: #ff5019;
                            }
                        }
                    }
                }
            }

            .project-contents {
                .project-intro {
                    padding-bottom: getPercent(1920, 120);
                    * {
                        color: #ff5019;
                    }
                }

                [class^="cont-sec"] {
                    background-color: #f1f1f1;
                }

                .cont-sec-01 {
                    padding-bottom: getPercent(1929, 1369);

                    .cont-img-01 {
                        .reactive {
                            background-image: url("../../../public/site/res/image/img_archiveat_section_01_01.webp");
                            &::after {
                                padding-bottom: getPercent(1920, 900);
                            }
                        }
                    }

                    .cont-img-02 {
                        position: absolute;
                        top: 0;
                        margin-top: getPercent(1920, 409);
                        right: 0;
                        margin-right: getPercent(1920, 273);
                        width: getPercent(1920, 423);
                    }

                    .img-slide {
                        display: flex;
                        position: absolute;
                        width: 100%;
                        top: 0;
                        margin-top: getPercent(1920, 1374);
                        overflow: hidden;
                        ul {
                            animation: animateSymbolContainer 20s linear forwards infinite;
                            flex-shrink: 0;
                            justify-content: space-around;
                            width: getPercent(1920, 3165);
                            display: flex;
                            li {
                                width: getPercent(3165, 375);
                                &:nth-child(2) {
                                    margin-top: getPercent(3265, 106);
                                }
                                &:nth-child(3) {
                                    margin-top: getPercent(3265, 21);
                                }
                                &:nth-child(4) {
                                    margin-top: getPercent(3265, 137);
                                }
                                &:nth-child(5) {
                                    margin-top: getPercent(3265, 34);
                                }
                                &:nth-child(7) {
                                    margin-top: getPercent(3265, 119);
                                }
                                img {
                                    display: block;
                                    width: getPercent(375, 463);
                                }
                            }
                        }
                    }
                }

                .cont-sec-02 {
                    overflow: hidden;
                    background: {
                        image: url("../../../public/site/res/image/img_archiveat_section_02_bg.webp");
                        size: cover;
                        repeat: no-repeat;
                        position: center;
                    }

                    .cont-wrap {
                        position: relative;
                        width: getPercent(1920, 480);
                        margin: 0 auto;
                        transform: translateY(getPercent(960, 200));
                        .cont-wrap-01 {
                            overflow: hidden;
                            border-radius: 46px;

                            .cont-img-01 {
                                position: relative;
                                z-index: 1;
                                border-radius: 46px;
                                border: 12px solid #fff;
                                box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.08);
                                background: {
                                    image: url("../../../public/site/res/image/img_archiveat_section_02_mockup.webp");
                                    size: 100% auto;
                                    repeat: no-repeat;
                                }

                                &::after {
                                    padding-bottom: getPercent(480, 962);
                                }
                            }

                            .cont-img-02 {
                                position: absolute;
                                top: 1px;
                                left: 12px;
                                width: calc(100% - 24px);
                            }
                        }

                        &.fade-up.show {
                            .cont-img-02 {
                                img ~ img {
                                    position: absolute;
                                    top: 0;
                                    transform: translateY(100%);
                                }
                                @keyframes scrollviewLoopInfinite {
                                    0% {
                                        transform: translateY(0);
                                    }

                                    100% {
                                        transform: translateY(-100%);
                                    }
                                }
                                animation: scrollviewLoopInfinite 8s linear 0.8s forwards infinite;
                            }
                        }
                    }
                }

                .cont-sec-03 {
                    padding-top: getPercent(1920, 292);
                    padding-bottom: getPercent(1920, 867);

                    .cont-img-01 {
                        position: relative;
                        margin-left: getPercent(1920, 454);
                        width: getPercent(1920, 475);
                        z-index: 1;
                    }

                    .img-slide {
                        display: flex;
                        position: absolute;
                        width: 100%;
                        top: 0;
                        margin-top: getPercent(1920, 1058);
                        overflow: hidden;
                        ul {
                            animation: animateSymbolContainer 20s linear forwards infinite;
                            flex-shrink: 0;
                            justify-content: space-around;
                            width: getPercent(1920, 3165);
                            display: flex;
                            li {
                                width: getPercent(3165, 375);
                                &:nth-child(2) {
                                    margin-top: getPercent(3165, 140);
                                }
                                &:nth-child(4) {
                                    margin-top: getPercent(3165, 97);
                                }
                                &:nth-child(5) {
                                    margin-top: getPercent(3165, 233);
                                }
                                &:nth-child(7) {
                                    margin-top: getPercent(3165, 263);
                                }
                                img {
                                    display: block;
                                    width: getPercent(375, 463);
                                }
                            }
                        }
                    }
                }

                .cont-sec-04 {
                    .cont-wrap-01 {
                        .cont-img-02 {
                            position: relative;
                            background-color: #c3b496;

                            .cont-img-container {
                                position: absolute;
                                bottom: 0;
                                left: 50%;
                                transform: translateX(-50%);
                                width: getPercent(960, 375);
                                border-radius: 36px 36px 0 0;
                                box-shadow: 40px 40px 48px rgba(61, 56, 48, 0.12);
                                overflow: hidden;

                                img {
                                    display: block;
                                    position: relative;
                                    z-index: 1;
                                }
                                video {
                                    position: absolute;
                                    top: 0;
                                    width: 100%;
                                    object-fit: cover;
                                }
                            }
                        }
                    }
                }

                .cont-sec-05 {
                    position: relative;
                    padding: getPercent(1920, 260) 0;
                    background: {
                        image: url("../../../public/site/res/image/img_archiveat_section_05_02_text.webp");
                        repeat: no-repeat;
                        position: center getPercent(1332, 708);
                        size: getPercent(1920, 1608);
                    }

                    > .fade-up {
                        width: getPercent(1920, 375);

                        &:nth-child(1) {
                            margin-left: getPercent(1920, 579);

                            &.show {
                                .cont-img-scrollview {
                                    img {
                                        @include scrollviewLoop(0, -81%);
                                        animation: scrollviewLoop0 10s 0.8s forwards infinite;
                                    }
                                }
                            }
                        }

                        &:nth-child(2) {
                            position: absolute;
                            right: 0;
                            top: 0;
                            z-index: 1;
                            margin-top: getPercent(1920, 938);
                            margin-right: getPercent(1920, 451);
                        }
                    }
                    .cont-wrap-01 {
                        position: relative;
                        border-radius: 36px;
                        overflow: hidden;
                        box-shadow: 40px 40px 48px rgba(61, 56, 48, 0.12);

                        .cont-img-mockup {
                            position: relative;
                            z-index: 1;
                            border-radius: 36px;
                            border: 10px solid #fff;
                            box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.08);
                            background: {
                                image: url("../../../public/site/res/image/img_archiveat_section_05_01_mockup.webp");
                                repeat: no-repeat;
                                size: 101% auto;
                                position: bottom center;
                            }

                            &::after {
                                padding-bottom: getPercent(375, 812);
                            }
                        }

                        .cont-img-scrollview {
                            position: absolute;
                            bottom: 10px;
                            left: 10px;
                            top: 10px;
                            width: calc(100% - 20px);
                            overflow: hidden;
                            border-radius: 10px;

                            img {
                                animation: scrollviewLoop0 10s 0.8s forwards infinite;
                            }
                        }
                    }

                    .cont-wrap-02 {
                        border-radius: 36px;
                        overflow: hidden;
                        background-color: #fff;

                        .cont-img-mockup {
                            position: relative;
                            z-index: 2;
                            border-radius: 36px;
                            border: 10px solid #fff;
                            box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.08);

                            background: {
                                // image: url('../../../public/site/res/image/img_archiveat_section_05_02_mockup.webp');
                                repeat: no-repeat;
                                size: 100% auto;
                                position: top center;
                            }

                            &::after {
                                padding-bottom: getPercent(375, 812);
                            }
                        }

                        .cont-img-slide {
                            position: absolute;
                            top: 0;
                            left: getPercent(375, 10);
                            width: getPercent(375, 355);

                            ul {
                                display: flex;
                                width: getPercent(375, 1125);
                                li {
                                    width: getPercent(1125, 375);
                                    flex-shrink: 0;
                                }
                            }
                        }
                    }
                }

                .cont-sec-06 {
                    .cont-img-01 {
                        .reactive {
                            background-image: url("../../../public/site/res/image/img_archiveat_section_06_01.webp");
                            &::after {
                                padding-bottom: getPercent(1920, 960);
                            }
                        }
                    }
                }

                .cont-sec-07 {
                    background-color: #f1f1f1;
                    padding: getPercent(1920, 320) 0;
                    overflow: hidden;

                    div[class^="bg-img"] {
                        position: absolute;
                        top: 0;
                        left: 0;

                        img {
                            display: block;
                            width: 100%;
                        }

                        &.bg-img-01 {
                            width: getPercent(1920, 256);
                            margin-top: getPercent(1920, 179);
                            margin-left: getPercent(1920, 327);
                        }
                        &.bg-img-02 {
                            width: getPercent(1920, 239);
                            margin-top: getPercent(1920, 93);
                            margin-left: getPercent(1920, 1257);
                        }
                        &.bg-img-03 {
                            width: getPercent(1920, 252);
                            margin-top: getPercent(1920, 606);
                        }
                        &.bg-img-04 {
                            width: getPercent(1920, 234);
                            margin-top: getPercent(1920, 663);
                            margin-left: getPercent(1920, 460);
                        }
                        &.bg-img-05 {
                            width: getPercent(1920, 508);
                            margin-top: getPercent(1920, 486);
                            margin-left: getPercent(1920, 1423);
                        }
                        &.bg-img-06 {
                            width: getPercent(1920, 470);
                            margin-top: getPercent(1920, 1128);
                            margin-left: getPercent(1920, 294);
                        }
                        &.bg-img-07 {
                            width: getPercent(1920, 476);
                            margin-top: getPercent(1920, 968);
                            margin-left: getPercent(1920, 984);
                        }
                    }

                    .cont-wrap-01 {
                        position: relative;
                        width: getPercent(1920, 375);
                        margin: 0 auto;
                        border-radius: 36px;
                        overflow: hidden;
                        box-shadow: 40px 40px 48px rgba(61, 56, 48, 0.12);

                        .cont-img-mockup {
                            position: relative;
                            z-index: 1;
                            border: 10px solid #ffffff;
                            border-radius: 36px;
                            box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.08);
                            background: {
                                image: url("../../../public/site/res/image/img_archiveat_section_07_mockup.webp");
                                size: 100% auto;
                                position: top center;
                            }

                            &::after {
                                padding-bottom: getPercent(375, 812);
                            }
                        }
                        .cont-img-scrollview {
                            position: absolute;
                            top: getPercent(812, 223);
                            bottom: getPercent(812, 12);
                            left: 10px;
                            width: calc(100% - 20px);
                            border-radius: 0 0 36px 36px;
                            overflow: hidden;

                            img {
                                @include scrollviewLoop(1, -64%);
                                animation: scrollviewLoop1 10s 0.8s forwards infinite;
                            }
                        }
                    }
                }

                .cont-sec-08 {
                    overflow: hidden;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;
                    background-color: #c3b496;
                    flex-shrink: 0;
                    flex-grow: 0;
                    padding-top: getPercent(1920, 280);
                    gap: 0 getPercent(1920, 60);

                    > .fade-up {
                        width: getPercent(1920, 368);

                        div[class^="cont-wrap"] {
                            flex-direction: column;
                            justify-content: flex-end;

                            > div[class^="cont-img"] {
                                &:not(& ~ div[class^="cont-img"]):not(:only-child) {
                                    img {
                                        transform: translateY(getPercent(368, 20));
                                    }
                                }

                                img {
                                    width: getPercent(368, 456);
                                }
                            }
                        }

                        &:last-child {
                            width: getPercent(1920, 246);
                            div[class^="cont-img"],
                            img {
                                width: 100% !important;
                            }
                        }

                        .cont-img-01 {
                            .reactive {
                                background-image: url("../../../public/site/res/image/img_archiveat_section_08_01.webp");
                                &::after {
                                    padding-bottom: getPercent(320, 692);
                                }
                            }
                        }
                        .cont-img-02 {
                            .reactive {
                                background-image: url("../../../public/site/res/image/img_archiveat_section_08_02.webp");
                                &::after {
                                    padding-bottom: getPercent(320, 306);
                                }
                            }
                        }
                        .cont-img-03 {
                            .reactive {
                                background-image: url("../../../public/site/res/image/img_archiveat_section_08_03.webp");
                                &::after {
                                    padding-bottom: getPercent(320, 692);
                                }
                            }
                        }
                        .cont-img-04 {
                            .reactive {
                                background-image: url("../../../public/site/res/image/img_archiveat_section_08_04.webp");
                                &::after {
                                    padding-bottom: getPercent(320, 451);
                                }
                            }
                        }
                        .cont-img-05 {
                            .reactive {
                                background-image: url("../../../public/site/res/image/img_archiveat_section_08_05.webp");
                                &::after {
                                    padding-bottom: getPercent(320, 745);
                                }
                            }
                        }
                        .cont-img-06 {
                            .reactive {
                                background-image: url("../../../public/site/res/image/img_archiveat_section_08_06.webp");
                                &::after {
                                    padding-bottom: getPercent(246, 693);
                                }
                            }
                        }
                        .cont-img-07 {
                            .reactive {
                                background-image: url("../../../public/site/res/image/img_archiveat_section_08_07.webp");
                                &::after {
                                    padding-bottom: getPercent(246, 253);
                                }
                            }
                        }
                    }
                }
            }
        }
        &[data-hash="shinsegae"] {
            .project-cover {
                .cover-img-wrap {
                    background-color: #000;
                    height: fit-content;
                    .cover-content-wrap {
                        position: relative;
                        width: 100%;
                        background-image: url("../../../public/site/res/image/shinsegae/img_cover_pattern.webp");
                        .cover-bg-text {
                            padding: getPercent(1920, 216) 0 getPercent(1920, 254);

                            span {
                                @include fontStyle(240px, 400, 240px, -7.2px);
                                font-size: getVw(240px);
                                font-family: $font-en;
                                text-transform: uppercase;
                                background: linear-gradient(
                                    94deg,
                                    rgba(51, 51, 51, 0) 0.35%,
                                    #333 53.92%,
                                    rgba(51, 51, 51, 0) 99.55%
                                );
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                display: block;
                                text-align: center;
                            }
                        }
                        .cover-mockup {
                            z-index: 1;
                            position: absolute;
                            top: getPercent(950, 107.29);
                            left: 50%;
                            transform: translate(-50%, 0%);
                            width: getPercent(1920, 380);
                        }
                        .swiper-container {
                            z-index: 2;
                            position: absolute;
                            width: 100%;
                            top: getPercent(950, 320);

                            .mySwiper {
                                .swiper-slide {
                                    width: getPercent(1920, 350);
                                }
                            }
                        }
                    }
                }

                .cover-text-wrap {
                    background-color: #ff329b;
                    .container {
                        * {
                            color: #fff !important;
                        }
                        .team-tag {
                            border-color: #fff;
                        }
                        .project-name {
                            font-size: getVw(240px);
                        }
                        .project-desc {
                            margin-bottom: getPercent(1920, 197);
                        }
                    }
                }
            }
            .project-contents {
                h4.h4-title {
                    @include fontStyle(20px, 300);
                    font-family: $font-en;
                }
                p.p-sec-desc {
                    @include fontStyle(20px, 500, 190%, -0.6px);
                    font-family: $font-ko;
                }
                h5.h5-text-title {
                    @include fontStyle(60px, 300, 130%);
                    font-size: getVw(60px);
                    text-transform: uppercase;
                    font-family: $font-en;
                    width: fit-content;
                }
                p.p-text-feature {
                    @include fontStyle(20px, 300, 160%, -1px);
                    font-family: $font-ko;
                }
                img {
                    display: block;
                    width: 100%;
                }
                .cont-sec-01 {
                    .container {
                        width: 100% !important;
                        .cont-wrap-01 {
                            position: relative;
                            background-image: url("../../../public/site/res/image/shinsegae/img_shin_section01_bg_black.webp");
                            background-position: center;
                            background-size: cover;
                            text-align: center;
                            padding-top: getPercent(1920, 518);

                            h2 {
                                position: absolute;
                                left: 50%;
                                transform: translateX(-50%);
                                top: 0;
                                padding-top: getPercent(1920, 382);
                                @include fontStyle(200px, 300);
                                font-size: getVw(200px);
                                font-family: $font-en;
                                text-transform: uppercase;
                                opacity: 0.12;
                            }
                            p {
                                z-index: 1;
                                position: relative;
                                font-family: $font-ko;
                                @include fontStyle(20px, 500, 190%, -0.8px);
                                padding-bottom: getRem(67.3);
                            }
                            .gradient-bg {
                                position: absolute;
                                bottom: 0;
                                width: 100%;
                                display: block;
                                background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
                                padding-bottom: getPercent(1920, 273);
                            }
                        }
                        .cont-wrap-02 {
                            width: getPercent(1920, 1541);
                            margin: 0 auto;
                            ul {
                                display: flex;
                                justify-content: space-between;
                                width: 100%;

                                li {
                                    width: getPercent(1541, 900);
                                    h3 {
                                        font-family: $font-en;
                                        @include fontStyle(16px, 300, 120%);
                                        text-transform: uppercase;
                                        text-align: center;
                                        padding-bottom: getRem(61);
                                    }
                                    &.shopping {
                                        .circle-list {
                                            width: getPercent(900, 300);
                                            height: getPercent(900, 300);
                                            padding-bottom: getPercent(900, 300);
                                            &:not(:first-of-type) {
                                                transform: translateX(-6.666%);
                                            }
                                            &:nth-of-type(3) {
                                                transform: translateX(-13.333%);
                                            }
                                        }
                                    }
                                    &.valuable {
                                        width: getPercent(1541, 600);
                                        .circle-list {
                                            width: getPercent(600, 300);
                                            padding-bottom: getPercent(600, 300);
                                            background-color: #ff329b !important;
                                            &:first-of-type {
                                                transform: translateX(6.666%);
                                            }
                                            h4,
                                            span {
                                                color: #fff !important;
                                            }
                                        }
                                    }
                                    .circle-list-wrap {
                                        display: flex;
                                        .circle-list {
                                            font-family: $font-ko;
                                            display: flex;
                                            flex-direction: column;
                                            align-items: center;
                                            justify-content: center;
                                            background-color: #fff;
                                            border-radius: 50%;
                                            position: relative;

                                            .circle-text-wrap {
                                                position: absolute;
                                                top: 50%;
                                                left: 50%;
                                                transform: translate(-50%, -50%);
                                                text-align: center;
                                                h4 {
                                                    @include fontStyle(30px, 500, 140%, -1.5px);
                                                    font-size: getVw(30px);
                                                    color: #131313;
                                                    margin-bottom: getRem(19.5);
                                                    text-wrap: nowrap;
                                                }
                                                span {
                                                    @include fontStyle(16px, 300, 140%, -0.8px);
                                                    color: #131313;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .line {
                                margin-top: getRem(70);
                                width: 100%;
                            }
                        }
                        .cont-wrap-03 {
                            text-align: center;
                            margin-top: getRem(61);
                            padding-bottom: getPercent(1920, 254);
                            strong {
                                @include fontStyle(80px, 300, 120%);
                                font-size: getVw(80px);
                                font-family: $font-en;
                                text-transform: uppercase;
                            }
                            p {
                                @include fontStyle(30px, 500, 140%, -1.5px);
                                font-family: $font-ko;
                                margin-top: getRem(22);
                            }
                        }
                    }
                }
                .cont-sec-02 {
                    background-color: #ffffff;
                    .sec-text-box {
                        width: 100%;
                        text-align: center;
                        padding: getPercent(1920, 150) 0;
                        h4 {
                            color: #ff329b;
                            padding-bottom: getRem(30);
                        }
                        p {
                            color: #000000;
                        }
                    }
                    .sec-detail-box {
                        width: 100%;
                        ul {
                            display: flex;
                            width: 100%;
                            li {
                                position: relative;
                                width: 50%;
                                .detail-img {
                                    width: 100%;
                                    position: relative;
                                    img {
                                        width: 100%;
                                        display: block;
                                    }
                                    &::after {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        display: block;
                                        content: "";
                                        width: 100%;
                                        height: 100%;
                                        background-color: rgba(0, 0, 0, 0);
                                    }
                                }
                                strong {
                                    z-index: 0;
                                    position: absolute;
                                    display: block;
                                    bottom: getPercent(950, 117);
                                    left: 50%;
                                    transform: translateX(-50%);
                                    @include fontStyle(90px, 300, 100%, -4.5px);
                                    font-size: getVw(90px);
                                    font-family: $font-ko;
                                    color: #ff329b;
                                    pointer-events: none;
                                }
                                .hover-tag {
                                    visibility: hidden;
                                    z-index: 1;
                                    position: absolute;
                                    bottom: 0;
                                    color: #fff;
                                    background-color: #595959;
                                    padding: getVw(10px) getVw(20px);
                                    border-radius: 27px;
                                    @include fontStyle(18px, 300, 140%, -0.9px);
                                    font-size: getVw(18px);
                                    font-family: $font-ko;
                                    text-wrap: nowrap;

                                    &.consumer-01 {
                                        bottom: getVw(116px);
                                        left: getVw(151px);
                                    }
                                    &.consumer-02 {
                                        bottom: getVw(198px);
                                        left: getVw(564px);
                                    }
                                    &.consumer-03 {
                                        bottom: getVw(94px);
                                        left: getVw(564px);
                                    }
                                    &.contents-01 {
                                        bottom: getVw(103px);
                                        right: getVw(623px);
                                    }
                                    &.contents-02 {
                                        bottom: getVw(190px);
                                        right: getVw(127px);
                                    }
                                }
                                &:hover {
                                    .hover-tag {
                                        visibility: visible;
                                    }
                                    & + li.for-contents {
                                        .detail-img {
                                            &::after {
                                                background-color: rgba(0, 0, 0, 0.8);
                                            }
                                        }
                                        strong {
                                            opacity: 0.3;
                                        }
                                    }
                                }
                                &.for-consumer {
                                    &.show-dark {
                                        .detail-img {
                                            &::after {
                                                background-color: rgba(0, 0, 0, 0.8);
                                            }
                                        }
                                        strong {
                                            opacity: 0.3;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .cont-sec-03 {
                    background-color: #fff;
                    position: relative;
                    overflow: hidden;

                    div[class^="bg-img"] {
                        z-index: 1;
                        position: absolute;
                        top: 0;
                        left: 0;
                        img {
                            display: block;
                        }
                        .bg-text {
                            position: absolute;
                            width: 100%;
                            @include fontStyle(20px, 500, 160%, -0.6px);
                            text-align: right;
                            color: #131313;
                            font-family: $font-ko;
                            margin-top: getRem(26);
                        }

                        &.bg-img-01 {
                            width: getPercent(1920, 425);
                            top: getPercent(1705, 120);
                        }
                        &.bg-img-02 {
                            width: getPercent(1920, 450);
                            top: getPercent(1705, 862);
                            left: getPercent(1920, 74);
                            .bg-text {
                                width: getRem(248);
                                right: 0;
                            }
                        }
                        &.bg-img-03 {
                            width: getPercent(1920, 500);
                            top: unset;
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                        &.bg-img-04 {
                            width: getPercent(1920, 450);
                            top: getPercent(1705, 177);
                            right: getPercent(1920, 88);
                            left: unset;

                            .bg-text {
                                width: getRem(269);

                                text-align: left;
                                margin-top: getRem(30);
                            }
                        }
                        &.bg-img-05 {
                            width: getPercent(1920, 387);
                            bottom: getPercent(1705, 315);
                            right: 0;
                            left: unset;
                            top: unset;
                        }
                    }
                    .cont-text-box {
                        z-index: 0;
                        position: relative;
                        padding: getPercent(1920, 627) 0 getPercent(1920, 650);
                        margin: 0 auto;

                        p {
                            @include fontStyle(100px, 300, 107%);
                            font-size: getVw(100px);
                            font-family: $font-en;
                            text-transform: uppercase;
                            text-align: center;
                            color: #222222;
                        }
                    }
                }
                .cont-sec-04 {
                    .cont-wrap {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: getPercent(1920, 93.5) 0 getPercent(1920, 67);
                        background: linear-gradient(
                            90deg,
                            #ff329b 0%,
                            #fe8c07 24.9%,
                            #8edb63 49.79%,
                            #e960ff 75.09%,
                            #ff329b 100%
                        );
                        background-size: 400% 400%;
                        animation: gradient 11s ease infinite;
                        text-transform: uppercase;
                        background-position: 0% 50%;
                        p {
                            text-align: center;
                            font-family: $font-ko;
                            @include fontStyle(28px, 500, 160%, -0.84px);
                            padding: getPercent(1920, 86) 0 getPercent(1920, 185.5);
                        }
                        strong.big-text-wrap {
                            z-index: 0;
                            position: relative;
                            width: 100%;
                            padding-left: getPercent(1920, 97);
                            &:nth-of-type(2) {
                                z-index: 1;
                                text-align: right;
                                padding-left: 0;
                                padding-right: getPercent(1920, 74);
                                margin-top: getPercent(1920, -83);
                            }

                            div {
                                display: inline-block;

                                span {
                                    display: inline-block;
                                    font-family: $font-en;
                                    @include fontStyle(250px, 300);
                                    font-size: getVw(250px);
                                }
                            }
                        }
                        .item-img-wrap {
                            z-index: 0;
                            position: absolute;
                            width: getPercent(1920, 500);
                            padding-bottom: getPercent(1920, 600);
                            background-image: url("../../../public/site/res/image/shinsegae/img_shin_sec04_01.webp");
                            background-repeat: no-repeat;
                            background-size: cover;
                            bottom: getPercent(1920, 42);
                            left: getPercent(1920, 489);
                            animation: changeImg 11s infinite step-end;
                            -webkit-animation-fill-mode: forwards;
                            animation-fill-mode: forwards;
                        }
                    }
                    @keyframes gradient {
                        0% {
                            background-position: 0% 50%;
                        }
                        25% {
                            background-position: 50% 50%;
                        }
                        50% {
                            background-position: 100% 50%;
                        }
                        75% {
                            background-position: 50% 50%;
                        }
                        100% {
                            background-position: 0% 50%;
                        }
                    }
                    @keyframes changeImg {
                        0% {
                            background-image: url("../../../public/site/res/image/shinsegae/img_shin_sec04_01.webp");
                        }
                        20% {
                            background-image: url("../../../public/site/res/image/shinsegae/img_shin_sec04_02.webp");
                        }
                        55% {
                            background-image: url("../../../public/site/res/image/shinsegae/img_shin_sec04_03.webp");
                        }
                        79% {
                            background-image: url("../../../public/site/res/image/shinsegae/img_shin_sec04_02.webp");
                        }
                        100% {
                            background-image: url("../../../public/site/res/image/shinsegae/img_shin_sec04_01.webp");
                        }
                    }
                }
                .cont-sec-05 {
                    .container-01 {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding-top: getPercent(1920, 190);
                        padding-bottom: getPercent(1920, 881);
                        background-image: url("../../../public/site/res/image/shinsegae/img_shin_sec05_bg.webp");
                        background-size: cover;
                        background-blend-mode: multiply;
                        background-color: linear-gradient(
                            180deg,
                            #000 0%,
                            rgba(0, 0, 0, 0) 33%,
                            rgba(0, 0, 0, 0.8) 100%
                        );

                        .p-sec-desc {
                            line-height: 160%;
                            text-align: center;
                            padding: getPercent(1920, 30) 0 getPercent(1920, 120);
                        }
                        .keyword-list-box {
                            width: 100%;
                            padding: 0 getPercent(1920, 110);
                            .keyword-list-wrap {
                                display: flex;
                                justify-content: space-between;

                                .keyword-list {
                                    text-align: center;
                                    width: getVw(376px);

                                    h5 {
                                        font-family: $font-en;
                                        @include fontStyle(40px, 500, 110%);
                                        font-size: getVw(40px);
                                        margin-bottom: getVw(32px);
                                    }
                                    p {
                                        width: 100%;
                                        font-family: $font-ko;
                                        @include fontStyle(16px, 300, 160%, -0.8px);
                                        font-size: getVw(16px);
                                    }
                                }
                            }
                        }
                        .img-cont {
                            position: absolute;
                            width: getPercent(1920, 467);
                            top: 0;
                            margin-top: getPercent(1920, 795);
                            overflow: hidden;

                            img {
                                position: relative;
                                z-index: 1;
                            }
                            video {
                                z-index: 0;
                                position: absolute;
                                left: 50%;
                                transform: translateX(-50%);
                                width: getPercent(467, 390);
                                height: auto;
                                top: getPercent(919, 38);
                                border-radius: 15px;
                            }
                        }
                    }
                    .container-02 {
                        background-color: #fff;
                        padding: getPercent(1920, 249) getPercent(1920, 388.63) getPercent(1920, 124)
                            getPercent(1920, 413);
                        .icon-box-wrap {
                            display: flex;
                            justify-content: space-between;
                            .app-wrap {
                                width: getVw(120px);
                            }
                            .icon-box {
                                &:nth-of-type(2) {
                                    padding: 0 getVw(207px);
                                }
                                h5 {
                                    font-family: $font-en;
                                    @include fontStyle(20px, 300);
                                    text-transform: uppercase;
                                    color: #131313;
                                    margin-bottom: getRem(20);
                                    text-wrap: nowrap;
                                }
                                > ul {
                                    display: flex;
                                    flex-wrap: wrap;
                                    gap: getRem(20);
                                }

                                .color-wrap {
                                    width: getVw(262px);
                                    .ui-color {
                                        width: getVw(50px);
                                        height: getVw(50px);

                                        &.FF329B {
                                            background-color: #ff329b;
                                        }
                                        &.FF58B2 {
                                            background-color: #ff58b2;
                                        }
                                        &.FFEBF5 {
                                            background-color: #ffebf5;
                                        }
                                        &.gradient {
                                            background: var(
                                                --main-Main-gradient,
                                                linear-gradient(
                                                    144deg,
                                                    #ff329b 20.3%,
                                                    #ff3792 29.64%,
                                                    #ff467a 65.35%,
                                                    #ff5f52 82.77%,
                                                    #ff801b 94.14%,
                                                    #ff9100 98.1%
                                                )
                                            );
                                        }
                                        &.black {
                                            background-color: #131313;
                                        }
                                        &.white {
                                            background-color: #ffffff;
                                            border: 0.5px solid #a2a2a2;
                                        }
                                        &.D0D0D0 {
                                            background-color: #d0d0d0;
                                        }
                                        &.gray {
                                            background-color: #717171;
                                        }
                                    }
                                }
                                .icon-wrap {
                                    width: getVw(325px);
                                    gap: getRem(20) 0;
                                    .icongraphy {
                                        width: getVw(48.86px);
                                        margin-right: getVw(20px);
                                        &:nth-of-type(5),
                                        &:nth-of-type(10) {
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .cont-sec-06 {
                    z-index: 1;
                    position: relative;
                    background-image: url("../../../public/site/res/image/shinsegae/img_shin_sec06_bg_01.webp");
                    background-size: cover;
                    background-blend-mode: lighten;
                    background-color: #ff329b;
                    padding-top: getPercent(1920, 377);
                    padding-bottom: getPercent(1920, 278);

                    .text-cont-wrap {
                        display: flex;
                        justify-content: space-between;
                        padding: 0 getPercent(1920, 150) 0 getPercent(1920, 89);
                        .text-wrap {
                            position: relative;
                            width: fit-content;
                            p {
                                @include fontStyle(150px, 300, 130%);
                                font-size: getVw(150px);
                                font-family: $font-en;
                                text-transform: uppercase;
                                pointer-events: none;
                            }
                            .tag {
                                position: absolute;
                                top: getVw(62px);
                                left: getVw(271px);
                                @include fontStyle(12px, 400, 100%, -0.36px);
                                padding: getRem(8) getRem(12);
                                background-color: #131313;
                                pointer-events: none;
                                &::after {
                                    width: 0;
                                    display: block;
                                    content: "";
                                    position: absolute;
                                    bottom: -12px;
                                    border: 4px solid transparent;
                                    border-top: 8px solid #131313;
                                }
                            }
                            &.fashion {
                                p {
                                    opacity: 0.12;
                                }
                                .tag {
                                    background-color: #ff329b;
                                    visibility: hidden;
                                    &::after {
                                        border-top-color: #ff329b;
                                    }
                                }
                            }
                        }
                    }
                    .img-cont-wrap {
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        width: getPercent(1920, 924);
                        padding-bottom: getPercent(1920, 556);
                        background-image: url("../../../public/site/res/image/shinsegae/img_shin_sec06_01.webp");
                        background-size: cover;
                        background-repeat: no-repeat;
                        pointer-events: none;
                        transition: all 0.3s ease-in-out;
                    }

                    &.show-fashion {
                        background-image: url("../../../public/site/res/image/shinsegae/img_shin_sec06_bg_02.webp");
                        background-color: #040404;
                        background-blend-mode: screen;

                        .text-wrap {
                            &.shopping {
                                p {
                                    opacity: 0.12;
                                }
                                .tag {
                                    visibility: hidden;
                                }
                            }
                            &.fashion {
                                p {
                                    opacity: 1;
                                }
                                .tag {
                                    visibility: visible;
                                }
                            }
                        }
                        .img-cont-wrap {
                            background-image: url("../../../public/site/res/image/shinsegae/img_shin_sec06_02.webp");
                        }
                    }
                }
                .cont-sec-07 {
                    z-index: 0;
                    position: relative;
                    background-color: #f5f5f5;
                    padding-right: getPercent(1920, 228);
                    .text-cont-wrap {
                        display: flex;
                        justify-content: flex-end;

                        .text-left-box {
                            position: absolute;
                            top: 0;
                            left: 0;
                            margin-left: getPercent(1920, 250);
                            transform: translateY(-5%);

                            .p-text-left {
                                @include fontStyle(35px, 300, 130%, -1.05px);
                                font-size: getVw(35px);
                                font-family: $font-ko;
                                margin-bottom: getVw(121px);
                                color: #000000;
                                &:nth-of-type(1) {
                                    opacity: 0.2;
                                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 15.62%, #000 100%);
                                    background-clip: text;
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                }
                                &:last-of-type {
                                    margin-bottom: 0;
                                    opacity: 0.1;
                                    background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 80.21%);
                                    background-clip: text;
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                }
                            }
                        }
                        .text-right-box {
                            padding: getPercent(1920, 172) 0 getPercent(1920, 384);
                            h5 {
                                color: #000;
                                margin-bottom: getRem(32);
                            }
                            .p-text-feature {
                                font-size: getVw(20px);
                                // width: getRem(370);
                                color: #000;
                                @media screen and (max-width: 970px) {
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                    .img-cont-wrap {
                        position: absolute;
                        top: 0;
                        left: getPercent(1920, 460);
                        // transform: translateX(-50%);
                        width: getPercent(1920, 850);
                        margin-top: getPercent(1920, 180);
                    }
                }
                .cont-sec-08 {
                    position: relative;
                    background-color: #fff;
                    width: 100%;
                    .top-container{
                        padding: getPercent(1920, 79) getPercent(1920, 314) 0 getPercent(1920, 405);
                    }
                    .bottom-container{
                        width: 100%;
                        padding-bottom: getVw(241px);
                        background-color: #000;
                    }
                    .cont-img-box {
                        position: relative;
                        width: getPercent(1201, 600);
                        img {
                            z-index: 1;
                            position: relative;
                        }
                        video {
                            z-index: 0;
                            position: absolute;
                            top: getPercent(1040, 110);
                            height: auto;
                            width: getPercent(600, 378);
                            left: 50%;
                            transform: translateX(-50%);
                            border-radius: 15px;
                        }
                    }
                    .cont-wrap {
                        display: flex;
                        justify-content: space-between;
                        .h5-text-title {
                            color: #000;
                        }
                        .p-text-feature {
                            color: #131313;
                        }
                        &.top {
                            .h5-text-title {
                                margin-top: getPercent(472.45, 260);
                                margin-bottom: getRem(30);
                            }
                        }
                        &.bottom {
                            justify-content: flex-end;
                            padding-top: getPercent(1201, 241);
                            padding-right: getPercent(1201, 112);
                            padding-bottom: getPercent(1201, 230);
                            .h5-text-title {
                                margin-bottom: getRem(33);
                            }
                            .cont-img-box {
                                z-index: 2;
                                position: absolute;
                                width: getPercent(1920, 600);
                                left: getPercent(1920, 291);
                                bottom: 0;
                                margin-bottom: getVw(65.45px);
                            }
                        }
                    }
                }
                .cont-sec-09 {
                    z-index: 1;
                    position: relative;
                    background-color: #000;
                    padding: getPercent(1920, 115) 0 getPercent(1920, 219.82);
                    .cont-wrap {
                        position: relative;
                        img {
                            z-index: 1;
                            position: relative;
                        }
                        video {
                            z-index: 0;
                            position: absolute;
                            top: getPercent(809, 27);
                            left: 50%;
                            transform: translateX(-50%);
                            width: getPercent(400, 360);
                            height: auto;
                            border-radius: 39px;
                        }
                    }
                    .cont-text-wrap {
                        z-index: 1;
                        position: relative;
                        padding-bottom: getPercent(1920, 197);
                        padding-left: getPercent(1920, 943);
                        .h5-text-title {
                            margin-bottom: getRem(50);
                        }
                    }
                    .cont-img-wrap {
                        z-index: 1;
                        position: relative;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        .cont-img-list {
                            width: getPercent(1920, 400);
                            &:nth-of-type(2) {
                                margin-left: getPercent(1920, 66.93);
                                margin-right: getPercent(1920, 300.93);
                            }
                        }
                    }
                    .cont-img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: getPercent(1920, 400);
                        // margin-top: getPercent(1920, 241);
                        margin-left: getPercent(1920, 296);
                    }
                    .sec-bg {
                        z-index: 0;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: getPercent(1920, 939);

                        &::before {
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            display: block;
                            content: "";
                            width: getPercent(931, 490);
                            padding-bottom: getPercent(931, 991);
                            background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 70.5%, #000 100%);
                        }
                    }
                }
                .cont-sec-10 {
                    z-index: 0;
                    position: relative;
                    background: linear-gradient(180deg, #e7e8ec 0%, #fff 100%);
                    display: flex;
                    flex-direction: column;
                    .text-cont-wrap-bg {
                        z-index: 0;
                        position: absolute;
                        top: 0;
                        left: 0;
                        span {
                            display: block;
                            @include fontStyle(310.435px, 300, 100%);
                            font-size: getVw(310.435px);
                            text-transform: uppercase;
                            font-family: $font-en;
                            background: linear-gradient(272deg, #eee 1.74%, #f6f6f6 101.05%);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;

                            &.fashion {
                                margin-left: getVw(-40px);
                                margin-top: getVw(-48px);
                            }
                            &.trend{
                                text-align: right;
                                display: inline-block;
                                margin-left: getVw(314px);
                            }
                            &.style{
                                display: inline-block;
                                margin-left: getVw(119px);
                            }
                        }

                        &.end {
                            top: unset;
                            bottom: 0;
                            left: unset;
                            right: 0;
                            span {
                                background: linear-gradient(272deg, #eee 1.74%, #f6f6f6 101.05%);
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                margin-bottom: getVw(-44px);
                            }
                        }
                    }
                    .cont-img-01 {
                        z-index: 3;
                        position: relative;
                        align-self: flex-end;
                        margin-right: getPercent(1920, 250);
                        margin-top: getPercent(1920, 149);
                        width: getPercent(1920, 600);
                    }
                    div[class^="bg-img"] {
                        z-index: 1;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: getPercent(1920, 300);

                        &.bg-img-01 {
                            width: getPercent(1920, 280);
                            margin-top: getPercent(1920, 280);
                        }
                        &.bg-img-02 {
                            margin-left: getPercent(1920, 383);
                            margin-top: getPercent(1920, -20);
                        }
                        &.bg-img-03 {
                            margin-top: getPercent(1920, 609);
                            margin-left: getPercent(1920, 710);
                        }
                        &.bg-img-04 {
                            margin-top: getPercent(1920, 909);
                            margin-right: getPercent(1920, 81);
                            left: unset;
                            right: 0;
                        }
                        &.bg-img-05 {
                            width: getPercent(1920, 200);
                            margin-top: getPercent(1920, 118);
                            left: unset;
                            right: 0;
                        }
                    }
                    .bg-gradient {
                        z-index: 2;
                        position: absolute;
                        top: getVw(956px);
                        left: 0;
                        width: 100%;
                    }

                    .cont-wrap {
                        z-index: 3;
                        position: relative;
                        display: flex;
                        margin-top: getPercent(1920,-160px);
                        margin-bottom: getPercent(1920, 100.45px);
                        .cont-img-02 {
                            position: relative;
                            width: getPercent(1920, 600);
                            margin-left: getPercent(1920, 249);
                            margin-right: getPercent(1920, 53);
                            img {
                                z-index: 1;
                                position: relative;
                            }
                            video {
                                z-index: 0;
                                position: absolute;
                                left: 50%;
                                transform: translateX(-50%);
                                width: getPercent(600, 380);
                                height: auto;
                                top: getPercent(1039, 110);
                                border-radius: 15px;
                            }
                        }
                        .h5-text-title {
                            color: #131313;
                            margin: getVw(235px) 0 getRem(30);
                        }
                        .p-text-feature {
                            color: #131313;
                        }
                    }
                }
                .cont-sec-11 {
                    display: flex;
                    overflow: hidden;

                    > div {
                        width: 50%;

                        &.cont-wrap-02 {
                            background-color: #ff329b;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .cont-wrap {
                                position: relative;
                                width: getPercent(960, 508);
                                img {
                                    z-index: 1;
                                    position: relative;
                                }
                                video {
                                    z-index: 0;
                                    position: absolute;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    width: getPercent(508, 323);
                                    height: auto;
                                    top: getPercent(881, 93.08);
                                    border-radius: 15px;
                                }
                            }
                        }
                    }
                }
                .cont-sec-12 {
                    display: flex;
                    overflow: hidden;
                    .cont-text-wrap {
                        padding-top: getPercent(1920, 248);
                        padding-left: getPercent(1920, 250);
                        padding-right: getPercent(1920, 73);
                        h5 {
                            margin-bottom: getRem(30);
                        }
                    }
                    .img-cont-wrap {
                        display: flex;
                        flex-grow: 1;
                        justify-content: flex-end;
                        transform: translateX(4.92%);
                        .img-cont-list {
                            width: getPercent(1251.38, 360);
                            margin-right: getPercent(1251.38, 68);
                            div {
                                &:first-of-type {
                                    margin-bottom: getPercent(360, 50);
                                }
                            }
                            &:nth-of-type(1) {
                                transform: translateY(-10.795%);
                            }
                            &:nth-of-type(2) {
                                transform: translateY(-9.56%);
                            }
                            &:last-of-type {
                                margin-right: 0;
                                transform: translateY(-39.583%);
                                div {
                                    &:first-of-type {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }
                .cont-sec-13 {
                    position: relative;
                    padding-bottom: getPercent(1920, 950);

                    video {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                    }
                }
            }
        }

        /* new lexus */
        &[data-hash="lexus_new"] {
            .project-cover {
                .cover-img-wrap {
                    background: {
                        image: url("../../../public/site/res/image/lexus/img_lexus_bg_sec1.webp");
                        size: cover;
                        position: bottom;
                    }
                    position: relative;

                    &.on {
                        .phone-wrap {
                            transform: translateY(0);
                            transition: transform 1.5s;
                        }
                    }

                    .phone-wrap {
                        z-index: 1;
                        position: absolute;
                        width: getPercent(1920, 1200);
                        bottom: 0;
                        right: getPercent(1920, 10);
                        // transition: transform 1.2s;
                        transform: translateY(100%);
                        transition-delay: 0.25s;
                    }
                }

                .cover-text-wrap {
                    .container {
                        background-color: #000;
                        margin-top: -3px;
                        .project-type,
                        .project-name {
                            color: #fff;
                        }
                        .project-team {
                            .team-tag {
                                color: #fff;
                                border-color: #fff;
                            }
                        }

                        .project-desc {
                            margin-bottom: getPercent(1920, 202);
                            width: getRem(700px);
                            p {
                                color: #fff;
                                @include fontStyle(18px, 500, 190%, -0.72px);
                                word-break: keep-all;
                            }
                        }
                    }
                }
            }

            .work-detail-contents {                                
                .work-detail-information {
                    .work-detail-information-container {
                        color: #fff;
                        
                        .container-desc {
                            .desc-tit {
                                @include fontStyle(18px, 400, 130%);
                                color: #B9C0C2;
                                text-transform: uppercase;
                                font-family: $font-en;
                            }
                            .desc-text {
                                word-break: keep-all;
                                margin-top: 32px;
                                @include fontStyle(18px, 500, 190%, -0.72px);
                                
                            }
                        }
                        .cont-img-mockup {
                            position: relative;
                            z-index: 1;
                            background-size: 100% auto;
                            background-repeat: no-repeat;

                            &:after {
                                content:'';
                                display: block;
                            }
                        }

                        .cont-img-scrollview {
                            z-index: 0;
                            position: absolute;
                            top: 5px;
                            left: 50%;
                            transform: translateX(-50%);
                            overflow: hidden;
                        }


                        &.work-detail-information-container-01 {
                            overflow: hidden;
                            align-items: flex-start;
                            padding: getPercent(1920, 160) 0;
                            background-image: url("../../../public/site/res/image/lexus/img_lexus_bg_sec3_pc.webp"); ;
                            background-size: cover;

                            .container-desc {
                                max-width: getRem(1060);
                                margin-left: getPercent(1920, 250);
                                padding-bottom: getPercent(1920, 318);

                            }
                            .point-wrap {
                                display: flex;
                                flex-direction: column;
                                width:100%;
                                padding: 0 getPercent(1920, 40);

                                .point-list {
                                    color: #fff;
                                    display: flex;
                                    flex-direction: column;
                                    width: fit-content;
                                    div {
                                        display: flex;
                                        align-items: center;
                                        color: #F3F4F4;

                                        &.ml-auto {
                                            margin-left: auto;
                                        }
                                    }
                                    strong {
                                        @include fontStyle(120px, 300, 100%);
                                        text-transform: uppercase;
                                        font-family: $font-en;
                                    }
                                    span {
                                        @include fontStyle(16px, 500, 130%, -0.64px);

                                        &.desc_01 {
                                            margin-left: getRem(49px);
                                        }
                                        &.desc_02 {
                                            margin-left: getRem(36px);
                                        }
                                        &.desc_03 {
                                            margin-right: getRem(43px);
                                        }
                                    }
                                    sub {
                                        align-self: flex-start;
                                        transform: translateY(17px);
                                        margin-left: getRem(10px);

                                        &.sub_02 {
                                            margin-left: 0;
                                            margin-right: getRem(10px);
                                        }

                                    }

                                    &:nth-of-type(2) {
                                        align-self:flex-end;
                                    }
                                    &:nth-of-type(3) {
                                        padding-left: getPercent(1840, 158);
                                    }
                                }
                            }
                        }
                        &.work-detail-information-container-02 {
                            position: relative;
                            min-height: 180vh;
                            .sticky {
                                position: sticky;
                                top: 0;
                                left: 0;
                                width: 100%;
                                min-height: 100vh;
                            }
                            .tit-wrap {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                overflow: hidden;
                                padding-top: getPercent(1920, 160);
                                div {
                                    position: relative;
                                    width: fit-content;
                                    h3 {
                                        @include fontStyle(140px, 300, 100%);
                                        color: #fff;
                                        text-transform: uppercase;
                                        font-family: $font-en;
                                        text-align: center;
                                    }
                                    .line {
                                        position: absolute;
                                        top: 50%;
                                        left: 0;
                                        width: 100%;
                                        height: 1px;
                                        background-color: #fff;
                                        display: inline-block;
                                        opacity: 0;
                                        
                                    }

    
                                    &.show-line {
                                        .line {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                            
                            .img-box {
                                width: 100%;
                                position: relative;

                                img {
                                    display: block;
                                    width: 100%;
                                    height: 100vh;
                                    object-fit: cover;
                                }

                                .text-wrap {
                                    position: absolute;
                                    top: getVw(255px);
                                    left: 0;
                                    width: 100%;
                                    overflow: hidden;
                                    opacity: 0;

                                    &.show {
                                        opacity: 1;
                                    }
            
                                    p {
                                        @include fontStyle(220px, 300, 100%);
                                        font-size: getVw(220px);
                                        font-family: $font-en;
                                        color: #fff;
                                        text-transform: uppercase;

                                        &.lexus {
                                            padding-left: getPercent(1920, 773);
                                        }

                                        &.owner {
                                            padding-left: getPercent(1920, 483);
                                        }
                                    }
                                    
                                }
                            }
                        }
                        &.work-detail-information-container-03 {
                            padding: getPercent(1920, 100);
                            .container-desc {
                                width: getRem(940px);
                            }
                        }
                        &.work-detail-information-container-04 {
                            position: relative;

                            .img-bg {

                                width: 100%;
                                img {
                                    display: block;
                                    width: 100%;
                                }
                            }
                            .tit-wrap {
                                position: absolute;
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap:0 getPercent(1920, 48);
                                padding-top: getPercent(1920, 160);
                                overflow: hidden;
                                
                                .strong {
                                    overflow: unset;
                                    @include fontStyle(200px, 300, 100%, -4px);
                                    font-size: getVw(200px);
                                    
                                }
                                .small {
                                    text-transform: lowercase;
                                    @include fontStyle(28px, 300, 100%, -0.56px);
                                    
                                }
                            } 

                            .desc {
                                position: absolute;
                                bottom: 0;                                
                                text-align: center;
                                padding-bottom: getPercent(1920, 160);
                            }
                            
                            .blur {
                                padding-bottom: getPercent(1920, 46.31);
                                width: 100%;
                                background-color: #01040D;
                            }
                        }
                        &.work-detail-information-container-05 {
                            position: relative;
                            min-height: 250vh;

                            .sticky {
                                position: sticky;
                                width: 100%;
                                min-height: 100vh;
                                top: 0;
                                left: 0;
                                transition: background .9s;
                                transition-timing-function: linear;
                                transition-delay: 0.2s;
                                background: {
                                    size: cover;
                                };
                                display: flex;
                            }
                            .content-wrap {
                                display: flex;
                                align-items: center;
                                width: 100%;

                                .desc {
                                    text-align: center;
                                    width: getPercent(1920, 720);
                                    h5 {
                                        @include fontStyle(18px, 400, 130%);
                                        color: #E6E8E9;
                                        font-family: $font-en;
                                        text-transform: capitalize;
                                    }

                                    strong {
                                        @include fontStyle(48px, 700, 140%, -1.92px);
                                        display: block;
                                    }

                                    p {
                                        @include fontStyle(16px, 400, 190%, -0.64px);
                                        color: #B9C0C2;
                                    }

                                    &.desc-01 {
                                        h5 {
                                            margin-bottom: getRem(60px);
                                        }
                                    }

                                    &.desc-02 {
                                        strong {
                                            margin: getRem(48px) 0 getRem(16px);
                                            font-family: $font-en
                                        }
                                    }

                                    &.on {
                                        
                                        animation: textOpacity 1.2s 0.5s step-end;
                                        animation-timing-function: linear;
                                    }
                                }

                                @keyframes textOpacity {
                                    0% {
                                        opacity: 0;
                                    }

                                    100% {
                                        opacity: 1;
                                    }
                                }

                                .img-wrap {
                                    width: getPercent(1920, 480);
                                    position: relative;

                                    .img { 
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                        height: auto;
                                        opacity: 1;
                                        transition: transform 0.6s,opacity 0.7s .3s;
                                        transition-timing-function: linear;

                                        &:nth-of-type(1) {
                                            z-index: 3;
                                            transform: rotate(5deg) translateY(0%);

                                            &.shouldAnimate {
                                                transform: rotate(5deg) translateY(-40%);
                                            }
                                        }

                                        &:nth-of-type(2) {
                                            z-index: 2;
                                            transform: rotate(-5deg) translateY(0%);

                                            &.shouldAnimate {
                                                transform: rotate(-5deg) translateY(-40%);
                                            }
                                        }

                                        &:nth-of-type(3) {
                                            z-index: 1;
                                            position: relative;
                                        }
                                        
                                        &.shouldAnimate {
                                            opacity: 0;
                                            transition-delay: 0.2s; /* 트랜지션 실행 지연 */
                                        }
                                    }
                                    
                                    .line {
                                        z-index: 4;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        width: getPercent(480, 660);
                                        height: 1px;
                                        background-color: #B9C0C2;
                                    }
                                }
                            }
                        }
                        &.work-detail-information-container-06 {
                            background-color: #fff;
                            padding-top: getPercent(1920, 156);
                            .swiper-wrapper {
                                div {
                                    width: 480px !important;

                                }
                            }
                            .container-desc {
                                width: 100%;
                                padding: 0 getPercent(1920, 250);
                                display: flex;

                                .desc-tit {
                                    padding-top: getRem(6px);
                                }

                                .desc-text {
                                    width: getRem(1180px);

                                    margin-top: 0;
                                    margin-left: getPercent(1670, 78);
                                    color: #000;
                                }
                            }

                            .logo-wrap {
                                width: 100%;
                                padding: 0 getPercent(1920, 250);
                                display: flex;
                                justify-content: space-between;
                                margin-top: 50px;

                                .logo {
                                    width: getPercent(1420, 1060);
                                    flex-shrink: 0;
                                    background-color: #F3F4F4;
                                    
                                    img {
                                        
                                        width: getPercent(1060, 580);
                                        margin: 0 auto;

                                    }
                                }
                                .mark {
                                    width: getPercent(1420, 340);
                                    
                                }
                            }

                            .icon-box {
                                width: getPercent(1920, 1500);
                                margin-top: 60px;
                                margin-bottom: getPercent(1920, 150);
                            }
                            .color-list-wrap {
                                width: 100%;

                                .color-list {
                                    display: flex;

                                    .color-name,
                                    .color-code {
                                        @include fontStyle(18px, 400, 130%);
                                        text-transform: uppercase;
                                        color: #fff;
                                        font-family: $font-en;
                                    }
                                    .color-code {
                                        opacity: 0.5;
                                        margin-left: auto;
                                    }

                                    &.black {
                                        background-color: black;
                                        padding:16px getPercent(1920, 789) getPercent(1920, 281) getPercent(1920, 250);


                                    }
                                    &.blue {
                                        background-color: #3366CC;
                                        padding:16px getPercent(1920, 787) getPercent(1920, 121) getPercent(1920, 250);
                                        
                                    }
                                    &.gray {
                                        background-color: #B9C0C2;
                                        padding:16px getPercent(1920, 785) getPercent(1920, 41) getPercent(1920, 250);
                                    }

                                    &.light-gray {
                                        background-color: #F3F4F4;
                                        padding:16px getPercent(1920, 796) getPercent(1920, 46) getPercent(1920, 250);
                                        
                                        .color-name,
                                        .color-code {
                                            color: #9FA0A0;

                                        }
                                    }
                                    &.white {
                                        background-color: #fff;
                                        padding:16px getPercent(1920, 799) getPercent(1920, 41) getPercent(1920, 250);
                                        
                                        .color-name,
                                        .color-code {
                                            color: #9FA0A0;

                                        }
                                    }
                                }
                            }
                        }
                        &.work-detail-information-container-07 {
                            background-color: #fff;

                            .container-07-wrap {
                                position: relative;
                                background-color: #000;
                                border-radius: getVw(80px);
                                background-image: linear-gradient(108deg, #272A31 0%, #000 100%);
                                padding: getPercent(1920, 160) 0 getPercent(1920, 862);
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                width: 100%;

                                .work-detail-information-img-01 {
                                    width: getPercent(1920, 1202);
                                }
                                .work-detail-information-img-02 {
                                    position: absolute;
                                    width:getPercent(1905, 1076);
                                    top: getVw(428px);
                                }
                            }
                        }
                        &.work-detail-information-container-08 {
                            background-color: #fff;
                            padding-top: getRem(160px);
                            // padding-top: getPercent(1920, 160);
                            padding-bottom: getPercent(1920, 160.44);
                            position: relative;

                            .text-wrap {
                                margin-right: auto;
                                padding-left: getPercent(1920, 490);
                            }
                            .container-desc {
                                color: #000;
                                // max-width: getRem(580px);
                                h4 {
                                    @include fontStyle(56px, 400, 100%, -1.12px);
                                    text-transform: uppercase;
                                    font-family: $font-en;
                                }

                                .desc-text {
                                    margin-top: getRem(32px);
                                }
                            }

                            .work-detail-information-img {
                                position: absolute;
                                width: getPercent(1920, 320);
                                top:0;

                                &.work-detail-information-img-01 {
                                    margin-top: getRem(247px);
                                    // margin-top: getPercent(1920, 247);
                                    right: getPercent(1920, 370);
                                }
                                &.work-detail-information-img-02 {
                                    margin-top: getRem(439px);
                                    // margin-top: getPercent(1920, 439);
                                    left: getPercent(1920, 370);
                                }
                                &.work-detail-information-img-03 {
                                    top: unset;
                                    bottom: getVw(-250.13px);
                                    right: getPercent(1920, 370);
                                }

                                
                            }

                            .work-detail-information-img-lottie {
                                width:  getPercent(1920, 320);
                                padding-top: getPercent(1920, 240);

                                .lottie-wrap {
                                    position: relative;
                                    // width: getPercent(1920, 320);
                                    overflow: hidden;

                                    .cont-img-mockup {
                                        position: relative;
                                        z-index: 1;
                                        background-image: url('../../../public/site/res/image/lexus/img_sec10_mockup_pc.webp');
                                        
                                        
                                        &:after {
                                            padding-bottom: getPercent(320, 650);
                                        }
                                    }

                                    .cont-img-scrollview {
                                        width: getPercent(320, 288);
                                        height: 98%;
                                        border-radius: getVw(30px);
                                        overflow: hidden;
                                        
                                        img {
                                            &.ani-on {
                                                animation: scrollUp 10s .8s forwards infinite;
                                            }
                                        }
                                        
                                    }

                                    @keyframes scrollUp {
                                        0% {
                                            transform: translateY(0%);
                                        }
                                        20% {
                                            transform: translateY(-14%);
                                        }
                                        40% {
                                            transform: translateY(-34%);
                                        }
                                        60% {
                                            transform: translateY(-55%);
                                        }
                                        80% {
                                            transform: translateY(-72%);
                                        }
                                        100% {
                                            transform: translateY(0%);
                                        }
                                    }
                                }
                            }
                        }
                        &.work-detail-information-container-09 {
                            padding-top: getPercent(1920, 160);
                            padding-bottom: getPercent(1920, 782);
                            .img-bg {
                                
                                position: absolute;
                                top:0;
                                width: 100%;
                                height: 100%;
                                background-color: #fff;

                                img {
                                    z-index: 1;
                                    position: relative;
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                }

                                &::after {
                                    z-index: 0;
                                    display: block;
                                    content: '';
                                    position: absolute;
                                    bottom: 0;
                                    width: 100%;
                                    height: 50%;
                                    background-color: #000;
                                }
                            }

                            .work-detail-information-img {
                                position: relative;
                                z-index: 1;
                            }

                           .work-detail-information-img-01 {
                                width: getPercent(1920, 868);
                           }
                           .work-detail-information-img-02 {
                            position:absolute;
                            top: getVw(382px);
                            width: getPercent(1920, 800);
                        }
                            
                        }

                        &.work-detail-information-container-10 {
                            .img-bg {
                                width: 100%;
                            }
                            .content {
                                padding-top: getPercent(1920, 160);
                                position: absolute;
                                width: 100%;

                                .container-desc {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    h4 {
                                        @include fontStyle(56px, 400, 100%, -1.12px);
                                        text-transform: uppercase;
                                        margin-right: getPercent(1920, 84);
                                        font-family: $font-en;
                                    }
                                    .desc-text {
                                        // width:getRem(340px);
                                        margin-left: getPercent(1920, 90);
                                    }

                                    .work-detail-information-img {
                                        position: relative;
                                        width: getPercent(1920, 320);

                                        .cont-img-mockup {
                                            width: 100%;
                                            background-image: url('../../../public/site/res/image/lexus/img_sec12_mockup_pc.webp');

                                            &:after {
                                                padding-bottom: getPercent(320, 650.323);
                                            }
                                            
                                        }

                                        .cont-img-scrollview {
                                            width: getPercent(320, 288);
                                            height: 98%;
                                            border-radius: getVw(30px);

                                            img {
                                                height: auto;
                                                object-fit: unset;

                                                &.ani-on {
                                                    animation: scrollUp02Pc 5s .8s forwards infinite;
                                                }
                                            }
                                        }
                                    }

                                }
                                @keyframes scrollUp02Pc {
                                    0% {
                                        transform: translateY(0%);
                                    }
                                    40% {
                                        transform: translateY(-29%);
                                    }
                                    80% {
                                        transform: translateY(-56%);
                                    }
                                    100% {
                                        transform: translateY(0%);
                                    }
                                }
                                
                            }
                        }
                        &.work-detail-information-container-11 {
                            background-color: #000;

                            .container-11-wrap {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                width: 100%;
                                padding: getPercent(1920, 160) 0;
                                border-radius: getVw(80px);
                                background: linear-gradient(180deg, #224EAC 0%, #C0C3CA 100%);

                                .work-detail-information-img-01 {
                                    width: getPercent(1920, 868);
                                }
                                .work-detail-information-img-02 {
                                    z-index: 1;
                                    

                                    .video-wrap {
                                        position: absolute;
                                        top: getVw(440px);
                                        left: getVw(560px);
                                        width: getPercent(1920, 360);

                                        .cont-img-mockup {
                                            background-image: url('../../../public/site/res/image/lexus/img_sec13_mockup_mo.webp');
                                            &::after {
                                                padding-bottom: getPercent(360, 731.613);
                                            
                                            }
                                        }

                                        .cont-img-scrollview {
                                            width: getPercent(360, 324);
                                            height: 98%;
                                            border-radius: getVw(32px);

                                            img {
                                                object-fit: unset;
                                                height: auto;

                                                &.ani-on {
                                                    animation: scrollUp03Pc 5s .8s forwards infinite;
                                                }
                                            }
                                        }
                                        
                                    }
                                    @keyframes scrollUp03Pc {
                                        0% {
                                            transform: translateY(0%);
                                        }
                                        40% {
                                            transform: translateY(-20%);
                                        }
                                        80% {
                                            transform: translateY(-54%);
                                        }
                                        100% {
                                            transform: translateY(0%);
                                        }
                                    }
                                }

                                .work-detail-information-img-03  {
                                    position: absolute;
                                    top: getVw(640px);
                                    right: getVw(560px);
                                    width: getPercent(1920, 360);
                                }

                                .desc {
                                    text-align: center;
                                    padding-top: getPercent(1920, 986);
                                    // max-width: getRem(733px);
                                    @include fontStyle(18px, 500, 190%, -0.72px);
                                }
                            }

                        }
                        &.work-detail-information-container-12 {
                            padding: getPercent(1920, 126) 0 getPercent(1920, 464);
                            background-image: url("../../../public/site/res/image/lexus/img_lexus_bg_sec14_pc.webp"); ;
                            background-size: cover;

                            .work-detail-information-img-01 {
                                width: getPercent(1920, 297);
                            }
                        }
                    }
                }
            }
        }

        @media all and (max-width: 767px) {
            .work-detail-wrap {
                .container {
                    width: getPercent(360, 296);
                }

                .project-cover {
                    .cover-img-wrap {
                        height: auto;

                        &::after {
                            content: "";
                            display: block;
                            padding-bottom: getPercent(360, 460);
                        }
                    }

                    .cover-text-wrap {
                        position: relative;
                        padding-top: getPercent(360, 48);

                        .container {
                            .client-name {
                                font-size: 1.6rem;
                            }

                            .project-title {
                                font-size: 3.8rem;
                                margin-top: getRem(8px);
                            }
                        }
                    }
                }

                .project-contents {
                    section {
                        &.project-intro {
                            display: block;
                            padding-top: getPercent(360, 48);

                            > div {
                                &.left {
                                    .project-description {
                                        p {
                                            width: 100%;
                                            // @include fontStyle(18px, 500, 28px, -0.04em);
                                            word-break: break-all;
                                            font-size: 1.8rem;
                                            font-weight: 500;
                                        }
                                    }
                                }

                                &.right {
                                    margin-top: getPercent(360, 96);

                                    .project-team {
                                        .team-title {
                                            font-size: 1.6rem;
                                        }

                                        .team-row {
                                            flex-wrap: wrap;
                                            gap: 2.4rem getPercent(296, 54);

                                            .team-list {
                                                width: getPercent(296, 110);
                                                li {
                                                    font-size: 1.6rem;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &[data-hash="kiaRental"] {
                    .project-cover {
                        .cover-img-wrap {
                            background-image: url("../../../public/site/res/image/img_cover_kiaRental_mobile.webp");
                        }
                    }

                    .project-contents {
                        section {
                            &.cont-sec-01 {
                                .cont-wrap {
                                    flex-direction: column;
                                    gap: 8.4rem 0;

                                    .cont-img-02 {
                                        width: getPercent(292, 105);
                                    }
                                }
                            }

                            &.cont-sec-02 {
                                background-image: url("../../../public/site/res/image/img_kia_section_02_bg_mobile.webp");
                                padding: getPercent(360, 302) 0 getPercent(360, 305);
                                *[class^="cont-img"]:not(.cont-img-01) {
                                    width: getPercent(360, 296);
                                }

                                .cont-img-01 {
                                    width: getPercent(360, 154);
                                    top: -6px;
                                    z-index: 4;
                                }
                                .cont-img-02 {
                                    top: getPercent(1015, 240);
                                    left: getPercent(360, 32);
                                }

                                .cont-img-03 {
                                    top: getPercent(1015, 678);
                                    right: getPercent(360, 32);
                                }
                            }

                            &.cont-sec-03 {
                                .cont-wrap {
                                    flex-direction: column;

                                    *[class^="cont-img"] {
                                        flex: none;
                                        padding: 0;
                                        width: 100%;

                                        .animation-container {
                                            &::after {
                                                padding-bottom: getPercent(360, 640);
                                            }
                                        }
                                    }

                                    .cont-img-01 {
                                        .animation-container {
                                            background-image: url("../../../public/site/res/image/img_kia_section_03_01_mobile.webp");
                                            background-size: getPercent(360, 230);
                                        }
                                    }
                                    .cont-img-02 {
                                        .animation-container {
                                            background-image: url("../../../public/site/res/image/img_kia_section_03_02_mobile.webp");
                                            background-size: getPercent(360, 160);
                                        }
                                    }
                                }
                            }

                            &.cont-sec-04 {
                                padding: getPercent(360, 160) 0;
                                .cont-img-01 {
                                    background-image: url("../../../public/site/res/image/img_kia_section_04_01_mobile.webp");
                                    &::before {
                                        padding-bottom: getPercent(296, 707);
                                    }
                                }

                                .cont-wrap {
                                    flex-direction: column;
                                    gap: 3.2rem 0;
                                    margin-top: 16rem;
                                }
                            }
                            &.cont-sec-05 {
                                overflow: hidden;
                                padding-bottom: getPercent(360, 164);
                                .img-card-list-wrap {
                                    &:nth-child(2) {
                                        margin-top: 0.8rem;
                                    }
                                    ul {
                                        gap: getPercent(360, 8);
                                        li {
                                            width: getPercent(360, 90);
                                        }
                                    }
                                }

                                > .cont-wrap {
                                    padding-top: getPercent(360, 374);
                                    flex-direction: column;
                                    margin-top: 0;
                                    *[class^="cont-img"] {
                                        width: getPercent(296, 158);
                                        img {
                                            border-radius: 14px;
                                            border-width: 4.45px;
                                        }

                                        &.cont-img-01 img,
                                        .cont-img-03 img {
                                            box-shadow: 40.448px 48.8747px 58.9867px -21.0667px rgba(0, 0, 0, 0.2);
                                        }
                                    }

                                    .cont-img-02 {
                                        position: relative;
                                        align-self: flex-end;
                                        right: getPercent(292, -72);
                                        margin-top: calc(-50% + 48px);
                                    }

                                    .cont-wrap {
                                        transform: none;

                                        .cont-img-03 {
                                            position: absolute;
                                            top: 0;
                                            right: 0;
                                            transform: translateY(-24px);
                                        }

                                        .cont-img-04 {
                                            margin-top: -71px;
                                        }
                                    }
                                }
                            }

                            &.cont-sec-06 {
                                .cont-wrap {
                                    flex-direction: column;
                                    *[class^="cont-img"] {
                                        width: 100%;

                                        &::after {
                                            padding-bottom: getPercent(360, 640);
                                        }

                                        @for $i from 1 to 5 {
                                            &.cont-img-0#{$i} {
                                                background-image: url("../../../public/site/res/image/img_kia_section_06_0#{$i}_mobile.webp");
                                            }
                                        }
                                    }
                                }
                            }

                            &.cont-sec-07 {
                                padding: getPercent(360, 160) 0;
                                .cont-img-01 {
                                    background-image: url("../../../public/site/res/image/img_kia_section_07_01_mobile.webp");

                                    &::after {
                                        padding-bottom: getPercent(296, 851);
                                    }
                                }
                            }

                            &.cont-sec-08 {
                                background-image: url("../../../public/site/res/image/img_kia_section_08_bg_mobile.webp");
                                overflow: hidden;
                                padding-bottom: getPercent(360, 200);

                                .cont-img-01 {
                                    width: getPercent(296, 468);
                                }
                            }

                            &.cont-sec-09 {
                                padding: getPercent(360, 126) 0;

                                .cont-img-01 {
                                    width: 100%;
                                }
                            }

                            &.cont-sec-10 {
                                .cont-img-01 {
                                    width: getPercent(360, 202);
                                    margin-left: getPercent(360, 32);
                                }

                                .cont-img-02 {
                                    width: getPercent(360, 117);
                                    top: getPercent(360, 94);
                                }

                                .cont-img-03 {
                                    width: getPercent(360, 253);
                                    img {
                                        border-radius: 4px;
                                        box-shadow: 21.4938px 25.9717px 31.3451px -11.1947px rgba(0, 0, 0, 0.2);
                                    }
                                }

                                .cont-img-04 {
                                    width: getPercent(360, 253);
                                    left: getPercent(360, 75);
                                    margin-top: 6rem;
                                    img {
                                        border-radius: 4px;
                                        box-shadow: 16.8667px 20.3806px 24.5972px -8.78472px rgba(0, 0, 0, 0.2);
                                    }
                                }
                            }

                            &.cont-sec-11 {
                                .cont-img-01 {
                                    background-image: url("../../../public/site/res/image/img_kia_section_11_01_mobile.webp");

                                    &::after {
                                        padding-bottom: getPercent(360, 640);
                                    }
                                }
                            }

                            &.cont-sec-12 {
                                .cont-img-01 {
                                    width: 100%;
                                    background-image: url("../../../public/site/res/image/img_kia_section_12_01_mobile.webp");

                                    &::after {
                                        padding-bottom: getPercent(296, 378);
                                    }
                                }
                            }

                            &.cont-sec-13 {
                                .cont-wrap {
                                    flex-direction: column;
                                    *[class^="cont-img"] {
                                        width: 100%;
                                        &::after {
                                            padding-bottom: getPercent(296, 378);
                                        }
                                    }

                                    @for $i from 1 to 5 {
                                        &.cont-img-0#{$i} {
                                            background-image: url("../../../public/site/res/image/img_kia_section_13_0#{$i}_mobile.webp");
                                        }
                                    }

                                    .cont-img-03 {
                                        order: 4;
                                    }
                                }
                            }

                            &.cont-sec-14 {
                                background-image: url("../../../public/site/res/image/img_kia_section_14_bg_mobile.webp");
                                padding: getPercent(360, 306) 0;
                                .cont-img-01 {
                                    width: getPercent(360, 224);
                                }
                            }
                        }
                    }
                }

                &[data-hash="metaPassport"] {
                    .project-cover {
                        .cover-img-wrap {
                            background-image: url("../../../public/site/res/image/img_cover_bg_metaPassport_mobile.webp");
                        }

                        .cover-text-wrap {
                            background-image: url("../../../public/site/res/image/img_bg_metaPassport_mobile.webp");
                            p {
                                word-break: keep-all;
                            }
                        }
                    }

                    .project-contents {
                        background-image: url("../../../public/site/res/image/img_bg_metaPassport_mobile.webp");

                        .cont-sec-01 {
                            background-image: url("../../../public/site/res/image/img_bg_metaPassport_mobile.webp");
                            background-size: 100% auto;
                            background-position: bottom;
                            padding: getPercent(360, 250) 0 getPercent(360, 105);
                            .cont-img-01 {
                                width: getPercent(360, 175);
                            }
                        }

                        .cont-sec-02 {
                            background-color: #000;
                            padding: getPercent(360, 160) 0 getPercent(360, 80);

                            .cont-img-01 {
                                .animation-container {
                                    background-image: url("../../../public/site/res/image/img_mpp_section_01_01_mobile.webp");

                                    &::after {
                                        padding-bottom: getPercent(296, 200);
                                    }
                                }
                            }

                            .d-flex {
                                flex-direction: column;
                                margin-top: getPercent(360, 24);

                                div[class^="cont-img"] ~ div[class^="cont-img"] {
                                    margin-top: getPercent(360, 24);
                                }

                                .cont-img-02 {
                                    .animation-container {
                                        background-image: url("../../../public/site/res/image/img_mpp_section_01_02_mobile.webp");

                                        &::after {
                                            padding-bottom: getPercent(296, 360);
                                        }
                                    }
                                }
                                .cont-img-03 {
                                    .animation-container {
                                        background-image: url("../../../public/site/res/image/img_mpp_section_01_03_mobile.webp");

                                        &::after {
                                            padding-bottom: getPercent(296, 360);
                                        }
                                    }
                                }
                            }
                        }

                        .cont-sec-03 {
                            padding: getPercent(360, 209) 0 getPercent(360, 290);
                            overflow: hidden;
                            background-image: url("../../../public/site/res/image/img_mpp_bg_section_02_mobile.webp");

                            .container {
                                width: 100%;
                                *[class^="cont-wrap"] {
                                    display: flex;
                                    flex-direction: column;
                                    width: 100%;

                                    *[class^="cont-img"] {
                                        flex-shrink: 0;
                                        width: getPercent(360, 350);

                                        &:nth-child(1) {
                                            transform: translateX(#{getPercent(360, -107)});
                                        }

                                        &:nth-child(2) {
                                            align-self: flex-end;
                                            transform: translateX(#{getPercent(350, 11)});
                                        }
                                    }
                                }

                                .cont-wrap-01 {
                                    margin-left: 0;
                                    .cont-img-02 {
                                        margin-top: getPercent(360, -316);
                                    }
                                }

                                .cont-wrap-02 {
                                    position: relative;
                                    margin-top: getPercent(360, -117);
                                    transform: none;
                                    .cont-img-04 {
                                        margin-top: getPercent(360, -116);
                                    }
                                }
                            }
                        }

                        .cont-sec-04 {
                            padding-bottom: getPercent(360, 1214);
                            .container {
                                .cont-wrap-01 {
                                    flex-direction: column;
                                    margin-top: getPercent(296, 24);

                                    div[class^="cont-img"] ~ div[class^="cont-img"] {
                                        margin-top: getPercent(296, 24);
                                    }
                                }

                                .cont-img-03 {
                                    margin-top: getPercent(296, 24);

                                    .animation-container {
                                        background-image: url("../../../public/site/res/image/img_mpp_section_03_03_mobile.webp");

                                        &::after {
                                            padding-bottom: getPercent(296, 1574);
                                        }
                                    }
                                }
                            }
                        }

                        .cont-sec-05 {
                            background-image: url("../../../public/site/res/image/img_mpp_bg_section_04_mobile.webp");
                            padding-top: getPercent(360, 621);
                            .cont-img-01 {
                                transform: translateX(#{getPercent(360, -43)});
                                width: getPercent(360, 345);

                                &::after {
                                    background-color: url("../../../public/site/res/image/img_mpp_section_04_01_mobile.webp");
                                    padding-bottom: getPercent(345, 529);
                                }
                            }

                            .img-slide {
                                margin-top: getPercent(360, 752);
                                ul {
                                    width: getPercent(360, 1826);

                                    li {
                                        width: getPercent(1826, 131);
                                        padding: getPercent(1826, 5);

                                        img {
                                            transform: none;
                                        }
                                    }
                                }
                            }

                            .container {
                                position: relative;
                                flex-wrap: wrap;
                                flex-direction: column;
                                gap: 0 getPercent(360, 15);
                                margin: getPercent(360, 255) auto getPercent(360, 356);
                                width: getPercent(360, 296);
                                align-items: flex-start;

                                div[class^="cont-wrap"] {
                                    width: calc(50% - #{getPercent(360, 7.5)});
                                    flex: auto;
                                    flex-grow: 0;
                                }

                                .cont-wrap-01 {
                                    .cont-img-02 {
                                        .animation-container {
                                            background-image: url("../../../public/site/res/image/img_mpp_section_05_01_mobile.webp");
                                            &::after {
                                                padding-bottom: getPercent(141, 302);
                                            }
                                        }
                                    }
                                    .cont-img-03 {
                                        .animation-container {
                                            background-image: url("../../../public/site/res/image/img_mpp_section_05_02_mobile.webp");
                                            &::after {
                                                padding-bottom: getPercent(141, 305);
                                            }
                                        }
                                    }
                                }

                                .cont-wrap-02 {
                                    position: absolute;
                                    right: 0;
                                    margin-top: getPercent(360, -70);

                                    .cont-img-04 {
                                        .animation-container {
                                            background-image: url("../../../public/site/res/image/img_mpp_section_05_05_mobile.webp");
                                            &::after {
                                                padding-bottom: getPercent(141, 510);
                                            }
                                        }
                                    }
                                    .cont-img-05 {
                                        .animation-container {
                                            background-image: url("../../../public/site/res/image/img_mpp_section_05_06_mobile.webp");
                                            &::after {
                                                padding-bottom: getPercent(141, 305);
                                            }
                                        }
                                    }
                                    .cont-img-06 {
                                        .animation-container {
                                            background-image: url("../../../public/site/res/image/img_mpp_section_05_07_mobile.webp");
                                            &::after {
                                                padding-bottom: getPercent(141, 560);
                                            }
                                        }
                                    }
                                }

                                .cont-wrap-03 {
                                    margin-top: getPercent(360, 32);

                                    .cont-img-07 {
                                        .animation-container {
                                            background-image: url("../../../public/site/res/image/img_mpp_section_05_03_mobile.webp");
                                            &::after {
                                                padding-bottom: getPercent(141, 305);
                                            }
                                        }
                                    }
                                    .cont-img-08 {
                                        .animation-container {
                                            background-image: url("../../../public/site/res/image/img_mpp_section_05_04_mobile.webp");
                                            &::after {
                                                padding-bottom: getPercent(141, 305);
                                            }
                                        }
                                    }
                                }
                            }

                            .cont-img-09 {
                                width: getPercent(360, 219);
                                margin: getPercent(360, 171) auto;
                            }
                        }
                    }
                }

                &[data-hash="ddb"] {
                    .project-cover {
                        .cover-img-wrap {
                            background-image: url("../../../public/site/res/image/img_cover_ddb_mobile.webp");
                        }
                        .cover-text-wrap {
                            .container {
                                .project-title,
                                .client-name {
                                    color: #304c3f;
                                }
                            }
                        }
                    }

                    .project-contents {
                        .project-intro {
                            padding-bottom: getPercent(360, 100);
                        }

                        .cont-sec-01 {
                            padding-top: getPercent(360, 100);
                            background-image: url("../../../public/site/res/image/img_ddb_section_01_bg_mobile.webp");
                            background-repeat: no-repeat;
                            background-size: cover;
                            .cont-img-01 {
                                width: getPercent(360, 214);

                                & ~ div[class^="cont-img"] {
                                    width: getPercent(360, 175);
                                }
                            }

                            .cont-img-02 {
                                display: none;
                            }

                            .cont-img-03 {
                                top: getPercent(700, 585);
                                right: getPercent(360, 23);
                            }

                            .cont-img-04 {
                                left: getPercent(360, 26);
                                bottom: getPercent(700, 161);
                            }
                        }

                        .cont-sec-02 {
                            padding-top: getPercent(360, 100);
                            .cont-wrap-01 {
                                flex-direction: column;
                                width: getPercent(360, 234);
                                div[class^="cont-img"] {
                                    width: 100%;
                                    transform: translate(getPercent(360, 24), getPercent(360, 16));

                                    &.cont-img-02 {
                                        margin-top: getPercent(360, 154);
                                    }
                                    &.cont-img-03 {
                                        margin-top: getPercent(360, 80);
                                    }
                                }
                            }

                            .cont-wrap-02 {
                                width: getPercent(360, 160);
                                margin-top: getPercent(360, 655);
                                left: getPercent(360, 144);
                            }
                        }

                        .cont-sec-03 {
                            overflow: hidden;
                            padding-top: getPercent(360, 158);
                            background-image: url("../../../public/site/res/image/img_ddb_section_03_bg_mobile.webp");
                            div[class^="bg-chart"] {
                                &.bg-chart-line {
                                    width: 100%;
                                    top: 0;
                                    &::after {
                                        padding-bottom: getPercent(360, 131);
                                    }
                                    .line {
                                        .bg {
                                            height: getPercent(131, 125);
                                            background-image: url("../../../public/site/res/image/img_ddb_section_03_bg_chart_line_mobile.webp");
                                            top: 0;
                                            transform: none;
                                        }
                                    }
                                    .point {
                                        background-image: url("../../../public/site/res/image/img_ddb_section_03_bg_chart_point_mobile.webp");
                                    }
                                }

                                &.bg-chart-bar {
                                    width: 100%;
                                    .bg {
                                        background-image: url("../../../public/site/res/image/img_ddb_section_03_bg_chart_bar_mobile.webp");
                                    }

                                    &::after {
                                        padding-bottom: getPercent(360, 209);
                                    }
                                }
                            }

                            .emoji {
                                z-index: 3;
                                &.emoji-05,
                                &.emoji-06,
                                &.emoji-04 {
                                    display: none;
                                }

                                &.emoji-02 {
                                    width: getPercent(360, 88);
                                    top: getPercent(600, 45);
                                    left: auto;
                                    right: getPercent(360, 20);
                                }
                                &.emoji-03 {
                                    width: getPercent(360, 63);
                                    top: getPercent(600, 158);
                                    left: getPercent(360, 33);
                                }
                                &.emoji-01 {
                                    width: getPercent(360, 48);
                                    top: getPercent(600, 281);
                                    left: auto;
                                    right: getPercent(360, 32);
                                }
                            }
                            .cont-img-01 {
                                width: getPercent(360, 340);
                                transform: translateX(getPercent(360, 46));
                                &::after {
                                    padding-bottom: getPercent(340, 426);
                                }

                                .animation-container {
                                    background-image: url("../../../public/site/res/image/img_ddb_section_03_01_mobile.webp");
                                }
                            }
                        }

                        .cont-sec-04 {
                            overflow: hidden;
                            padding: getPercent(360, 84) 0 getPercent(360, 79);
                            .cont-wrap-01 {
                                align-items: flex-start;
                                width: getPercent(360, 324);
                                .cont-img-01 {
                                    position: relative;
                                    width: getPercent(324, 206);
                                    z-index: 1;

                                    & ~ div[class^="cont-img"] {
                                        transform: translate(getPercent(324, -96), getPercent(324, 54));
                                        width: getPercent(324, 188);
                                    }
                                }

                                .cont-img-03 {
                                    margin-left: getPercent(324, -16);
                                }

                                .cont-img-04 {
                                    display: none;
                                }
                            }

                            .cont-wrap-02 {
                                display: flex;
                                margin-top: getPercent(360, 40);
                                transform: translateX(getPercent(360, -8));

                                div[class^="cont-img"] {
                                    width: getPercent(360, 188);
                                }

                                .cont-img-04 {
                                    margin-left: getPercent(360, -16);
                                }
                            }
                        }

                        .cont-sec-05 {
                            padding: getPercent(360, 95) 0;
                            .cont-wrap-01 {
                                width: getPercent(360, 190);
                            }

                            .swiper-container {
                                top: getPercent(610, 205);
                                .swiper-wrapper {
                                    .swiper-slide {
                                        width: getPercent(360, 107);
                                        padding: 0 getPercent(360, 3);
                                    }
                                }
                            }
                        }

                        .cont-sec-06 {
                            overflow: hidden;
                            .container {
                                width: getPercent(360, 330);

                                div[class^="cont-wrap"] {
                                    width: getPercent(360, 144);
                                    &.cont-wrap-01 {
                                        transform: translateX(getPercent(360, -48));
                                    }

                                    &.cont-wrap-02 {
                                        transform: translateX(getPercent(360, -80));
                                    }
                                    &.cont-wrap-03 {
                                        transform: translateX(getPercent(360, -112));
                                    }
                                }
                            }
                        }

                        .cont-sec-07 {
                            padding: getPercent(360, 147) 0;
                            .cont-img-01 {
                                width: getPercent(360, 100);
                            }
                        }
                    }
                }

                &[data-hash="archiveat"] {
                    .project-cover {
                        .cover-img-wrap {
                            background-image: url("../../../public/site/res/image/img_cover_archiveat_mobile.webp");
                        }
                        .cover-text-wrap {
                            .container {
                                * {
                                    color: #ff5019 !important;
                                }
                            }
                        }
                    }

                    .project-contents {
                        .project-intro {
                            padding-bottom: getPercent(360, 100);
                        }
                        .cont-sec-01 {
                            padding-bottom: getPercent(360, 609);
                            .cont-img-01 {
                                .reactive {
                                    background-image: url("../../../public/site/res/image/img_archiveat_section_01_01_mobile.webp");

                                    &::after {
                                        padding-bottom: getPercent(360, 270);
                                    }
                                }
                            }

                            .cont-img-02 {
                                width: getPercent(360, 174);
                                margin-top: getPercent(360, 236);
                                margin-right: getPercent(360, 23);
                            }

                            .img-slide {
                                margin-top: getPercent(360, 586);
                                ul {
                                    width: getPercent(360, 1033);

                                    li {
                                        width: getPercent(1033, 121);
                                    }
                                }
                            }
                        }

                        .cont-sec-02 {
                            background-image: url("../../../public/site/res/image/img_archiveat_section_02_bg_mobile.webp");
                            padding: 0;

                            &::after {
                                content: "";
                                display: block;
                                padding-bottom: getPercent(360, 360);
                            }

                            .cont-wrap {
                                position: absolute;
                                width: getPercent(360, 200);
                                left: 50%;
                                transform: translate(-50%, getPercent(433, 80));

                                .cont-wrap-01 {
                                    border-radius: 20px;

                                    .cont-img-01 {
                                        border: 5px solid #fff;
                                        border-radius: 20px;
                                        background-image: url("../../../public/site/res/image/img_archiveat_section_02_mockup_mobile.webp");

                                        &::after {
                                            padding-bottom: getPercent(200, 433);
                                        }
                                    }

                                    .cont-img-02 {
                                        width: calc(100% - 10px);
                                        left: 5px;
                                    }
                                }
                            }
                        }

                        .cont-sec-03 {
                            padding: getPercent(360, 101) 0 getPercent(360, 329);
                            .cont-img-01 {
                                width: getPercent(360, 184);
                                margin-left: getPercent(360, 48);
                            }

                            .img-slide {
                                margin-top: getPercent(360, 443);
                                ul {
                                    width: getPercent(360, 1099);

                                    li {
                                        width: getPercent(1099, 122);
                                    }
                                }
                            }
                        }

                        .cont-sec-04 {
                            .cont-wrap-01 {
                                flex-direction: column;

                                .cont-img-02 {
                                    padding-top: getPercent(360, 54);
                                    .cont-img-container {
                                        position: relative;
                                        width: getPercent(360, 148);
                                        border-radius: 14px 14px 0 0;
                                        box-shadow: 15.7867px 15.7867px 18.944px rgba(61, 56, 48, 0.12);
                                    }
                                }
                            }
                        }

                        .cont-sec-05 {
                            padding: getPercent(360, 102) 0 getPercent(360, 288);
                            background-image: url("../../../public/site/res/image/img_archiveat_section_05_02_text_mobile.webp");
                            background-position: center getPercent(694, 455);

                            > .fade-up {
                                width: getPercent(360, 140);

                                &:nth-child(1) {
                                    margin-left: getPercent(360, 56);
                                }

                                &:nth-child(2) {
                                    margin-top: getPercent(360, 465);
                                    margin-right: getPercent(360, 56);
                                }
                            }

                            div[class^="cont-wrap"] {
                                border-radius: 13.44px;

                                .cont-img-mockup {
                                    border-radius: 13.44px;
                                    border-width: 5px;
                                }
                            }

                            .cont-wrap-01 {
                                .cont-img-mockup {
                                    box-shadow: 14.9333px 14.9333px 17.92px rgba(61, 56, 48, 0.12);
                                }

                                .cont-img-scrollview {
                                    width: calc(100% - 10px);
                                    left: 5px;
                                    top: 5px;
                                    bottom: 5px;
                                }
                            }
                        }

                        .cont-sec-06 {
                            .cont-img-01 {
                                .reactive {
                                    background-image: url("../../../public/site/res/image/img_archiveat_section_06_01_mobile.webp");

                                    &::after {
                                        padding-bottom: getPercent(360, 270);
                                    }
                                }
                            }
                        }

                        .cont-sec-07 {
                            padding: getPercent(360, 132) 0;
                            div[class^="bg-img"] {
                                &.bg-img-01 {
                                    width: getPercent(360, 48);
                                    margin-top: getPercent(360, 62);
                                    margin-left: 0;
                                }
                                &.bg-img-02 {
                                    width: getPercent(360, 46);
                                    margin-top: getPercent(360, 36);
                                    margin-left: getPercent(360, 267);
                                }
                                &.bg-img-03 {
                                    width: getPercent(360, 59);
                                    margin-top: getPercent(360, 344);
                                    margin-left: getPercent(360, 0);
                                }
                                &.bg-img-04 {
                                    width: getPercent(360, 49);
                                    margin-top: getPercent(360, 185);
                                    margin-left: getPercent(360, 30);
                                }
                                &.bg-img-05 {
                                    width: getPercent(360, 123);
                                    margin-top: getPercent(360, 255);
                                    margin-left: getPercent(360, 237);
                                }
                                &.bg-img-06 {
                                    width: getPercent(360, 114);
                                    margin-top: getPercent(360, 531);
                                    margin-left: getPercent(360, 36);
                                }
                                &.bg-img-07 {
                                    width: getPercent(360, 105);
                                    margin-top: getPercent(360, 435);
                                    margin-left: getPercent(360, 222);
                                }
                            }
                            .cont-wrap-01 {
                                width: getPercent(360, 160);
                                border-width: 5px;
                                border-radius: 15px;
                                box-shadow: 17.0667px 17.0667px 20.48px rgba(61, 56, 48, 0.12);
                                background-color: #f1f1f1;

                                .cont-img-mockup {
                                    border-radius: 15px;
                                    border-width: 4.27px;
                                }
                                .cont-img-scrollview {
                                    border-radius: 0 0 15px 15px;
                                    width: calc(100% - 10px);
                                    left: 5px;
                                }
                            }
                        }

                        .cont-sec-08 {
                            gap: 0 getPercent(360, 14);
                            > .fade-up {
                                width: getPercent(360, 75);
                            }
                        }
                    }
                }
            }

            .work-detail-cover {
                padding: 0;
                height: auto;
            }

            .work-detail-cover .work-detail-cover-img {
                position: relative;
            }

            .work-detail-cover .work-detail-cover-img::after {
                content: "";
                display: block;
                padding-bottom: 127.778%;
            }

            .work-detail-cover .work-detail-cover-img img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            .work-detail-cover-text {
                position: relative;
                width: 100%;
                height: 29.688%;
                margin: 0;
                bottom: 0;
                padding: 9.723% 8.889% 6.667%;
            }

            .work-detail-cover .work-detail-cover-sub-title {
                color: #000;
                font-size: 1.6rem;
                line-height: 1.269;
            }

            .work-detail-cover h3.work-detail-cover-title {
                color: #000;
                font-size: 4rem;
                margin-top: 2.021%;
            }

            .work-detail-intro {
                flex-direction: column;
                padding: 4.445% 8.889% 33.612%;
            }

            .work-detail-intro .work-detail-desc {
                width: 100%;
                margin: 0;
            }

            .work-detail-intro .work-detail-desc .work-detail-intro-text p {
                font-size: 1.8rem;
                line-height: 1.556;
            }

            .work-detail-intro .work-detail-team {
                margin-top: 32.433%;
            }

            .work-detail-team > h5 {
                font-size: 1.6rem;
                line-height: 1.75;
            }

            .work-detail-wrap .work-detail-project-role article {
                width: 50%;
            }

            .work-detail-project-role article h6,
            .work-detail-project-role article ul li {
                font-size: 1.6rem;
                line-height: 1.75;
            }

            .work-detail-project-role article.w-100 {
                width: 100%;
            }

            /* ekolon */
            .work-detail-wrap[data-hash="ekolon"] .work-detail-contents {
                background-color: #fff;
            }

            .work-detail-wrap[data-hash="ekolon"] .work-detail-team > h5 {
                color: #001fd2;
            }

            .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-01 {
                padding: 28.056% 0 50.834%;
                overflow: hidden;
            }

            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-01
                .work-detail-information-img-00 {
                width: 48.889%;
            }

            .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-01 .work-detail-information-img {
                top: 0;
            }

            /* 1 */
            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-01
                .work-detail-information-img-01 {
                width: 71.667%;
                right: -19.445%;
            }

            /* 2 */
            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-01
                .work-detail-information-img-02 {
                width: 37.223%;
                left: -16.013%;
                margin-top: 24.723%;
            }

            /* 3 */
            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-01
                .work-detail-information-img-03 {
                width: 53.334%;
                left: -8.52%;
                margin-top: 83.334%;
            }

            /* 4 */
            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-01
                .work-detail-information-img-04 {
                width: 35.556%;
                right: 3.59%;
                margin-top: 114.445%;
            }

            /* 5 */
            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-01
                .work-detail-information-img-05 {
                display: none;
            }

            /* 6 */
            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-01
                .work-detail-information-img-06 {
                margin-top: 10%;
                left: 24.731%;
            }

            /* 7, 9, 10 */
            .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-01 .work-detail-information-img-07,
            .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-01 .work-detail-information-img-09,
            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-01
                .work-detail-information-img-10 {
                display: none;
            }

            /* 8 */
            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-01
                .work-detail-information-img-08 {
                margin-top: 88.612%;
                right: 10.27%;
            }

            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-01
                .work-detail-information-img:nth-child(n + 7) {
                width: 12.5%;
            }

            /* work-detail-information-container-02  */
            .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-02 {
                padding: 0;
            }

            .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-02 .work-detail-information-img {
                width: 100% !important;
                margin: 0;
            }

            /* 1 */
            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-02
                .work-detail-information-img-01 {
                padding: 36.945% 25.556% !important;
                min-height: 100vh;
            }

            /* 2 */
            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-02
                .work-detail-information-img-02 {
                padding: 33.334% 25.556% !important;
                min-height: 100vh;
            }

            /* 3 */
            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-02
                .work-detail-information-img-03 {
                padding: 36.945% 25.556% !important;
                min-height: 100vh;
            }

            /* 4 */
            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-02
                .work-detail-information-img-04 {
                margin: 78.612% 0 66.667%;
            }

            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-02
                .work-detail-information-img-04::after {
                padding-bottom: 187.223%;
            }

            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-02
                .work-detail-information-img-04
                ul {
                flex-direction: column;
                align-items: flex-start;
                background-color: #f6f7f8;
                padding: 23.056% 5.834%;
                bottom: auto;
                height: 100%;
            }

            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-02
                .work-detail-information-img-04
                ul
                li {
                width: 46.541%;
                filter: drop-shadow(4px 6px 16px rgba(0, 0, 0, 0.12));
            }

            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-02
                .work-detail-information-img-04
                ul
                li
                ~ li {
                margin-left: 0;
                align-self: flex-end;
            }

            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-02
                .work-detail-information-img-04
                ul
                li:nth-child(1) {
                position: absolute;
                width: 41.112%;
            }

            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-02
                .work-detail-information-img-04
                ul
                li:nth-child(2) {
                margin-top: -41.195%;
            }

            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-02
                .work-detail-information-img-04
                ul
                li:nth-child(3) {
                margin-top: 10.692%;
            }

            /* 5 */
            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-02
                .work-detail-information-img-05 {
                position: relative;
            }

            /* 6 */
            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-02
                .work-detail-information-img-06 {
                position: relative;
                margin-top: 7.673%;
            }

            .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-02 .swiper-container li {
                width: 36.6%;
            }

            .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-02 .swiper-container li ~ li {
                margin-left: 7.706%;
            }

            /*work-detail-information-container-03 */
            .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-03 {
                margin-top: -16.389%;
                padding-top: 0;
            }

            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-03
                .work-detail-information-img-container:not(.for-mobile) {
                display: none;
            }

            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-03
                .work-detail-information-img-container.for-mobile {
                display: flex;
                position: relative;
                align-items: flex-start;
                padding: 0;
                margin: 0;
            }

            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-03
                .work-detail-information-img-container.for-mobile::after {
                padding-bottom: 353.889%;
            }

            .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-03 .work-detail-information-img {
                position: absolute;
                width: 41.112%;
                top: 0;
                height: auto;
            }

            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-03
                .work-detail-information-img:first-child {
                margin-top: 57.778%;
                left: 5.834%;
            }

            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-03
                .work-detail-information-img:last-child {
                margin-top: 80.556%;
                right: 5.834%;
                left: auto;
            }

            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-03
                .work-detail-information-img
                ul {
                flex-direction: column;
            }

            .work-detail-wrap[data-hash="ekolon"] .work-detail-information-container-04 {
                padding: 69.167% 0 0;
            }

            .work-detail-wrap[data-hash="ekolon"]
                .work-detail-information-container-04
                .work-detail-information-img-01 {
                padding: 22.328% 26.389%;
            }

            /* //ekolon */
            /* lexus */
            .work-detail-wrap[data-hash="lexus"] .work-detail-cover {
                background-color: #f6f6f6;
            }

            .work-detail-wrap[data-hash="lexus"] .work-detail-contents {
                background-color: #f6f6f6;
            }

            /* work-detail-information-container-01 */
            .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-01 {
                padding: 31.112% 4.445%;
            }

            /* 1 */
            .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-01 .work-detail-information-img-01 {
                margin-top: 12.196%;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-01
                .work-detail-information-img-01
                ul {
                flex-direction: row;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-02
                .work-detail-information-img-01
                li:nth-child(2) {
                width: 38.889%;
                left: 4.445%;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-02
                .work-detail-information-img-01
                li:nth-child(3) {
                right: 4.445%;
                margin-top: -21.389%;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-02
                .work-detail-information-img-01
                li:nth-child(4) {
                right: 18.162%;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-02
                .work-detail-information-img-01
                li:nth-child(n + 3) {
                width: 23.612%;
            }

            /* work-detail-information-container-03 */
            .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-03 {
                padding: 65.556% 4.445% 0;
            }

            .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-03 .work-detail-information-img-02 {
                width: 56.403%;
            }

            /* work-detail-information-container-04 */
            .work-detail-wrap[data-hash="lexus"] {
                .work-detail-information-container-04 {
                    background-color: red;

                    .work-detail-information-img-02 {
                        .swiper {
                            .swiper-wrapper {
                                .swiper-slide {
                                    width: 36.684%;
                                }
                            }
                        }
                    }
                }
            }
            .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-04 {
                padding-top: 35.684%;
                background-color: red;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-04
                .work-detail-information-img-02
                .swiper-wrapper
                .swiper-slide {
                width: 36.684%;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-04
                .work-detail-information-img-02
                .swiper-wrapper
                .swiper-slide
                ~ .swiper-slide {
                margin-left: 5.981%;
            }

            .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-04 .work-detail-information-img-01 {
                width: 38.889%;
            }

            /* work-detail-information-container-05 */
            .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-05 {
                margin-top: 35.556%;
                overflow: hidden;
                padding: 0 0 26.667%;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-05
                .work-detail-information-bg.circle {
                width: 209.723%;
                top: 0;
                margin-top: 31.389%;
            }

            .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-05 .work-detail-information-img-01 {
                width: 69.445%;
            }

            .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-05 .work-detail-information-img-02 {
                width: 100%;
                margin-top: 13.334%;
                padding: 0 6.667%;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-05
                .work-detail-information-img-02
                ul {
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-05
                .work-detail-information-img-02
                li {
                width: 44.872%;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-05
                .work-detail-information-img-02
                li:nth-child(2) {
                position: absolute;
                align-self: flex-start;
                margin: 0;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-05
                .work-detail-information-img-02
                li:nth-child(3) {
                margin-top: 10.256%;
            }

            /* work-detail-information-container-06 */
            .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-06:not(.for-mobile) {
                display: none;
            }

            .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-06.for-mobile {
                display: flex;
                flex-direction: column;
                padding-bottom: 35.556%;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-06.for-mobile
                .work-detail-information-img-01 {
                width: 48.889%;
                align-self: flex-start;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-06.for-mobile
                .work-detail-information-img-02 {
                width: 38.889%;
                margin-top: -3px;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-06.for-mobile
                .work-detail-information-img-03 {
                width: 48.889%;
                align-self: flex-start;
                margin-left: 4.445%;
                margin-top: -8.334%;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-06.for-mobile
                .work-detail-information-img-04 {
                width: 42.778%;
                align-self: flex-end;
                margin-top: -6.945%;
            }

            /* work-detail-information-container-07 */
            .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-07 .work-detail-information-img-01 {
                position: relative;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-07
                .work-detail-information-img-01::after {
                content: "";
                display: block;
                padding-bottom: 87.223%;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-07
                .work-detail-information-img-01
                img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                object-fit: cover;
            }

            .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-07 .work-detail-information-img-02 {
                width: 100%;
                padding: 0 6.667%;
                top: 0;
                margin-top: 15.556%;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-07
                .work-detail-information-img-02
                ul {
                flex-direction: column;
                justify-content: center;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-07
                .work-detail-information-img-02
                li {
                width: 44.872%;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-07
                .work-detail-information-img-02
                li:nth-child(2) {
                position: absolute;
                margin: 0;
                align-self: flex-end;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-07
                .work-detail-information-img-02
                li:nth-child(3) {
                margin-top: 12.821%;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-08
                .work-detail-information-bg-01::after {
                padding-bottom: 217.223%;
            }

            .work-detail-wrap[data-hash="lexus"]
                .work-detail-information-container-08
                .work-detail-information-bg-02::after {
                padding-bottom: 102.778%;
            }

            .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-08 .work-detail-information-img-01 {
                width: 52.778%;
                right: auto;
            }

            .work-detail-wrap[data-hash="lexus"] .work-detail-information-container-08 .work-detail-information-img-02 {
                width: 43.056%;
                left: auto;
                bottom: 0;
                margin-bottom: 98.056%;
            }

            /* wego */
            .work-detail-wrap[data-hash="wego"] .work-detail-cover .work-detail-cover-img img {
                object-position: 85%;
            }

            /* work-detail-information-container-01 */
            .work-detail-wrap[data-hash="wego"] .work-detail-information-container-01 {
                flex-direction: row;
            }

            .work-detail-wrap[data-hash="wego"] .work-detail-information-container-01 .for-mobile {
                display: block;
            }

            .work-detail-wrap[data-hash="wego"]
                .work-detail-information-container-01
                .work-detail-information-img
                ul:not(.for-mobile) {
                display: none;
            }

            .work-detail-wrap[data-hash="wego"]
                .work-detail-information-container-01
                .work-detail-information-img-01::after {
                padding-bottom: 137.223%;
            }

            .work-detail-wrap[data-hash="wego"] .work-detail-information-container-01 .work-detail-information-img-04 {
                display: none;
            }

            .work-detail-wrap[data-hash="wego"]
                .work-detail-information-container-01
                .work-detail-information-img:not(.work-detail-information-img-01) {
                width: 41.112%;
            }

            .work-detail-wrap[data-hash="wego"] .work-detail-information-container-01 .work-detail-information-img-02 {
                left: 6.667%;
                margin-top: 22.778%;
            }

            .work-detail-wrap[data-hash="wego"] .work-detail-information-container-01 .work-detail-information-img-03 {
                right: 6.667%;
                left: auto;
                margin-top: 62.778%;
            }

            /* work-detail-information-container-02 */
            .work-detail-wrap[data-hash="wego"] .work-detail-information-container-02 {
                padding: 247.778% 0 85.278%;
                overflow: hidden;
            }

            .work-detail-wrap[data-hash="wego"] .work-detail-information-container-02 .work-detail-information-img-01 {
                width: 70.556%;
                margin-left: 6.667%;
            }

            .work-detail-wrap[data-hash="wego"] .work-detail-information-container-02 .work-detail-information-img-02 {
                top: auto;
                margin-top: 0;
                bottom: 0;
                margin-bottom: 36.112%;
                right: 0;
                width: 84.723%;
            }

            .work-detail-wrap[data-hash="wego"]
                .work-detail-information-container-02
                .work-detail-information-img-02
                ul
                li
                ~ li {
                margin-top: 6.558%;
            }

            .work-detail-wrap[data-hash="wego"]
                .work-detail-information-container-02
                .work-detail-information-img-02
                ul
                li {
                width: 86.23%;
            }

            /* work-detail-information-container-03 */
            .work-detail-wrap[data-hash="wego"] .work-detail-information-container-03 {
                padding: 0;
            }

            .work-detail-wrap[data-hash="wego"] .work-detail-information-container-03 .work-detail-information-img-01 {
                margin-top: 0;
                border-radius: 0;
            }

            .work-detail-wrap[data-hash="wego"] .work-detail-information-container-03 .work-detail-information-img-02 {
                width: 46.389%;
                align-self: center;
                margin-left: 0;
            }

            .work-detail-wrap[data-hash="wego"] .work-detail-information-container-03 .work-detail-information-img-03 {
                width: 100%;
                margin-right: 0;
                margin-top: 22.223%;
                padding: 0 5.278%;
            }

            .work-detail-wrap[data-hash="wego"]
                .work-detail-information-container-03
                .work-detail-information-img-03
                ul {
                flex-direction: row;
            }

            .work-detail-wrap[data-hash="wego"]
                .work-detail-information-container-03
                .work-detail-information-img-03
                ul
                li {
                width: 45.967%;
            }

            .work-detail-wrap[data-hash="wego"]
                .work-detail-information-container-03
                .work-detail-information-img-03
                ul
                li:last-child {
                margin-top: 8.68%;
            }

            .work-detail-wrap[data-hash="wego"] .work-detail-information-container-04 {
                padding: 49.445% 6.667% 22.223%;
            }

            .work-detail-wrap[data-hash="wego"] .work-detail-information-container-04 .work-detail-information-img ul {
                flex-direction: column;
            }

            .work-detail-wrap[data-hash="wego"] .work-detail-information-container-04 .work-detail-information-img li {
                width: 100% !important;
            }

            .work-detail-wrap[data-hash="wego"]
                .work-detail-information-container-04
                .work-detail-information-img
                ~ .work-detail-information-img,
            .work-detail-wrap[data-hash="wego"]
                .work-detail-information-container-04
                .work-detail-information-img
                li
                ~ li {
                margin-top: 7.693%;
            }

            .work-detail-wrap[data-hash="wego"] .work-detail-information-container-04 .work-detail-information-img-04 {
                width: 56.411%;
                margin-top: 25.642% !important;
            }

            /* //wego */
            /* stylerpick */
            .work-detail-wrap[data-hash="styler"] .work-detail-cover .work-detail-cover-img {
                border-bottom: 1px solid #000;
            }

            .work-detail-wrap[data-hash="styler"] .work-detail-cover .work-detail-cover-img img {
                object-position: 66%;
            }

            .work-detail-wrap[data-hash="styler"] .work-detail-cover {
                border-bottom: none;
            }

            .work-detail-container .work-detail-information-img ul:not(.swiper-wrapper) {
                flex-direction: column;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-01
                .work-detail-information-img-01
                ul {
                padding: 0 8.334%;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-01
                .work-detail-information-img-01
                li {
                padding: 21.622% 0;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-01
                .work-detail-information-img-01
                li
                ~ li {
                border-left: none;
                border-top: 1px solid #000;
            }

            .work-detail-wrap[data-hash="styler"] .work-detail-information-container-02 {
                overflow: hidden;
                padding: 13.33% 0 22.223%;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-02
                .work-detail-information-img-01 {
                width: 68.889%;
                margin-top: 13.334%;
                margin-left: -2.778%;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-02
                .work-detail-information-img-02 {
                width: 62.223%;
                margin-top: -13.334%;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-02
                .work-detail-information-img-03 {
                width: 82.223%;
                margin-right: -32.223%;
                margin-top: -55.556%;
            }

            .work-detail-wrap[data-hash="styler"] .work-detail-information-container-03 {
                padding: 22.223% 0;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-03
                .work-detail-information-img-01 {
                position: absolute;
                order: 2;
                top: 0;
                margin-top: 135%;
                width: 91.112%;
                align-self: center;
                margin-left: 0;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-03
                .work-detail-information-img-01::after {
                content: "";
                display: block;
                padding-bottom: 100%;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-03
                .work-detail-information-img-01
                > .img-clip-container {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-03
                .work-detail-information-img-01
                > .img-clip-container
                img {
                object-fit: cover;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-03
                .work-detail-information-img-02 {
                order: 1;
                width: 100%;
                margin-top: 0;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-03
                .work-detail-information-img-02
                ul {
                padding: 0 8.889%;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-03
                .work-detail-information-img-02
                li {
                width: 75.676%;
                z-index: 1;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-03
                .work-detail-information-img-02
                li:last-child {
                margin-top: 75%;
                align-self: flex-end;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-03
                .work-detail-information-img-03 {
                order: 3;
                width: 62.223%;
                align-self: flex-start;
                margin-right: 0;
                margin-left: 8.889%;
                margin-top: 33.334%;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-03
                .work-detail-information-img-04 {
                order: 4;
                width: 64.625%;
                margin-left: 0;
                margin-right: 8.889%;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-04
                .work-detail-information-img-01
                ul {
                padding: 0 8.334%;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-04
                .work-detail-information-img-01
                li {
                padding: 21.622% 0;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-04
                .work-detail-information-img-01
                li
                ~ li {
                border-left: none;
                border-top: 1px solid #000;
            }

            .work-detail-wrap[data-hash="styler"] .work-detail-information-container-05 {
                padding: 22.223% 0;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-05
                .work-detail-information-img-01 {
                width: 68.334%;
                margin-left: 9.166%;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-05
                .work-detail-information-img:nth-child(n + 3):nth-child(-n + 4) {
                position: relative;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-05
                .work-detail-information-img-02 {
                position: absolute;
                width: 68.334%;
                align-self: flex-end;
                right: 9.166%;
                margin-top: 32.223%;
                left: auto !important;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-05
                .work-detail-information-img-03 {
                width: 47.223%;
                align-self: flex-start;
                margin-left: 9.166%;
                margin-top: 28.056%;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-05
                .work-detail-information-img-04 {
                width: 104.45%;
                margin-left: 0;
                align-self: flex-end;
                margin-right: -14.445%;
            }
            .work-detail-wrap[data-hash="styler"] {
                .work-detail-information-container-05 {
                    .work-detail-information-img-04 {
                        ul {
                            li {
                                // &:nth-of-type(1){
                                //     div{

                                //     }
                                // }
                                &:nth-of-type(2) {
                                    div {
                                        transition-delay: 0.5s;
                                    }
                                }
                                &:nth-of-type(3) {
                                    div {
                                        transition-delay: 1s;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-05
                .work-detail-information-img-04
                ul
                li {
                width: 61.309% !important;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-05
                .work-detail-information-img-04
                ul
                li:nth-child(2) {
                margin-left: 17.264%;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-05
                .work-detail-information-img-04
                ul
                li:nth-child(3) {
                margin-left: 38.692%;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-05
                .work-detail-information-img-05 {
                width: 90.834%;
                margin-top: 33.334%;
                margin-right: -6.112%;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-05
                .work-detail-information-img-06 {
                width: 64.723%;
                margin-left: 6.389%;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-05
                .work-detail-information-img-07 {
                width: 62.223%;
                margin-right: 6.389%;
                margin-top: -36.389%;
            }

            .work-detail-wrap[data-hash="styler"] .work-detail-information-container-06 {
                padding: 38.889% 0 61.112%;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-06
                .work-detail-information-img-01 {
                width: 91.112%;
                margin-left: 0;
                align-self: center;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-06
                .work-detail-information-img-02 {
                width: 65%;
                margin-left: 8.889%;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-06
                .work-detail-information-img-03 {
                width: 58.612%;
                margin-right: 8.889%;
                margin-top: -6.003%;
            }

            .work-detail-wrap[data-hash="styler"] .work-detail-information-container-07 {
                flex-direction: column;
                padding: 0 8.889%;
                border-bottom: none;
                margin-bottom: 0;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-07
                .work-detail-information-img-01 {
                width: 100%;
                border-right: none;
                border-bottom: 1px solid #000;
                padding: 16.217% 0 21.622%;
            }

            .work-detail-wrap[data-hash="styler"]
                .work-detail-information-container-07
                .work-detail-information-img-02 {
                padding: 21.622% 0;
                width: 58.109%;
            }

            /* //stylerpick */
            /* picks */
            .work-detail-wrap[data-hash="picks"] .work-detail-cover-text {
                background-color: #191919;
            }

            .work-detail-wrap[data-hash="picks"] .work-detail-cover-text > div > * {
                color: #fff;
            }

            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-01 {
                position: relative;
                width: 100%;
                margin-top: 0;
                border-radius: 0;
            }

            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-01::after {
                padding-bottom: 0;
                content: none;
            }

            .work-detail-wrap[data-hash="picks"]
                .work-detail-information-container-01
                .work-detail-information-img-container {
                position: relative;
                flex-direction: column;
                padding-bottom: 0;
            }

            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-01 .work-detail-information-img-01 {
                width: 75%;
                margin-left: 0;
                align-self: center;
                margin-top: -36.111%;
            }

            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-01 .work-detail-information-img-02 {
                width: 53.889%;
                margin-top: 22.778%;
                margin-left: 6.667%;
                align-self: flex-start;
            }

            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-01 .work-detail-information-img-03 {
                width: 85.278%;
                margin-top: 21.884%;
            }

            .work-detail-wrap[data-hash="picks"]
                .work-detail-information-container-01
                .work-detail-information-img-03
                img {
                object-position: 27px;
            }

            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-02 {
                padding: 37.645% 6.667% 33.334%;
            }

            .work-detail-wrap[data-hash="picks"]
                .work-detail-information-container-02
                .work-detail-information-img-01
                li {
                width: 100%;
            }

            .work-detail-wrap[data-hash="picks"]
                .work-detail-information-container-02
                .work-detail-information-img-01
                li:first-child {
                padding: 39.424% 0;
            }

            .work-detail-wrap[data-hash="picks"]
                .work-detail-information-container-02
                .work-detail-information-img-01
                li
                ~ li {
                margin-top: 7.693%;
            }

            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-02 .work-detail-information-img-03 {
                margin-top: 7.693%;
                position: relative;
            }

            .work-detail-wrap[data-hash="picks"]
                .work-detail-information-container-02
                .work-detail-information-img-03::after {
                content: "";
                display: block;
                padding-bottom: 79.167%;
            }

            .work-detail-wrap[data-hash="picks"]
                .work-detail-information-container-02
                .work-detail-information-img-03
                img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                object-fit: cover;
            }

            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-03 {
                flex-wrap: wrap;
                padding: 20.278% 6.667% 42.223%;
            }

            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-03 .work-detail-information-img-00 {
                width: 100% !important;
            }

            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-03 .work-detail-information-img-02 {
                display: none;
            }

            .work-detail-wrap[data-hash="picks"]
                .work-detail-information-container-03
                .work-detail-information-img.for-mobile {
                display: flex;
                border-radius: 8px;
                overflow: hidden;
            }

            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-03 .work-detail-information-img-01,
            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-03 .work-detail-information-img-03 {
                width: 47.346% !important;
                margin-top: 17.949%;
            }

            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-03 .work-detail-information-img-04,
            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-03 .work-detail-information-img-05,
            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-03 .work-detail-information-img-06 {
                width: 100% !important;
                margin-top: 17.949%;
            }

            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-04 {
                padding: 0 0 28.334%;
            }

            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-04 .swiper-slide {
                width: 47.545%;
            }

            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-04 .swiper-slide ~ .swiper-slide {
                margin-left: 4.134%;
            }

            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-05 {
                padding: 92.223% 0 36.389%;
            }

            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-05 .work-detail-information-bg {
                width: 100%;
            }

            .work-detail-wrap[data-hash="picks"]
                .work-detail-information-container-05
                .work-detail-information-bg::after {
                padding-bottom: 72.5%;
            }

            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-05 .work-detail-information-img-01 {
                width: 97.5%;
                margin-left: -4.167%;
                top: 0;
                margin-top: 24.723%;
            }

            .work-detail-wrap[data-hash="picks"] .work-detail-information-container-05 .work-detail-information-img-02 {
                width: 93.334%;
            }

            /* //picks */
            /* beautyselfpick */
            .work-detail-wrap[data-hash="beautyselfpick"] {
                .project-cover {
                    opacity: 0.5;
                }
            }

            .work-detail-wrap[data-hash="beautyselfpick"] .btn-menu.close rect {
                fill: #000;
            }
            .work-detail-wrap[data-hash="beautyselfpick"] .work-detail-information-container-01 {
                padding: 0;
            }

            .work-detail-wrap[data-hash="beautyselfpick"]
                .work-detail-information-container-01
                .work-detail-information-img-01
                ul
                li {
                width: 100%;
            }

            .work-detail-wrap[data-hash="beautyselfpick"]
                .work-detail-information-container-01
                .work-detail-information-img-01
                ul
                li:first-child {
                padding: 33.056% 10.556%;
            }

            .work-detail-wrap[data-hash="beautyselfpick"]
                .work-detail-information-container-01
                .work-detail-information-img-02 {
                position: relative;
                margin: 0;
            }

            .work-detail-wrap[data-hash="beautyselfpick"]
                .work-detail-information-container-01
                .work-detail-information-img-02::after {
                content: "";
                display: block;
                padding-bottom: 78.612%;
            }

            .work-detail-wrap[data-hash="beautyselfpick"]
                .work-detail-information-container-01
                .work-detail-information-img-02
                img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                object-fit: cover;
            }

            .work-detail-wrap[data-hash="beautyselfpick"] .work-detail-information-container-02 {
                padding: 72.5% 0;
            }

            .work-detail-wrap[data-hash="beautyselfpick"]
                .work-detail-information-container-02
                .work-detail-information-img-01
                ul {
                width: 100%;
                position: relative;
                padding: 0 11.112% 60.556%;
                transition: none;
            }

            .work-detail-wrap[data-hash="beautyselfpick"]
                .work-detail-information-container-02
                .work-detail-information-img-01
                ul
                li {
                width: 63.572%;
                margin: 0;
            }

            .work-detail-wrap[data-hash="beautyselfpick"]
                .work-detail-information-container-02
                .work-detail-information-img-01
                ul
                li
                ~ li {
                margin-top: 22.858%;
            }

            .work-detail-wrap[data-hash="beautyselfpick"]
                .work-detail-information-container-02
                .work-detail-information-img-01
                ul
                li:nth-child(1) {
                margin-top: -38.929%;
            }

            .work-detail-wrap[data-hash="beautyselfpick"]
                .work-detail-information-container-02
                .work-detail-information-img-01
                ul
                li:nth-child(even) {
                align-self: flex-end;
            }

            .work-detail-wrap[data-hash="beautyselfpick"]
                .work-detail-information-container-02
                .work-detail-information-img-02 {
                width: 59.167%;
                align-self: center;
                margin-top: -17.223%;
                margin-right: 0;
            }

            .work-detail-wrap[data-hash="beautyselfpick"]
                .work-detail-information-container-02
                .work-detail-information-img-02 {
                width: 59.167%;
                align-self: center;
                margin-top: -17.223%;
                margin-right: 0;
            }

            .work-detail-wrap[data-hash="beautyselfpick"]
                .work-detail-information-container-02
                .work-detail-information-img-03 {
                width: 67.778%;
                margin-top: 15.556%;
                margin-left: 0;
                align-self: center;
            }

            .work-detail-wrap[data-hash="beautyselfpick"] .work-detail-information-container-03 {
                padding: 0 0 101.112%;
            }

            .work-detail-wrap[data-hash="beautyselfpick"]
                .work-detail-information-container-03
                .work-detail-information-img-01 {
                width: 73.334%;
                margin-top: -13.056%;
                margin-left: 0;
                align-self: center;
            }

            .work-detail-wrap[data-hash="beautyselfpick"]
                .work-detail-information-container-03
                .work-detail-information-img-02 {
                width: 22.223%;
                margin-top: 41.112%;
            }

            .work-detail-wrap[data-hash="beautyselfpick"]
                .work-detail-information-container-03
                .work-detail-information-img-03 {
                width: 86.667%;
                margin-top: 74.445%;
                margin-right: 0;
                align-self: center;
            }

            .work-detail-wrap[data-hash="beautyselfpick"] .work-detail-information-container-04 {
                padding: 35.834% 0 26.667%;
            }

            .work-detail-wrap[data-hash="beautyselfpick"]
                .work-detail-information-container-04
                .work-detail-information-img-01 {
                width: 54.445%;
            }

            /* //beautyselfpick */
            /* toyota */
            .work-detail-wrap[data-hash="toyota"] .work-detail-cover .work-detail-cover-sub-title {
                color: #fff;
            }

            .work-detail-wrap[data-hash="toyota"] .work-detail-cover h3.work-detail-cover-title {
                color: #fff;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-01
                .work-detail-information-img-01 {
                position: relative;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-01
                .work-detail-information-img-01::after {
                content: "";
                display: block;
                padding-bottom: 86.112%;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-01
                .work-detail-information-img-01
                img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                object-fit: cover;
            }

            .work-detail-wrap[data-hash="toyota"] .work-detail-information h5.work-detail-information-title {
                font-size: 3.2rem;
                line-height: 1.282;
                margin-top: 22.223%;
            }

            .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-01 {
                padding-bottom: 38.889%;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-01
                .work-detail-information-img-02 {
                width: 91.112%;
                z-index: 1;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-01
                .work-detail-information-img-02
                ul
                li
                ~ li {
                margin-top: 4.445%;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-01
                .work-detail-information-img-03 {
                align-self: flex-start;
                width: 22.778%;
                margin-left: 8.612%;
                margin-top: 28.889%;
                z-index: 1;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-01
                .work-detail-information-img-04 {
                margin-top: 44.167%;
                margin-left: 6.667%;
                width: 78.334%;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-01
                .work-detail-information-img-05 {
                position: absolute;
                width: 80%;
                margin-top: 207.223%;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-01
                .work-detail-information-img-06 {
                align-self: flex-end;
                width: 82.223%;
                margin-top: 17.778%;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-01
                .work-detail-information-img-07 {
                width: 78.334%;
                margin-left: 5%;
                margin-top: 16.112%;
                margin-right: 0;
                align-self: flex-start;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-01
                .work-detail-information-img-08 {
                width: 91.112%;
                margin-top: 27.223%;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-01
                .work-detail-information-img-08
                ul {
                flex-direction: row;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-02
                .work-detail-information-img-01 {
                position: relative;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-02
                .work-detail-information-img-01::after {
                content: "";
                display: block;
                padding-bottom: 86.112%;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-02
                .work-detail-information-img-01
                img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                object-fit: cover;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-02
                .work-detail-information-img-02 {
                width: 91.112%;
                z-index: 1;
            }

            .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-03 {
                overflow: hidden;
                padding: 39.167% 0 20%;
            }

            .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-03 .work-detail-information-bg {
                width: 147.223%;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-03
                .work-detail-information-img-01
                li {
                width: 78.334%;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-03
                .work-detail-information-img-01
                li
                ~ li {
                margin-left: 7.778%;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-04
                .work-detail-information-img-01 {
                position: relative;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-04
                .work-detail-information-img-01::after {
                content: "";
                display: block;
                padding-bottom: 86.112%;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-04
                .work-detail-information-img-01
                img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                object-fit: cover;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-04
                .work-detail-information-img-02 {
                width: 53.056%;
                margin-top: -21.389%;
                margin-left: 4.445%;
                z-index: 1;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-04
                .work-detail-information-img:nth-child(n + 3):not(.work-detail-information-img-07) {
                width: 78.334%;
                align-self: center;
                margin: 16.112% 0 0;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-04
                .work-detail-information-img-07 {
                width: 91.112%;
                margin-top: 28.889%;
            }

            .work-detail-wrap[data-hash="toyota"]
                .work-detail-information-container-04
                h5.work-detail-information-title {
                width: 69.445%;
                font-size: 2.4rem;
                line-height: 1.268;
                margin-top: 20.556;
            }

            .work-detail-wrap[data-hash="toyota"] .work-detail-information-container-04 {
                padding-bottom: 23.056%;
            }

            /* //toyota */

            .work-detail-wrap .work-detail-project-role ul.project-people.clear {
                float: none;
                margin-left: 0;
            }
        }

        /* bentley */
        &[data-hash="bentley"] {
            .project-cover {
                .cover-img-wrap {
                    background-image: url("../../../public/site/res/image/img_cover_bentley.webp");
                    background-size: cover;
                }
            }
            .cover-text-wrap {
                background-color: #f2f4f3;
                .project-name {
                    text-align: center;
                }
            }
            .work-detail-information-container-02 {
                .swiper-container {
                    display: flex;
                    z-index: 1;
                    position: relative;

                    .swiper-wrapper {
                        &.top {
                            animation: animateSymbolContainer 22s linear forwards infinite;
                        }
                        &.bottom {
                            animation: animateSymbolContainerBack 22s linear forwards infinite;
                        }
                    }
                }
            }
            & .btn-menu.close circle {
                fill: #8cb837;
            }
            & .btn-menu.close rect {
                fill: #fff;
            }
            & .work-detail-information-container {
                overflow: hidden;
            }
            & .work-detail-cover .work-detail-cover-img .bentley-bg-mobile {
                display: block;
            }
            & .work-detail-cover .work-detail-cover-img .bentley-bg-mobile {
                display: none;
            }

            /* work-detail-information-container-01 */
            & .work-detail-contents {
                background-color: #f2f4f3;
            }
            & .work-detail-information-container-01 {
                padding: getPercent(1920, 198) 9.2708vw 10.4167vw;
            }

            & .work-detail-information-container-01 .work-detail-information-video-01 {
                width: 100%;
            }
            &
                .work-detail-information-container-01
                .work-detail-information-video-01
                .work-detail-information-video-wrap {
                width: 100%;
                background: #fff;
                border-radius: 40px;
                overflow: hidden;
            }
            & .work-detail-information-container-01 .work-detail-information-video-wrap::after {
                padding-bottom: 38.36%;
            }
            & .work-detail-information-container-01 .work-detail-information-video-01 video {
                object-fit: contain;
            }
            & .work-detail-information-container-01 .work-detail-information-img-01 {
                width: 100%;
                margin-top: 1.667vw;
            }
            & .work-detail-information-container-01 .work-detail-information-img-01 li {
                width: 39.895vw;
                text-align: center;
                background: #fff;
                border-radius: 40px;
                padding-top: 5.208vw;
            }
            & .work-detail-information-container-01 .work-detail-information-img-01 li:nth-child(2) {
                transition-property: transform;
                transition-delay: 0.3s;
                margin-left: 1.6667vw;
            }
            & .work-detail-information-container-01 .work-detail-information-img-01 h4 {
                font-size: 2.625rem;
                line-height: 1.28em;
                letter-spacing: -0.06em;
                font-weight: 600;
                margin-bottom: 3.906vw;
            }
            & .work-detail-information-container-01 .work-detail-information-img-01 img {
                width: 20.5208vw;
                height: auto;
                margin: 0 auto;
            }

            /* work-detail-information-container-02*/
            & .work-detail-information-container-02 .work-detail-information-img-01 {
                position: relative;
                width: 100%;
                background-color: #0d3121;
                padding: 10.937vw 0;
            }
            & .work-detail-information-container-02 .swiper-container:nth-child(1) {
                margin-bottom: 2.344vw;
            }
            /* & .work-detail-information-container-02 .swiper-container-no:nth-child(2) {
            margin-left: -5.208vw;
        } */
            & .work-detail-information-container-02 .swiper-wrapper li {
                width: 16.145vw;
                margin-left: 3.125vw;
            }
            & .work-detail-information-container-02 .swiper-wrapper.top li.slide01 {
                width: 18.906vw;
            }
            & .work-detail-information-container-02 .swiper-wrapper.top li.slide02 {
                width: 16.145vw;
            }
            & .work-detail-information-container-02 .swiper-wrapper.top li.slide03 {
                width: 19.1667vw;
            }
            & .work-detail-information-container-02 .swiper-wrapper.bottom li.slide01 {
                width: 16.667vw;
            }
            & .work-detail-information-container-02 .swiper-wrapper.bottom li.slide02 {
                width: 18.28vw;
            }
            & .work-detail-information-container-02 .swiper-wrapper.bottom li.slide03 {
                width: 10.52vw;
            }
            & .work-detail-information-container-02 .swiper-wrapper.bottom li.slide04 {
                width: 16.667vw;
            }

            & .work-detail-information-container-02 .bentley-sticker {
                z-index: 2;
                position: absolute;
                width: 26vw;
                top: 2.9126vw;
                right: 10.994vw;
            }

            /* work-detail-information-container-03*/
            & .work-detail-information-container-03 {
                padding: 10.416vw 9.2708vw;
                background: #fff;
            }
            & .work-detail-information-container-03 .work-detail-information-img-01 {
                width: 100%;
            }
            & .work-detail-information-container-03 .work-detail-information-img-01 .work-detail-information-img-01-bg {
                background: #f2f4f3;
                border-radius: 40px;
                width: 100%;
                padding: 6.25vw 11.562vw;
            }
            & .work-detail-information-img-01 .work-detail-information-img-01-bg .img-icon-mb {
                display: none;
            }
            & .work-detail-information-container-03 .work-detail-information-img-02 {
                width: 100%;
                margin-top: 1.667vw;
            }
            & .work-detail-information-container-03 .work-detail-information-img-02 li {
                width: 39.895vw;
                border-radius: 45px;
                overflow: hidden;
            }
            & .work-detail-information-container-03 .work-detail-information-img-02 .work-detail-information-img-bg {
                background: url(../../../public/site/res/image/img_bentley_section03_bg_01.webp) no-repeat center;
                background-size: cover;
                padding-bottom: 54.1667vw;
            }

            & .work-detail-information-container-03 .work-detail-information-img-02 li:nth-child(2) {
                padding: 5.9375vw 10.208vw;
                background: url(../../../public/site/res/image/img_bentley_section03_bg_02.webp) no-repeat;
                background-size: cover;
                margin-left: 1.667vw;
                transition-property: transform;
                transition-delay: 0.3s;
            }
            &
                .work-detail-information-container-03
                .work-detail-information-img-02
                li
                .work-detail-information-img-wrap {
                position: relative;
                width: 100%;
                height: 100%;
                background: url(../../../public/site/res/image/img_bentley_section03_01.webp) no-repeat;
                background-size: contain;
                background-position: center;
                animation: nextImg 8s infinite;
                animation-timing-function: step-end;
            }

            @keyframes nextImg {
                0% {
                    background: url(../../../public/site/res/image/img_bentley_section03_01.webp) no-repeat;
                    background-size: contain;
                }
                25% {
                    background: url(../../../public/site/res/image/img_bentley_section03_02.webp) no-repeat;
                    background-size: contain;
                }
                50% {
                    background: url(../../../public/site/res/image/img_bentley_section03_03.webp) no-repeat;
                    background-size: contain;
                }
                75% {
                    background: url(../../../public/site/res/image/img_bentley_section03_04.webp) no-repeat;
                    background-size: contain;
                }
                100% {
                    background: url(../../../public/site/res/image/img_bentley_section03_01.webp) no-repeat;
                    background-size: contain;
                }
            }

            &
                .work-detail-information-container-03
                .work-detail-information-img-02
                li
                .work-detail-information-img-wrap
                img {
                position: absolute;
            }
            /* work-detail-information-container-04*/
            & .work-detail-information-container-04 {
                padding: 8.333vw 0;
            }
            & .work-detail-information-container-04 .work-detail-information-img-01 li {
                width: 19.53vw;
                border-radius: 24px;
                box-shadow: 6px 6px 30px rgba(0, 0, 0, 0.1);
                overflow: hidden;
                backface-visibility: hidden;
                -webkit-backface-visibility: hidden;
            }
            & .work-detail-information-container-04 .work-detail-information-img-01 li:nth-child(2) {
                transition-delay: 0.2s;
            }
            & .work-detail-information-container-04 .work-detail-information-img-01 li:nth-child(3) {
                transition-delay: 0.4s;
            }
            & .work-detail-information-container-04 .work-detail-information-img-01 li:not(:last-child) {
                margin-right: 4.1667vw;
            }
            /* work-detail-information-container-05*/
            & .work-detail-information-container-05 {
                padding: 10.416vw 9.2708vw;
                background: #fff;
            }
            & .work-detail-information-container-05 .work-detail-information-img-01 {
                display: flex;
                background: #f2f4f3;
                border-radius: 40px;
                padding: 5.208vw;
            }
            &
                .work-detail-information-container-05
                .work-detail-information-img-01
                .information-img-wrap:not(:last-child) {
                margin-right: 1.667vw;
            }
            & .work-detail-information-container-05 .work-detail-information-img-01 .information-img-wrap ul {
                flex-direction: column;
            }
            & .work-detail-information-container-05 .work-detail-information-img-01 .information-img-wrap ul li {
                box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.08);
            }
            &
                .work-detail-information-container-05
                .work-detail-information-img-01
                .information-img-wrap
                ul
                li:not(:last-child) {
                margin-bottom: 1.667vw;
            }
            & .work-detail-information-container-05 .work-detail-information-img-02 {
                display: none;
            }
            /* work-detail-information-container-06*/
            & .work-detail-information-container-06 {
                background: #8cb837;
                position: relative;
                text-align: center;
                padding-top: 11.7vw;
            }

            & .work-detail-information-container-06 .work-detail-information-img-01 {
                position: absolute;
                z-index: 1;
                top: 10.2vw;
                width: 59.635vw;
            }
            & .work-detail-information-container-06 .work-detail-information-img-02 {
                z-index: 2;
                position: relative;
                width: 93.75vw;
            }

            /* bentley mobile */
            @media all and (max-width: 767px) {
                & .work-detail-cover {
                    background-color: #f2f4f3;
                }
                & .work-detail-cover .work-detail-cover-img .bentley-bg-mobile {
                    display: none;
                }
                & .work-detail-cover .work-detail-cover-img .bentley-bg-mobile {
                    display: block;
                }
                & .work-detail-information-container-01 {
                    padding: 0 8.889vw 27.778vw;
                }
                &
                    .work-detail-information-container-01
                    .work-detail-information-video-01
                    .work-detail-information-video-wrap {
                    border-radius: 16px;
                }
                & .work-detail-information-container-01 .work-detail-information-video-wrap::after {
                    padding-bottom: 69.567%;
                }
                & .work-detail-information-container-01 .work-detail-information-img-01 li {
                    width: 100%;
                    padding-top: 11.111vw;
                    margin-top: 6.667vw;
                    border-radius: 16px;
                }
                & .work-detail-information-container-01 .work-detail-information-img-01 h4 {
                    font-size: 2rem;
                    letter-spacing: -0.06em;
                    margin-bottom: 9.422vw;
                }
                /* work-detail-information-container-02*/
                & .work-detail-information-container-02 .work-detail-information-img-01 {
                    padding: 27.78vw 0 17.589vw;
                }
                & .work-detail-information-container-02 .swiper-container:nth-child(1) {
                    margin-bottom: 5.4167vw;
                }
                & .work-detail-information-container-02 .swiper-wrapper.top li.slide01 {
                    width: 70.556vw;
                }
                & .work-detail-information-container-02 .swiper-wrapper.top li.slide02 {
                    width: 60.278vw;
                }
                & .work-detail-information-container-02 .swiper-wrapper.top li.slide03 {
                    width: 71.667vw;
                }
                & .work-detail-information-container-02 .swiper-wrapper.bottom li.slide01 {
                    width: 62.22vw;
                }
                & .work-detail-information-container-02 .swiper-wrapper.bottom li.slide02 {
                    width: 68.33vw;
                }
                & .work-detail-information-container-02 .swiper-wrapper.bottom li.slide03 {
                    width: 37.5vw;
                }
                & .work-detail-information-container-02 .swiper-wrapper.bottom li.slide04 {
                    width: 62.22vw;
                }
                & .work-detail-information-container-02 .bentley-sticker {
                    z-index: 2;
                    position: relative;
                    width: 96vw;
                    top: 0;
                    right: 0;
                    margin: -25vw auto 0 auto;
                }
                /* work-detail-information-container-03*/
                & .work-detail-information-container-03 {
                    padding: 27.778vw 8.889vw;
                }
                &
                    .work-detail-information-container-03
                    .work-detail-information-img-01
                    .work-detail-information-img-01-bg {
                    padding: 16.667vw;
                    border-radius: 16px;
                }
                & .work-detail-information-img-01 .work-detail-information-img-01-bg .img-icon-mb {
                    display: block;
                }
                & .work-detail-information-img-01 .work-detail-information-img-01-bg .img-icon-pc {
                    display: none;
                }
                & .work-detail-information-container-03 .work-detail-information-img-02 li {
                    width: 100%;
                    border-radius: 17px;
                    margin-top: 6.667vw;
                }
                &
                    .work-detail-information-container-03
                    .work-detail-information-img-02
                    .work-detail-information-img-bg {
                    padding-bottom: 127.778vw;
                }
                &
                    .work-detail-information-container-03
                    .work-detail-information-img-02
                    li
                    .work-detail-information-img-text {
                    font-size: 2.473rem;
                    line-height: 1.415em;
                }
                & .work-detail-information-container-03 .work-detail-information-img-02 li:nth-child(2) {
                    padding: 11.11vw 16.667vw;
                    margin-left: 0;
                }
                &
                    .work-detail-information-container-03
                    .work-detail-information-img-02
                    li
                    .work-detail-information-img-wrap {
                    padding-bottom: 105.56vw;
                }

                /* work-detail-information-container-04*/
                & .work-detail-information-container-04 {
                    display: block;
                    padding: 16.667vw;
                    padding-right: 0;
                }
                & .work-detail-information-container-04 .work-detail-information-img-01 {
                    overflow: hidden;
                }

                & .work-detail-information-container-04 .work-detail-information-img-01 ul {
                    flex-direction: row;
                }
                & .work-detail-information-container-04 .work-detail-information-img-01 li {
                    width: 66.944vw !important;
                    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.1);
                    -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);
                }
                & .work-detail-information-container-04 .work-detail-information-img-01 li:not(:last-child) {
                    margin-right: 6.667vw;
                }

                /* work-detail-information-container-05*/
                & .work-detail-information-container-05 {
                    padding: 27.778vw 8.889vw;
                }
                & .work-detail-information-container-05 .work-detail-information-img-01 {
                    display: none;
                }
                & .work-detail-information-container-05 .work-detail-information-img-02 {
                    display: block;
                    background: #f2f4f3;
                    padding: 6.667vw;
                    border-radius: 16px;
                }
                & .work-detail-information-container-05 .work-detail-information-img-02 li:not(:last-child) {
                    margin-bottom: 4.44vw;
                }

                /* work-detail-information-container-06*/
                & .work-detail-information-container-06 {
                    padding-top: 11.389vw;
                }
                & .work-detail-information-container-06 .work-detail-information-img-01 {
                    top: 16.667vw;
                    width: 82.222vw;
                }
                & .work-detail-information-container-06 .work-detail-information-img-02 {
                    width: 186.11vw;
                }
            }
        }

        .swiper-container {
            overflow: hidden;
        }

        .work-detail-wrap {
            &::-webkit-scrollbar {
                display: none;
            }
        }

        .project-desc {
            display: inline-block;
        }
    }
}
