@import '_mixins.scss';

@function getVw($px, $base-vw: 360px){
    @return($px * 100vw) / $base-vw;
}

@function getVh($px, $base-vh: 740px){
    @return($px * 100vh) / $base-vh;
}

.Mobile {
    .WorkDetail {
        // (S) work-detail-wrap
        .work-detail-wrap {
            height: 100vh;
            overflow: auto;

            img, video {
                display: block;
                width: 100%;
            }

            .marquee-container {
                width: 100%;
                overflow: hidden;
                &.reverse {
                    .marquee-wrap {
                        animation-direction: reverse;
                    }
                }
                
                .marquee-wrap {
                    display: flex;
                    animation: marquee 10s linear infinite;
                    width: fit-content;

                    ul {
                        display: flex;
                        gap: 0 getVw(10px);

                        li {
                            flex-shrink: 0;
                            width: getVw(154px);
                        }
                    }
                }
                
            }

            .project-cover {
                .cover-img-wrap {
                    height: 100vh;
                    overflow: hidden;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .cover-text-wrap {
                    min-height: 100vh;
                    background-color: #fff;

                    .award-mark {
                        width: 100%;

                        img {
                            width: 100%;
                        }
                    }

                    .container {
                        padding: getVh(80px) getVw(20px);
                    }

                    .project-type {
                        font-family: $font-en;
                        @include fontStyle(21px, 500, 1.2, -0.6px);
                        text-align: center;
                    }
                    .project-name {
                        text-align: center;
                        font-family: $font-en;
                        @include fontStyle(56px, 500, 1.2, -1.68px);
                        font-size: getVw(56px, 390px);
                        text-transform: uppercase;

                        &.ko {
                            font-family: $font-ko;
                            font-weight: 700;
                        }
                    }

                    .project-team {
                        display: flex;
                        gap: 0 getVw(8px, 390px);
                        justify-content: center;
                        margin-top: getVh(20px);
                        .team-tag {
                            padding: getVh(9px) getVw(15px, 390px);
                            border-radius: getRem(30px);
                            border: 0.5px solid #000;
                            text-align: center;
                            text-wrap: nowrap;
                            @include fontStyle(10px, 400, 12px);
                            text-transform: uppercase;
                            font-family: $font-en;
                        }
                    }

                    .project-desc {
                        margin-top: getVh(60px);
                        @include fontStyle(14px, 500, 24px, -0.56px);
                        font-size: getVw(14px, 390px);
                    }
                }

                &.dark {
                        .cover-text-wrap {
                            background-color: #000;
    
                            * {
                                color: #fff;
                            }
                            
                            .project-team {
                                .team-tag {
                                    border-color: #fff;
                                }
                            }
                        }
                    }
            }

            // (S) lexus new
            &.lexus_new {
                .project-cover {
                    .cover-img-wrap {
                        position: relative;
                        width: 100%;
                        background-image: url('../../../public/site/res/image/mobile/lexus/img_bg_intro_mo.webp');
                        background-size: cover;

                        .phone-wrap {
                            position: absolute;
                            width: 100%;
                            bottom: 39px;
                        }
                    }
                    .cover-text-wrap {
                        background-color: #000;
                        color: #fff;

                        .project-team {
                            .team-tag {
                                border-color: #fff;
                            }
                        }

                        .project-desc {
                            p {
                                font-size: getRem(15px);
                            }
                        }
                    }
                }

                .project-contents {
                    color: #fff;
                    .container-desc {
                        position: relative;
                        
                        .desc-tit {
                            @include fontStyle(16px, 400, 130%);
                            color: #B9C0C2;
                            text-transform: uppercase;
                            font-family: $font-en;
                            margin-bottom: getRem(30px);
                        }
                        .desc-text {
                            @include fontStyle(15px, 500, 170%, -0.6px);
                            color: #fff;

                        }
                    }
                    .gradient-tit {
                        background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.20) 100%);
                        @include gradientText;
                        @include fontStyle(48px, 300, 100%);
                        font-family: $font-en;
                        text-transform: uppercase;
                    }

                    .Fadeup:has(.gradient-tit) {
                        text-align: center;
                    }

                    .cont-img-mockup {
                        z-index: 1;
                        position: relative;
                        background-size: 100%;

                        &::after {
                            display: block;
                            content: '';
                        }
                    }

                    .cont-img-scrollview {
                        z-index: 0;
                        position: absolute;
                        top: 5px;
                        left: 50%;
                        transform: translateX(-50%);
                        overflow: hidden;
                        border-radius: getVw(10px);
                    }

                    .section-01 {
                        position: relative;
                        width: 100%;

                        .container {
                            .img-bg {
                                z-index: 0;
                                position: absolute;
                                width: 100%;
            
                                &::after {
                                    display: block;
                                    content: '';
                                    width: 100%;
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    padding-bottom: getPercent(390, 383);
                                    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);;
                                }
                            }
                            .content-wrap {
                                position: relative;
                            }
                            .container-desc {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                padding: getPercent(390, 120) getPercent(390, 60) 0;

                                .desc-tit  {
                                    margin-right: auto;
                                }

                                &.show {
                                    opacity: 1;
                                    transform: translateY(0%) translateZ(0px);
                                }

                                &.hide {
                                    opacity: 0 !important;
                                    
                                }
                            }

                            .benefit-wrap {
                                z-index: 1;
                                position: relative;
                                width: 100%;
                                padding: getPercent(390, 120) getPercent(390, 16);
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                gap: getRem(56px);

                                .benefit-list {
                                    color: #F3F4F4;
                                    
                                    > div {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        gap: getRem(12px);
                                    }
                                    sub {
                                        @include fontStyle(12px, 500, 130%, -0.48px);
                                    }
                                    strong {
                                        @include fontStyle(40px, 300, 100%);
                                        text-transform: uppercase;
                                        text-align: center;
                                        font-family: $font-en;
                                    }
                                    p {
                                        @include fontStyle(14px, 500, 130%, -0.56px);
                                    }
                                }

                                &.show {
                                    .benefit-list {

                                        opacity: 1;
                                        transform: translateY(0%) translateZ(0px);
                                        transition: opacity .4s, transform .7s;

                                        &:nth-of-type(1) {
                                            transition-delay: 0.5s;
                                        }
                                        &:nth-of-type(2) {
                                            transition-delay: 0.7s;
                                        }
                                        &:nth-of-type(3) {
                                            transition-delay: 1s;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .section-02 {
                        overflow: hidden;
                        background-color: #000;
                        padding: getPercent(390, 80) 0 getPercent(390, 50);
                        .text-wrap {
                            display: flex;
                            flex-direction: column;
                            position: relative;
                            z-index: 1;
                            .ml-auto {
                                margin-left: auto;

                                p {
                                    padding-right: getRem(16px);
                                    
                                }
                            }
                            
                            p {
                                text-transform: uppercase;
                                @include fontStyle(80px, 300, 100%);
                                font-family: $font-en;

                                

                                &.to-right {
                                    padding-left: getRem(16px);
                                }

                                
                            }
                        }
                        .img-01 {
                            z-index: 0;
                            position: relative;
                            margin-top: getRem(-33px);
                        }
                    }

                    .section-03 {
                        padding: 0 getPercent(390, 60) getPercent(390, 80);
                    }

                    .section-04 {
                        position: relative;
                        padding: getPercent(390, 80) 0;
                        background-image: url('../../../public/site/res/image/mobile/lexus/img_bg_sec6_mo.webp');
                        background-size: cover;

                        .tit-wrap {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: getRem(10px);

                            .strong {
                                @include fontStyle(80px, 300, 100%);
                                font-family: $font-en;
                                text-transform: uppercase;
                            }

                            .small {
                                @include fontStyle(20px, 300, 100%, -0.4px);
                                text-transform: unset;
                            }
                        }

                        .desc-wrap {
                            padding: getPercent(390, 269) getRem(60px) 0;
                            p {
                                @include fontStyle(15px, 500, 170%, -0.6px);
                                text-align: center;
                            }
                        }

                        .gradient-rectangle {
                            position: absolute;
                            width: 100%;
                            top: 0;
                            left: 0;
                            padding-bottom: getPercent(390, 67);
                            background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
                        }
                    }

                    .section-05 {
                        position: relative;
                        

                        .container {
                            width: 100%;
                            // height: 100vh;
                            background-image: url('../../../public/site/res/image/mobile/lexus/img_bg_sec7_1_mo.webp');
                            background-size: cover;
                            background-repeat: no-repeat;
                        }

                        .content-wrap {
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            gap: getRem(50px);
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            padding: getPercent(390, 80) 0;

                            .bg-wrap {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;

                                .swiper {
                                    width: 100%;
                                    height: 100%;
                                }

                            }

                            .thumb-swiper {
                                width: 100%;

                                .swiper-slide {
                                    img {
                                        width: getPercent(390, 350);
                                        margin: 0 auto;
                                    }
                                }
                            }

                            .desc {
                                width: 100%;

                                h5 {
                                    @include fontStyle(12px, 400, 130%);
                                    text-transform: capitalize;
                                    color: #E6E8E9;
                                    font-family: $font-en;
                                }

                                strong {
                                    @include fontStyle(24px, 700, 140%, -0.96px);
                                    text-align: center;
                                }

                                p {
                                    @include fontStyle(13px, 500, 170%, -0.52px);
                                    text-align: center;
                                }


                                &.desc-02 {
                                    strong {
                                        font-family: $font-en;
                                    }
                                }
                            }

                            .img-wrap {
                                width: 100%;
                                position: relative;
                                // margin: getPercent(390, 45) 0;

                                .swiper-slide {
                                    width: getPercent(390, 250) !important;
                                }
                                
                                .img {
                                    width: 100%;

                                }

                                .line {
                                    z-index: 4;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    width: 1px;
                                    height: getPercent(240, 300);
                                    background-color: #fff;
                                    transform: translate(-50%,-50%);
                                }
                            }
                        }
                    }

                    .section-06 {
                        padding-top: getPercent(390, 80);
                        background-color: #fff;
                        display: flex;
                        flex-direction: column;
                        gap: getRem(30px);

                        .container-desc {
                            margin-left: getPercent(390, 40);
                            width: getRem(260px);

                            .desc-text {
                                color: #000;
                            }
                        }

                        .img-01 {
                            width: getPercent(390, 260);
                            padding: getPercent(390, 50) getPercent(390, 46);
                            background-color: #F3F4F4;
                            margin-left: getPercent(390, 40);
                            img {
                                width:  100%
                            }
                        }

                        .img-02 {
                            width: getPercent(390, 140);
                            align-self: end;
                            margin-right: getPercent(390, 40);
                        }
                        .img-03 {
                            width: getPercent(390, 352);
                            align-self: center;
                            margin-bottom: getPercent(390, 80);
                        }
                        .color-list-wrap {
                            .color-list {
                                @include fontStyle(14px, 400, 130%);
                                padding-top: getPercent(390, 16);
                                padding-left: getPercent(390, 20);
                                padding-bottom: getPercent(390, 10);

                                font-family: $font-en;
                                .color-name {
                                    text-transform: uppercase;
                                    margin-bottom: 2px;
                                    font-family: $font-en;
                                    display: inline-block;
                                }
                                .color-code {
                                    display: block;
                                    opacity: 0.5;
                                }

                                &.black {
                                    background-color: #000;
                                    padding-bottom: getPercent(390, 120);
                                }

                                &.blue {
                                    background-color: #36C;
                                    padding-bottom: getPercent(390, 62);
                                }
                                &.gray {
                                    background-color: #B9C0C2;
                                }
                                &.light-gray {
                                    background-color: #F3F4F4;
                                    .color-name,
                                    .color-code {
                                        color: #9FA0A0;
                                        opacity: 1;
                                    }
                                }
                                &.white {
                                    background-color: #fff;
                                    .color-name,
                                    .color-code {
                                        color: #9FA0A0;
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }

                    .section-07 {
                        background-color: #fff;
                        .container {
                            border-radius: 32px;
                            background: linear-gradient(108deg, #272A31 0%, #000 100%);
                            padding: getPercent(390, 100) 0 getPercent(390, 101.32) 0;

                            .img-01 {
                                margin-top: getPercent(390, 32);

                                > div {
                                    width: getPercent(390, 330);
                                    margin: 0 auto;
                                }
                            }
                        }
                    }

                    .section-08 {
                        background-color: #fff;
                        position: relative;
                        padding-top: getPercent(390, 100) ;
                        
                        .tit-wrap {
                            color: #000;
                            width: 100%;
                            padding: 0 getPercent(390, 40);
                            h5 {
                                @include fontStyle(38px, 400, 100%, -0.76px);
                                text-transform: uppercase;
                                font-family: $font-en;
                                margin-bottom: getRem(32px);
                            }
                            p {
                                @include fontStyle(15px, 500, 170%, -0.6px);
                            }
                        }

                        .img-01 {
                            position: relative;
                            width: 100%;
                            height: 100%;

                            > div {
                                width: 100%;
                                height: 100%;
                            }

                            &.end {
                                .img-02 {
                                    img {
                                        animation: scrollUp01 9s .8s forwards infinite;
                                    }
                                }
                            }

                            .img-wrap {
                                position: absolute;
                                width: getPercent(390, 103);
                                top: 0;

                                &.img-1 {
                                    right: 0;
                                    margin-top: getPercent(390, 5);
                                }
                                &.img-2 {
                                    left: getPercent(390, -25);
                                    margin-top: getPercent(390, 74);
                                    width: getPercent(390, 128);
                                }
                                &.img-3 {
                                    top: unset;
                                    bottom: 0;
                                    right: 0;
                                    margin-bottom: getPercent(390, -35);
                                }

                            }
                        }
                        .img-02 {
                            padding-top: getPercent(390, 157);
                            padding-bottom: getPercent(390, 111.97);
                            width: getPercent(390, 128);
                            margin:0 auto ;
                            position: relative;

                            .video-wrap {
                                position: relative;
                            }
                            

                            .cont-img-mockup {
                                background-image: url(../../../public/site/res/image/mobile/lexus/img_lexus_sec10_mockup_mo.webp);
                               
                                &::after {
                                    padding-bottom: getPercent(128, 260.129);
                                    
                                }

                            }
                            .cont-img-scrollview {
                                width: getPercent(128, 118); 
                                height: 90%;

                                @keyframes scrollUp01 {
                                    0% {
                                        transform: translateY(0%);
                                    }
                                    20% {
                                        transform: translateY(-14.1%);
                                    }
                                    40% {
                                        transform: translateY(-33%);
                                    }
                                    60% {
                                        transform: translateY(-52.4%);
                                    }
                                    80% {
                                        transform: translateY(-72.6%);
                                    }
                                    100% {
                                        transform: translateY(0%);
                                    }
                                }
                            }
                        }
                    }

                    .section-09 {
                        background-color: #fff;
                        .container {
                            position: relative;
                            padding: getPercent(390, 100) 0 getPercent(390, 336);
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            background: url('../../../public/site/res/image/mobile/lexus/img_bg_sec11_mo.webp');
                            background-size: cover;
                            background-repeat: no-repeat;

                            .gradient-tit {
                                @include fontStyle(60px, 300, 100%);
                                font-family: $font-en;
                            }

                            .img-01 {
                                width: getPercent(390, 330);
                                position: absolute;
                                top: 0;
                                margin-top: getPercent(390, 174);
                            }
                        }
                    }

                    .section-10 {
                        margin-top: getPercent(390, -32);
                        .container {
                            padding: getPercent(390, 132) 0 getPercent(390, 241);
                            background: url('../../../public/site/res/image/mobile/lexus/img_bg_sec12_mo.webp');
                            background-size: cover;

                            .Fadeup {
                                > div {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    color: #fff;
                                }

                                &.img-fade {
                                    &.end {
                                        .cont-img-scrollview {
                                            img {
                                                animation: scrollUp02 5s .8s forwards infinite;
                                            }
                                        }
                                    }
                                }
                            }

                            .tit {
                                @include fontStyle(38px, 400, 100%, -0.76px);
                                font-family: $font-en;
                                text-transform: uppercase;
                            }

                            .img-box {
                                width: 100%;

                                .img-wrap {
                                    position: relative;
                                    width: getPercent(390, 190);
                                    margin: getPercent(390, 40) auto getPercent(390, 44);
    
                                    .cont-img-mockup {
                                        background-image: url(../../../public/site/res/image/mobile/lexus/img_sec12_mockup_mo.webp);
                                        width: 100%;
    
                                        &::after {
                                            padding-bottom: getPercent(190, 386.129);
                                        }
                                    }
                                    .cont-img-scrollview {
                                        width: getPercent(190, 172);
                                        height: 90%;
                                    }
                                }

                                @keyframes scrollUp02 {
                                    0% {
                                        transform: translateY(0%);
                                    }
                                    40% {
                                        transform: translateY(-20.5%);
                                    }
                                    80% {
                                        transform: translateY(-55%);
                                    }
                                    100% {
                                        transform: translateY(0%);
                                    }
                                }
                            }
                            

                            .desc-text {
                                width: getRem(310px);
                                @include fontStyle(15px, 500, 170%, -0.6px);
                                text-align: center;
                            }
                        }
                    }

                    .section-11 {
                        .container {
                            padding: getRem(96px) 0;
                            border-radius: 32px;
                            background: linear-gradient(180deg, #224EAC 0%, #C0C3CA 100%);
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            position: relative;

                            .gradient-tit {
                                @include fontStyle(60px, 300, 100%);
                                background: linear-gradient(180deg, #FFF 0%, #789BE1 100%);
                                @include gradientText;
                            }

                            .vid-box {
                                width: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;

                                &.end {
                                    .cont-img-scrollview  {
                                        img {
                                            animation: scrollUp03 5s .8s forwards infinite;
                                        }
                                    }
                                }
                                .video-wrap {
                                    margin-top: getPercent(390, 190);
                                    margin-left: getPercent(390,40);
                                    width: getPercent(390, 146);
                                    position: relative;

                                    .cont-img-mockup {
                                        background-image: url('../../../public/site/res/image/mobile/lexus/img_sec13_mockup_mo.webp');

                                        &::after {
                                            padding-bottom: getPercent(146, 296.71);
                                        }
                                    }

                                    .cont-img-scrollview {
                                        width: getPercent(146, 132);
                                        height: 90%;
                                    }

                                    @keyframes scrollUp03 {
                                        0% {
                                            transform: translateY(0%);
                                        }
                                        40% {
                                            transform: translateY(-29.2%);
                                        }
                                        80% {
                                            transform: translateY(-57%);
                                        }
                                        100% {
                                            transform: translateY(0%);
                                        }
                                    }
                                }

                                
                            }

                            .img-box {
                                width: 100%;

                                .img-wrap {
                                    position: relative;
                                    margin-top: getPercent(390, 55);
                                    margin-right: getPercent(390,40);
                                    margin-left: auto;
                                    width: getPercent(390, 146);
                                }
                            }

                            .desc-text {
                                width: getRem(310px);
                                padding-top: getPercent(390, 44.38);
                                @include fontStyle(15px, 500, 170%, -0.6px);
                                text-align: center;
                                word-break: keep-all;
                            }
                        }
                    }

                    .section-12 {
                        margin-top: getPercent(390, -32);
                        padding: getPercent(390, 122) 0 getPercent(390, 238.35);
                        background: url('../../../public/site/res/image/mobile/lexus/img_bg_sec14_mo.webp');
                        background-size: cover;
                        background-repeat: no-repeat;
                        .Fadeup {
                            > div {
                                width: getPercent(390, 152);
                                margin: 0 auto;
                            }
                        
                        }
                    }
                }
            }
            // (E) lexus new

            // (S) shinsegae
            &.shinsegae{
                .project-cover {
                    .cover-img-wrap {
                        background-color: #000;
                        height: fit-content;
                        .cover-content-wrap{
                            position: relative;
                            width: 100%;
                            background-image: url('../../../public/site/res/image/shinsegae/img_cover_pattern.webp');
                            
                            .cover-bg-text{
                                z-index: 0;
                                position: absolute;
                                width: 100%;
                                @include fontStyle(75px, 400, 100%, -2.25px);
                                font-size: getVw(75px);
                                font-family: $font-en;
                                text-transform: uppercase;
                                background:linear-gradient(94deg, rgba(51, 51, 51, 0.00) 0.35%,#333 53.92%, rgba(51, 51, 51, 0.00) 99.55%);
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                display: block;
                                text-align: center;
                                &:nth-of-type(1){
                                    top: getPercent(844,91);
                                }
                                &:nth-of-type(2){
                                    bottom: getPercent(844,10);
                                }
                            }
                            .cover-mockup{
                                z-index: 1;
                                position: relative;
                                margin: 0 auto;
                                width: getPercent(390,275);
                                padding: getPercent(390,149) 0 getPercent(390,128.81);
                            }
                            .swiper-container{
                                z-index: 2;
                                position: absolute;
                                width: 100%;
                                top: getPercent(844,292);
        
                                .mySwiper{
                                    .swiper-slide{
                                        width: getPercent(390,300);
                                    }
                                }
                            }
                        }
                    }
                    
                    .cover-text-wrap {
                        background-color: #FF329B;
                        .container {
                            * {
                                color: #fff !important;
                            }
                            .team-tag{
                                border-color: #fff;
                            }
                        }
                    }
                }
                .project-contents{
                    h4.h4-title{
                        @include fontStyle(14px, 300);
                        font-size: getVw(14px);
                        font-family: $font-en;
                    }
                    p.p-sec-desc{
                        @include fontStyle(14px, 500,160%,-0.56px);
                        font-size: getVw(14px);
                        font-family: $font-ko;
                    }
                    h5.h5-text-title{
                        @include fontStyle(30px, 300,39px);
                        text-transform: uppercase;
                        font-family: $font-en;
                    }
                    p.p-text-feature{
                        @include fontStyle(14px, 300,22.4px,-0.05em);
                        font-family: $font-ko;
                    }
                    img{
                        display: block;
                        width: 100%;
                    }
                    .swiper-pagination-bullet{
                        width: getRem(50);
                        height: getRem(5);
                        background-color: #fff;
                        opacity: 0.3;
                        border-radius: unset;
                }
                    .swiper-pagination-bullet-active{
                        opacity: 1;
                    }
                    .cont-sec-01{
                        .container{
                            width:100% !important;
                            .cont-wrap-01{
                                position: relative;
                                
                                text-align: center;
                                padding-top: getPercent(390,366);
                                
                                h2{
                                    z-index: 1;
                                    position: absolute;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    top: 0;
                                    padding-top: getPercent(390,300);
                                    @include fontStyle(75px, 300);
                                    font-size: getVw(75px);
                                    font-family: $font-en;
                                    text-transform: uppercase;
                                    opacity: 0.12;
                                    color: #FFF;
                                }
                                p{
                                    z-index: 1;
                                    position: relative;
                                    font-family: $font-ko;
                                    @include fontStyle(14px, 500, 160%,-0.56px);
                                    font-size: getVw(14px);
                                    padding: 0 getPercent(390,20) getPercent(390,121);
                                    color: #FFF;
                                }
                                .bg-img{
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    padding-bottom: getPercent(390,390);
                                    background-image: url('../../../public/site/res/image/mobile/shinsegae/img_shin_section01_bg_black.webp');
                                    background-position: center;
                                    background-size: cover;
                                }
                                .bg-gradient{
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    margin-top: getPercent(390,209);
                                    width: 100%;
                                    padding-bottom: getPercent(390,273);
                                    background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
                                }
                            }
                            .cont-wrap-02{
                                width: 100%;
                                ul{
                                    display: flex;
                                    flex-direction: column;
                                    
                                    li{
                                        width: 100%;
                                        h3{
                                            color: #fff;
                                            font-family: $font-en;
                                            @include fontStyle(16px, 300, 120%);
                                            font-size: getVw(16px);
                                            text-transform: uppercase;
                                            text-align: center;
                                            padding-bottom: getPercent(390,30);
                                        
                                        }
                                        &.shopping{
                                            padding-bottom: getPercent(390,28);
                                            .circle-list{
                                                width: getPercent(390,240);
                                                padding-bottom:  getPercent(390,240);
                                                &:not(:first-of-type){
                                                    transform: translateY(-6.666%);
                                                }
                                                &:nth-of-type(3){
                                                    transform: translateY(-13.333%);
                                                }
                                            }
                                        }
                                        &.valuable{
                                            .circle-list{
                                                width: getPercent(390,240);
                                                padding-bottom:  getPercent(390,240);
                                                background-color: #FF329B !important;
                                                &:nth-of-type(2){
                                                    transform: translateY(-6.666%);
                                                }
                                                h4,span{
                                                    color: #fff !important;
                                                }
                                            }
                                        }
                                        .circle-list-wrap{
                                            display: flex;
                                            flex-direction: column;
                                            width: 100%;
                                            align-items: center;
                                            .circle-list{
                                                font-family: $font-ko;
                                                display: flex;
                                                flex-direction: column;
                                                align-items: center;
                                                justify-content: center;
                                                background-color: #fff;
                                                border-radius: 50%;
                                                position: relative;
                                                
                                                .circle-text-wrap{
                                                    position: absolute;
                                                    top: 50%;
                                                    left: 50%;
                                                    transform: translate(-50%,-50%);
                                                    text-align: center;
                                                    h4{
                                                        @include fontStyle(24px, 500, 140%,-1.5px);
                                                        font-size: getVw(24px,390px);
                                                        color: #131313;
                                                        margin-bottom: getRem(8);
                                                        text-wrap: nowrap;
                                                        
                                                    }
                                                    span{
                                                        @include fontStyle(14px, 300, 140%,-0.8px);
                                                        font-size: getVw(14px,390px);
                                                        color: #131313;
                                                    }
                                                }
                                                
                                            }
                                        }
                                    }
                                }
                                .line{
                                    margin: getPercent(390,50) auto;
                                    width: getPercent(390,240);
                                }
                            }
                            .cont-wrap-03{
                                text-align: center;
                                margin-top: getRem(61);
                                padding-bottom: getPercent(390,170);
                                color: #fff;
                                strong{
                                    @include fontStyle(45px, 300,120%);
                                    font-size: getVw(45px,390px);
                                    font-family: $font-en;
                                    text-transform: uppercase;
                                }
                                p{
                                    @include fontStyle(24px, 500,140%,-1.5px);
                                    font-size: getVw(24px,390px);
                                    font-family: $font-ko;
                                    margin-top: getPercent(390,20);
                                }
                            }
                        }
                    }
                    .cont-sec-02{
                        background-color: #FFFFFF;
                        .sec-text-box{
                            width: 100%;
                            text-align: center;
                            padding: getPercent(390,100) getPercent(390,20);
                            h4{
                                color: #FF329B;
                                padding-bottom:getPercent(390,30)
                            }
                            p{
                                letter-spacing: -0.073em;
                                color: #000000;
                            }
                        }
                        .sec-detail-box{
                            width: 100%;
                            ul{
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                li{
                                    position: relative;
                                    .detail-img{
                                        width: 100%;
                                        position: relative;
                                        &::after{
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            display: block;
                                            content: '';
                                            width: 100%;
                                            height: 100%;
                                            background-color: rgba(0, 0, 0, 0);
                                            transition: background-color .3s;
                                        }
                                    }
                                    strong{
                                        z-index: 0;
                                        position: absolute;
                                        display: block;
                                        bottom: getPercent(500,54);
                                        left: 50%;
                                        transform: translateX(-50%);
                                        @include fontStyle(45px, 300,53.7px,-2.25px);
                                        font-size: getVw(45px,390px);
                                        font-family: $font-ko;
                                        color: #FF329B;
                                        width: 100%;
                                        text-align: center;
                                        transition: opacity .3s;
                                        
                                    }
                                    .hover-tag {
                                        visibility: visible;
                                        z-index: 1;
                                        position: absolute;
                                        bottom: 0;
                                        color: #fff;
                                        background-color: #595959;
                                        padding: getVw(10px,390px) getVw(20px,390px);
                                        border-radius: 27px;
                                        @include fontStyle(14px, 300, 19.6px, -0.05em);
                                        font-size: getVw(14px,390px);
                                        font-family: $font-ko;
                                        text-wrap: nowrap;
    
                                        &.consumer-01 {
                                            bottom: getVw(25px,390px);
                                            right: getVw(8px,390px);
                                        }
                                        &.consumer-02 {
                                            bottom: getVw(93px,390px);
                                            left: getVw(20px,390px);
                                        }
                                        &.contents-01 {
                                            bottom: getVw(108px,390px);
                                            left: getVw(33px,390px);
                                        }
                                        &.contents-02 {
                                            bottom: getVw(98px,390px);
                                            right: getVw(14px,390px);
                                        }
                                        &.contents-03 {
                                            bottom: getVw(25px,390px);
                                            left: getVw(33px,390px);
                                        }
                                    }

                                    &.show-dark{
                                        .detail-img{
                                            &::after{
                                                background-color: rgba(0, 0, 0, 0.8);
                                            }
                                        }
                                        strong{
                                            opacity: 0.3;
                                        }
                                        .hover-tag{
                                            visibility: hidden;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .cont-sec-03{
                        background-color: #fff;
                        position: relative;
                        overflow: hidden;

                            .bg-text{
                                width: 100%;
                                @include fontStyle(14px, 500, 22.4px,-0.04em);
                                text-align: left;
                                color: #131313;
                                font-family: $font-ko;
                                margin-top: getRem(19);
                            }
                            .bg-img-01{
                                width: getPercent(390,212.5);
                                margin-top: getPercent(390,57);
                                margin-bottom: getPercent(390,43.5);
                            }
                            .bg-img-02{
                                width: getPercent(390,225);
                                margin-left: getPercent(390,122);
                                .bg-text{
                                    width:getRem(187);
                                }
                            }
                            .bg-img-03{
                                display: flex;
                                flex-direction: column;
                                width: getPercent(390,225);
                                margin-left: getPercent(390,30);
                                .bg-text{
                                    align-self: flex-end;
                                    width: getRem(172);
                                    text-align: right;
                                    margin-top: getRem(19.5);
                                    margin-left: getPercent(390,83);
                                }
                            }
                            .bg-img-04{
                                width: getPercent(390,193.5);
                                margin: {
                                    top:getPercent(390,67);
                                    bottom: getPercent(390,61);
                                    left: getPercent(390,197);
                                }
                            }
                            .bg-img-05{
                                width: getPercent(390,250);
                            }
                        
                        .cont-text-box{
                            padding: getPercent(390,96) 0 getPercent(390,100);
    
                            p{
                                @include fontStyle(45px, 300, 48.15px);
                                font-family: $font-en;
                                text-transform: uppercase;
                                text-align: center;
                                color: #222222;
                            }
                        }
                    }
                    .cont-sec-04{
                        .cont-wrap{
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding-top:  getPercent(390,100);
                            background: linear-gradient(90deg, #FF329B 0%, #FE8C07 24.9%, #8EDB63 49.79%, #E960FF 75.09%, #FF329B 100%);
                            background-size: 700% 100%;
                            animation: gradient 11s ease infinite;
                            text-transform: uppercase;
                            color: #fff;
                            p{
                                text-align: center;
                                font-family: $font-ko;
                                @include fontStyle(20px, 500, 32px, -0.04em);
                                font-size: getVw(20px);
                                padding: getPercent(390,30) 0 getPercent(390,132);
    
                            }
                            strong.big-text-wrap{
                                width: 100%;
                                &:nth-of-type(2){
                                    text-align: center;
                                    padding: {
                                        top: getPercent(390,228);
                                        bottom: getPercent(390,50);
                                    }
                                }
                                div{
                                    display: inline-block;
                                    
                                    span{
                                        display: inline-block;
                                        font-family: $font-en;
                                        @include fontStyle(74.91px,300,94.99px,-0.05em);
                                        font-size: getVw(74.91px);
                                    }
                                }
                            }
                            .item-img-wrap{
                                position: absolute;
                                width: getPercent(390,361.39);
                                padding-bottom: getPercent(390,361.39);
                                background-image: url('../../../public/site/res/image/mobile/shinsegae/img_product_01.webp');
                                background-repeat: no-repeat;
                                background-size: cover;
                                bottom: 0;
                                margin-bottom: getPercent(390,130);
                                left: getPercent(390,24);
                                animation: changeImg 14s infinite step-end;
                                -webkit-animation-fill-mode: forwards;
                                animation-fill-mode: forwards;
                                will-change:background-image;
                            }
                        }
                        @keyframes gradient {
                            0% {
                                background-position: 0% 50%;
                            }
                            25% {
                                background-position: 50% 50%;
                            }
                            50%{
                                background-position: 100% 50%;
                            }
                            75%{
                                background-position: 50% 50%;
                            }
                            100% {
                                background-position: 0% 50%;
                            }
                        }
                        @keyframes changeImg {
                            0% {
                                background-image: url('../../../public/site/res/image/mobile/shinsegae/img_product_01.webp');
                            }
                            20% {
                                background-image: url('../../../public/site/res/image/mobile/shinsegae/img_product_02.webp');
                            }
                            55% {
                                background-image: url('../../../public/site/res/image/mobile/shinsegae/img_product_03.webp');
                            }
                            79%{
                                background-image: url('../../../public/site/res/image/mobile/shinsegae/img_product_02.webp');
                            }
                            100% {
                                background-image: url('../../../public/site/res/image/mobile/shinsegae/img_product_01.webp');
                            }
                        }
                    }
                    .cont-sec-05{
                        
                        .container-01{
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding-top: getPercent(390,100);
                            background-image: url('../../../public/site/res/image/mobile/shinsegae/img_shin_sec05_bg.webp');
                            background-size: cover;
                            background-blend-mode: multiply;
                            background-color: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 33%, rgba(0, 0, 0, 0.80) 100%);
                            color: #fff;
                            
                            .p-sec-desc{
                                line-height: 1.60em;
                                text-align: center;
                                padding: getPercent(390,30) 0 getPercent(390,171);
                            }
                            .keyword-list-box{
                                width: 100%;
                                padding: 0 getPercent(390,40);
                                .keyword-list-wrap{
                                    display: flex;
                                    flex-direction: column;
    
                                    .keyword-list{
                                        margin-bottom: getPercent(310,50);
                                        &:nth-of-type(even){
                                            text-align: right;
                                        }
                                        &:last-of-type{
                                            margin-bottom: getPercent(310,63);
                                        }
    
                                        h5{
                                            font-family: $font-en;
                                            @include fontStyle(24px, 500,26.4px);
                                            margin-bottom: getRem(12);
                                        }
                                        p{
                                            width: 100%;
                                            font-family: $font-ko;
                                            @include fontStyle(12px, 300,19.2px,-0.05em);
                                        }
                                    }
                                }
                            }
                        }
                        .container-02{
                            position: relative;
                            padding-bottom: getPercent(390,498);
                            .img-cont{
                                position: absolute;
                                width: getPercent(390,300);
                                top: 0;
                                left: 50%;
                                transform: translateX(-50%);
                                margin-top: getPercent(390,20);
                                overflow: hidden;
    
                                img{
                                    position: relative;
                                    z-index: 1;
                                }
                                video{
                                    z-index: 0;
                                    position: absolute;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    width: getPercent(300,250.54);
                                    height: auto;
                                    top: getPercent(590.36,24.41);
                                    border-radius: 15px;
                                }
                            }
                        }

                        .container-03{
                            background-color: #fff;
                            padding: getPercent(390,176) getPercent(390,32.68) getPercent(390,88.27) getPercent(390,33);
                            .icon-box-wrap{
                                display: flex;
                                flex-direction: column;
                                align-items:center;
                                
                                .icon-box{
                                    width: 100%;
                                    text-align: center;
                                    margin-bottom: getPercent(324.33,70);
                                    &:last-of-type{
                                        margin-bottom: 0;
                                    }
                                    h5{
                                    font-family: $font-en;
                                    @include fontStyle(14px, 300);
                                    font-size: getVw(14px);
                                    text-transform: uppercase;
                                    color: #131313;
                                    margin-bottom: getRem(20);
                                    }
                                    >ul{
                                        display: flex;
                                        flex-wrap: wrap;
                                        gap: getRem(20);
                                    }
                                    .app-wrap{
                                        width: getVw(120px);
                                        margin: 0 auto;
                                    }
                                    .color-wrap{
                                        justify-content: center;
                                        .ui-color{
                                            width: getVw(50px);
                                            height: getVw(50px);
    
                                            &.FF329B{background-color: #FF329B;}
                                            &.FF58B2{background-color: #FF58B2;}
                                            &.FFEBF5{background-color: #FFEBF5;}
                                            &.gradient{
                                                background: var(--main-Main-gradient, linear-gradient(144deg, #FF329B 20.3%, #FF3792 29.64%, #FF467A 65.35%, #FF5F52 82.77%, #FF801B 94.14%, #FF9100 98.1%));
                                            }
                                            &.black{background-color: #131313;}
                                            &.white{
                                                background-color: #FFFFFF;
                                                border: 0.5px solid #A2A2A2;
                                            }
                                            &.D0D0D0{background-color: #D0D0D0;}
                                            &.gray{background-color: #717171;}
                                        }
                                    }
                                    .icon-wrap{
                                        gap: getRem(20) 0;
                                        .icongraphy{
                                            width: getPercent(324.33,48.86);
                                            margin-right: getPercent(324.33,20);
                                            &:nth-of-type(5),
                                            &:nth-of-type(10){
                                            margin-right: 0;}
                                        }
                                    }
                                }
                               
                            }
                        }
    
                    }
                    .cont-sec-06{
                        .slide-container{
                            background-image: url('../../../public/site/res/image/mobile/shinsegae/img_shin_sec06_bg_01_new.webp');
                            background-size: cover;
                            background-blend-mode: lighten;
                            background-color: #FF329B;
                            padding-top: getPercent(390,284);
                            &.fashion{
                                background-image: url('../../../public/site/res/image/mobile/shinsegae/img_shin_sec06_bg_02.webp');
                                background-color:rgba(0, 0, 0, 0.2);
                                .tag{
                                    background-color: #FF329B;
                                    &::after{
                                        border-top-color: #FF329B;
                                    }
                                }
                            }
                        }
                        .text-cont-wrap{
                                padding-bottom: getPercent(390,180);
                                position: relative;
                                p{
                                    color: #fff;
                                    @include fontStyle(65px, 300,84.5px);
                                    font-size: getVw(65px);
                                    font-family: $font-en;
                                    text-transform: uppercase;
                                    text-align: center;
                                }
                                .tag{
                                    position: absolute;
                                    top: 0;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    @include fontStyle(12px, 400,100%,-0.36px);
                                    font-size: getVw(12px);
                                    padding: getRem(8) getRem(12);
                                    background-color: #131313;
                                    color: #fff;
                                    &::after{
                                        width: 0;
                                        display: block;
                                        content: '';
                                        position: absolute;
                                        bottom: -12px;
                                        border: 4px solid transparent;
                                        border-top: 8px solid #131313;
                                    }
                                }
                                
                            
                        }
                        .img-cont-wrap{
                            width:100%;
                        }
                        .swiper-pagination{
                            bottom: 0;
                            margin-bottom: getPercent(390,294);
                        }
                    }
                    .cont-sec-07{
                        z-index: 0;
                        position: relative;
                        background-color: #F5F5F5;
                        padding-left: getPercent(390,40);
                        padding-bottom: getPercent(390,109);
                        .text-cont-wrap{
    
                            .text-bottom-box{
                                .p-text-left{
                                    @include fontStyle(20px, 300,26px,-0.03em);
                                    font-family: $font-ko;
                                    margin-bottom: getRem(50);
                                    color: #000000;
                                    &:nth-of-type(1){
                                        opacity: 0.2;
                                        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 15.62%, #000 100%);
                                        background-clip: text;
                                        -webkit-background-clip: text;
                                        -webkit-text-fill-color: transparent;
                                    }
                                    &:last-of-type{
                                        margin-bottom: 0;
                                        opacity: 0.1;
                                        background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 80.21%);
                                        background-clip: text;
                                        -webkit-background-clip: text;
                                        -webkit-text-fill-color: transparent;
                                    }
    
                                }
                            }
                            .text-top-box{
                                padding: getPercent(350,110) 0 getPercent(350,59);
                                h5{
                                    margin-bottom: getRem(12);
                                }
                            }
                        }
                        .img-cont-wrap{
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            width: getPercent(390,370);
                        }
                    }
                    .cont-sec-08{
                        position: relative;
                        background-color: #fff;
                        padding: getPercent(390, 110) 0 getPercent(390, 552.34);
                        .cont-text-box{
                            text-align: center;
                        .h5-text-title{
                            margin-bottom: getRem(12);
                        }
                        .p-text-feature{
                                color: #131313;
                                
                            }
                        }
                        .cont-img-box{
                            position: relative;
                            width: 100%;
                            margin: 0 auto;
                            img{
                                z-index: 1;
                                position: relative;
                            }
                            video{
                                z-index: 0;
                                position: absolute;
                                top:getPercent(1040,110);
                                height: auto;
                                width: getPercent(600,381);
                                left: 50%;
                                transform: translateX(-50%);
                                border-radius: 15px;
                            }
                        }
                        .cont-wrap{
                            &.bottom{
                                padding-top: getPercent(390,90);
                                .cont-img-box{
                                    z-index: 2;
                                    position: absolute;
                                    top: 0;
                                    margin-top: getPercent(390,1277.66);
                                    left: 50%;
                                    transform: translateX(-50%);
                                }
                            }
                            
                        }
    
                    }
                    .cont-sec-09{
                        z-index: 1;
                        position: relative;
                        background-color: #000;
                        padding-top: getPercent(390,195);
                        .cont-container{
                            
                            padding: getPercent(390,92) 0 getPercent(390,72);
                                background-size: cover;
                                margin-top: -1px;
                                background-image: url('../../../public/site/res/image/mobile/shinsegae/img_shin_sec09_bg_01.webp');
                                background-position: right;
                          
                            .swiper-pagination{
                                bottom: 0;
                                margin-bottom: getPercent(390,72);
                            }
                        }
                        
                        .cont-text-wrap{
                            z-index: 1;
                            position: relative;
                            color: #fff;
                            text-align: center;
                            padding-bottom: getPercent(390,74);
                            .h5-text-title{
                                margin-bottom: getRem(12);
                            }
                        }
                        .cont-img-wrap{
                            width: getPercent(390,300);
                            margin: 0 auto;
                            position: relative;
                            padding-bottom: getPercent(390,122.87);
                            overflow: hidden;
                            img{
                                z-index: 0;
                                position: relative;
                            }
                            video{
                                z-index: 1;
                                position: absolute;
                                top: 2.38721%;
                                left: 50%;
                                transform: translateX(-50%);
                                width: getPercent(300,267);
                                // width: getPercent(600,381);
                                height: auto;
                                border-radius: 32px;
                                overflow: hidden;

                                &.video01{
                                    width: 91%;
                                }
                            }
                        }
                        
                        .sec-bg{
                            z-index: 0;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            width: getPercent(1920,939);
    
                            &::before{
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                display: block;
                                content: '';
                                width: getPercent(931,490);
                                padding-bottom: getPercent(931,991);
                                background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, #000 70.5%, #000 100%);
                            }
                        }
                    }
                    .cont-sec-10{
                        z-index: 0;
                        position: relative;
                        background: linear-gradient(180deg, #E7E8EC 0%, #FFF 100%);
                        display: flex;
                        flex-direction: column;
                        overflow: hidden;
                        .text-cont-wrap-bg{
                            span{
                                z-index: 0;
                                position: absolute;
                                display: block;
                                @include fontStyle(90px, 300,130%);
                                font-size: getVw(90px);
                                text-transform: uppercase;
                                font-family: $font-en;
                                background: linear-gradient(272deg, #EEE 1.74%, #F6F6F6 101.05%);
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;

                                &.fashion{
                                    top: getRem(-15);
                                    left: getRem(-15);;
                                } 
                                &:nth-of-type(2){
                                    top: 0;
                                    margin-top: getPercent(390,274);
                                    right: 0;
                                    margin-right: getPercent(390,12);
                                }
                                &:nth-of-type(3){
                                    top: 0;
                                    left: 0;
                                    margin-top: getPercent(390,906);
                                    margin-left: getPercent(390,124);
                                }   
                            }
    
                            &.end{
                                z-index: 0;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                margin-left: getPercent(390,25);
                                margin-bottom: getPercent(390,-26);
                                span{
                                    position: relative;
                                }
                            }
                        }
                        .cont-img-01{
                            z-index: 3;
                            position: relative;
                            width: 100%;
                            padding-top: getPercent(390,280);
                        }
                        div[class^=bg-img]{
                            z-index: 1;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: getPercent(390,160);
    
                            &.bg-img-01{
                                margin-top: getPercent(390,-15);
                                margin-left: getPercent(390,34);
                            }
                            &.bg-img-02{
                                width: getPercent(390,101.59);
                                left: unset;
                                right: 0;
                                margin-top: getPercent(390,157);
                            }
                            &.bg-img-03{
                                width: getPercent(390,84);
                                margin-top: getPercent(390,866);
                            }
                            &.bg-img-04{
                                width: getPercent(390,133);
                                margin-top: getPercent(390,1003);
                                left: unset;
                                right: 0;
                            }
                        }
                        .bg-gradient{
                            z-index: 2;
                            position: absolute;
                            top: 0;
                            left: 0;
                            margin-top: getPercent(390,946);
                            width: 100%;
                        }
    
                        .cont-wrap{
                            z-index: 3;
                            position: relative;
                            padding-bottom: getPercent(390,89.38);
                            .cont-text-wrap{
                                text-align: center;
                                padding-bottom: getPercent(390,55);
                            }
                            .cont-img-02{
                                position: relative;
                                width: 100%;
                                
                                img{
                                    z-index: 1;
                                    position: relative;
                                }
                                video{
                                    z-index: 0;
                                    position: absolute;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    width: getPercent(600,381);
                                    height: auto;
                                    top: getPercent(1040,110);
                                    border-radius: 15px;
                                }
                            }
                            .h5-text-title{
                                color: #131313;
                                margin: getPercent(390,73.34) 0 getRem(12);
                            }
                            .p-text-feature{
                                color: #131313;
                            }
                        }
                    }
                    .cont-sec-11{
                        position: relative;
                        display: flex;
                        flex-direction: column;
    
                        > div{
                            width: 100%;
    
                            &.cont-wrap-02{
                                padding: getPercent(390,31.82795) 0;
                                background-color: #FF329B;
                                 display: flex;
                                    align-items: center;
                                    justify-content: center;
                                .cont-wrap{

                                    position: relative;
                                    width:100%;
                                    img{
                                        z-index: 1;
                                        position: relative;
                                    }
                                    video{
                                        z-index: 0;
                                        position: absolute;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        width: getPercent(600,381);
                                        height: auto;
                                        top:getPercent(1040,110);
                                        border-radius: 15px;
                                    }
                                }
                            }
                        }
                    }
                    .cont-sec-12{
                        position: relative;
                        overflow: hidden;
                        .cont-text-wrap{
                            padding: {
                                top: getPercent(390,109);
                                left: getPercent(390,40);
                                bottom: getPercent(390,88);
                            }
                            color: #fff;
                            h5{
                                margin-bottom: getRem(12);
                            }
                        }
                        .img-cont-wrap{
                            display: flex;
                            .img-cont-list{
                                width: getPercent(390,160);
                                flex-shrink: 0;
                                &:first-of-type{
                                    width: getPercent(390,125);
                                    margin-right: getPercent(390,30.22);
                                    div{
                                    &:first-of-type{
                                        margin-bottom: getPercent(125,22.33);
                                    }
                                }
                                }
                                &:nth-of-type(2){
                                    padding-top: getPercent(390,64);
                                    div{
                                    &:first-of-type{
                                        margin-bottom: getPercent(160,22.33);
                                    }
                                }
                                }
                                &:last-of-type{
                                    position: absolute;
                                    right: 0;
                                    top:0;
                                    margin-top: getVw(120px,390px);
                                    width: getPercent(390,50);
                                }
                            }
                        }
                    }
                    .cont-sec-13{
                        position: relative;
                        padding-bottom: getPercent(390,538);

                        video{
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                        }
                    }
    
                }
            }
            // (E) shinsegae
            // (S) archiveat
            &.archiveat {
                background-color: #fff;

                .cover-img-wrap {
                    padding: getVh(62px) getVw(7px);
                    background-color: #ff5019;

                    .logo-wrap {
                        width: getVw(377px);
                        margin: 0 auto;
                        img {
                            display: block;
                            width: 100%;
                        }
                    }
                    
                    .phone-wrap {
                        position: relative;
                        width: getVw(304px);
                        margin: getVh(61px) auto 0;
                        z-index: 1;
                        img {
                            display: block;
                            width: 100%;
                        }
                    }

                    .bg-wrap {
                        position: absolute;
                        bottom: getVh(-115px);
                        right: 0;
                    }
                }

                .cover-text-wrap {
                    * {
                        color: #ff5019;
                    }
                    .project-team {
                        .team-tag {
                            border-color: #ff5019;
                        }
                    }
                }

                section {
                    overflow: hidden;

                    &.section-01 {
                        padding-bottom: getVh(106px);
                        .img-01 {
                            width: 100%;
                        }

                        .img-02 {
                            width: getVw(183px);
                            margin-top: getVh(-34px);
                            margin-left: getVw(170px);
                        }

                        .marquee-container {
                            .marquee-wrap {
                                animation-duration: 15s;
                                ul {
                                    gap: 0 getVw(10px);
                                    padding: getVw(5px);

                                    li {
                                        width: getVw(154px);

                                        &:nth-child(2) {
                                            margin-top: getVh(34px);
                                        }
                                        &:nth-child(3) {
                                            margin-top: getVh(8px);
                                        }
                                        &:nth-child(4) {
                                            margin-top: getVh(45px);
                                        }
                                        &:nth-child(5) {
                                            margin-top: getVh(14px);
                                        }
                                        &:nth-child(7) {
                                            margin-top: getVh(40px);
                                        }
                                    }
                                }
                            }
                        }
                        
                    }

                    &.section-02 {
                        padding: getVw(75px);
                        padding-bottom: 0;
                        background: {
                            image: url("../../../public/site/res/image/mobile/archiveat/img_02_bg.png");
                            repeat: no-repeat;
                            size: cover;
                        };

                        .img-frame {
                            position: relative;
                            width: getVw(265px);
                            
                            .img-01 {
                                // position: absolute;
                                position: relative;
                                z-index: 1; 
                                margin-top: getVh(-5px);
                                margin-left: getVh(-5px);
                            }
                            .img-02 {
                                background-color: #fff;
                                position: absolute;
                                overflow: hidden;
                                width: getVw(218px);
                                top: getVh(58px);
                                left: getVw(5px);
                            }
                        }
                    }

                    &.section-03 {

                        .img-01 {
                            width: getVw(184px);
                            margin-top: getVh(87px);
                            margin-left: getVw(48px);
                        }

                        .marquee-container {
                            .marquee-wrap {
                                animation-duration: 13s;
                                ul {
                                    gap: 0 getVw(12px);
                                    padding: 0 getVw(6px);
                                    li {
                                        width: getVw(155px);

                                        &:nth-child(2) {
                                            margin-top: getVh(45px);
                                        }
                                        &:nth-child(4) {
                                            margin-top: getVh(31px);
                                        }
                                        &:nth-child(5) {
                                            margin-top: getVh(76px);
                                        }
                                        &:nth-child(6) {
                                            margin-top: getVh(18px);
                                        }
                                        &:nth-child(6) {
                                            margin-top: getVh(85px);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.section-04 {
                        width: 100%;
                    }

                    &.section-05 {
                        background-color: #C3B496;
                        padding: getVh(54px) 0 0;

                        .img-frame {
                            position: relative;
                            width: getVw(148px);
                            margin: 0 auto;
                            border-top-right-radius: getRem(15px);
                            border-top-left-radius: getRem(15px);
                            overflow: hidden;

                            .img-01 {
                                position: relative;
                                z-index: 1;
                            }

                            .vid {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 100%;
                                
                                video {
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                        }
                    }

                    &.section-06 {
                        position: relative;
                        background-color: #F1F1F1;
                        padding-bottom: getVh(288px);
                        overflow: visible;
                        .img-bg {
                            position: absolute;
                            bottom: getVh(183px);
                            left: 50%;
                            transform: translateX(-50%);
                            width: getVw(300px);
                        }
                        .img-frame-01 {
                            position: relative;
                            width: getVw(183px);
                            margin-top: getVh(102px);
                            margin-left: getVw(56px);
                            
                            .img-01 {
                                position: relative;
                                z-index: 1;
                            }
                            .img-02 {
                                position: absolute;
                                width: getVw(144px);
                                height: getVw(275px);
                                overflow: hidden;
                                border-top-right-radius: getRem(15px);
                                border-top-left-radius: getRem(15px);
                                top: getVh(4px);
                                left: getVw(3.733px);
                            }
                        }
                        .img-frame-02 {
                            position: absolute;
                            width: getVw(183px);
                            margin-top: getVh(59px);
                            margin-left: getVw(164px);
                            z-index: 1;

                            .img-03 {
                                position: relative;
                                z-index: 1;
                            }

                            .img-slide {
                                position: absolute;
                                display: flex;
                                top: getVh(4px);
                                left: getVw(7px);
                                width: getVw(140px);
                                border-radius: getRem(14px);
                                overflow: hidden;

                                img {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    &.section-07 {
                        width: 100%;
                    }

                    &.section-08 {
                        position: relative;

                        .img-frame {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: getVw(168px);
                            height: getVh(350px);

                            .img-01 {
                                position: relative;
                                width: getVw(210px);
                                margin-left: getVw(-4px);
                                margin-top: getVh(-4px);
                                z-index: 1;
                            }

                            .img-02 {
                                position: absolute;
                                top: 0;
                                margin-top: getVh(95px);
                                left: getVw(4px);
                                width: getVw(160px);
                                height: getVw(225px);
                                overflow: hidden;
                            }
                        }
                    }

                    &.section-09 {
                        display: flex;
                        justify-content: flex-end;
                        gap: 0 getVw(15px);
                        padding-top: getVh(74px);
                        background-color: #C3B496;

                        .img-wrap {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            width: getVw(75px);
                            margin-bottom: getVh(-22px);
                            
                            &:last-child {
                                width: getVw(58px);
                                margin-bottom: getVh(-26px);
                            }
                            
                            img {
                                width: getVw(98.5px);
                            }
                        }
                    }
                }
            }
            // (E) archiveat

            // (S) metapassport 
            &.metapassport {
                background-color: #000;

                section {

                    &.section-01 {
                        padding: getVh(250px) 0;
                        background: {
                            image: url("../../../public/site/res/image/mobile/metapassport/img_01_bg.png");
                            position: bottom;

                        }
                        .img-01 {
                            width: getVw(176px);
                            margin: 0 auto;
                        }
                    }

                    &.section-02 {
                        padding: getVh(160px) getVw(32px) getVh(28px);
                        .img-wrap {
                            background-color: #25282E;
                            border-radius: getRem(16px);
                            overflow: hidden;

                            & ~ .img-wrap {
                                margin-top: getVh(24px);
                            }

                            .img-01 {
                                padding: getVh(70px) getVw(90px);
                            }
                            .img-02 {
                                padding: getVh(43px) getVh(43px) 0;
                            }
                            .img-03 {
                                padding: getVh(43px) getVh(43px) 0;
                            }
                        }
                    }

                    &.section-03 {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        background: {
                            image: url("../../../public/site/res/image/mobile/metapassport/img_03_bg.png");
                            position: top;
                            size: 100%;
                        }
                        padding: getVh(209px) 0;

                        > .Fadeup {
                            flex-shrink: 0;
                        }

                        .img-01 {
                            width: getVw(250px);
                        }
                        .img-02 {
                            width: getVw(353px);
                            margin-top: getVh(-334px);
                            align-self: flex-end;
                        }
                        .img-03 {
                            width: getVw(250px);
                            margin-top: getVh(-116px);
                        }
                        .img-04 {
                            width: getVw(353px);
                            margin-top: getVh(-128px);
                            align-self: flex-end;
                        }
                    }

                    &.section-04 {
                        padding: getVh(24px) getVw(32px);
                        display: flex;
                        flex-direction: column;
                        gap: getVw(20px) 0;
                        .img-wrap {
                            position: relative;
                            border-radius: getRem(16px);
                            background-color: #25282E;
                            overflow: hidden;
                            z-index: 1;

                            &.img-wrap-03 {
                                padding: getVw(20px);

                                div ~ div {
                                    margin-top: getVw(20px);
                                }
                            }

                            .img-01 {
                                padding: getVw(48px) getVw(48px) 0;
                            }
                        }
                    }

                    &.section-05 {
                        position: relative;
                        padding-top: getVh(368px);
                        padding-bottom: getVw(172px);
                        overflow: hidden;
                        .img-bg {
                            margin-top: getVh(-384px);
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                        }
                        .marquee-container {
                            .marquee-wrap {
                                animation-duration: 20s;

                                ul {
                                    li {
                                        width: getVw(130px);
                                    }
                                }
                            }
                        }

                        .img-01 {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: getVw(307px);
                            margin-top: getVh(237px);
                            margin-left: getVw(19px);
                        }

                        .img-container {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 0 getVw(32px);
                            margin-top: getVh(381px);
                            .img-wrap {
                                display: flex;
                                flex-direction: column;
                                width: getPercent(296, 141);
                                gap: getVw(32px) 0;

                                .Fadeup {
                                    flex-grow: 0;
                                }
                            }
                        }

                        .img-logo {
                            margin: getVh(472px) auto 0; 
                            width: getVw(220px);
                        }
                    }
                }
            }
            // (E) metapassport 

            // (S) kiarental
            &.kiarental {
                background-color: #000;
                section {
                    &.section-01 {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .img-01 {
                            width: getVw(296px);
                        }
                        .img-02 {
                            position: relative;
                            width: getVw(105px);
                            margin-top: getVw(-11px);
                            z-index: 1;
                        }
                        .img-03 {
                            width: getVw(296px);
                            margin-top: getVw(-11px);
                        }
                    }

                    &.section-02 {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding-top: getVh(250px);
                        padding-bottom: getVh(240px);
                        background: {
                            image: url("../../../public/site/res/image/mobile/kiarental/img_02_bg.png");
                            position: top right;
                            size: getPercent(360, 312);
                            repeat: no-repeat;
                        }

                        .img-01 {
                            width: getVw(296px);
                        }

                        .img-02 {
                            position: relative;
                            z-index: 1;
                            width: getVw(154px);
                            margin-top: getVw(-7px);
                        }
                        .img-03 {
                            width: getVw(296px);
                            margin-top: getVw(-13px);
                        }
                    }

                    &.section-03 {
                        padding: getVh(71px) getVw(65px);
                        background-color: #979D9F;
                    }

                    &.section-04 {
                        padding: getVh(240px) getVw(100px);
                        background-color: #34414A;
                    }

                    &.section-05 {
                        padding: getVh(160px) getVw(32px);
                        background-color: #CDD0D2;
                        .img-02 {
                            margin-top: getVw(16px);
                        }
                        .img-03 {
                            margin-top: getVh(160px);
                        }
                        .img-04 {
                            margin-top: getVh(32px);
                        }
                    }

                    &.section-06 {
                        background-color: #CDD0D2;
                        padding-bottom: getVh(160px);
                        .marquee-container {
                            & ~ .marquee-container {
                                margin-top: getVh(8px);
                            }
                            .marquee-wrap {
                                ul {
                                    gap: 0 getVw(8px);
                                    padding: 0 getVw(4px);
                                    li {
                                        width: getVw(90px);
                                    }
                                }
                            }
                        }

                        .img-wrap {
                            display: flex;
                            flex-direction: column;
                            margin-top: getVw(-32px);
    
                            .img-01 {
                                align-self: flex-end;
                                width: getVw(199px);
                            }
    
                            .img-02 {
                                margin-left: getVw(36px);
                                margin-top: getVw(-30px);
                                width: getVw(245px);
                            }
                            .img-03 {
                                width: getVw(123px);
                                align-self: flex-end;
                                margin-top: getVw(-183px);
                            }
                            .img-04 {
                                margin-left: getVw(36px);
                                margin-top: getVw(-60px);
                                width: getVw(167px);
                            }
                        }
                    }

                    &.section-07 {
                        
                        .img-01 {
                            padding-top: getVh(29px);
                            background-color: #616A71;
                            overflow: hidden;
                        }
                        
                        .img-02 {
                            background-color: #fff;
                            padding: getVh(211px) getVw(20px);
                            overflow: hidden;
                        }
                        
                        .img-03 {
                            background-color: #656C7F;
                            padding: getVh(71px) getVw(65px);
                            overflow: hidden;
                        }
                    }

                    &.section-08 {
                        background-color: #000;
                        padding: getVh(160px) getVw(32px);

                        .img-wrap {
                            display: flex;
                            flex-direction: column;
                            padding: getVh(100px) 0; 
                            background: {
                                image: url("../../../public/site/res/image/mobile/kiarental/img_08_texture.png");
                                color: #979D9F;
                            }

                            .img-01 {
                                width: getVw(256px);
                                align-self: flex-end;
                            }

                            .img-02 {
                                width: getVw(256px);
                            }
                        }
                    }

                    &.section-09 {
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        padding-top: getVh(282px);
                        padding-bottom: getVh(200px);
                        background: {
                            image: url("../../../public/site/res/image/mobile/kiarental/img_09_bg.svg");
                            repeat: no-repeat;
                            position: left getVh(160px);
                        };

                        .img-01 {
                            align-self: flex-end;
                            width: getVw(328px);
                        }
                    }

                    &.section-10 {
                        padding: getVh(126px) getVw(32px);
                        background-color: #CCD0D0;
                    }

                    &.section-11 {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        background-color: #fff;
                        padding: getVh(80px) 0 getVh(112px);

                        .img-01 {
                            width: getVw(328px);
                            align-self: flex-end;
                        }

                        .img-02 {
                            position: absolute;
                            width: getVw(280px);
                            left: 0;
                            top: getVh(367px);
                        }
                        .img-03 {
                            position: absolute;
                            width: getVw(285px);
                            left: 0;
                            bottom: 0;
                            margin-left: getVw(75px);
                            margin-bottom: getVh(-50px);
                        }
                    }

                    &.section-13 {
                        padding: getVh(160px) getVw(32px);

                        .img-01 {
                            padding: getVh(110px) getVw(20px);
                            background-color: #979D9F;
                        }
                    }

                    &.section-15 {
                        .img-01 {
                            padding: getVh(137px) getVw(32px);
                            background-color: #797F71;
                        }
                    }

                    &.section-17 {
                        background-color: #34414A;;
                    }

                    &.section-18 {
                        background: {
                            image: url("../../../public/site/res/image/mobile/kiarental/img_18_bg.png");
                            size: cover;
                            repeat: no-repeat;
                        };
                        padding: getVh(306px) getVw(68px);
                    }
                }
            }
            // (E) kiarental

            // (S) daldabang
            &.daldabang {
                background-color: #fff;
                .cover-text-wrap {
                    * {
                        color: #304C3F;
                    }
                }

                .project-contents {
                    section {
                        &.section-01 {
                            overflow: hidden;
                            background: {
                                image: url("../../../public/site/res/image/mobile/ddb/img_01_bg.png");
                                size: 100%;
                            };
                            display: flex;
                            flex-direction: column;
                            position: relative;
                            padding-top: getVh(100px);

                            .img-01 {
                                width: getVw(297px);
                                align-self: flex-end;
                            }

                            .img-02 {
                                position: absolute;
                                width: getVw(193px);
                                bottom: 0;
                                left: 0;
                                margin-bottom: getVh(155px);
                                margin-left: getVw(28px);
                            }
                            
                            .img-03 {
                                position: absolute;
                                width: getVw(193px);
                                bottom: 0;
                                left: 0;
                                margin-bottom: getVh(66px);
                                margin-left: getVw(161px);
                            }
                        }

                        &.section-02 {
                            padding: getVh(84px) 0;
                            .img-01 {
                                width: getVw(249px);
                                margin-left: getVw(65px);
                            }

                            .img-02 {
                                position: relative;
                                width: getVw(249px);
                                margin-left: getVw(65px);
                                margin-top: getVh(149px);

                                .img-wrap {
                                    position: absolute;
                                    width: getVw(194.5px);
                                    top: 0;
                                    left: 0;
                                    margin-left: getVw(75px);
                                    margin-top: getVh(-62px);

                                    .img-02-02 {
                                        margin-top: getVh(-30px);
                                    }
                                }
                            }

                            .img-03 {
                                width: getVw(249px);
                                margin-left: getVw(65px);
                                margin-top: getVh(46px);
                            }
                        }

                        &.section-03 {
                            position: relative;
                            padding-top: getVh(156px);
                            background: {
                                image: url("../../../public/site/res/image/mobile/ddb/img_03_bg.png");
                                size: contain;
                                position: bottom;
                            };
                            overflow: hidden;

                            .img-01 {
                                width: getVw(309.75px);
                                margin-left: getVw(40px);
                            }

                            > div[class^=graph] {
                                position: absolute;
                                
                                &.graph-line {
                                    width: 0px;
                                    top: 0;
                                    left: 0;
                                    overflow: hidden;

                                    img {
                                        width: 100vw;
                                    }
                                }

                                &.graph-bar-wrap {
                                    bottom: 0;
                                    left: 0;
                                    height: getVh(209px);
                                    margin-bottom: getVh(95px);
                                    
                                    .graph-bar {
                                        transform: scaleY(0);
                                        overflow: hidden;
                                        transform-origin: 0 100%;

                                        img {
                                            height: getVh(209px);
                                        }
                                    }

                                }
                            }
                        }

                        &.section-04 {
                            position: relative;
                            background-color: #F5F6F7;
                            padding-top: getVh(80px);
                            padding-bottom: getVh(87px);

                            .img-01 {
                                position: relative;
                                width: getVw(183.5px);
                                margin-left: getVw(33px);
                                z-index: 1;
                            }

                            .img-02 {
                                position: absolute;
                                top: 0;
                                right: 0;
                                margin-top: getVh(117px);
                                width: getVw(192px);
                            }

                            .img-03 {
                                width: getVw(311px);
                                margin-top: getVh(64px);
                            }
                        }

                        &.section-05 {
                            position: relative;
                            background-color: #82B5A0;
                            padding-top: getVh(100px);
                            padding-bottom: getVh(98px);

                            &::before, &::after {
                                content: "";
                                display: block;
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                z-index: 2;
                                width: getVw(42px);
                                background: linear-gradient(270deg, #82B5A0 0%, rgba(130, 181, 160, 0.00) 100%);
                            }

                            &::after {
                                left: 0;
                                transform: rotate(180deg);
                            }

                            &::before {
                                right: 0;
                            }

                            .img-01 {
                                width: getVw(190px);
                                margin: 0 auto;
                            }
                            .img-slide {
                                position: absolute;
                                width: 100%;
                                top: 0;
                                margin-top: getVh(205px);

                            }
                            .img-border {
                                position: absolute;
                                width: getVw(264px);
                                top: 0;
                                left: 0;
                                margin-top: getVh(75px);
                                margin-left: getVh(56px);
                                z-index: 1;
                            }

                        }

                        &.section-06 {
                            display: flex;
                            align-items: flex-end;
                            padding-top: getVh(80px);
                            overflow: hidden;
                            
                            .img-01 {
                                width: getVw(127.5px);
                            }
                            .img-02 {
                                width: getVw(127.5px);
                            }
                            .img-03 {
                                width: getVw(127.5px);
                            }
                        }

                        &.section-07 {
                            background-color: #304C3F;
                            padding: getVh(147px) getVw(130px);
                        }
                    }
                }
            }
            // (E) daldabang

            // (S) bentley
            &.bentley {
                background-color: #F2F4F3;
                .project-contents {
                    section {
                        &.section-01 {
                            display: flex;
                            flex-direction: column;
                            gap: getVh(24px) 0;
                            padding: getVh(120px) getVw(32px);
                            .vid-01 {
                                width: getVw(296px);
                                border-radius: getRem(16px);
                                background-color: #fff;       
                                overflow: hidden;                         

                                video {
                                    width: 100%;
                                }
                            }
                        }

                        &.section-02 {
                            overflow: hidden;
                            background-color: #0D3121;
                            padding: getVh(100px) 0 getVh(63px);

                            .marquee-container {
                                &.marquee-01 {
                                    .marquee-wrap {
                                        animation-duration: 15s;
                                        ul {
                                            li {
                                                &:nth-child(1) {
                                                    width: getVw(254px);
                                                }
                                                &:nth-child(2) {
                                                    width: getVw(217px);
                                                }
                                                &:nth-child(3) {
                                                    width: getVw(258px);
                                                }
                                            }
                                        }
                                    }
                                }
                                &.marquee-02 {
                                    margin-top: getVh(16px);
                                    .marquee-wrap {
                                        animation-duration: 15s;
                                        ul {
                                            li {
                                                &:nth-child(1) {
                                                    width: getVw(224px);
                                                }
                                                &:nth-child(2) {
                                                    width: getVw(246px);
                                                }
                                                &:nth-child(3) {
                                                    width: getVw(135px);
                                                }
                                            }
                                        }
                                    }
                                }

                                ul {
                                    padding: 0 getVw(21px);
                                    gap: 0 getVw(42px);
                                }
                            }
                            .img-01 {
                                width: getVw(345px);
                                margin: 0 auto;
                            }
                        }

                        &.section-03 {
                            display: flex;
                            flex-direction: column;
                            gap: getVh(24px) 0;
                            padding: getVh(100px) getVw(32px);
                            background-color: #fff;
                        }

                        &.section-04 {
                            padding: getVh(60px) 0;
                            .img-slide {
                                padding-left: getVw(60px);
                            }
                        }

                        &.section-05 {
                            overflow: hidden;
                            background-color: #fff;
                            padding: getVh(100px) getVw(32px);
                        }
                    }
                }
            }
            // (E) bentley

            // (S)ekolon
            &.ekolon {
                background-color: #fff;
                section {
                    position: relative;
                    overflow: hidden;
                    &.section-01 {
                        padding: getVh(211px) 0;
                        .img-bg {
                            position: absolute;

                            &.img-bg-01 {
                                width: getVw(176px);
                                right: 0;
                                top: 0;
                                margin-top: getVh(120px);
                            }
                            

                            &.img-bg-02 {
                                width: getVw(45px);
                                top: 0;
                                left: 0;
                                margin-left: getVw(89px);
                                margin-top: getVh(156px);
                            }

                            &.img-bg-03 {
                                width: getVw(77px);
                                top: 0;
                                left: 0;
                                margin-top: getVh(209px);
                            }

                            &.img-bg-04 {
                                width: getVw(168px);
                                top: 0;
                                left: 0;
                                margin-top: getVh(420px);
                            }

                            &.img-bg-05 {
                                width: getVw(45px);
                                top: 0;
                                right: 0;
                                margin-top: getVh(439px);
                                margin-right: getVw(36px);
                            }

                            &.img-bg-06 {
                                width: getVw(128px);
                                top: 0;
                                right: 0;
                                margin-top: getVh(532px);
                                margin-right: getVw(12px);
                            }
                        }

                        .img-01 {
                            position: relative;
                            z-index: 1;
                            width: getVw(216px);
                            margin: 0 auto;
                            transform: translate(#{getVw(4px)}, #{getVw(-6px)});
                        }
                    }

                    &.section-02 {
                        padding: getVh(136px) 0 getVh(114px);
                        background-color: #F6F7F8;
                        .img-01 {
                            width: getVw(216px);
                            margin: 0 auto;
                            transform: translate(#{getVw(4px)}, #{getVw(-16px)});
                        }
                    }

                    &.section-03 {
                        padding: getVh(136px) 0 getVh(114px);
                        background-color: #B5B5BB;
                        .img-01 {
                            width: getVw(216px);
                            margin: 0 auto;
                            transform: translate(#{getVw(4px)}, #{getVw(-16px)});
                        }
                    }

                    &.section-04 {
                        padding: getVh(136px) 0 getVh(118px);
                        
                        background: {
                            image: url("../../../public/site/res/image/mobile/ekolon/img_04_bg.png");
                            size: cover;
                            repeat: no-repeat;
                        }

                        .img-01 {
                            width: getVw(216px);
                            margin: 0 auto;
                            transform: translate(#{getVw(4px)}, #{getVw(-16px)});
                        }
                    }

                    &.section-05 {
                        display: flex;
                        align-items: center;
                        padding: getVh(136px) 0 getVh(120px);

                        &::before {
                            content: "";
                            display: block;
                            width: 100%;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            height: getVh(674px);
                            background-color: #F6F7F8;
                        }                        
                    }

                    &.section-06 {
                        padding-bottom: getVh(300px);
                        .marquee-container {
                            .marquee-wrap {
                                animation-duration: 15s;
                                ul {
                                    gap: 0 getVw(24px);
                                    padding: getVw(12px);

                                    li {
                                        width: getVw(131px);
                                    }
                                }
                            }
                        }
                    }

                    &.section-07 {
                        display: flex;
                        padding: getVh(96px) 0;

                        .img-wrap {
                            transform: translateX(getVw(4px));
                            & ~ .img-wrap {
                                transform: translateX(getVw(-16px));

                                .Fadeup:nth-child(1) {
                                    margin-top: getVh(82px);
                                }
                            }
                        }

                        .Fadeup {
                            width: getVw(188px); 
                        }
                    }

                    &.section-08 {
                        padding: getVh(80px) getVw(94px);
                        background-color: #1B1B1B;
                    }
                }
            }
            // (E)ekolon

            // (S) lexus
            &.lexus {
                background: #F6F6F6;
            
                .project-contents {
                    section {
                        position: relative;
                        overflow: hidden;
                        &.section-01 {
                            padding: getVh(128px) getVw(16px) 0;
                            .vid-01 {
                                
                                iframe {
                                    height: getVw(185px);
                                }
                            }

                            .img-wrap {
                                display: flex;
                                gap: 0 getVw(7px);
                                margin-top: getVh(40px);
                                
                                .Fadeup {
                                    flex: 1 1;
                                }
                            }
                        }

                        &.section-02 {
                            padding: getVh(128px) 0;
                            background: {
                                image: url("../../../public/site/res/image/mobile/lexus/img_02_bg.png");
                                size: contain;
                                position: center;
                                repeat: no-repeat;
                            }

                            .img-01 {
                                width: getVw(140px);
                                margin-left: getVw(16px);
                            }

                            .img-02 {
                                position: absolute;
                                width: getVw(170px);
                                top: 0;
                                right: 0;
                                margin-top: getVw(94px);
                            }
                            .img-03 {
                                position: absolute;
                                width: getVw(221px);
                                top: 0;
                                right: 0;
                                margin-top: getVh(200px);
                            }
                        }

                        &.section-03 {
                            padding: 0 getVw(16px);

                            .img-wrap {
                                transform: translate(#{getVw(21px)}, #{getVh(-14px)});

                                .img-02 {
                                    margin-top: getVh(-75px);
                                }
                                .img-03 {
                                    margin-top: getVh(-146px);
                                }
                            }
                        }

                        &.section-04 {
                            .img-01 {
                                position: relative;
                                width: getVw(140px);
                                margin: 0 auto;
                                z-index: 1;
                            }
                            .marquee-container {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                .marquee-wrap {
                                    animation-duration: 15s;

                                    ul {
                                        padding: 0 getVw(10.5px);
                                        gap: 0 getVw(21px);
                                    }
                                }
                            }
                        }

                        &.section-05 {
                            padding-top: getVh(128px);
                            padding-bottom: getVh(309px);

                            &::before {
                                content: "";
                                display: block;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                width: getVw(755px);
                                height: getVw(755px);
                                border-radius: 100%;
                                background-color: #ECECEC;
                            }

                            .img-01 {
                                width: getVw(250px);
                                margin: 0 auto;
                            }

                            .img-container {
                                margin-top: getVh(171px);
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;

                                .img-02 {
                                    width: getVw(233px);
                                    flex-shrink: 0;
                                }
                                .img-wrap {
                                    position: absolute;
                                    right: 0;
                                    width: getVw(233px);
                                    flex-shrink: 0;

                                    .img-04 {
                                        margin-top: getVh(-102px);
                                    }
                                }
                            }
                        }

                        &.section-06 {
                            padding-bottom: getVh(253px);
                            .img-01 {
                                position: relative;
                                width: getVw(140px);
                                margin: 0 auto;
                                margin-top: getVh(72px);
                                z-index: 1;
                            }

                            .img-bg-01 {
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: getVw(176px);
                            }
                            .img-bg-02 {
                                position: absolute;
                                width: getVw(261px);
                                left: 0;
                                top: 0;
                                margin-top: getVh(260px);
                                z-index: 2;
                            }

                            .img-bg-03 {
                                position: absolute;
                                width: getVw(154px);
                                right: 0;
                                top: 0;
                                margin-top: getVh(401px);
                            }
                        }

                        &.section-07 {
                            display: flex;
                            align-items: center;
                            background: {
                                image: url("../../../public/site/res/image/mobile/lexus/img_07_bg.png");
                                size: contain;
                                position: top;
                                repeat: no-repeat;
                            };

                            .img-wrap {
                                position: relative;
                                width: getVw(233px);
                                flex-shrink: 0;
                                z-index: 1;

                                .img-02 {
                                    margin-top: getVh(-109px);
                                }
                            }
                            
                            .img-03 {
                                position: absolute;
                                right: 0;
                                width: getVw(233px);
                                flex-shrink: 0;
                                z-index: 1;
                            }

                            &::after {
                                content: "";
                                display: block;
                                position: absolute;
                                width: 100%;
                                height: getVh(782px);
                                bottom: 0;
                                left: 0;
                                background: linear-gradient(180deg, rgba(246, 246, 246, 0.00) 0%, rgba(214, 214, 214, 0.60) 25.51%, #878787 58.85%, #141414 100%);
                            }
                        }

                        &.section-08 {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding-top: getVh(221px);
                            background-color: #141414 ;
                            background: {
                                image: url("../../../public/site/res/image/mobile/lexus/img_08_bg.png");
                                size: contain;
                                repeat: no-repeat;
                                position: bottom;
                            }

                            .img-01 {
                                width: getVw(155px);
                            }
                            .img-02 {
                                width: getVw(190px);
                                margin-top: getVh(48px);
                            }
                        }
                    }
                }
            }
            // (E) lexus

            // (S) picks
            &.picks {
                background-color: #fff;
                .project-cover {
                    .cover-text-wrap {
                        padding-bottom: getVh(304px);
                    }
                }
                .project-contents {
                    section {
                        position: relative;

                        &.section-01 {
                            display: flex;
                            flex-direction: column;
                            padding-bottom: getVh(121px);
                            
                            .img-01 {
                                position: relative;
                                width: getVw(270px);
                                z-index: 1;
                                margin: 0 auto;
                                margin-top: getVh(-184px);
                                > .img-wrap {
                                    &::before {
                                        content: "";
                                        display: block;
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        top: 0;
                                        left: 0;
                                        background-color: #191919;
                                        border-radius: getRem(8px);
                                        z-index: -1;
                                        transition: transform 0.3s;
                                    }
                                }
                                &.end {
                                    .img-wrap {
                                        &::before {
                                            transform: translate(#{getVw(10px)},#{getVw(10px)});
                                        }
                                    }
                                }
                            }

                            .vid-01 {
                                margin-top: getVh(92px);
                                margin-left: getVw(24px);
                                .vid-wrap {
                                    width: getVw(194px);
                                    border-radius: getRem(16px);
                                    overflow: hidden;
                                }

                                .vid-title {
                                    @include fontStyle(18px, 500, 1.2, -0.54px);
                                    margin-top: getVh(10px);
                                }
                            }

                            .img-02 {
                                width: getVw(280px);
                                align-self: flex-end;
                                margin-top: getVh(78px);
                            }

                            > .img-wrap {
                                display: flex;
                                flex-direction: column;
                                align-self: center;
                                gap: getVh(24px) 0;
                                width: getVw(312px);
                                margin-top: getVh(119px);
                            }
                        }

                        &.section-02 {
                            padding: getVh(73px) getVw(24px) getVh(152px);
                            background-color: #000;

                            .img-container {
                                display: flex;
                                gap: 0 getVw(16px);
                                margin-top: getVh(56px);
                                
                                > * {
                                    flex: 1 1;
                                }

                                .img-wrap {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;
                                }
                            }


                            .img-wrap-01 {
                                display: flex;
                                flex-direction: column;
                                gap: getVh(24px) 0;
                                margin-top: getVh(56px);
                            }
                        }

                        &.section-03 {
                            background-color: #000;
                            padding-bottom: getVh(102px) ;
                            .title {
                                @include fontStyle(32px, 700, 1.2, -2.88px);
                                color: #fff;
                                margin-left: getVw(24px);
                                margin-bottom: getVh(48px);
                            }
                        }

                        &.section-04 {
                            &::before {
                                content: "";
                                display: block;
                                position: absolute;
                                top: 0;
                                left: 0;
                                margin-top: getVh(332px);
                                width: 100%;
                                height: getVh(261px);
                                background-color: #EB128D;
                            }
                            padding: getVh(61px) 0 getVh(188px);
                            overflow: hidden;

                            .img-01 {
                                width: getVw(336px);
                            }

                            .img-02 {
                                position: absolute;
                                bottom: 0;
                                right: 0;
                                width: getVw(336px);
                            }
                        }
                    }
                }
            }
            // (E) picks

            // (S) stylerpick
            &.stylerpick {
                background-color: #FFFCF9;
                .project-contents {
                    section {
                        position: relative;
                        overflow: hidden;

                        .line {
                            height: 1px;
                            background-color: #000;
                            margin: getVh(64px) 0;
                        }

                        &.section-01 {
                            padding: getVw(80px) 0;
                            border-top: 1px solid #000;
                            border-bottom: 1px solid #000;
                            .img-wrap {
                                padding: 0 getVw(32px);
                            }
                        }

                        &.section-02 {
                            display: flex;
                            flex-direction: column;
                            padding: getVh(48px) 0 getVh(80px);

                            .img-01 {
                                width: getVw(238px);
                            }
                            .img-02 {
                                position: relative;
                                width: getVw(224px);
                                align-self: center;
                                margin-top: getVh(-48px);
                                z-index: 1;
                            }
                            .img-03 {
                                width: getVw(180px);
                                align-self: flex-end;
                                margin-top: getVh(-200px);
                            }
                        }

                        &.section-03 {
                            display: flex;
                            flex-direction: column;
                            padding: getVh(80px) getVw(16px);
                            border-top: 1px solid #000;

                            .img-01, 
                            .img-03, 
                            .img-04 {
                                width: getVw(224px);
                                margin: 0 getVw(16px);
                            }
                            .img-01 {
                                position: relative;
                                z-index: 1;
                            }

                            .img-02 {
                                margin-top: getVh(-78px);
                            }

                            .img-03 {
                                align-self: flex-end;
                                margin-top: getVh(-28px);

                            }
                            .img-04 {
                                margin-top: getVh(120px);
                            }

                            .img-05 {
                                width: getVw(232px);
                                align-self: flex-end;
                                margin-top: getVh(-198px);
                            }
                        }

                        &.section-04 {
                            padding: getVh(80px) getVw(32px);
                            border-top: 1px solid #000;
                            border-bottom: 1px solid #000;

                            .vid-01 {
                                border-radius: getRem(300px) getRem(300px) 0px 0px;
                                border: 1px solid #000;
                                overflow: hidden;
                            }
                        }

                        &.section-05 {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding: getVh(80px) 0;

                            .img-01 {
                                width: getVw(295px);
                            }
                            
                            .img-02 {
                                width: getVw(170px);
                                margin-top: getVh(56px);
                                margin-left: getVw(33px);
                                align-self: flex-start;
                            }
                            .img-wrap {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;
                                align-self: flex-end;
                                margin-top: getVh(56px);

                                .img-03 {
                                    width: getVw(179px);

                                    & ~ .Fadeup {
                                        margin-top: getVh(-30px);
                                    }
                                }
                                .img-04 {
                                    width: getVw(230px);
                                    margin-right: getVw(28px);
                                }
                                .img-05 {
                                    width: getVw(230px);
                                    margin-right: getVw(93px);
                                }
                            }
                        }

                        &.section-06 {
                            display: flex;
                            flex-direction: column;
                            padding-bottom: getVh(220px);

                            .img-01 {
                                width: getVw(305px);
                                align-self: flex-end;
                            }

                            .img-02 {
                                width: getVw(233px);
                                margin-left: getVw(25px);
                                margin-top: getVh(-51px);
                            }
                            
                            .img-03 {
                                width: getVw(224px);
                                margin-right: getVw(32px);
                                align-self: flex-end;
                                margin-top: getVh(-132px);
                            }

                            .img-04 {
                                width: getVw(328px);
                                align-self: center;
                                margin-top: getVh(220px);
                            }

                            .img-05 {
                                width: getVw(234px);
                                margin-top: getVh(-67px);
                                margin-left: getVw(32px);
                            }

                            .img-06 {
                                width: getVw(211px);
                                align-self: flex-end;
                                margin-right: getVw(32px);
                                margin-top: getVh(-22px);
                            }
                        }

                        &.section-07 {
                            padding: getVh(48px) getVw(32px) getVh(68px);
                            border-top: 1px solid #000;

                            .img-02 {
                                width: getVw(172px);
                                margin: 0 auto;
                            }

                            .img-03 {
                                width: getVw(172px);
                                margin: getVh(24px) auto 0;
                            }
                        }
                    }
                }
            }
            // (E) stylerpick

            // (S) toyota
            &.toyota {
                background-color: #1F1F1F;
                .cover-img-wrap {
                    img {
                        object-position: bottom;
                    }
                }

                .project-contents {
                    section {
                        .txt {
                            @include fontStyle(32px, 500, 1.2, -0.96px);
                            color: #fff;
                            text-align: center;
                        }
                        &.section-01 {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding-top: getVh(80px);
                            padding-bottom: getVh(140px);
                            background: {
                                image: url("../../../public/site/res/image/mobile/toyota/img_01_bg.png");
                                repeat: no-repeat;
                                size: contain;
                            }

                            .vid-01 {
                                width: getVw(331px);
                                margin-top: getVh(56px);
                            }
                            .img-01 {
                                width: getVw(331px);
                                margin-top: getVh(16px);
                            }

                            .img-02 {
                                position: relative;
                                z-index: 1;
                                width: getVw(82px);
                                align-self: flex-start;
                                margin-left: getVw(31px);
                                margin-top: getVh(104px);
                            }

                            .img-03, .img-05 {
                                width: getVw(288px);
                                align-self: flex-end;
                            }
                            
                            .img-03 {
                                margin-top: getVh(-85px);
                                
                                
                                & ~ .Fadeup:not(.img-07) {
                                    margin-top: getVh(64px);
                                }
                            }

                            .img-04, .img-06 {
                                width: getVw(282px);
                                align-self: flex-start;
                                margin-left: getVw(24px);
                            }

                            .img-07 {
                                width: getVw(328px);
                                margin-top: getVh(104px);
                            }
                        }

                        &.section-02 {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding: getVh(80px) 0;
                            overflow: hidden;
                            background: {
                                image: url("../../../public/site/res/image/mobile/toyota/img_02_bg.png");
                                repeat: no-repeat;
                                size: 100%;
                            }

                            .vid-01 {
                                width: getVw(328px);
                                margin-top: getVh(56px);
                            }
                        }
                        
                        &.section-03 {
                            position: relative;
                            overflow: hidden;
                            padding: getVh(236px) 0;

                            &::before {
                                content: "";
                                display: block;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                width: getVw(530px);
                                height: getVw(530px);
                                background-color: #000;
                                border-radius: 100%;
                            }

                            .marquee-container {
    
                                .marquee-wrap {
                                    animation-duration: 20s;
                                    ul {
                                        gap: 0 getVw(40px);
                                        padding: 0 getVw(20px);
    
                                        li {
                                            width: getVw(282px);
                                        }
                                    }
                                }
                            }
                        }

                        &.section-04 {
                            padding-bottom: getVh(117px);
                            .img-02 {
                                width: getVw(191px);
                                margin-top: getVh(-77px);
                                margin-left: getVw(16px);
                            }


                            .img-wrap {
                                margin-top: getVh(64px);
                                padding: 0 getVw(33px);

                                .Fadeup ~ .Fadeup {
                                    margin-top: getVw(58px);
                                }
                            }
                        }

                        &.section-05 {

                            .img-01 {
                                width: getVw(328px);
                                margin: 0 auto;
                            }

                            .img-02 {
                                width: getVw(250px);
                                margin: getVh(75px) auto getVh(83px);
                            }
                        }
                    }
                }
            }
            // (E) toyota

            // (S) wego
            &.wego {
                background-color: #fff;
                section {
                    position: relative;
                    overflow: hidden;

                    &.section-01 {
                        padding-top: getVh(82px);
                        background: {
                            image: url("../../../public/site/res/image/mobile/wego/img_01_bg.png");
                            size: 100%;
                            repeat: no-repeat;
                        };
                        
                        .img-container {
                            display: flex;
    
                            .img-wrap {
                                display: flex;
                                flex-direction: column;
                                width: 50%;
        
                                .Fadeup {
                                    width: getVw(221px);
                                    & ~ .Fadeup {
                                        margin-top: getVh(-49px);
                                    }
                                }
        
                                & ~ .img-wrap {
                                    align-items: flex-end;
                                    margin-top: getVh(144px);
                                }
                            }
                        }
    
                        .img-07 {
                            width: getVw(254px);
                            margin-left: getVw(24px);
                            margin-top: getVh(83px);
                        }
    
                        .img-noti-wrap {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            gap: getVh(20px) 0;
    
                            .Fadeup {
                                transform: translateY(getVh(-43px));
                            }
    
                            .img-08 {
                                width: getVw(336px);
                                margin-left: getVw(35px);
                                margin-top: getVh(-32px);
                            }
                            .img-09 {
                                width: getVw(294px);
                                margin-left: getVw(65px);
                                margin-top: getVh(-97px);
                            }
                            .img-10 {
                                width: getVw(294px);
                                margin-left: getVw(65px);
                                margin-top: getVh(-97px);
                            }
                        }
                    }
    
                    &.section-02 {
                        padding-bottom: getVh(79px);
                        background: {
                            image: url("../../../public/site/res/image/mobile/wego/img_02_bg.png");
                            size: 100%;
                            repeat: no-repeat;
                        };
                        .img-01 {
                            width: getVw(265px);
                            margin: getVh(155px) auto 0;
                        }

                        .img-wrap {
                            display: flex;

                            .Fadeup {
                                flex-shrink: 0;
                                width: getVw(216px);
                            }

                            .img-03 {
                                position: absolute;
                                right: 0;
                                margin-top: getVh(28px);
                            }
                        }
                    }

                    &.section-03 {
                        .img-wrap {
                            display: flex;
                            flex-direction: column;
                            gap: getVh(24px) 0;
                            padding: 0 getVw(24px);
                        }

                        .img-05 {
                            width: getVw(176px);
                            margin: getVh(80px) auto;
                        }
                    }
                }
            }
            // (S) wego
        }
        // (E) work-detail-wrap
    }
}