// 단위 제거
@function deUnit($value) {
    @return ($value / ($value * 0 + 1));
}

@function getUnit($value) {
    @return str-slice($value * 0 + "", 2, -1);
}

@function getPercent($parentValue, $value) {
    @return ((deUnit($value) / deUnit($parentValue)) * 100) * 1%;
}

@function getRem($value) {
    @return deUnit($value / 16) * 1rem;
}


@function getVw($px, $base-vw: 390px){
    @return($px * 100vw) / $base-vw;
}

@function getVh($px, $base-vh: 844px){
    @return($px * 100vh) / $base-vh;
}

@font-face {
    font-family: 'Pretendard';
    src: url('../../res/font/Pretendard-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Pretendard';
    src: url('../../res/font/Pretendard-ExtraLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Pretendard';
    src: url('../../res/font/Pretendard-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Pretendard';
    src: url('../../res/font/Pretendard-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Pretendard';
    src: url('../../res/font/Pretendard-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Pretendard';
    src: url('../../res/font/Pretendard-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Pretendard';
    src: url('../../res/font/Pretendard-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Pretendard';
    src: url('../../res/font/Pretendard-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Pretendard';
    src: url('../../res/font/Pretendard-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'EuclidCircularA';
    src: url('../../res/font/EuclidCircularA-Light-WebS.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'EuclidCircularA';
    src: url('../../res/font/EuclidCircularA-Regular-WebS.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'EuclidCircularA';
    src: url('../../res/font/EuclidCircularA-Medium-WebS.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'EuclidCircularA';
    src: url('../../res/font/EuclidCircularA-Semibold-WebS.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
}

$font-ko: 'Pretendard', sans-serif;
$font-en: 'EuclidCircularA', sans-serif;

$vh100: calc(var(--vh, 1vh) * 100);
$vh-100: calc(var(--vh, 1vh) * -100);

@mixin fontStyle(
    $fontSize,
    $fontWeight,
    $lineHeight: 140%,
    $letterSpacing: -0.02em
) {
    font-size: deUnit($fontSize / 16) * 1rem;
    font-weight: $fontWeight;
    letter-spacing: $letterSpacing;

    @if (getUnit($lineHeight) == "px") {
        line-height: deUnit($lineHeight / deUnit($fontSize));
    } @else {
        line-height: $lineHeight;
    }
}

@mixin reactive {
    &::after {
        content: "";
        display: block;
    }

    > .contents {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@mixin blur($blur: 5px) {
    backdrop-filter: blur($blur);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    -webkit-transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000;
    transform: translateZ(0);
    will-change: transform;
}

@mixin popupHeader(){
    background-color: rgba(7, 7, 7, 0.3);
    padding: getRem(8.5) 0;
    text-align: center;

    .close-btn {
        cursor: pointer;
        position: absolute;
        width: 17px;
        height: 17px;
        border: none;
        border-radius: 50%;
        background-color: rgba(237, 105, 94, 1);
        left: getPercent(390, 9);
        top: getRem(6.5px);
        padding: 0;

        &:hover {
            background-color: rgba(252, 96, 92, 0.5);
        }
    }

    p{
        font-family: $font-en;
        @include fontStyle(11px, 300, 13.2px, -0.02em);
        color: #fff;
        text-shadow: 0px 0.9466016292572021px 2.8398051261901855px #00000066;
        ;
    }
}

@mixin gradientText() {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@keyframes scrollviewLoop0 {
    0% {
        transform: translateY(0)
    }

    10% {
        transform: translateY(0)
    }

    90% {
        transform: translateY(-81%)
    }

    100% {
        transform: translateY(0%)
    }
}

@mixin scrollviewLoop($animationIdx, $translateVal) {
    @keyframes scrollviewLoop#{$animationIdx} {
        0% {
            transform: translateY(0);
        }
        10% {
            transform: translateY(0);
        }
        90% {
            transform: translateY(#{$translateVal});
        }
        100% {
            transform: translateY(0%);
        }
    }
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}