@import "_mixins";

html {
    &.lock {
        height: calc(var(--vh, 1vh) * 100);
        overflow: hidden;
    }

    div {
        box-sizing: border-box;
    }
    body {
        font-family: $font-ko;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera*/
        }
    }

    .Pc {
        .text-container {
            width: fit-content;
            display: flex;
            flex-direction: column;

            &.en {
                .Typography,
                > span {
                    font-family: $font-en;
                }
            }

            .Typography,
            > span {
                font-family: $font-ko;
                @include fontStyle(16px, 300, 34px, -0.05em);

                &.right {
                    text-align: right;
                }
            }
        }

        .global-logo {
            display: flex;
            width: 100%;
            padding: 0 getPercent(1920, 89);

            div {
                position: relative;
                overflow: hidden;
                flex-shrink: 0;

                svg {
                    width: 100%;
                    height: auto;
                }

                // a
                &:nth-child(1) {
                    width: getPercent(1742, 234.953);
                    margin-top: getPercent(1742, 92.5);
                }
                // p
                &:nth-child(2) {
                    width: getPercent(1742, 235.132);
                    margin-left: getPercent(1742, 54.62);
                    margin-top: getPercent(1742, 92.5);
                }
                // p
                &:nth-child(3) {
                    width: getPercent(1742, 235.042);
                    margin-left: getPercent(1742, 37.61);
                    margin-top: getPercent(1742, 92.5);
                }
                // k
                &:nth-child(4) {
                    width: getPercent(1742, 219.821);
                    margin-left: getPercent(1742, 37.88);
                }
                // n
                &:nth-child(5) {
                    width: getPercent(1742, 208.359);
                    margin-left: getPercent(1742, 26.77);
                    margin-top: getPercent(1742, 92.5);
                }
                // o
                &:nth-child(6) {
                    width: getPercent(1742, 241.221);
                    margin-left: getPercent(1742, 36.98);
                    margin-top: getPercent(1742, 92.5);
                }
                // t
                &:nth-child(7) {
                    width: getPercent(1742, 164.948);
                    margin-left: getPercent(1742, 9.85);
                }
            }
        }

        .Gnb {
            z-index: 99;
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            color: #000;
            padding: 0 getRem(30);
            box-sizing: border-box;

            &.white {
                .header-container {
                    .full {
                        li {
                            a {
                                color: #fff;
                            }
                        }
                    }

                    .logo {
                        svg {
                            path {
                                fill: #fff;
                            }
                        }
                    }

                    .copy-right {
                        color: #fff;
                    }
                }
            }

            &.on {
                .header-container {
                    .logo {
                        > svg {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }

                    .nav {
                        .bg {
                            opacity: 1;
                            transform: translateY(0) !important;
                        }
                    }

                    ul {
                        &.full {
                            width: getPercent(1920, 416);

                            li {
                                opacity: 0;
                                transform: none;
                            }
                        }

                        &:not(.full) {
                            opacity: 1;
                            visibility: visible;
                        }
                    }

                    .copy-right {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }

            .header-container {
                position: relative;
                width: 100%;
                display: flex;
                justify-content: center;

                .logo {
                    display: block;
                    position: absolute;
                    left: getPercent(1920, 30);
                    top: 50%;
                    transform: translateY(-50%);
                    box-sizing: border-box;
                    width: getPercent(1920, 80);

                    > svg {
                        width: 100%;
                        height: 100%;
                        transform: translateY(-100%);
                        transition:
                            transform 1.3s,
                            opacity 1.3s;
                        opacity: 0;

                        path {
                            transition: fill 0.4s;
                        }
                    }
                }

                .nav {
                    position: relative;
                    margin: getRem(20) 0;
                    padding: 0 getPercent(1920, 5);
                    transition: width 0.5s cubic-bezier(0.4, 0, 0.2, 1);

                    .bg {
                        opacity: 0;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        position: absolute;
                        border-radius: 10px;
                        border: 1px solid #a1a1a166;
                        background: linear-gradient(#a1a1a166, #65656566);
                        @include blur(10px);
                        transform: translateY(-100%);
                        transition:
                            transform 0.5s,
                            opacity 0.2s;
                    }
                }

                ul {
                    display: flex;
                    justify-content: space-between;

                    &:not(.full) {
                        position: relative;
                        z-index: 1;
                        opacity: 0;
                        visibility: hidden;
                        transition: opacity 0.3s;
                        transition-delay: 0.2s;

                        li {
                            a {
                                color: #fff;
                            }
                        }
                    }

                    &.full {
                        position: absolute;
                        width: 100%;
                        left: 50%;
                        top: 0;
                        transform: translate(-50%, 0);
                        transition: width 0.5s cubic-bezier(0.4, 0, 0.2, 1);
                        li {
                            transition:
                                transform 0.5s cubic-bezier(0.4, 0, 0.2, 1),
                                opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1);
                            transform: translate3d(0, 20px, 0);
                        }
                    }

                    li {
                        a {
                            display: block;
                            height: getRem(38px);
                            color: #000;
                            text-decoration: none;
                            font-family: $font-en;
                            @include fontStyle(14px, 300, 17.75px, -0.02em);
                            line-height: getRem(38px);
                            transition: color 0.5s;
                            padding: 0 getRem(15px);
                        }
                    }
                }

                .copy-right {
                    opacity: 0;
                    transform: translateY(-100%);
                    position: absolute;
                    right: 0;
                    top: getRem(30);
                    transition:
                        opacity 0.4s,
                        transform 1.3s,
                        color 0.4s;
                    font-family: $font-en;
                }
            }
        }

        .MainPage {
            position: relative;
            .MainSectionIntro {
                z-index: 2;
                position: fixed;
                top: 0;
                width: 100%;
                height: 100vh;

                & ~ * {
                    position: relative;
                }
                .MatterJs {
                    -ms-overflow-style: none; /* IE and Edge */
                    scrollbar-width: none; /* Firefox */
                    ::-webkit-scrollbar {
                        display: none; /* Chrome, Safari, Opera*/
                    }
                    #canvas {
                        border: none !important;
                    }
                }
            }

            .MainIntroText {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                z-index: 3;
                width: 100%;
                margin-top: calc(100vh - 23.095703125vw);
                position: sticky;
                top: getPercent(1920, 30);
                pointer-events: none;

                .main-text-wrap {
                    text-align: center;
                    width: 100%;
                }

                .what-we-do {
                    position: absolute;
                    font-family: $font-en;
                    @include fontStyle(10px, 300, 13px, -0.02em);
                    color: #000;
                    left: 50%;
                    transform: translate(-50%, -100%);
                    margin-top: getPercent(1920, -104);
                }

                .greeting-msg {
                    position: absolute;
                    font-family: $font-en;
                    @include fontStyle(20px, 300, 26px, -0.02em);
                    color: #000;
                    text-transform: uppercase;
                    margin-top: getPercent(1920, -19);
                    left: 50%;
                    transform: translate(-50%, -100%);
                }

                .global-logo {
                    position: relative;
                    transform-origin: left top;
                    transition: transform 1.3s;

                    &.on {
                        transform: scale(0.0416) translate(#{getPercent(80, 29)}, 50%) !important;
                    }

                    path {
                        transition: fill 0.5s;
                    }

                    &.white {
                        svg {
                            path {
                                fill: #fff;
                            }
                        }
                    }
                }
            }

            .MainSectionAbout {
                width: 100%;
                @include reactive;

                &::after {
                    padding-bottom: calc(150vh + getPercent(1920, 2256));
                }

                .contents-wrap {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;

                    .about-bg-container {
                        height: 250vh;
                        .about-bg {
                            position: sticky;
                            top: 0;
                            height: 100vh;
                            z-index: 2;

                            .clip-container {
                                position: absolute;
                                overflow: hidden;
                                left: 50%;
                                width: 100%;
                                -webkit-transform: translate(-50%, getRem(73px)) scale((deUnit(738) / deUnit(1920)));
                                transform: translate(-50%, getRem(73px)) scale((deUnit(738) / deUnit(1920)));
                                transform-origin: top;
                                transition: transform 0.4s;
                                will-change: transform;
                                border-radius: getRem(15px);
                                background-color: #000;

                                img {
                                    display: block;
                                    width: 100%;
                                }
                            }
                        }
                    }

                    .slogan {
                        position: absolute;
                        top: 0;
                        margin-top: calc(150vh + getPercent(1920, 465));
                        width: 100%;
                        overflow: hidden;
                        z-index: 3;
                        mix-blend-mode: difference;

                        .marquee-wrap {
                            display: flex;
                            animation: marquee 25s linear infinite forwards;
                            width: fit-content;

                            .slogan-text {
                                position: relative;
                                width: fit-content;
                                flex-shrink: 0;
                                font-family: $font-en;
                                @include fontStyle(376px, 500, 130%, 0);
                                font-size: getVw(376px);
                                text-transform: uppercase;
                                color: #393939;
                                padding: 0 getRem(50px);
                            }
                        }

                        @keyframes marquee {
                            0% {
                                transform: translateX(0);
                            }
                            100% {
                                transform: translateX(-50%);
                            }
                        }
                    }

                    .about-contents {
                        margin-top: -100vh;
                        width: 100%;

                        .contents {
                            position: relative;
                            z-index: 2;
                        }

                        .Typography {
                            &.unleashing {
                                margin-left: getPercent(1920, 817);
                            }

                            &.innovation {
                                margin-top: getPercent(1920, -50);
                                margin-left: getPercent(1920, 191);
                            }

                            &.with-a {
                                margin-top: getPercent(1920, 681);
                                margin-left: getPercent(1920, 841);
                            }

                            &.touch-of {
                                margin-top: getPercent(1920, -50);
                                margin-left: getPercent(1920, 1130);
                            }

                            &.geekiness {
                                right: 0;
                                margin-top: getPercent(1920, -50);
                                margin-left: getPercent(1920, 741);
                            }
                        }

                        .small-msg-01 {
                            margin-top: getPercent(1920, -80);
                            margin-left: getPercent(1920, 1260);
                        }

                        .small-msg-02 {
                            position: absolute;
                            margin-top: getPercent(1920, 653);
                            left: 0;
                            margin-left: getPercent(1920, 336);
                        }

                        .about-video {
                            display: block;
                            position: relative;
                            top: getRem(113);
                            left: getPercent(1920, 291);
                            width: getPercent(1920, 392);
                            text-decoration: none;

                            .video-wrap {
                                position: relative;
                                width: 100%;
                                padding-bottom: getPercent(392, 222);
                                border-radius: 15px;
                                overflow: hidden;
                                transform: translateZ(0);

                                video {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }

                            .video-text {
                                font-family: $font-en;
                                @include fontStyle(14px, 300, 17.75px, -0.02em);
                                color: #fff;
                                text-transform: uppercase;
                                margin-top: getRem(17);
                            }
                        }
                    }
                }

                & + section {
                    z-index: 2;
                }
            }

            .MainSectionWork {
                background-color: #000;
                padding-bottom: 1px;
                margin-top: -1px;

                .sticky {
                    position: sticky;
                    top: 0;
                    padding-top: getRem(100px);
                    > h3 {
                        position: absolute;
                        top: getRem(100px);
                        left: 50%;
                        transform: translateX(-50%);
                        white-space: nowrap;
                        text-transform: uppercase;
                        color: #fff;
                        z-index: 1;
                        pointer-events: none;

                        .Typography {
                            span {
                                font-family: $font-en;
                                @include fontStyle(180px, 300, 228.24px, -0.02em);
                                font-size: getVw(180px);
                            }
                        }
                    }
                    .horizontal-scroll {
                        width: 100%;
                        overflow: hidden;
                        .work-list {
                            padding: getPercent(1920, 146) getPercent(1920, 70) 0;
                            display: flex;
                            width: fit-content;
                            will-change: transform;
                            transition: transform 0.3s;
                            li.WorkList {
                                width: getVw(1120px);
                                border-radius: getRem(15);
                                overflow: hidden;
                                flex-shrink: 0;

                                & ~ li {
                                    margin-left: getRem(30px);
                                }

                                @at-root .work-tag {
                                    position: fixed;
                                    top: 0;
                                    left: 0;
                                    z-index: 2;
                                    pointer-events: none;
                                    transform: scale(0);

                                    > span {
                                        display: inline-block;
                                        border-radius: getRem(30px);
                                        border: 0.5px solid #616161;
                                        background: rgba(62, 62, 62, 0.8);
                                        @include blur(10px);
                                        overflow: hidden;
                                        white-space: nowrap;
                                        padding: getRem(15px) getRem(26px);
                                        @include fontStyle(16px, 500, 140%, -0.64px);
                                        color: #fff;
                                        transition: transform 0.1s;
                                        transform: scale(0);
                                    }

                                    &.active {
                                        > span {
                                            transform: scale(1);
                                        }
                                    }
                                }
                                .thumb-nail {
                                    position: relative;
                                    img {
                                        display: block;
                                        width: 100%;
                                    }

                                    .text-wrap {
                                        position: absolute;
                                        bottom: getRem(30px);
                                        left: getRem(30px);

                                        .title {
                                            @include fontStyle(24px, 700, 28.8px, -1.2px);
                                            color: #fff;
                                        }

                                        .sub-title {
                                            font-family: $font-en;
                                            @include fontStyle(15px, 300, 18px, -0.3px);
                                            color: #fff;
                                            margin-top: getRem(8px);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .btn-view-all {
                        display: block;
                        position: relative;
                        width: getPercent(1920, 1780);
                        padding: getPercent(1920, 30) 0;
                        margin: getPercent(1920, 30) auto 0;
                        font-family: $font-en;
                        @include fontStyle(18px, 300, 22.82px, -0.02em);
                        text-transform: uppercase;
                        color: #fff;
                        text-align: center;
                        text-decoration: none;
                        border: 1px solid #505050;
                        border-radius: getRem(15);
                        overflow: hidden;
                        isolation: isolate;

                        &:hover {
                            &::after {
                                transform: none;
                            }
                            color: #000;
                        }

                        &::after {
                            content: "";
                            display: block;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background-color: #fff;
                            top: 0;
                            left: 0;
                            transform: translate(0, 200%) rotate(5deg);
                            transition: transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
                            z-index: -1;
                            border-radius: getRem(15);
                        }
                    }
                }
            }

            .MainSectionInsight {
                z-index: 2;
                top: 0;
                height: 250vh;
                background-color: #000;

                .sticky {
                    position: sticky;
                    height: 100vh;
                    top: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    > h3 {
                        z-index: 1;
                        position: absolute;
                        width: 100%;
                        top: 50%;
                        transform: translate(0, -50%);
                        text-transform: uppercase;
                        text-align: center;
                        display: flex;
                        flex-direction: column;

                        > small {
                            font-family: $font-en;
                            @include fontStyle(20px, 300, 22px);
                            color: #fff;
                        }
                        .Typography {
                            span {
                                display: block;
                                font-family: $font-en;
                                @include fontStyle(260px, 300, 286px);
                                font-size: 13.542vw;
                                color: #fff;

                                & ~ span {
                                    margin-top: getPercent(1920, 72);
                                }
                            }
                        }
                    }

                    .card-container {
                        z-index: 2;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .card {
                            width: getPercent(1920, 376);
                            transition: transform 0.4s;
                            will-change: transform;

                            img {
                                display: block;
                                width: 100%;
                                @include blur(15px);
                                border-radius: getRem(15px);
                                filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.7));
                            }

                            &.handly {
                                position: absolute;
                                z-index: 3;
                                transform: rotate(7deg);
                            }

                            &.popee {
                                z-index: 2;
                                transform: rotate(-18.616deg);
                            }

                            &.mentor {
                                position: absolute;
                                z-index: 1;
                                transform: rotate(-5deg);
                            }
                        }
                    }
                }
            }

            .MainSectionRecruit {
                z-index: 2;
                padding-top: 0;
                background-color: #000;
                color: #fff;
                transition:
                    background-color 0.6s ease-out,
                    color 0.6s ease-out;
                overflow: hidden;

                &.on {
                    background-color: #fff;
                    color: #000;

                    .recruit-msg {
                        .Typography {
                            color: #000;
                        }
                    }
                }

                .line {
                    opacity: 0;
                    position: absolute;
                    background-color: #dcdcdc;
                    pointer-events: none;

                    &.on {
                        opacity: 1;
                    }

                    &.horizontal {
                        width: 100%;
                        height: 1px;
                        top: 0;
                        left: 0;
                    }
                    &.vertical {
                        width: 1px;
                        height: 100%;
                        left: 0;
                        top: 0;
                    }
                }

                .recruit-top {
                    position: relative;
                    text-transform: uppercase;
                    padding-bottom: 200px;

                    .recruit-msg {
                        position: relative;
                        padding-top: getPercent(1920, 137);
                        font-family: $font-en;
                        @include fontStyle(70px, 300, 84px);
                        padding-left: getPercent(1920, 47);
                        margin-left: 0;

                        .row {
                            display: flex;
                            align-items: center;

                            .emoji-wrap {
                                display: inline-block;

                                img {
                                    display: block;
                                }
                            }
                        }
                        .Typography {
                            display: block;
                            @include fontStyle(70px, 300, 84px);
                        }
                        .emoji-alien {
                            display: inline-block;
                            width: getRem(70);
                            margin-left: getRem(5);
                        }
                        .recruit-tag {
                            position: absolute;
                            bottom: getRem(50px);
                            left: getRem(526.21px);
                            transform: rotate(-12.45deg);
                            width: getRem(174px);
                            border-radius: 30px;
                            background-color: #000000;
                            @include fontStyle(16px, 500, 22.4px);
                            color: #fff;
                            padding: getRem(15px) 0;
                            text-align: center;
                            font-family: $font-ko;
                            transition: transform 0.5s;
                            z-index: 2;

                            &:hover {
                                transform: rotate(12.45deg);
                            }

                            .emoji-point {
                                display: inline-block;
                                width: getRem(20px);
                                vertical-align: middle;
                                transform: rotate(45deg);
                                margin-left: 2px;
                            }
                        }
                    }

                    .recruit-job {
                        position: absolute;
                        display: flex;
                        gap: 0 getRem(16px);
                        top: 0;
                        right: 0;
                        margin-top: getPercent(1920, 372);
                        margin-right: getPercent(1920, 369);
                        font-family: $font-en;
                        @include fontStyle(15px, 300, 18px);
                    }
                }

                .recruit-slide-box {
                    padding: 0 0 200px;
                    .recruit-slide-container {
                        width: 100%;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        perspective: 2000px;
                        cursor: ew-resize;
                        z-index: 1;
                        transform: rotate(6deg);

                        .recruit-slide-wrap {
                            transform-style: preserve-3d;
                            width: getVw(535px);
                            height: getVw(535px);
                            flex-grow: 0;
                            will-change: transform;

                            .recruit-slide {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                padding: getVw(17.5px);
                                -webkit-user-select: none;
                                -khtml-user-select: none;
                                -moz-user-select: none;
                                -o-user-select: none;
                                user-select: none;

                                .slide-content {
                                    position: relative;
                                    background: #d3d3d3;
                                    border-radius: getRem(13px);
                                    transform: scaleX(-1);
                                    width: 100%;
                                    height: 100%;
                                    &:hover {
                                        background-color: #000000;

                                        .slide-text {
                                            color: #fff;
                                        }
                                    }
                                    .slide-img {
                                        position: relative;
                                        width: getVw(274px);
                                        pointer-events: none;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);

                                        img {
                                            width: 100%;
                                            pointer-events: none;
                                        }
                                    }

                                    .slide-text {
                                        position: absolute;
                                        top: getRem(20px);
                                        left: getRem(20px);
                                        font-family: $font-en;
                                        @include fontStyle(15px, 300, 18px);
                                        text-transform: uppercase;
                                    }
                                }
                            }
                        }
                    }
                    .TeamTag {
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: fit-content;
                        display: inline-block;
                        border-radius: 30px;
                        background: linear-gradient(#9d9d9d, #a3a3a399);
                        transform: rotate(12.81deg) scale(0);
                        padding: 15px 26px;
                        border: 0.5px solid #9d9d9d;
                        @include fontStyle(16px, 500, 22.4px);
                        color: #fff;
                        font-family: $font-ko;
                        pointer-events: none;
                        @include blur(10px);
                        z-index: 2;
                        transition: opacity 0.15s;
                        pointer-events: none;

                        &.on {
                            opacity: 1;
                        }
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        display: block;
                        width: 100%;
                        height: 30vh;
                        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.97) 100%);
                    }
                }
            }
            .MainAwardMark {
                .award-mark {
                    z-index: 3;
                    position: fixed;
                    top: 50%;
                    right: getPercent(1920, 37);
                    width: getPercent(1920, 80);
                    padding-bottom: getPercent(1920, 80);
                    transform: translateY(-50%);
                    overflow: hidden;
                    border-radius: 50%;
                    &.hidden {
                        visibility: hidden;
                    }
                    img {
                        display: block;
                        width: 102%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .award-line {
                    position: fixed;
                    z-index: 3;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    width: fit-content;
                    padding: 4px 20px;
                    background-color: #000;
                    flex-wrap: nowrap;
                    transform: rotate(-46.28deg) translate(30%, 600%);
                    overflow: hidden;
                    cursor: pointer;

                    &.hidden {
                        visibility: hidden;
                    }

                    p {
                        flex-shrink: 0;
                        text-transform: uppercase;
                        font-family: $font-en;
                        @include fontStyle(12px, 300, 15.22px, -0.02em);
                        color: #fff;
                        animation: toRight 5s linear infinite forwards;
                    }
                }

                @keyframes toRight {
                    0% {
                        transform: translateX(0);
                    }
                    100% {
                        transform: translateX(50%);
                    }
                }
            }
            .MainAwardMark {
                .award-mark {
                    z-index: 3;
                    position: fixed;
                    top: 50%;
                    right: getPercent(1920, 37);
                    width: getPercent(1920, 80);
                    padding-bottom: getPercent(1920, 80);
                    transform: translateY(-50%);
                    overflow: hidden;
                    border-radius: 50%;
                    &.hidden {
                        visibility: hidden;
                    }
                    img {
                        display: block;
                        width: 102%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .award-line {
                    position: fixed;
                    z-index: 3;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    width: fit-content;
                    padding: 4px 20px;
                    background-color: #000;
                    flex-wrap: nowrap;
                    transform: rotate(-46.28deg) translate(30%, 600%);
                    overflow: hidden;
                    cursor: pointer;

                    &.hidden {
                        visibility: hidden;
                    }

                    p {
                        flex-shrink: 0;
                        text-transform: uppercase;
                        font-family: $font-en;
                        @include fontStyle(12px, 300, 15.22px, -0.02em);
                        color: #fff;
                        animation: toRight 5s linear infinite forwards;
                    }
                }

                @keyframes toRight {
                    0% {
                        transform: translateX(0);
                    }
                    100% {
                        transform: translateX(50%);
                    }
                }
            }
        }

        //solution page
        .SolutionPage {
            .SolutionSectionEntry {
                width: 100%;
                height: 100vh;
                background-color: #000;
                position: relative;

                .solution-entry-bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #000;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .solution-entry-text {
                    color: #fff;
                    font-family: $font-en;

                    &.text-01 {
                        width: fit-content;
                        position: absolute;
                        top: getRem(192px);
                        right: getRem(263px);
                        .text-en {
                            @include fontStyle(24px, 300, 31.2px);
                            text-transform: uppercase;
                        }
                        .text-ko {
                            width: fit-content;
                            margin-left: auto;
                            margin-top: getRem(29px);

                            .Typography {
                                font-family: $font-ko;
                                @include fontStyle(16px, 300, 24px, -0.05em);
                            }
                        }
                    }
                    &.text-02 {
                        position: absolute;
                        bottom: getRem(14px);
                        left: getRem(130px);
                        .we-have {
                            position: absolute;
                            top: getRem(13.37px);
                            left: getRem(381px);
                            @include fontStyle(20px, 300, 22px);
                        }
                        .solutions {
                            @include fontStyle(260px, 300, 286px);
                            font-size: getVw(260px);
                        }
                    }
                }
            }
            .SolutionSectionIntro {
                min-height: 200vh;
                background-color: #000;
                position: relative;
                transition: background-color 0.5s;

                &.white {
                    background-color: #fff;
                }

                .sticky {
                    position: sticky;
                    top: 0;
                    left: 0;
                    min-height: 100vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: getPercent(1920, 285.19) 0 getPercent(1920, 174);
                }
                .solution-intro-text {
                    z-index: 2;
                    position: relative;
                    width: getPercent(1920, 1648);
                    font-family: $font-en;
                    text-transform: uppercase;
                    overflow: hidden;
                    span {
                        font-family: $font-en;
                        @include fontStyle(24px, 700, 28.8px);
                    }
                    .show-text-box {
                        display: flex;
                        transform: translateX(getRem(-154px));
                        .hidden-num {
                            display: inline-block;
                            @include fontStyle(260px, 700, 286px);
                            color: #007dee;
                            width: getRem(154px);
                            font-size: getVw(260px);
                        }
                        h2 {
                            display: inline-block;
                            @include fontStyle(260px, 300, 286px);
                            font-size: getVw(260px);
                            margin-left: getRem(26px);
                        }
                    }
                }
                .solution-intro-text-ko {
                    z-index: 2;
                    align-self: flex-start;
                    margin-left: getPercent(1920, 322);
                    font-family: $font-ko;
                    @include fontStyle(20px, 500, 28px, -0.05em);
                    margin-bottom: getPercent(1920, 108.61);
                }
                .solution-intro-icon {
                    z-index: 2;
                    width: getPercent(1920, 1295.78);
                    .icon-wrap {
                        display: flex;
                        justify-content: space-between;
                        gap: 0 getPercent(1295, 102);

                        li {
                            flex: 1 1;
                            img {
                                width: 100%;
                                transform: rotate(3.914deg);
                            }
                        }
                    }
                }
                .tag {
                    &.handly {
                        margin-top: getPercent(1648, 157.81);
                        margin-left: getPercent(1648, 463.05);
                        > span {
                            transform: rotate(-8.2deg);
                        }
                    }
                    &.popee {
                        margin-top: getPercent(1648, 99.81);
                        margin-left: getPercent(1648, 915.55);
                        > span {
                            transform: rotate(7.82deg);
                        }
                    }
                    &.mentor {
                        margin-top: getPercent(1648, 135.81);
                        margin-left: getPercent(1648, 1214);
                        > span {
                            transform: rotate(-8.2deg);
                        }
                    }
                }
                .line {
                    opacity: 0;
                    z-index: 1;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: #dcdcdc;
                    pointer-events: none;

                    &.on {
                        opacity: 1;
                    }

                    &.horizontal {
                        width: 100%;
                        height: 1px;
                        top: 0;
                        left: 0;
                    }
                    &.vertical {
                        width: 1px;
                        height: 100%;
                        top: 0;
                        left: 0;
                    }
                }
            }
            .SolutionSectionPopee {
                background-color: #000;
                width: 100%;
                .container {
                    position: sticky;
                    top: 0;
                    width: 100%;
                    overflow: hidden;
                    .scroll-wrap {
                        display: flex;
                        width: fit-content;

                        .scroll-section {
                            position: relative;
                            flex-shrink: 0;
                            height: 100vh;

                            &.section01 {
                                width: calc(100vw + 1px);
                                &::after {
                                    content: "";
                                    display: block;
                                    position: absolute;
                                    width: getVw(376px);
                                    height: 100%;
                                    right: 0;
                                    top: 0;
                                    background: linear-gradient(
                                        -90deg,
                                        rgba(0, 0, 0, 0.97) 0%,
                                        rgba(11, 11, 11, 0) 100%
                                    );
                                }

                                .sticky {
                                    height: 100%;

                                    .bg {
                                        width: 100%;
                                        height: 100%;

                                        img {
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                        }
                                    }
                                }

                                .SolutionIntroduceIntro {
                                    color: #ff60ae;
                                    margin-top: -100vh;
                                    .num,
                                    .text {
                                        color: #ff60ae;
                                    }
                                    strong {
                                        @include fontStyle(336.47px, 300, 370.12px);
                                        div {
                                            span {
                                                color: #ff60ae;
                                            }
                                        }
                                    }
                                }
                            }
                            &.section02 {
                                background-color: #000;
                                width: getVw(2108px);

                                &::before {
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    content: "";
                                    display: block;
                                    width: getPercent(2108, 329);
                                    height: 100%;
                                    background: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, #000 100%);
                                }

                                &::after {
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    content: "";
                                    display: block;
                                    width: getPercent(2108, 957);
                                    height: 100%;
                                    background: linear-gradient(-90deg, #000 0%, rgba(0, 0, 0, 0) 100%);
                                }

                                .bg-img {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    overflow: hidden;

                                    img {
                                        display: block;
                                        width: 100vw;
                                        height: 100%;
                                    }
                                }

                                .small-msg {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    width: fit-content;
                                    margin-right: getPercent(2108, 249);
                                    margin-top: getPercent(2018, 259);
                                    z-index: 1;
                                    .text-box-top {
                                        .Typography {
                                            font-family: $font-ko;
                                            color: #ff60ae;
                                            @include fontStyle(35px, 700, 45.5px, -0.05em);
                                        }
                                    }
                                    .text-box-bottom {
                                        margin-top: getPercent(2180, 30);
                                        .Typography {
                                            font-family: $font-ko;
                                            color: #ff60ae;
                                            @include fontStyle(18px, 500, 30.6px, -0.05em);
                                        }
                                    }
                                }

                                .popee-info-box {
                                    z-index: 2;
                                    position: absolute;
                                    width: getPercent(2108, 832.89);
                                    left: 0;
                                    bottom: 0;
                                    margin-bottom: getPercent(2108, 66);
                                    margin-left: getPercent(2108, 194);

                                    ul {
                                        width: 100%;
                                        display: flex;
                                        justify-content: space-between;
                                        gap: 0 getVw(24px);

                                        li {
                                            position: relative;
                                            width: getVw(430px);
                                            flex-shrink: 0;
                                            flex-grow: 0;

                                            img {
                                                display: block;
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                                .big-title {
                                    z-index: 1;
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    margin-bottom: getVw(46px);
                                    margin-left: getVw(118.32px);
                                    color: #412935;
                                    width: getVw(1684px);

                                    img {
                                        display: block;
                                        width: 100%;
                                    }
                                }
                                .painter-bg {
                                    position: absolute;
                                    top: 0;
                                    right: -1px;
                                    display: inline-block;
                                    width: getPercent(2108, 107);
                                    height: 400%;
                                    z-index: 1;
                                    margin-right: -1px;
                                    transform-origin: right;
                                    animation: move_wave 5s forwards infinite;
                                    animation-play-state: paused;
                                    @keyframes move_wave {
                                        0% {
                                            transform: translateY(0) translateZ(0) scaleX(1.5);
                                        }
                                        50% {
                                            transform: translateY(-25%) translateZ(0) scaleX(0.5);
                                        }
                                        100% {
                                            transform: translateY(-50%) translateZ(0) scaleX(1.5);
                                        }
                                    }

                                    &.scroll {
                                        animation-play-state: running;
                                    }

                                    img {
                                        display: block;
                                        width: 100%;
                                        height: 50%;
                                    }
                                }
                            }
                            &.section03 {
                                width: 100vw;

                                .rect-text {
                                    pointer-events: none;
                                    position: absolute;
                                    transform: translate(-50%, -50%);

                                    span {
                                        display: block;
                                        width: fit-content;
                                        padding: getRem(8px) getRem(11px);
                                        border-radius: getRem(20px);
                                        border: 1px solid #fff;
                                        @include fontStyle(20px, 500, 1.2, -0.9px);
                                        color: #fff;
                                        white-space: nowrap;

                                        & ~ span {
                                            margin-top: getRem(9px);
                                            margin-left: getRem(68px);
                                        }
                                    }
                                }

                                .text-container {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    margin-top: getPercent(1920, 158);
                                    margin-right: getPercent(1920, 425);

                                    > strong {
                                        @include fontStyle(35px, 700, 45.5px);
                                        color: #ff60ae;
                                    }

                                    > p {
                                        @include fontStyle(18px, 500, 30.6px, -0.9px);
                                        color: #ff60ae;
                                        margin-top: getPercent(1080, 26);
                                    }
                                }

                                .painter-bg {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    display: inline-block;
                                    width: getPercent(2108, 107);
                                    height: 100%;
                                    z-index: 1;
                                    margin-right: -1px;
                                    transform-origin: right;

                                    img {
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                            &.section04 {
                                position: relative;
                                background-color: #000;
                                padding-right: getVw(293px);
                                .contents {
                                    display: flex;
                                    align-items: center;
                                    height: 100%;

                                    .SolutionInfoIntro {
                                        height: fit-content;
                                        width: 100vw;
                                        margin-left: getVw(84px);
                                        z-index: 2;
                                        .title {
                                            color: #ff60ae;
                                        }
                                        &:nth-child(1) {
                                            position: relative;
                                        }
                                    }

                                    .solution-introduction {
                                        z-index: 2;
                                        padding-left: getPercent(2934, 246);

                                        flex-direction: column;
                                        color: #fff;
                                        .detail-text {
                                            margin-top: getRem(30px);
                                        }
                                        .btn-link {
                                            background-color: #ff60ae;
                                        }
                                    }
                                    .section04-bg {
                                        z-index: 1;
                                        position: absolute;
                                        top: 0;
                                        right: getRem(117px);
                                        height: 100%;
                                        img {
                                            display: block;
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .SolutionSectionDestination {
                &.black {
                    background-color: #000;
                    transition: 0.5s;
                }

                .fade-in {
                    opacity: 0;
                }

                .text-container {
                    position: relative;
                    padding: getPercent(1920, 133) getPercent(1920, 103) 0 getPercent(1920, 103);
                }

                .RandomTypo {
                    span {
                        @include fontStyle(260px, 400, 0.98);
                        font-size: getVw(260px);
                        color: #fff;
                    }
                }

                .msg-en {
                    position: absolute;
                    top: 0;
                    left: 0;
                    margin-top: getPercent(1920, 167);
                    margin-left: getPercent(1920, 1145);
                    font-family: $font-en;
                    @include fontStyle(24px, 400, 24px);
                    text-transform: uppercase;
                    color: #fff;
                }

                .msg-ko {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin-top: getPercent(1920, 250);
                    margin-right: getPercent(1920, 283);
                    width: getPercent(1920, 343);

                    > span {
                        @include fontStyle(16px, 400, 24px);
                        color: #fff;

                        &.right {
                            align-self: flex-end;
                        }
                    }
                }

                .tag {
                    font-family: $font-en;
                    &.handly {
                        margin-top: getPercent(1920, 335);
                        margin-left: getPercent(1920, 359);
                        > span {
                            transform: rotate(11deg);
                        }
                    }

                    &.popee {
                        margin-top: getPercent(1920, 442);
                        margin-left: getPercent(1920, 942);
                        > span {
                            transform: rotate(-5deg);
                        }
                    }
                    &.mentor {
                        margin-top: getPercent(1920, 504);
                        margin-left: getPercent(1920, 1523);
                        > span {
                            transform: rotate(9deg);
                        }
                    }
                }
            }
            .SolutionSectionFinish {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: #000;
                padding: getPercent(1920, 302) 0 getPercent(1920, 133);
                .solution {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    a {
                        display: inline-flex;
                        position: relative;
                        font-family: $font-en;
                        @include fontStyle(210px, 300, 231px);
                        text-transform: uppercase;
                        text-decoration: none;

                        > span {
                            display: inline-block;
                            position: relative;
                            z-index: 1;
                            background-color: #000;
                            padding-left: getRem(30px);
                        }

                        .img {
                            display: flex;
                            align-items: center;
                            width: getRem(178px);

                            img {
                                display: block;
                                width: 100%;
                            }
                        }
                    }

                    &.handly {
                        a {
                            color: #6b3be9;
                        }

                        .rolling-msg-01 {
                            background-color: #a561c5;
                            margin-top: getPercent(1920, 70);
                            margin-left: getPercent(1920, 728);
                        }

                        .rolling-msg-02 {
                            background-color: #5b5b5b;
                            margin-top: getPercent(1920, 122);
                            margin-left: getPercent(1920, 1189);
                        }
                    }

                    &.popee {
                        a {
                            color: #ffffff;
                        }

                        .rolling-msg-01 {
                            margin-top: getPercent(1920, 72);
                            margin-left: getPercent(1920, 568);
                            background-color: #ff60ae;
                        }

                        .rolling-msg-02 {
                            font-family: $font-en;
                            margin-top: getPercent(1920, 135);
                            margin-left: getPercent(1920, 1035);
                            background-color: #5275d3;
                        }
                    }

                    &.mentor {
                        a {
                            color: #36a569;
                        }

                        .rolling-msg-01 {
                            margin-top: getPercent(1920, 88);
                            margin-left: getPercent(1920, 1194);
                            background-color: #2f6b38;
                        }

                        .rolling-msg-02 {
                            font-family: $font-en;
                            margin-top: getPercent(1920, 136);
                            margin-left: getPercent(1920, 750);
                            background-color: #ec7303;
                        }
                    }

                    .rolling-msg {
                        position: absolute;
                        display: flex;
                        width: fit-content;
                        border-radius: 16.5px;
                        overflow: hidden;
                        z-index: 2;
                        top: 0;
                        left: 0;
                        pointer-events: none;

                        .animation-container {
                            display: flex;
                            align-items: center;
                            animation: rollingMsgAni 5s linear forwards infinite;
                        }
                        .msg-container {
                            display: flex;
                            flex-shrink: 0;
                            align-items: center;

                            & ~ .msg-container {
                                position: absolute;
                                transform: translateX(100%);
                            }

                            @keyframes rollingMsgAni {
                                0% {
                                    transform: translateX(0);
                                }
                                100% {
                                    transform: translateX(-100%);
                                }
                            }

                            span {
                                display: block;
                                flex-shrink: 0;
                                font-family: $font-ko;
                                @include fontStyle(14px, 600, 16.8px, -0.7px);
                                color: #fff;
                                padding: getRem(8px) getRem(24px);
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
            .SolutionSectionHandly {
                .sticky {
                    position: sticky;
                    height: 100vh;
                    width: 100%;
                    top: 0;
                    .bg {
                        height: 100%;

                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .SolutionIntroduceIntro {
                    height: fit-content;
                    padding-bottom: getPercent(1920, 133);
                    .introduce {
                        display: flex;
                        width: 100%;
                        align-self: flex-start;
                        margin-top: getPercent(1920, 234);

                        .desktop-img {
                            // width: getPercent(1920, 1082);
                            position: relative;
                            width: 138.6138613861vh;
                            max-width: 75%;
                            margin-left: -15%;

                            video {
                                z-index: 0;
                                position: absolute;
                                width: 72.84442%;
                                top: 2.1126760563%;
                                left: 17%;
                            }

                            img {
                                z-index: 1;
                                position: relative;
                                display: block;
                                width: 100%;
                            }
                        }

                        .text {
                            align-self: flex-end;
                            margin-left: getPercent(1920, 78);
                            margin-bottom: getPercent(1920, 143);
                            strong {
                                @include fontStyle(35px, 700, 52.5px, -1.75px);
                            }
                            p {
                                @include fontStyle(18px, 500, 30.6px, -0.9px);
                                margin-top: getPercent(1920, 30);
                            }
                        }
                    }
                }

                .contents {
                    position: relative;
                    background-color: #fff;
                    padding-top: getPercent(1920, 126);
                    padding-bottom: getPercent(1920, 236);
                    width: 100%;

                    .SolutionInfoIntro {
                        width: 100%;
                        overflow: hidden;
                        .title {
                            > div {
                                > span {
                                    background: linear-gradient(156deg, #40dcfd 0%, #9f78fb 34.54%, #723dfc 100%);
                                    background-clip: text;
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                    backdrop-filter: blur(28.940698623657227px);
                                }
                            }
                        }
                    }

                    .solution-introduction {
                        gap: 0 getPercent(1920, 122);

                        .detail-text {
                            .btn-link {
                                background-color: #8b5ffc;
                            }
                        }
                    }
                }
            }
            .SolutionSectionMentor {
                .sticky {
                    position: sticky;
                    height: 100vh;
                    width: 100%;
                    top: 0;
                    .bg {
                        height: 100%;

                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
                .contents {
                    position: relative;
                    background-color: #fff;
                    padding-top: getPercent(1920, 126);
                    padding-bottom: getPercent(1920, 236);
                    .SolutionInfoIntro {
                        .title {
                            > div {
                                > span {
                                    color: #33a467;
                                }
                            }
                        }
                    }
                    .solution-introduction {
                        gap: 0 getPercent(1920, 113);

                        .detail-text {
                            margin-top: getPercent(1141, 47);

                            .btn-link {
                                background-color: #e94d20;
                            }
                        }
                    }
                }
            }

            .solution-introduction {
                display: flex;
                justify-content: center;
                width: width;
                margin-top: getPercent(1920, 102);
                gap: 0 getPercent(1920, 122);

                .simple-text {
                    @include fontStyle(30px, 700, 45px, -1.5px);
                    white-space: nowrap;
                }

                .detail-text {
                    white-space: nowrap;
                    > p {
                        @include fontStyle(18px, 500, 30.6px, -0.9px);

                        span.en {
                            font-family: $font-en;
                            letter-spacing: 0;
                            text-transform: uppercase;
                        }
                    }

                    .btn-link {
                        display: inline-block;
                        padding: getRem(20px) getRem(40px);
                        border-radius: getRem(46.5px);
                        @include fontStyle(25px, 700, 37.5px, -1.25px);
                        text-decoration: none;
                        color: #fff;
                        margin-top: getRem(80px);
                        text-transform: uppercase;
                    }
                }
            }

            .SolutionInfoIntro {
                .info-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .title {
                        text-align: center;

                        > div {
                            display: inline-block;

                            > span {
                                font-family: $font-en;
                                @include fontStyle(180px, 700, 216px);
                                font-size: getVw(180px);
                                display: inline-block;
                            }
                        }
                    }

                    .img {
                        position: absolute;
                        width: getPercent(1920, 272);
                        top: 0;
                        margin-top: getPercent(1920, 231);
                        img {
                            width: 100%;
                            display: block;
                            margin: 0 auto;
                            filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.3));
                        }
                    }
                }
            }

            .SolutionIntroduceIntro {
                position: sticky;
                top: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                gap: getRem(14px) 0;
                min-height: 100vh;
                margin-top: -50vh;

                .num {
                    font-family: $font-en;
                    @include fontStyle(53.653px, 300, 59.018px);
                    text-transform: uppercase;
                    color: #fff;
                }

                .text {
                    @include fontStyle(16px, 700, 19.2px);
                    color: #fff;
                    text-transform: uppercase;
                }

                strong {
                    > div {
                        display: inline-block;
                        > span {
                            display: inline-block;
                            font-family: $font-en;
                            @include fontStyle(336.469px, 300, 370.116px);
                            text-transform: uppercase;
                            color: #fff;
                        }
                    }
                }
            }

            .tag {
                position: absolute;
                display: inline-block;
                top: 0;
                left: 0;
                transform: translateY(500%);
                span {
                    opacity: 0;
                    display: block;
                    padding: getRem(15px) getRem(26px);
                    @include fontStyle(24px, 700, 28.8px);
                    color: #fff;
                    background-color: rgba(146, 146, 146, 0.5);
                    transform: rotate(11deg);
                    border-radius: getRem(30px);
                    border: 0.5px solid #b9b9b9;
                    border-radius: getRem(30px);
                    @include blur(10px);
                }
            }
        }

        //contact page
        .Contactpage {
            background-color: #000;
            .ContactSectionEntry {
                position: relative;
                width: 100%;
                height: 100vh;
                .contact-entry-bg {
                    background-color: #000;
                    width: 100%;
                    height: 100%;

                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }
                .contact-entry-text {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    text-align: center;

                    .contact {
                        font-family: $font-en;
                        @include fontStyle(260px, 300, 286px);
                    }
                }
            }
            .ContactSectionSummary {
                background-color: #000;
                transition: background-color 0.7s;
                &.white {
                    background-color: #fff;
                    .contact-summary-container {
                        .small-msg {
                            &.text01 {
                                .Typography {
                                    color: #000;
                                }
                            }
                        }
                    }
                }
                .contact-summary-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: getPercent(1920, 149) 0;

                    .text-container {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        .small-msg {
                            width: fit-content;
                            &.text01 {
                                .Typography {
                                    transition: color 0.7s;
                                    @include fontStyle(60px, 300, 78px);
                                    font-family: $font-en;
                                }
                            }
                            &.text02 {
                                margin-top: getRem(34px);

                                .Typography {
                                    font-family: $font-ko;
                                    color: #000;
                                    @include fontStyle(18px, 300, 27px, -0.05em);
                                }
                            }
                        }
                    }
                }
            }
            .ContactSectionReview {
                z-index: 0;
                position: relative;
                height: 350vh;
                background-color: #fff;
                transition: background-color 0.5s;
                &.black {
                    background-color: #000;
                }

                .contact-review-stick {
                    position: sticky;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100vh;
                    padding: getPercent(1920, 204) getPercent(1920, 225) 0;
                    z-index: 0;
                    overflow: hidden;

                    .text-slide-box {
                        display: flex;
                        transform: translateX(0);
                        width: fit-content;
                        transition-timing-function: linear;
                        transition: transform 0.3s;

                        &.action {
                            .hidden-text {
                                opacity: 1;
                            }
                        }

                        @keyframes scrollAction {
                            0% {
                                transform: translateX(0);
                            }
                            100% {
                                transform: translateX(-50%);
                            }
                        }
                    }
                    .text-wrap {
                        flex-shrink: 0;
                        display: flex;
                        text-transform: uppercase;
                        color: #fff;
                        @include fontStyle(480px, 300, 480px);
                        font-family: $font-en;

                        .hidden-text {
                            flex-shrink: 0;
                            opacity: 0;
                        }
                    }
                    .text-normal-wrap {
                        flex-shrink: 0;
                        color: #fff;
                        @include fontStyle(480px, 300, 480px);
                        font-family: $font-en;
                        text-transform: uppercase;
                    }
                    .small-text-box {
                        position: absolute;
                        top: getRem(702px);
                        right: getRem(396px);
                        p {
                            @include fontStyle(18px, 300, 27px, -0.05em);
                            font-family: $font-ko;
                            color: #fff;
                        }
                    }
                }
                .work-thumbnail-wrap {
                    position: relative;
                    z-index: 1;
                    .work-thumbnail {
                        position: relative;
                        width: getPercent(1920, 470);
                        img {
                            display: block;
                            width: 100%;
                        }

                        &.thumbnail01 {
                            left: getPercent(1920, 480);
                        }
                        &.thumbnail02 {
                            left: getPercent(1920, 1200);
                            margin: getPercent(1920, 169.93) 0;
                        }
                        &.thumbnail03 {
                            left: getPercent(1920, 161);
                        }
                        &.thumbnail04 {
                            left: getPercent(1920, 1098);
                            margin: getPercent(1920, 169.93) 0;
                        }
                        &.thumbnail05 {
                            left: getPercent(1920, 477);
                        }
                    }
                }
            }
            .ContactSectionFinish {
                z-index: 1;
                position: relative;
                margin-top: getPercent(1920, 175);
                padding: 0 getPercent(1920, 70) getPercent(1920, 78) getPercent(1920, 70);
                background-color: #000;
                .finish-container {
                    &::before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 200%;
                        background-color: #fff;
                        bottom: 0;
                        left: 0;
                        margin: auto;
                        transition: 0.5s;
                        transform: scale(1);
                        clip-path: circle(0% at 50% 77%);
                        z-index: 0;
                        transition: all 0.8s;
                    }

                    &.active {
                        &::before {
                            clip-path: circle(100% at 50% 77%);
                        }

                        .finish-text-box {
                            color: #000;
                        }
                    }
                    .finish-text-box {
                        position: relative;
                        width: 100%;
                        text-align: center;
                        text-transform: uppercase;
                        margin-bottom: getPercent(1780, 49);
                        color: #fff;
                        transition: color 0.8s;
                        strong {
                            @include fontStyle(200px, 300, 180px, 0);
                            font-family: $font-en;
                            display: inline-block;
                        }
                    }

                    .tag {
                        position: absolute;
                        transition: transform 0.4s;
                        // top:0;
                        // left:0;
                        span {
                            display: inline-block;
                            background-color: #000;
                            padding: getRem(15px) getRem(26px);
                            color: #fff;
                            @include fontStyle(14px, 400, 19.6px);
                            font-family: $font-en;
                            border-radius: 30px;
                            cursor: pointer;
                            opacity: 0;
                            transform: translateY(500%);
                            transition:
                                transform 0.7s,
                                opacity 0.5s;
                            &.action {
                                opacity: 1;
                                transform: translateY(0);
                            }
                        }
                        &.instagram {
                            top: getRem(82px);
                            left: getPercent(1780, 612);
                            // left: getRem(603px);
                            span {
                                transition-delay: 0.5s;
                            }
                        }
                        &.youtube {
                            bottom: getRem(96px);
                            right: getPercent(1780, 502);
                            // right: getRem(493px);
                            span {
                                transition-delay: 1s;
                            }
                        }
                        &.contact {
                            bottom: getRem(46px);
                            left: getPercent(1780, 482);
                            // left: getRem(493px);
                            span {
                                transition-delay: 1.4s;
                            }
                        }

                        &:hover {
                            transform: rotate(12.9deg);
                        }
                    }
                    .finish-member-img {
                        position: relative;
                        width: 100%;
                        clip-path: inset(0 30%);
                        transition: clip-path 0.5s;

                        img {
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                    .info-box {
                        position: relative;
                        margin-top: getRem(18px);
                        display: flex;
                        justify-content: space-between;

                        address {
                            @include fontStyle(15px, 300, 22.5px, -0.05em);
                        }
                        .tel {
                            li {
                                display: inline-block;
                                @include fontStyle(14px, 300, 21px);

                                &:first-of-type {
                                    margin-right: getRem(26.94px);
                                }
                            }
                        }
                    }
                }
            }
        }

        //about page
        .AboutPage {
            .AboutSectionIntro {
                position: relative;
                padding-top: calc(100vh + #{getPercent(1080, 41)});

                .bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: getVh(357px);
                        background: linear-gradient(180deg, #000 17.46%, rgba(0, 0, 0, 0) 98.6%);
                        mix-blend-mode: multiply;
                        transform: rotate(180deg);
                    }

                    img {
                        object-fit: cover;
                    }
                }

                img {
                    display: block;
                    width: 100%;
                }

                .intro-text-container {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;

                    .solution-entry-text {
                        color: #fff;
                        font-family: $font-en;

                        &.text-01 {
                            width: fit-content;
                            position: absolute;
                            top: getVh(155px);
                            right: getPercent(1920, 222);
                            .text-en {
                                @include fontStyle(22px, 300, 28.6px, -0.44px);
                                text-transform: uppercase;
                            }
                            .text-ko {
                                width: fit-content;
                                margin-left: auto;
                                margin-top: getRem(29px);

                                .Typography {
                                    font-family: $font-ko;
                                    @include fontStyle(18px, 300, 27px, -0.9px);
                                }
                            }
                        }
                        &.text-02 {
                            position: absolute;
                            top: 0;
                            margin-top: getVh(594px);
                            left: getRem(130px);
                            .about {
                                @include fontStyle(260px, 300, 286px);
                                font-size: getVw(260px);
                            }
                        }
                    }
                }

                .history-container {
                    width: 100%;
                    height: 4000px;
                    background-color: #000;
                    .year {
                        position: sticky;
                        top: 0;
                        font-family: $font-en;
                        @include fontStyle(592px, 400, 710px);
                        font-size: getVw(592px);
                        text-transform: uppercase;
                        color: #fff;
                        text-align: center;
                        mix-blend-mode: difference;
                        height: 100vh;
                    }

                    .contents {
                        position: sticky;
                        top: 0;
                        padding-top: 23.529411764705884vh;
                        height: 100vh;
                        .img-ipad {
                            position: relative;
                            width: getPercent(1920, 576);
                            margin: 0 auto;
                            &::after {
                                content: "";
                                display: block;
                                position: absolute;
                                top: getPercent(576, 19.34);
                                left: getPercent(576, 19.34);
                                width: getPercent(576, 534);
                                height: getPercent(576, 534);
                                background-color: #1f1f1f;
                                border-radius: getRem(8px);
                                transition: opacity 0.2s;
                            }

                            &.on {
                                &::after {
                                    opacity: 0;
                                }
                            }

                            .screen {
                                position: absolute;
                                top: getPercent(576, 22);
                                left: getPercent(576, 19.34);
                                width: getPercent(576, 534);
                                border-radius: getRem(8px);
                                overflow: hidden;

                                .slide-wrap {
                                    display: flex;
                                    transition: transform 0.4s;
                                }

                                img {
                                    display: block;
                                    width: 100%;
                                    object-fit: cover;
                                    flex-shrink: 0;
                                }
                            }
                        }

                        .text-container {
                            width: 100%;
                            @include fontStyle(28px, 400, 39.2px, -0.84px);
                            color: #fff;
                            text-align: center;
                            margin-top: getPercent(1920, 46);
                            opacity: 0;
                            transition: opacity 1s;

                            > p {
                                width: 100%;
                                flex-shrink: 0;
                            }

                            &.on {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .AboutSectionAbout {
                .container {
                    position: sticky;
                    top: 0;
                    width: 100%;
                    // width: 100vw;
                    overflow: hidden;
                    .scroll-wrap {
                        display: flex;
                        width: fit-content;

                        .scroll-section {
                            position: relative;
                            flex-shrink: 0;
                            height: 100vh;
                            min-width: 100vw;

                            &.section-01 {
                                position: absolute;
                                width: 100vw;
                                height: 100%;
                                background-color: #000;
                                overflow: hidden;

                                .text-container {
                                    margin-left: getPercent(1920, 28);
                                    margin-top: getPercent(1920, 127);
                                    align-self: flex-end;
                                    position: relative;

                                    .typo {
                                        span {
                                            @include fontStyle(120px, 300, 108px);
                                            font-family: $font-en;
                                            text-transform: uppercase;
                                            color: #fff;
                                        }
                                    }
                                    .typo-tag {
                                        position: absolute;
                                        top: getRem(182px);
                                        left: getRem(328px);
                                        padding: getRem(13px) getRem(25px);
                                        background-color: #7a7a7a;
                                        border-radius: 100px;
                                        transform: rotate(0);
                                        opacity: 1;
                                        transition: transform 0.4s;

                                        &:hover {
                                            transform: rotate(-8.47deg);
                                        }
                                        span {
                                            font-family: $font-en;
                                            display: inline-block;
                                            @include fontStyle(16px, 500, 22.4px);
                                            color: #fff;
                                            text-transform: uppercase;
                                        }
                                        img {
                                            display: inline-block;
                                            width: getRem(16px);
                                            vertical-align: middle;
                                        }
                                    }
                                    .text-container {
                                        margin-top: getRem(42px);

                                        .Typography {
                                            color: #fff;
                                            @include fontStyle(18px, 400, 27px, -0.9px);
                                        }
                                    }
                                }

                                .img-container {
                                    position: absolute;
                                    width: getPercent(1920, 930);
                                    height: 100%;
                                    right: 0;
                                    top: 0;

                                    div[class^="img"] {
                                        position: absolute;
                                        img {
                                            display: block;
                                            width: 100%;
                                        }
                                        &.img-01 {
                                            top: 0;
                                            left: 0;
                                            margin-top: getPercent(930, 414);
                                            margin-left: getPercent(930, 280);
                                            width: getPercent(930, 360);
                                            .tag {
                                                margin-top: getVw(37px);
                                                margin-left: getVw(82px);
                                                transform: rotate(7.169deg);
                                            }
                                        }
                                        &.img-02 {
                                            top: 0;
                                            left: 0;
                                            margin-top: getPercent(930, 36);
                                            margin-left: getPercent(930, 198);
                                            width: getPercent(930, 361);
                                            .tag {
                                                margin-top: getVw(77px);
                                                margin-left: getVw(164px);
                                                transform: rotate(-40deg);
                                            }
                                        }
                                        &.img-03 {
                                            top: 0;
                                            left: 0;
                                            margin-top: getPercent(930, 225);
                                            width: getPercent(930, 351);
                                            .tag {
                                                margin-top: getVw(156px);
                                                transform: rotate(6deg);
                                            }
                                        }
                                        &.img-04 {
                                            top: 0;
                                            left: 0;
                                            margin-top: getPercent(930, 549);
                                            margin-left: getPercent(930, 28);
                                            width: getPercent(930, 411);
                                            .tag {
                                                margin-top: getVw(133px);
                                                margin-left: getVw(-24px);
                                                transform: rotate(-42deg);
                                            }
                                        }
                                        &.img-05 {
                                            top: 0;
                                            left: 0;
                                            margin-top: getPercent(930, 703);
                                            margin-left: getPercent(930, 443);
                                            width: getPercent(930, 334);
                                            .tag {
                                                margin-top: getVw(68px);
                                                margin-left: getVw(107px);
                                                transform: rotate(-11deg);
                                            }
                                        }
                                        &.img-06 {
                                            top: 0;
                                            right: 0;
                                            margin-top: getPercent(930, 307);
                                            width: getPercent(930, 340);
                                            .tag {
                                                margin-top: getVw(175px);
                                                margin-left: getVw(3px);
                                                transform: rotate(-3deg);
                                            }
                                        }

                                        .tag-wrap {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            pointer-events: none;
                                            opacity: 0;

                                            .tag {
                                                padding: getVw(15px) getVw(26px);
                                                font-family: $font-en;
                                                @include fontStyle(12px, 500, 16.8px, -0.48px);
                                                border-radius: getRem(30px);
                                                border: 0.5px solid #9d9d9d;
                                                background-color: rgba(163, 163, 163, 0.6);
                                                backdrop-filter: blur(10px);
                                                text-transform: uppercase;
                                                color: #fff;
                                                top: 0;
                                                left: 0;
                                            }
                                        }

                                        &:hover {
                                            .tag-wrap {
                                                opacity: 1;
                                            }
                                        }
                                    }
                                }
                            }

                            &.section-02 {
                                display: flex;
                                background-color: #fff;
                                clip-path: circle(#{getVw(130px)} at 84vw 24vh);
                                transition: clip-path 0.2s;
                                background-color: #fff;
                                z-index: 2;

                                > * {
                                    flex-grow: 0;
                                    flex-shrink: 0;
                                }

                                .circle-area-text {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    top: 22%;
                                    left: 79.5vw;
                                    transition: opacity 0.2s;

                                    > span {
                                        @include fontStyle(15px, 300, 15px);
                                        color: #000;
                                        text-transform: uppercase;

                                        &.right {
                                            text-align: right;
                                        }
                                    }
                                }

                                .logo {
                                    position: relative;
                                    width: getVw(2395px);
                                    top: 50%;
                                    margin-left: getVw(176px);
                                    transform: translateY(-50%);
                                    transition: opacity 0.2s;

                                    svg {
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                    }

                                    .symbol {
                                        position: absolute;
                                        top: 0;
                                        left: 0;

                                        img {
                                            display: block;
                                            width: 100%;
                                        }

                                        &.symbol-01 {
                                            width: getVw(588px);
                                            margin-left: getVw(118px);
                                            margin-top: getVh(-19px);
                                        }
                                        &.symbol-02 {
                                            width: getVw(523px);
                                            transform: rotate(110deg);
                                            margin-left: getVw(777px);
                                            margin-bottom: getVw(-100px);
                                            bottom: 0;
                                            top: initial;
                                        }
                                        &.symbol-03 {
                                            width: getVw(391px);
                                            margin-left: getVw(1615px);
                                            margin-top: getVh(-232px);
                                            transform: rotate(110deg);
                                        }
                                        &.symbol-04 {
                                            width: getVw(550px);
                                            transform: rotate(-30deg);
                                            margin-left: getVw(2117px);
                                            margin-bottom: getVh(36px);
                                            bottom: 0;
                                            top: initial;
                                        }
                                    }
                                }
                            }

                            &.section-03 {
                                margin-left: getVw(325px);
                                width: 100vw;
                                overflow: hidden;

                                .text-cont-wrap {
                                    margin-top: getVh(127px);
                                    margin-left: getVw(367px);
                                    .introduce-msg {
                                        &.en {
                                            .Typography {
                                                @include fontStyle(60px, 400, 72px, -1.8px);
                                                color: #000;
                                            }
                                        }

                                        &.ko {
                                            margin-left: getVw(245px);
                                            margin-top: getVh(29px);
                                            .Typography {
                                                font-family: $font-ko;
                                                @include fontStyle(18px, 400, 27px, -0.9px);
                                                color: #000;
                                            }
                                        }
                                    }
                                }

                                .card-container {
                                    position: absolute;
                                    display: flex;
                                    bottom: 0;
                                    right: 0;
                                    margin-right: getVw(16px);

                                    .card {
                                        flex-shrink: 0;
                                        flex-grow: 0;
                                        width: getVw(260px);
                                        padding-bottom: getVh(40px);

                                        img {
                                            display: block;
                                            width: 100%;
                                        }

                                        &.card-01 {
                                            margin-top: getVh(-107px);
                                        }
                                        &.card-02 {
                                            margin-left: getVw(18px);
                                        }
                                        &.card-03 {
                                            margin-top: getVh(-348px);
                                            margin-left: getVw(-86px);
                                        }
                                        &.card-04 {
                                            margin-top: getVh(-198px);
                                            margin-left: getVw(-60px);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .AboutSectionCard {
                transform: scale(#{getPercent(1920, 1600)}) rotate(5deg);
                height: 250vh;

                .card-wrap {
                    position: sticky;
                    top: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    background-color: #000;
                    border-radius: getRem(15px);
                    padding-top: getVh(208px);
                    overflow: hidden;
                    min-height: 100vh;

                    .text-container {
                        display: block;
                        @include fontStyle(24px, 400, 36px, -1.2px);
                        color: #fff;
                        text-align: center;
                        margin: 0 auto;
                        img {
                            width: getRem(24px);
                            vertical-align: top;
                            margin-left: 5px;
                        }
                    }

                    .card-video {
                        video {
                            display: block;
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }

            .AboutSectionWorkWeDo {
                position: relative;
                z-index: 1;
                background-color: #fff;

                .large-text-container {
                    position: sticky;
                    top: getVh(600px);
                    mix-blend-mode: difference;
                    transform: translateY(calc(-100% - getVh(20px)));
                    padding-left: getVw(40px);
                    font-family: $font-en;

                    > div {
                        display: inline-block;
                        @include fontStyle(200px, 300, 200px);
                        font-size: getVw(200px);
                        text-transform: uppercase;
                        color: #fff;

                        &.space {
                            width: getVw(80px);
                        }
                    }
                }

                .scroll-contents {
                    position: relative;
                    z-index: 2;

                    &.we-do {
                        padding-top: getPercent(1920, 1013);
                        padding-bottom: getPercent(1920, 582);

                        div[class^="card-wedo"] {
                            position: relative;
                            width: getPercent(1920, 550);
                            border-radius: getRem(12px);
                            overflow: hidden;

                            &:hover {
                                .hover-contents {
                                    opacity: 1;
                                }
                            }

                            &.card-wedo-01 {
                                margin-left: getVw(598px);

                                .hover-contents {
                                    background-image: url("../../../public/image/img_about_wedo_01_hover.webp");
                                }
                            }
                            &.card-wedo-02 {
                                margin-left: getVw(1264px);
                                margin-top: getVh(230px);

                                .hover-contents {
                                    background-image: url("../../../public/image/img_about_wedo_02_hover.webp");
                                }
                            }
                            &.card-wedo-03 {
                                margin-left: getVw(245px);
                                margin-top: getVh(-128px);

                                .hover-contents {
                                    background-image: url("../../../public/image/img_about_wedo_03_hover.webp");
                                }
                            }
                            &.card-wedo-04 {
                                margin-left: getVw(1022px);
                                margin-top: getVh(202px);

                                .hover-contents {
                                    background-image: url("../../../public/image/img_about_wedo_04_hover.webp");
                                }
                            }
                            &.card-wedo-05 {
                                margin-left: getVw(172px);
                                margin-top: getVh(66px);

                                .hover-contents {
                                    background-image: url("../../../public/image/img_about_wedo_05_hover.webp");
                                }
                            }
                            &.card-wedo-06 {
                                margin-left: getVw(1223px);
                                margin-top: getVh(31px);

                                .hover-contents {
                                    background-image: url("../../../public/image/img_about_wedo_06_hover.webp");
                                }
                            }

                            &.card-wedo-07 {
                                margin-left: getVw(172px);
                                margin-top: getVh(31px);

                                .hover-contents {
                                    background-image: url("../../../public/image/img_about_wedo_07_hover.webp");
                                }
                            }

                            img {
                                display: block;
                                width: 100%;
                            }

                            .hover-contents {
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-repeat: no-repeat;
                                background-size: cover;
                                pointer-events: none;
                                opacity: 0;
                                transition: opacity 0.2s;

                                span {
                                    position: absolute;
                                    font-family: $font-en;
                                    @include fontStyle(15px, 400, 15px);
                                    color: #000;
                                    top: getPercent(550, 55);
                                    left: getPercent(550, 50);
                                    text-transform: uppercase;
                                }

                                p {
                                    position: absolute;
                                    @include fontStyle(23px, 400, 30px, -0.926px);
                                    color: #000;
                                    width: getPercent(550, 451);
                                    bottom: getPercent(550, 55);
                                    left: getPercent(550, 50);

                                    &.center {
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }

                    &.black {
                        min-height: 100vh;
                        padding-top: getPercent(1920, 62);
                        background-color: #000;
                        background: {
                            image: url("../../../public/image/img_bg_about_earth.webp");
                            position: getVh(44px) getVw(43px);
                            repeat: no-repeat;
                        }

                        .RandomTypo {
                            @include fontStyle(240px, 300, 240px, -4.8px);
                            font-size: getVw(240px);
                            color: #fff;
                            text-transform: uppercase;
                        }

                        .row {
                            display: flex;
                            align-items: center;
                            padding: 0 getVw(80px);

                            &.right {
                                justify-content: flex-end;
                            }
                        }

                        .text-container {
                            margin-left: getVw(244px);

                            .Typography {
                                @include fontStyle(18px, 400, 27px, -0.9px);
                            }

                            &.en {
                                margin-bottom: getRem(33px);
                                .Typography {
                                    font-family: $font-en;
                                    @include fontStyle(24px, 300, 31.2px, -0.48px);
                                }
                            }
                        }

                        .typo {
                            position: relative;
                            padding-bottom: getVh(342px);
                            @include fontStyle(200px, 400, 200px, -4px);
                            font-size: getVw(200px);
                            color: #fff;
                            text-align: center;
                            margin-top: getVh(294px);
                            z-index: 2;
                            font-family: $font-en;

                            .icn-wrap {
                                position: absolute;
                                display: flex;
                                justify-content: space-between;
                                left: 50%;
                                top: getVw(123px);
                                transform: translateX(-50%);

                                > div {
                                    width: getVw(160px);

                                    &.right {
                                        margin-right: getVw(250px);
                                        transition: margin-right 0.2s;
                                    }

                                    img {
                                        width: 100%;
                                    }
                                }
                            }

                            .btn-contact {
                                background-color: transparent;
                                border: 1px solid rgba(255, 255, 255, 0.5);
                                background: rgba(255, 255, 255, 0.4);
                                @include fontStyle(32px, 400, -0.64px);
                                color: #000;
                                padding: getRem(30px) getRem(50px);
                                text-transform: uppercase;
                                border-radius: getRem(100px);
                                transition:
                                    transform 0.2s,
                                    background-color 0.2s,
                                    color 0.2s;
                                cursor: pointer;

                                &:hover {
                                    background-color: #000;
                                    transform: rotate(-9.5deg);
                                    color: #fff;
                                }

                                img {
                                    width: getRem(32px);
                                }
                            }
                        }
                        .bg {
                            position: absolute;
                            width: 100%;
                            border-top-right-radius: getRem(50px);
                            border-top-left-radius: getRem(50px);
                            overflow: hidden;
                            background-color: #fff;
                            transition: transform 0.1s;

                            img {
                                display: block;
                                width: 100%;
                                object-fit: cover;
                            }

                            &::after {
                                content: "";
                                display: block;
                                position: absolute;
                                bottom: 0;
                                height: getVh(665px);
                                width: 100%;
                                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
                            }
                        }

                        &::after {
                            content: "";
                            display: block;
                            position: absolute;
                            width: 100%;
                            height: 5px;
                            bottom: -1px;
                            background-color: #fff;
                        }
                    }
                }
            }
        }

        //work page
        .WorkPage {
            .WorkEntry {
                padding: getPercent(1920, 294) 0 getPercent(1920, 247);
                height: auto;

                .transition {
                    position: fixed;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: #000;
                    z-index: 9;
                    pointer-events: none;
                    transition: opacity 0.2s;
                }

                &.on {
                    .title-box {
                        transform: translate(-50%, getVh(-90px));

                        .title {
                            transform: scale(0.787);
                        }
                    }
                    .top-wrap {
                        li {
                            transform: none !important;

                            > div {
                                transform: none !important;
                            }

                            .desc-wrap {
                                opacity: 1;
                            }
                        }
                    }
                }

                .msg-wrap {
                    width: 100%;
                    height: getRem(163.38px);
                    transition-delay: 1s;
                    .small-msg {
                        width: fit-content;
                        margin-left: auto;
                        margin-right: getPercent(1920, 422);
                        .text-en {
                            .Typography {
                                color: #000;
                                @include fontStyle(24px, 300, 31.2px);
                                font-family: $font-en;
                            }
                        }
                        .text-ko {
                            width: fit-content;
                            margin-left: auto;
                            margin-top: getRem(20px);
                            .Typography {
                                color: #000;
                                @include fontStyle(18px, 300, 27px);
                                font-family: $font-ko;
                                &.last {
                                    padding-left: getRem(37px);
                                }
                            }
                        }

                        .Typography {
                            color: #000;
                        }
                    }
                }

                .title-box {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 100%;
                    transform: translate(-50%, -50%);
                    transition: transform 0.7s;
                    z-index: 3;

                    h2.title {
                        position: relative;
                        width: fit-content;
                        margin: 0 auto;
                        transition: transform 0.7s;

                        .word {
                            display: flex;
                            justify-content: center;
                            font-family: $font-en;
                            @include fontStyle(330px, 300, 110%);
                            font-size: getVw(330px);

                            span {
                                display: inline-block;
                            }
                        }
                        .project-lenght {
                            opacity: 0;
                            transform: scale(0) translateZ(0) !important;
                            position: absolute;
                            top: 5%;
                            right: 0;
                            border-radius: 30px;
                            padding: getRem(15px) getRem(26px);
                            background-color: rgba(146, 146, 146, 0.5);
                            border: 0.5px solid #b9b9b9;
                            @include blur(10px);
                            span {
                                display: inline-block;
                                @include fontStyle(24px, 400, 28.8px);
                                color: #fff;
                                font-family: $font-en;
                            }
                            &.show {
                                opacity: 1;
                                transform: scale(1) translateZ(0) !important;
                                transition:
                                    opacity 0.5s,
                                    transform 0.7s;
                                transition-delay: 0.5s;
                            }
                        }
                    }
                }
                .top-wrap {
                    position: relative;

                    li {
                        transition: transform 0.7s ease-in-out;

                        &:nth-child(1) {
                            transform: translate(getVw(501px), calc(-100% + #{getVh(154px)})) rotate(-52.622deg);
                            z-index: 1;
                        }

                        &:nth-child(2) {
                            transform: translate(calc(-100% + #{getVw(511px)}), calc(-100% + #{getVh(166px)}))
                                rotate(-67.848deg);
                            transition-delay: 0.15s;
                        }

                        > div {
                            transform: scale(0.716) !important;
                            transform-origin: top;
                            transition: transform 0.3s;
                        }

                        .desc-wrap {
                            opacity: 0;
                            transition: opacity 0.3s;
                        }
                    }
                }
                .thumb-box {
                    width: 100%;
                    margin-top: getVh(255px);
                    padding: 0 getPercent(1920, 84) 0 getPercent(1920, 73);

                    .thumb-box-container {
                        width: 100%;

                        .thumb-wrap {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;

                            .thumb-list {
                                display: block;
                                width: getPercent(1763, 863);
                                &.first {
                                    z-index: 2;
                                }
                                a {
                                    display: block;
                                    cursor: pointer;
                                    text-decoration: none;
                                    color: #000;
                                }
                                &:not(.top-box) {
                                    padding-top: getPercent(1763, 160);
                                }
                                .img-wrap {
                                    width: 100%;
                                    border-radius: getRem(11px);
                                    overflow: hidden;
                                    img {
                                        display: block;
                                        width: 100%;
                                    }
                                }
                                .desc-wrap {
                                    margin-top: getRem(20px);
                                    font-family: $font-en;
                                    .work-name {
                                        @include fontStyle(20px, 700, 24px, -0.05em);
                                    }
                                    .work-name-en {
                                        @include fontStyle(12px, 300, 14.4px);
                                        margin-top: getRem(7px);
                                        text-transform: uppercase;
                                    }
                                }
                                &.top-box {
                                    .img-wrap {
                                        position: relative;
                                        width: 100%;
                                        left: 0;
                                        transition:
                                            left 0.6s,
                                            transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
                                        transform-origin: center;
                                        transition-delay: 0.3s;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        //WorkDetail
        .WorkDetail {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 10;
        }

        // NotFound
        .NotFound {
            position: relative;
            height: 100vh;
            overflow: hidden;
            background-color: #000;

            &.symbol-show {
                .symbol {
                    opacity: 1;
                }
            }

            .symbol {
                position: absolute;
                opacity: 0;
                transition: opacity 0.5s;
                pointer-events: none;

                &.symbol-01 {
                    width: getVw(376px);
                    top: getVh(214px);
                    left: getVw(412px);
                }
                &.symbol-02 {
                    width: getVw(354px);
                    bottom: getVh(19px);
                    right: getVw(61px);
                    z-index: 1;

                    img {
                        transform: rotate(110deg);
                    }
                }
                &.symbol-03 {
                    width: getVw(327px);
                    bottom: getVh(10px);
                    left: getVw(100px);
                    z-index: 1;

                    img {
                        transform: rotate(100deg);
                    }
                }
                &.symbol-04 {
                    width: getVw(341px);
                    right: getVw(303px);
                    top: getVh(50px);

                    img {
                        transform: rotate(-30deg);
                    }
                }

                img {
                    display: block;
                    width: 100%;
                }
            }

            .typo-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                .typo-wrap {
                    width: fit-content;
                    .Typography {
                        text-align: center;
                        @include fontStyle(191px, 300, 191px, -3.82px);
                        font-size: getVw(191px);
                        color: #fff;

                        .text-wrap {
                            transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
                            transition-duration: 1.1s;
                        }

                        .btn-back {
                            position: absolute;

                            .Tag {
                                @include fontStyle(16px, 500, 22.4px, -0.64px);
                            }
                        }
                    }
                }
            }

            .btn-back {
                position: relative;
                transform: translate(-50%, -50%);
                cursor: pointer;
                width: fit-content;
                pointer-events: none;
                z-index: 2;
                transition: opacity 0.2s;

                > div {
                    transition: transform 0.2s;

                    .Tag {
                        height: getRem(51px);
                        position: relative;
                        white-space: nowrap;
                        border-radius: getRem(25px);
                        text-align: center;
                        padding: getRem(14px) getRem(26px);
                        @include fontStyle(16px, 500, 22.4px, -0.64px);
                    }
                }
            }
        }

        // (S) component
        .Footer {
            position: relative;
            z-index: 2;
            background-color: #fff;
            padding-top: getPercent(1920, 114.46);
            transition: all 0.5s;

            .footer-text-box {
                display: flex;
                justify-content: space-between;
                padding-left: getPercent(1920, 28);
                padding-right: getPercent(1920, 29.11);
                text-transform: uppercase;

                &:last-of-type {
                    margin-top: getPercent(1920, 103);
                    padding-bottom: getPercent(1920, 35);
                    position: relative;
                }

                .menu-list,
                .menu-list a {
                    font-family: $font-en;
                    @include fontStyle(22px, 300, 24.6px);
                    color: #000;
                    text-decoration: none;
                    .rnd {
                        margin-top: 20px;
                    }
                }
                .footer-msg {
                    @include fontStyle(100px, 300, 100px);
                    font-family: $font-en;
                    position: relative;

                    &.text-right {
                        text-align: right;
                    }

                    .LinkTag {
                        position: absolute;
                        @include fontStyle(10px, 300, 13px, 0);
                        border-radius: 23px;
                        border: 0.5px solid #88888880;
                        text-align: center;
                        background: linear-gradient(#ffffffb3, #8888880b);
                        font-family: $font-en;
                        @include blur(0);
                        cursor: pointer;
                        transition: all 0.1s;
                        @include blur(10px);
                        &:hover {
                            @include blur(0);
                        }

                        &.handly {
                            top: getRem(66);
                            left: getRem(127);
                        }
                        &.mentor {
                            top: getRem(100);
                            right: getRem(103);
                        }
                        &.instagram {
                            top: getRem(64);
                            right: getRem(45.89);
                        }
                        &.youtube {
                            top: getRem(100);
                            left: getRem(320.11);
                        }

                        .link-name {
                            display: block;
                            padding: getRem(15) getRem(14);
                            text-decoration: none;
                            color: #000;
                        }
                    }
                }
                .address-box {
                    font-family: $font-en;
                    @include fontStyle(16px, 300, 25px);
                    padding-top: getPercent(1863, 33.5);
                    address {
                        font-family: $font-ko;
                        text-align: right;
                        margin-bottom: getPercent(220, 10);
                        letter-spacing: -0.08rem;
                    }
                    .num-info {
                        display: flex;
                        justify-content: space-around;
                    }
                }
                .rights {
                    position: absolute;
                    display: flex;
                    @include fontStyle(10px, 300, 25px);
                    right: getPercent(1862.89, 27);
                    bottom: 15.4%;
                    gap: 0 14px;

                    li {
                        &:first-of-type {
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .contact-badge {
            z-index: 10;
            position: fixed;
            bottom: getRem(20px);
            left: getRem(20px);
            width: getRem(100px);
            height: getRem(100px);
            border-radius: 50%;
            background-color: rgba(101, 101, 101, 0.6);
            cursor: pointer;
            @include blur(5px);

            .badge-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                position: relative;
                transform: rotate(90deg);

                .rotate-text {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(90deg);
                    width: 100%;
                    height: 100%;
                    padding: 5px;

                    svg {
                        width: 100%;
                        height: 100%;
                        animation: rotate360 60s linear infinite;
                        will-change: transform;
                    }
                    @keyframes rotate360 {
                        100% {
                            transform: rotate(1turn);
                        }
                    }
                }
                .badge-emoji {
                    width: getRem(30px);
                    height: getRem(30px);
                    overflow: hidden;
                    transition: transform 0.3s;
                    will-change: transform;

                    img {
                        height: 100%;
                    }
                }
            }
        }

        .contact-popup {
            display: none;
            z-index: 100;
            position: fixed;
            bottom: getRem(126);
            left: getRem(20);
            transform-origin: left bottom;
            @include blur(10px);

            .popup-container {
                display: flex;
                gap: 0 1px;
                width: getRem(825);
                height: getRem(493);

                .popup-box {
                    width: 50%;
                    height: 100%;
                    text-transform: uppercase;

                    &.box-left {
                        .box-left-main {
                            border-radius: 10px;
                            overflow: hidden;
                            transform: translateZ(0);

                            .video-box {
                                position: relative;
                                width: 100%;
                                height: getRem(233.33);
                                video {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 110%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                            .main-title-box {
                                position: relative;
                                padding: getRem(24);
                                background-color: #1e1e1e;
                                color: #fff;
                                height: getRem(205);
                                box-sizing: border-box;
                                .type-animation-first {
                                    font-family: $font-en;
                                    @include fontStyle(26px, 300, 31.2px, -0.02em);
                                    width: 100%;
                                    &::after {
                                        content: "";
                                        animation: none;
                                    }
                                }
                                .type-animation-sec {
                                    @include fontStyle(15px, 300, 22.5px, -0.05em);
                                    font-family: $font-ko;
                                    margin-top: 13px;
                                    &::after {
                                        content: "";
                                        animation: none;
                                    }
                                }

                                .sns-box {
                                    position: absolute;
                                    right: getRem(24);
                                    bottom: getRem(24);
                                    display: flex;
                                    justify-content: end;
                                    gap: 0 10px;
                                    height: getRem(36);
                                    li {
                                        width: getRem(36);
                                        height: 100%;
                                    }
                                }
                            }
                        }
                        .contact-info {
                            display: flex;
                            gap: 0 1px;
                            width: 100%;
                            text-align: center;
                            margin-top: 1px;
                            .info-box {
                                width: 50%;
                                font-family: $font-en;
                                @include fontStyle(15px, 300, 18px, -0.02em);
                                border-radius: 10px;
                                padding: getRem(18) 0;
                                a {
                                    color: #fff;
                                    text-decoration: none;
                                }
                                &.tel {
                                    background-color: #007dee;
                                }
                                &.email {
                                    text-transform: lowercase;
                                    background-color: #626262;
                                }
                            }
                        }
                    }
                    &.box-right {
                        border-radius: 10px;
                        overflow: hidden;
                        transform: translateZ(0);

                        .popup-header {
                            @include popupHeader();

                            p {
                                img {
                                    width: getRem(13);
                                    height: getRem(13);
                                }
                            }
                        }
                        .popup-main {
                            height: getRem(465);
                            overflow-y: auto;
                            padding: getRem(20);
                            background-color: rgba(30, 30, 30, 1);
                            box-sizing: border-box;
                            padding-bottom: getRem(30);

                            &::-webkit-scrollbar {
                                width: 12px; /* 스크롤바의 너비 */
                            }

                            &::-webkit-scrollbar-thumb {
                                border-radius: 3px;
                                background-color: #6f6f6f;
                                background-clip: padding-box;
                                border: 4px solid transparent;
                            }

                            &::-webkit-scrollbar-track {
                                background: transparent; /*스크롤바 뒷 배경 색상*/
                            }

                            .contact-question-wrap {
                                text-align: right;
                                .chat-time {
                                    display: block;
                                    font-family: $font-en;
                                    @include fontStyle(11px, 300, 13.2px, -0.02em);
                                    color: rgba(201, 201, 201, 0.87);
                                }
                                .chat-question {
                                    display: inline-block;
                                    padding: getRem(10) getRem(20);
                                    background-color: rgba(0, 125, 238, 1);
                                    color: rgba(255, 255, 255, 1);
                                    font-family: $font-ko;
                                    @include fontStyle(15px, 300, 22.5px, -0.05em);
                                    border-radius: 22px;
                                    margin-left: auto;
                                    margin-top: getRem(8);
                                    margin-bottom: getRem(15);

                                    a {
                                        color: #fff;
                                        text-transform: none;
                                    }
                                }
                            }
                            .list-btn-wrap {
                                .list-btn-box {
                                    .list-btn-input {
                                        width: 0;
                                        height: 0;
                                        margin: 0;
                                        opacity: 0;

                                        &:checked + label {
                                            background-color: rgba(255, 255, 255, 1);
                                            color: rgba(0, 0, 0, 1);
                                            border-color: rgba(255, 255, 255, 1);
                                        }
                                    }
                                    .list-btn-label {
                                        display: inline-block;
                                        cursor: pointer;
                                        padding: getRem(7) getRem(20);
                                        background: transparent;
                                        border-radius: 19px;
                                        border: 1px solid rgba(75, 75, 75, 1);
                                        color: rgba(255, 255, 255, 1);
                                        @include fontStyle(15px, 300, 22.5px, -0.05em);
                                        font-family: $font-ko;

                                        strong {
                                            font-weight: 700;
                                        }
                                        img {
                                            width: getRem(15);
                                            height: getRem(15);
                                            display: inline-block;
                                            vertical-align: middle;
                                            transform: translateY(-5%);
                                        }
                                        &:hover {
                                            border-color: rgba(255, 255, 255, 1);
                                        }
                                    }
                                }
                            }
                            .default-wrap {
                                margin-bottom: getRem(25);
                                .contact-question-wrap {
                                    opacity: 0;
                                    transform: translateY(30%);
                                    transition:
                                        opacity 0.5s,
                                        transform 0.5s;
                                }
                                .service-list-wrap {
                                    opacity: 0;
                                    transform: translateY(30%);
                                    transition: all 0.6s;
                                    .list-btn-wrap {
                                        .list-btn-box {
                                            margin-bottom: getRem(10);

                                            &:nth-of-type(5) {
                                                .list-btn-label {
                                                    img {
                                                        transform: translateY(-15%);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &.on {
                                    .contact-question-wrap {
                                        opacity: 1;
                                        transform: translateY(0);
                                    }
                                    .service-list-wrap {
                                        opacity: 1;
                                        transform: translateY(0);
                                        transition-delay: 0.3s;
                                    }
                                }
                            }

                            .budget-list,
                            .open-day-list {
                                .list-btn-wrap {
                                    display: flex;
                                    flex-wrap: wrap;
                                    gap: getRem(10) getRem(6);
                                }
                            }
                            .project-budget {
                                margin-bottom: getRem(25);

                                &.on {
                                    .contact-question-wrap {
                                        opacity: 1;
                                        transform: translateY(0);
                                        transition-delay: 0.2s;
                                    }
                                    .budget-list {
                                        opacity: 1;
                                        transform: translateY(0);
                                        transition-delay: 0.5s;
                                    }
                                }
                                .contact-question-wrap,
                                .budget-list {
                                    transform: translateY(30%);
                                    opacity: 0;
                                    transition: all 0.5s;
                                }
                            }
                            .project-open-day {
                                margin-bottom: getRem(25);
                                &.on {
                                    .contact-question-wrap {
                                        opacity: 1;
                                        transform: translateY(0);
                                        transition-delay: 0.2s;
                                    }
                                    .open-day-list {
                                        opacity: 1;
                                        transform: translateY(0);
                                        transition-delay: 0.5s;
                                    }
                                }
                                .contact-question-wrap,
                                .open-day-list {
                                    transform: translateY(30%);
                                    opacity: 0;
                                    transition: all 0.5s;
                                }
                            }
                            .info-box {
                                .contact-question-wrap {
                                    opacity: 0;
                                    transform: translateY(30%);
                                    transition: all 0.5s;
                                }
                                .info-input-wrap {
                                    opacity: 0;
                                    transform: translateY(30%);
                                    transition: all 0.5s;
                                    .input-box {
                                        width: 100%;
                                        margin-bottom: getRem(10);

                                        .info-input {
                                            width: 100%;
                                            box-sizing: border-box;
                                            @include fontStyle(15px, 300, 22.5px, -0.05em);
                                            border-radius: 18px;
                                            padding: getRem(7) getRem(15);
                                            border: 1px solid rgba(75, 75, 75, 1);
                                            background: transparent;
                                            color: rgba(255, 255, 255, 1);
                                            outline: none;
                                            transition:
                                                background 0.2s,
                                                color 0.2s;
                                            font-family: $font-ko;

                                            &.error {
                                                color: #ff0000;
                                                border-color: #ff0000;
                                                &:focus {
                                                    border-color: #ff0000;
                                                }
                                            }
                                            &.on {
                                                background-color: #ffffff;
                                                color: #000000;
                                            }
                                            &:focus {
                                                border-color: rgba(255, 255, 255, 1);
                                            }
                                            &::placeholder {
                                                color: rgba(255, 255, 255, 1);
                                            }
                                        }
                                        &.file-box {
                                            .file-input {
                                                display: none;
                                            }
                                            .file-label {
                                                display: flex;
                                                justify-content: space-between;
                                                align-items: center;
                                                width: 100%;
                                                box-sizing: border-box;
                                                border-radius: 18px;
                                                padding: getRem(7) getRem(15);
                                                border: 1px solid rgba(75, 75, 75, 1);
                                                background: transparent;

                                                &.upload {
                                                    background-color: #ffffff;
                                                    border-color: #ffffff;

                                                    span {
                                                        color: #000;
                                                    }
                                                    .delete-btn {
                                                        display: block;
                                                    }
                                                }
                                                &.error {
                                                    border-color: #ff0000;
                                                    span {
                                                        color: #ff0000;
                                                    }
                                                    & + .info-text {
                                                        color: #ff0000;
                                                    }
                                                }

                                                span {
                                                    color: rgba(255, 255, 255, 1);
                                                    @include fontStyle(15px, 300, 22.5px, -0.05em);
                                                }
                                                .delete-btn {
                                                    display: none;
                                                    background: transparent;
                                                    border: 0;
                                                    padding: 0;
                                                    cursor: pointer;
                                                }
                                            }
                                        }
                                        .textarea {
                                            width: 100%;
                                            height: getRem(130);
                                            border-radius: 18px;
                                            border: 1px solid #4b4b4b;
                                            background: transparent;
                                            padding: getRem(7) getRem(15);
                                            box-sizing: border-box;
                                            resize: none;
                                            font-family: $font-ko;
                                            @include fontStyle(15px, 300, 22.5px, -0.05em);
                                            color: rgba(255, 255, 255, 1);
                                            outline: none;

                                            &:focus {
                                                border-color: rgba(255, 255, 255, 1);
                                            }
                                            &::placeholder {
                                                color: rgba(255, 255, 255, 1);
                                            }
                                            &.on {
                                                background-color: #ffffff;
                                                color: #000000;
                                            }
                                        }
                                        .info-text {
                                            display: block;
                                            @include fontStyle(11px, 300, 16.5px, -0.05em);
                                            color: rgba(201, 201, 201, 1);
                                            text-align: right;
                                            margin-top: 3px;
                                            padding-right: 15px;
                                        }
                                        &.check-input-box {
                                            input {
                                                display: none;
                                                width: 0;

                                                &.on {
                                                    + label {
                                                        svg {
                                                            fill: #fff;
                                                            rect {
                                                                stroke: #fff;
                                                            }
                                                            path {
                                                                fill: #000;
                                                            }
                                                        }
                                                    }
                                                }

                                                &.error {
                                                    & + label {
                                                        svg {
                                                            rect {
                                                                stroke: #ff0000;
                                                            }
                                                            path {
                                                                fill: #ff0000;
                                                            }
                                                        }
                                                        span {
                                                            color: #ff0000;
                                                        }
                                                    }
                                                }
                                            }
                                            label {
                                                display: flex;
                                                align-items: center;
                                                span {
                                                    font-family: $font-ko;
                                                    @include fontStyle(12px, 300, 18px, -0.05em);
                                                    color: #fff;
                                                    display: inline-block;
                                                    margin-left: getRem(10);

                                                    .policy-btn {
                                                        font-family: inherit;
                                                        @include fontStyle(12px, 300, 18px, -0.05em);
                                                        color: inherit;
                                                        background-color: transparent;
                                                        border: none;
                                                        outline: none;
                                                        text-decoration: underline;
                                                        padding: 0;
                                                        cursor: pointer;
                                                    }
                                                }

                                                &:hover {
                                                    svg {
                                                        rect {
                                                            stroke: #fff;
                                                        }
                                                        path {
                                                            fill: #fff;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &.on {
                                    .contact-question-wrap {
                                        opacity: 1;
                                        transform: translateY(0);
                                        transition-delay: 0.2s;
                                    }
                                    .info-input-wrap {
                                        opacity: 1;
                                        transform: translateY(0);
                                        transition-delay: 0.5s;
                                    }
                                }
                            }
                            .submit-box {
                                margin-bottom: 1.5625rem;

                                .submit-btn {
                                    font-family: $font-ko;
                                    width: 100%;
                                    background: transparent;
                                    border-radius: 18px;
                                    @include fontStyle(20px, 300, 30px, -0.05em);
                                    color: rgba(255, 255, 255, 1);
                                    padding: getRem(19.5) getRem(20);
                                    border: 1px solid rgba(75, 75, 75, 1);
                                    text-align: center;
                                    cursor: pointer;

                                    &:hover,
                                    &.active {
                                        color: rgba(0, 0, 0, 1);
                                        background-color: #ffffff;
                                    }
                                    &:disabled {
                                        color: #5c5c5c;
                                        background-color: #2b2b2b;
                                        cursor: text;
                                    }
                                }
                            }
                            .last-box {
                                .chat-question {
                                    &:first-of-type {
                                        margin-bottom: getRem(8);
                                    }
                                    &:last-of-type {
                                        margin-top: 0;
                                    }
                                    img {
                                        width: getRem(15);
                                        height: getRem(15);
                                    }
                                }
                            }
                            .loading-animation {
                                opacity: 0;
                                position: absolute;
                                bottom: getRem(22px);
                                right: getPercent(352, 30);
                                transition: opacity 0.3s;
                                &.load {
                                    opacity: 1;
                                }

                                .indicator {
                                    display: flex;
                                    width: getRem(30);
                                    height: getRem(6);
                                    justify-content: space-between;
                                    margin-left: auto;

                                    span {
                                        display: block;
                                        width: getRem(6);
                                        border-radius: 50%;
                                        background-color: #cecece;
                                        animation: loadingAni 1.4s infinite forwards;

                                        &:nth-of-type(2) {
                                            animation-delay: 0.2s;
                                        }
                                        &:nth-of-type(3) {
                                            animation-delay: 0.4s;
                                        }
                                    }

                                    @keyframes loadingAni {
                                        0%,
                                        80%,
                                        100% {
                                            transform: translateY(0);
                                        }
                                        40% {
                                            transform: translateY(-100%);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .Loading {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #000;
            z-index: 999;
            transition: opacity 0.5s;

            .bg {
                position: absolute;
                height: 100%;
                background-color: #fff;
                transition: width 0.2s;
            }

            .content {
                position: relative;
                z-index: 1;
                mix-blend-mode: difference;
                height: 100%;
                color: #fff;

                .text {
                    position: absolute;
                    top: 0;
                    left: 0;
                    margin-top: getPercent(1920, 46);
                    margin-left: getPercent(1920, 49);
                    width: 100%;
                    > p {
                        width: fit-content;
                        @include fontStyle(24px, 300, 31.2px, -0.48px);
                        text-transform: uppercase;
                    }

                    > small {
                        display: block;
                        width: getPercent(1920, 372);
                        @include fontStyle(12px, 300, 15.6px, -0.24px);
                        text-transform: uppercase;
                        margin-left: getPercent(1920, 157);
                        margin-top: getPercent(1920, 24);
                    }

                    span {
                        display: block;
                        &.center {
                            text-align: center;
                        }

                        &.right {
                            text-align: right;
                        }
                    }
                }
                .progress {
                    position: absolute;
                    bottom: getVw(424px);
                    right: 0;
                    margin-right: getPercent(1920, 596);
                    @include fontStyle(90px, 300, 117px, -1.8px);
                }

                .global-logo {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: getVw(14px);

                    > div {
                        overflow: hidden;
                        svg {
                            transform: translateX(-100%);
                            transition: transform 2s;
                        }
                    }
                }
            }
        }

        .Transition {
            display: flex;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 99;

            .dim {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                background-color: #000;
                opacity: 0;
            }

            .bar {
                position: relative;
                z-index: 1;
                flex: 1 1;
                width: calc(100% / 5);
                height: 100%;
                background-color: black;
                transform: translate(0, -100%);
            }
        }

        .Typography {
            font-family: $font-en;
            @include fontStyle(130px, 300, 182px);
            font-size: getVw(130px);
            color: #fff;
            text-transform: uppercase;
            top: 0;
            &.typo-ani {
                display: inline-block;
                overflow: hidden;
                > .text-wrap {
                    display: inline-block;
                    white-space: nowrap;
                    opacity: 0;
                    transform-origin: left top;
                }
            }
        }
        .RandomTypo {
            span {
                font-family: $font-en;
                opacity: 0;
            }
        }

        *[class^="small-msg"] {
            color: #fff;
            width: getVw(261px);
            text-transform: uppercase;
            display: flex;
            flex-direction: column;

            .flex-column {
                display: inline-flex;
                flex-direction: column;
                .right {
                    align-self: flex-end;
                }
            }
            .en {
                .Typography {
                    font-family: $font-en;
                    @include fontStyle(18px, 300, 23px, -0.02em);
                    font-size: getVw(18px);
                }
            }

            .ko {
                margin-top: getRem(26);

                .Typography {
                    @include fontStyle(20px, 300, 26px, -0.05em);
                    font-size: getVw(20px);
                }
            }

            .typo-ani {
                .text-wrap {
                    transform: translate3d(0px, 250%, 0px) rotate(16deg);
                }
            }
        }

        .Tag {
            position: absolute;
            transform: translate(-50%, -50%);
            padding: getRem(14) getRem(15);
            @include fontStyle(10px, 300, 13px, 0);
            font-family: $font-en;
            border-radius: 21px;
            background: linear-gradient(#616161, rgba(105, 105, 105, 0.5));
            border: 0.5px solid rgba(97, 97, 97, 1);
            color: #fff;
            @include blur(10px);

            &.innovative {
                bottom: getRem(70);
                left: getRem(340);
            }
            &.creative {
                top: getRem(110);
                left: getRem(775);
            }
            &.confidence {
                bottom: getRem(70);
                right: getRem(1560);
            }
            &.experience {
                top: getRem(170);
                right: getRem(1160);
            }
        }

        .Privacy {
            display: none;
            position: fixed;
            z-index: 99;
            bottom: getRem(143.65);
            left: 0;
            margin-left: getPercent(1920, 645);
            border-radius: 10px;
            overflow: hidden;
            width: getPercent(1920, 630);
            cursor: pointer;

            &.up-index {
                z-index: 100;
            }
            .popup-header {
                @include popupHeader();
                p {
                    text-shadow: 0 1px 3px #00000080;
                }
            }
            .privacy-main {
                height: getRem(504);
                padding: getRem(10) getRem(10) getRem(29);
                background: #1e1e1e;
                color: #e9e9e9;
                @include fontStyle(12px, 300, 16.8px, -0.06em);
                font-family: $font-ko;
                overflow-y: scroll;
                h4 {
                    margin-bottom: getRem(5);
                }
                .mb-5 {
                    margin-bottom: getRem(5);
                }
                .list {
                    margin-top: getRem(20);
                }
                .link-text {
                    color: #888888;
                    text-decoration: none;
                }
                button.link-text {
                    border: none;
                    background: transparent;
                    display: inline-block;
                    padding: 0;
                    @include fontStyle(12px, 300, 16.8px, -0.06em);
                    cursor: pointer;
                }
                &::-webkit-scrollbar {
                    width: 12px; /* 스크롤바의 너비 */
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 3px;
                    background-color: #6f6f6f;
                    background-clip: padding-box;
                    border: 4px solid transparent;
                }

                &::-webkit-scrollbar-track {
                    background: transparent; /*스크롤바 뒷 배경 색상*/
                }
            }
        }
        // (E) component
    }

    // main, loading 페이지에서 사용
}

@media all and (min-width: 2000px) {
    html,
    body {
        font-size: 17px !important;
    }
}
@media all and (max-width: 1600px) {
    html,
    body {
        font-size: 15px !important;
    }
}
@media all and (max-width: 1400px) {
    html,
    body {
        font-size: 13px !important;
    }
}
@media all and (max-width: 1300px) {
    html,
    body {
        font-size: 12px !important;
    }
}
@media all and (max-width: 600px) {
    html,
    body {
        font-size: 16px !important;
    }
}
@media all and (max-width: 389px) {
    html,
    body {
        font-size: 15px !important;
    }
}
@media all and (max-width: 360px) {
    html,
    body {
        font-size: 14px !important;
    }
}
